import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { Button, Spinner } from 'react-bootstrap';
import AddSalesTeamModal from './AddSalesTeamModal';
import api from '../../../api';
import { useAuth0 } from '@auth0/auth0-react'; // Import useAuth0
import { ReactComponent as PersonCircleIcon } from '../../../icons/person-circle.svg';

import './sales-team.css';

function SalesTeam({ oagentId }) {
  const { getAccessTokenSilently } = useAuth0(); // Get the auth0 hook

  const [profiles, setProfiles] = useState([]);
  const [loading, setLoading] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const [modalType, setModalType] = useState(''); // For setting SDR or AE

  // API fetch function
  const fetchProfiles = async () => {
    setLoading(true); // Ensure loading state is reset
    try {
      const token = await getAccessTokenSilently();
      const response = await api.get(`/oagents/${oagentId}/profiles`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      // We have to filter in this way as this is how we delete a profile from association with the oagent, unsetting is_ae and is_sdr
      setProfiles(response.data.filter((x) => x.is_ae || x.is_sdr));
    } catch (error) {
      console.error('Error fetching profiles:', error);
    } finally {
      setLoading(false);
    }
  };

  // Initial fetch
  useEffect(() => {
    fetchProfiles();
  }, []);

  const removeProfile = async (profileId, role) => {
    try {
      const token = await getAccessTokenSilently();
      await api.delete(`/oagents/${oagentId}/profile/${profileId}/${role}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setProfiles(profiles.filter((profile) => profile.id !== profileId));
    } catch (error) {
      console.error('Error removing profile:', error);
    }
  };

  const handleShowModal = (type) => {
    setModalType(type);
    setShowModal(true);
  };

  const handleCloseModal = () => setShowModal(false);

  const refreshTeam = () => {
    // Refresh team profiles after adding or removing profiles
    fetchProfiles();
  };

  return (
    <>
      <div className='campaign-sales-team'>
        <div className='cst-set'>
          <h6>Sending profiles</h6>
          {loading ? (
            <div className='loading-state'>
              <Spinner animation='border' />
            </div>
          ) : (
            <div className='cst-profiles-wrapper'>
              {profiles.length > 0 && (
                <div className='cst-profiles-list'>
                  {profiles
                    .filter((profile) => profile.is_sdr)
                    .map((profile) => (
                      <div className='profile' key={profile.id}>
                        <div className='left-side'>
                          {profile.profile_image_url && (
                            <img
                              src={profile.profile_image_url}
                              alt={profile.name}
                            />
                          )}
                          {!profile.profile_image_url && (
                            <div className='pseudo-pic'>
                              <PersonCircleIcon />
                            </div>
                          )}
                          <div className='mid-section'>
                            <h6>{profile.name}</h6>
                            <p>{profile.sdr_mode_email}</p>
                          </div>
                        </div>
                        <div className='remove-button-wrapper'>
                          <Button
                            variant='outline-primary'
                            onClick={() => removeProfile(profile.id, 'SDR')}
                          >
                            Remove
                          </Button>
                        </div>
                      </div>
                    ))}
                </div>
              )}
              <div className='button-wrapper add-button-wrapper'>
                <Button
                  variant='outline-primary'
                  onClick={() => handleShowModal('SDR')}
                >
                  Add team member <FontAwesomeIcon icon={faPlus} />
                </Button>
              </div>
            </div>
          )}
        </div>
        <div className='cst-set'>
          <h6>Meeting profiles</h6>
          {loading ? (
            <div className='loading-state'>
              <Spinner animation='border' />
            </div>
          ) : (
            <div className='cst-profiles-wrapper'>
              {profiles.length > 0 && (
                <div className='cst-profiles-list'>
                  {profiles
                    .filter((profile) => profile.is_ae)
                    .map((profile) => (
                      <div className='profile' key={profile.id}>
                        <div className='left-side'>
                          {profile.profile_image_url && (
                            <img
                              src={profile.profile_image_url}
                              alt={profile.name}
                            />
                          )}
                          {!profile.profile_image_url && (
                            <div className='pseudo-pic'>
                              <PersonCircleIcon />
                            </div>
                          )}
                          <div className='mid-section'>
                            <h6>{profile.name}</h6>
                            <p>{profile.ae_mode_email}</p>
                          </div>
                        </div>
                        <div className='remove-button-wrapper'>
                          <Button
                            variant='outline-primary'
                            onClick={() => removeProfile(profile.id, 'AE')}
                          >
                            Remove
                          </Button>
                        </div>
                      </div>
                    ))}
                </div>
              )}
              <div className='button-wrapper add-button-wrapper'>
                <Button
                  variant='outline-primary'
                  onClick={() => handleShowModal('AE')}
                >
                  Add team member <FontAwesomeIcon icon={faPlus} />
                </Button>
              </div>
            </div>
          )}
        </div>
      </div>
      {showModal && (
        <AddSalesTeamModal
          oagentId={oagentId}
          type={modalType}
          header={`Add ${modalType === 'SDR' ? 'contacting team member' : 'Meeting team member'}`}
          descriptionText={`Add members of your sales team to the ${modalType === 'SDR' ? 'prospect outreach' : 'meeting booking'} team.`}
          refreshTeam={refreshTeam}
          handleClose={handleCloseModal}
          show={showModal}
        />
      )}
    </>
  );
}

export default SalesTeam;
