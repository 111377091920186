import React, { createContext, useState, useContext, useEffect } from 'react';

const ProcessingContext = createContext();

export const ProcessingProvider = ({ children }) => {
  const [funnelStates, setFunnelStates] = useState({});
  const [funnelNames, setFunnelNames] = useState({});

  const setOldestProcessingJob = (funnelId, oldestJobs) => {
    setFunnelStates((prevStates) => ({
      ...prevStates,
      [funnelId]: {
        ...prevStates[funnelId],
        oldestProcessing: oldestJobs,
      },
    }));
  };

  const setFunnelName = (funnelId, name) => {
    setFunnelNames((prevStates) => ({
      ...prevStates,
      [funnelId]: name,
    }));
  };

  return (
    <ProcessingContext.Provider
      value={{
        funnelStates,
        setOldestProcessingJob,
        funnelNames,
        setFunnelName,
        setFunnelStates,
      }}
    >
      {children}
    </ProcessingContext.Provider>
  );
};

export const useProcessing = () => useContext(ProcessingContext);
