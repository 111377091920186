import React, { useState } from 'react';
import './visual-loading.css';
import { ReactComponent as RightCaretIcon } from '../../../icons/right-caret.svg';
import { ReactComponent as CheckCircleIcon } from '../../../icons/check-circle-filled.svg';
import HighlightedEmailBody from './HighlightedEmailBody';

const DraftAndEmailViewer = ({
  drafts,
  generatedEmail,
  generatedSubject,
  isNewThread,
  emailId,
  prospectId,
  oagentId,
}) => {
  const [toShow, setToShow] = useState('final');
  const draftsToShow = drafts.slice(0, -1); // The last draft is the generatedEmail

  const body = toShow === 'final' ? generatedEmail : draftsToShow[toShow].draft;
  const feedback =
    toShow === 'final' ? null : draftsToShow[toShow].supervisionNotes;

  return (
    <div className='show-active-draft in-final-viewer'>
      <div className='draft-which'>
        {draftsToShow.map((draft, index) => (
          <>
            <div
              className={`draft-label ${toShow === index ? 'visible' : 'done'}`}
              onClick={() => setToShow(index)}
            >
              <p>{`Email draft ${index + 1}`}</p>
            </div>
            <RightCaretIcon />
          </>
        ))}
        <div
          onClick={() => setToShow('final')}
          className={`draft-label final ${toShow === 'final' ? 'visible' : 'done'}`}
        >
          <CheckCircleIcon /> Final draft
        </div>
      </div>

      {body && (
        <div className='draft-wrapper'>
          <div className='draft-display'>
            <div>
              {isNewThread ? (
                <>
                  <b>Subject:</b> {generatedSubject}
                </>
              ) : (
                <i>Reply to existing thread</i>
              )}
            </div>
            <HighlightedEmailBody
              text={body}
              emailId={emailId}
              prospectId={prospectId}
              oagentId={oagentId}
              feedback={feedback}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default DraftAndEmailViewer;
