import React, { useState, useEffect } from 'react';
import { Button, Form, Spinner, Modal } from 'react-bootstrap';
import { marked } from 'marked';
import api from '../../api';
import { useAuth0 } from '@auth0/auth0-react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import GeneratedEmailStatus from './GeneratedEmailStatus';
import { StatusProvider } from './StatusProvider'; // Import the provider
import DreamSideModal from '../misc/DreamSideModal';
import DreamModalInner from '../misc/DreamModalInner';
import { ReactComponent as FollowUpIcon } from '../../icons/follow-up.svg';
import { ReactComponent as NewThreadIcon } from '../../icons/new-thread.svg';
import { ReactComponent as PersonCircleIcon } from '../../icons/person-circle.svg';
import { ReactComponent as RegenerateIcon } from '../../icons/regenerate.svg';

const GeneratedEmails = ({
  oagentId,
  prospectId,
  sequence,
  profiles,
  selectedProfile,
  setSelectedProfile,
}) => {
  const { getAccessTokenSilently } = useAuth0();
  const [loadingData, setLoadingData] = useState(false);
  const [loadingEmailIds, setLoadingEmailIds] = useState([]);
  const [generatedEmails, setGeneratedEmails] = useState([]);
  const [editedEmailsContent, setEditedEmailsContent] = useState({});
  const [editedEmailsSubject, setEditedEmailsSubject] = useState({});
  const [showAlternatesModal, setShowAlternatesModal] = useState(false);
  const [bestEmail, setBestEmail] = useState(null);
  const [currentEmailId, setCurrentEmailId] = useState(null);
  const [alternateEmails, setAlternateEmails] = useState([]);

  const fetchData = async () => {
    try {
      const token = await getAccessTokenSilently();
      setLoadingData(true);

      const emailsResponse = await api.get(
        `/oagents/${oagentId}/prospect/${prospectId}/emails`,
        {
          headers: { Authorization: `Bearer ${token}` },
        },
      );
      setGeneratedEmails(emailsResponse.data.generatedEmails);
    } catch (error) {
      console.error('Error fetching generated emails:', error);
    } finally {
      setLoadingData(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, [oagentId, prospectId, getAccessTokenSilently]);

  const handleSaveGeneratedEmail = async (emailId, content, subject) => {
    setLoadingEmailIds((prev) => [...prev, emailId]);
    try {
      const token = await getAccessTokenSilently();
      await api.put(
        `/oagents/generated-email/${emailId}`,
        { content, subject },
        {
          headers: { Authorization: `Bearer ${token}` },
        },
      );
      setGeneratedEmails((prev) =>
        prev.map((email) =>
          email.id === emailId ? { ...email, content, subject } : email,
        ),
      );
    } catch (error) {
      console.error('Error saving generated email:', error);
    } finally {
      setLoadingEmailIds((prev) => prev.filter((id) => id !== emailId));
    }
  };

  const handleNewGeneratedEmailSelect = async ({
    id,
    text,
    subject,
    profile_id,
  }) => {
    const token = await getAccessTokenSilently();
    setLoadingData(true);

    if (!selectedProfile) {
      await api.put(
        `/oagents/oagent-to-email-prospect/${oagentId}/profile/${profile_id}`,
        { prospect_id: prospectId },
        {
          headers: { Authorization: `Bearer ${token}` },
        },
      );
      setSelectedProfile(profile_id);
    }

    await api.post(
      `/oagents/generated-email/${id}`,
      {
        prospect_id: prospectId,
        content: text,
        subject: subject,
        profile_id: profile_id,
        oagent_id: oagentId,
      },
      {
        headers: { Authorization: `Bearer ${token}` },
      },
    );

    const emailsResponse = await api.get(
      `/oagents/${oagentId}/prospect/${prospectId}/emails`,
      {
        headers: { Authorization: `Bearer ${token}` },
      },
    );
    setGeneratedEmails(emailsResponse.data.generatedEmails);
    setLoadingData(false);
    setShowAlternatesModal(false);
  };

  const handleGenerateEmail = async (
    emailId,
    regenerate = false,
    save_over_email_id = null,
  ) => {
    setLoadingEmailIds((prev) => [...prev, emailId]);
    try {
      const token = await getAccessTokenSilently();
      const { data } = await api.post(
        `/oagents/${oagentId}/emails/${emailId}`,
        { prospect_id: prospectId },
        {
          headers: { Authorization: `Bearer ${token}` },
        },
      );
      const requestId = data.requestId;
      pollForEmailGenerationResult(
        requestId,
        emailId,
        regenerate,
        save_over_email_id,
      );
    } catch (error) {
      console.error('Error initiating email generation:', error);
    }
  };

  const pollForEmailGenerationResult = async (
    requestId,
    emailId,
    regenerate,
    save_over_email_id,
  ) => {
    try {
      const token = await getAccessTokenSilently();

      const interval = setInterval(async () => {
        const response = await api.get(
          `/oagents/email-generate-status/${requestId}`,
          {
            headers: { Authorization: `Bearer ${token}` },
          },
        );

        const { status, result, error } = response.data;

        if (status === 'complete') {
          clearInterval(interval);

          if (result.alternateEmails.length > 0) {
            setBestEmail(result.bestEmail);
            setAlternateEmails(result.alternateEmails);
            setCurrentEmailId(emailId);
            setShowAlternatesModal(true);
          } else {
            if (!regenerate) {
              await handleNewGeneratedEmailSelect({
                id: emailId,
                ...result.bestEmail,
              });
            } else {
              await handleSaveGeneratedEmail(
                save_over_email_id,
                result.bestEmail?.text,
                result.bestEmail?.subject,
              );
              fetchData();
            }
          }

          setLoadingEmailIds((prev) => prev.filter((id) => id !== emailId));
        } else if (status === 'error') {
          clearInterval(interval);
          console.error('Error generating email: ', error);
          setLoadingEmailIds((prev) => prev.filter((id) => id !== emailId));
        }
      }, 2000); // Poll every 2 seconds
    } catch (error) {
      console.error('Error polling for email generation result:', error);
      setLoadingEmailIds((prev) => prev.filter((id) => id !== emailId));
    }
  };

  if (loadingData) {
    return (
      <div className='empty-state'>
        <Spinner />
      </div>
    );
  }

  // Logic to find the first active email in sequence that hasn't been sent
  const activeEmails = sequence.emails.filter((email) => email.active);
  const unsentEmail = activeEmails.find(
    (email) =>
      !generatedEmails.some((ge) => ge.email_id === email.id && ge.sent),
  );

  const bestEmailProfile = profiles.find((x) => x.id === bestEmail?.profile_id);
  return (
    <StatusProvider>
      <div className='outreach-section'>
        {unsentEmail && (
          <>
            <div className='header'>
              {unsentEmail.email_order === 0 && (
                <h5>
                  <NewThreadIcon /> Initial email
                </h5>
              )}
              {unsentEmail.email_order > 0 && unsentEmail.is_new_thread && (
                <h5>
                  <NewThreadIcon /> Follow-up - New Thread (Email #
                  {unsentEmail.email_order + 1})
                </h5>
              )}
              {unsentEmail.email_order > 0 && !unsentEmail.is_new_thread && (
                <h5>
                  <FollowUpIcon /> Follow-up (Email #
                  {unsentEmail.email_order + 1})
                </h5>
              )}
              {generatedEmails.some((ge) => ge.email_id === unsentEmail.id) && (
                <div className='email-status-wrapper'>
                  <GeneratedEmailStatus
                    oagentId={oagentId}
                    prospectId={prospectId}
                    onSend={fetchData}
                    generatedEmailId={
                      generatedEmails.find(
                        (ge) => ge.email_id === unsentEmail.id,
                      ).generated_email_id
                    }
                  />
                </div>
              )}
              {!generatedEmails.some(
                (ge) => ge.email_id === unsentEmail.id,
              ) && (
                <div className='generate-button-wrapper campaigns'>
                  <Button
                    variant='primary'
                    onClick={() => handleGenerateEmail(unsentEmail.id)}
                    disabled={
                      loadingEmailIds.includes(unsentEmail.id) ||
                      !profiles.length
                    }
                  >
                    {loadingEmailIds.includes(unsentEmail.id) ? (
                      <>
                        <Spinner as='span' animation='border' size='sm' />
                        Generating
                      </>
                    ) : (
                      <>
                        Generate <FontAwesomeIcon icon={faPlus} />
                      </>
                    )}
                  </Button>
                </div>
              )}
            </div>
            <hr />
            {!generatedEmails.some((ge) => ge.email_id === unsentEmail.id) && (
              <div className='pre-generated-wrapper'>
                {loadingEmailIds.includes(unsentEmail.id) && (
                  <div className='overlay'>
                    <Spinner as='span' animation='border' />
                  </div>
                )}
                {unsentEmail.is_new_thread && (
                  <div className='guidance-wrapper'>
                    <p>Subject</p>
                    <div className='guidance subject'>
                      {unsentEmail.subject_type === 'agent_generate'
                        ? unsentEmail.subject_value
                          ? `${unsentEmail.subject_value}`
                          : 'None'
                        : `Use exact subject: "${unsentEmail.subject_value}"`}
                    </div>
                  </div>
                )}
                <div className='guidance-wrapper'>
                  <p>Message</p>
                  <div
                    className='guidance body'
                    dangerouslySetInnerHTML={{
                      __html: marked(unsentEmail.contents),
                    }}
                  />
                </div>
              </div>
            )}
            {generatedEmails.some((ge) => ge.email_id === unsentEmail.id) && (
              <div className='email-editor-outer-wrapper'>
                {loadingEmailIds.includes(unsentEmail.id) && (
                  <div className='overlay'>
                    <Spinner as='span' animation='border' />
                  </div>
                )}
                <h6>
                  Generated email
                  <div
                    className='regenerate'
                    onClick={() =>
                      handleGenerateEmail(
                        unsentEmail.id,
                        true,
                        generatedEmails.find(
                          (ge) => ge.email_id === unsentEmail.id,
                        ).generated_email_id,
                      )
                    }
                  >
                    <RegenerateIcon />
                    <span>Regenerate</span>
                  </div>
                </h6>
                <Form.Group
                  className='edit-email dream-side-modal-form'
                  controlId={`generated-email-${unsentEmail.id}`}
                >
                  {unsentEmail.is_new_thread && (
                    <Form.Group
                      className='subject-editor'
                      controlId={`generated-email-subject-${unsentEmail.id}`}
                    >
                      <Form.Label>Subject</Form.Label>
                      <Form.Control
                        type='text'
                        defaultValue={
                          generatedEmails.find(
                            (ge) => ge.email_id === unsentEmail.id,
                          ).subject
                        }
                        disabled={loadingEmailIds.includes(unsentEmail.id)}
                        size='lg'
                        onChange={(e) =>
                          setEditedEmailsSubject({
                            ...editedEmailsSubject,
                            [unsentEmail.id]: e.target.value,
                          })
                        }
                      />
                    </Form.Group>
                  )}
                  <Form.Group>
                    <Form.Label>Message</Form.Label>
                    <Form.Control
                      as='textarea'
                      defaultValue={
                        generatedEmails.find(
                          (ge) => ge.email_id === unsentEmail.id,
                        ).content
                      }
                      disabled={loadingEmailIds.includes(unsentEmail.id)}
                      onChange={(e) =>
                        setEditedEmailsContent({
                          ...editedEmailsContent,
                          [unsentEmail.id]: e.target.value,
                        })
                      }
                    />
                  </Form.Group>
                  <div className='save-button-wrapper'>
                    <Button
                      variant='primary'
                      onClick={() =>
                        handleSaveGeneratedEmail(
                          generatedEmails.find(
                            (ge) => ge.email_id === unsentEmail.id,
                          ).generated_email_id,
                          editedEmailsContent[unsentEmail.id] ||
                            generatedEmails.find(
                              (ge) => ge.email_id === unsentEmail.id,
                            ).content,
                          editedEmailsSubject[unsentEmail.id] ||
                            generatedEmails.find(
                              (ge) => ge.email_id === unsentEmail.id,
                            ).subject,
                        )
                      }
                      disabled={loadingEmailIds.includes(
                        generatedEmails.find(
                          (ge) => ge.email_id === unsentEmail.id,
                        ).generated_email_id,
                      )}
                    >
                      {loadingEmailIds.includes(
                        generatedEmails.find(
                          (ge) => ge.email_id === unsentEmail.id,
                        ).generated_email_id,
                      ) ? (
                        <>
                          <Spinner as='span' animation='border' size='sm' />
                          Saving
                        </>
                      ) : (
                        'Save changes'
                      )}
                    </Button>
                  </div>
                </Form.Group>
              </div>
            )}
          </>
        )}

        {!unsentEmail && (
          <div className='empty-state'>
            <p>You have sent all emails in sequence to this prospect.</p>
          </div>
        )}

        {showAlternatesModal && (
          <DreamSideModal
            show={showAlternatesModal}
            onHide={() => setShowAlternatesModal(false)}
          >
            <DreamModalInner>
              <DreamModalInner.Header>Choose Email</DreamModalInner.Header>
              <DreamModalInner.Body>
                <div className='email-options-select'>
                  <p>
                    Choose your preferred sender from senders involved in this
                    campaign.
                  </p>
                  {bestEmail && (
                    <div className='email-option best'>
                      <div className='header'>
                        <h6>
                          {bestEmailProfile.profile_image_url && (
                            <img
                              src={bestEmailProfile.profile_image_url}
                              alt={bestEmailProfile.name}
                            />
                          )}
                          {!bestEmailProfile.profile_image_url && (
                            <div className='pseudo-pic'>
                              <PersonCircleIcon />
                            </div>
                          )}
                          {bestEmailProfile.name}
                        </h6>
                        <Button
                          variant='primary'
                          onClick={() =>
                            handleNewGeneratedEmailSelect({
                              id: currentEmailId,
                              text: bestEmail.text,
                              subject: bestEmail.subject,
                              profile_id: bestEmail.profile_id,
                            })
                          }
                        >
                          Choose This Email
                        </Button>
                      </div>
                      <hr />
                      <div
                        className='body'
                        dangerouslySetInnerHTML={{
                          __html: marked(bestEmail.text).replace(
                            /\n/g,
                            '<br />',
                          ),
                        }}
                      />
                    </div>
                  )}
                  {alternateEmails.map((email, index) => {
                    const profile = profiles.find(
                      (x) => x.id === email.profile_id,
                    );
                    return (
                      <div key={index} className='email-option alternate'>
                        <div className='header'>
                          <h6>
                            {profile.profile_image_url && (
                              <img
                                src={profile.profile_image_url}
                                alt={profile.name}
                              />
                            )}
                            {!profile.profile_image_url && (
                              <div className='pseudo-pic'>
                                <PersonCircleIcon />
                              </div>
                            )}
                            {profile.name}
                          </h6>
                          <Button
                            variant='primary'
                            onClick={() =>
                              handleNewGeneratedEmailSelect({
                                id: currentEmailId,
                                text: email.text,
                                subject: email.subject,
                                profile_id: email.profile_id,
                              })
                            }
                          >
                            Choose This Email
                          </Button>
                        </div>
                        <hr />
                        <div
                          className='body'
                          dangerouslySetInnerHTML={{
                            __html: marked(email.text).replace(/\n/g, '<br />'),
                          }}
                        />
                      </div>
                    );
                  })}
                </div>
              </DreamModalInner.Body>
            </DreamModalInner>
          </DreamSideModal>
        )}
      </div>
    </StatusProvider>
  );
};

export default GeneratedEmails;
