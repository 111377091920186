import React, { useState, useEffect, useContext } from 'react';
import { Alert, Button, Spinner, Modal, Form } from 'react-bootstrap';
import api from '../../api';
import { useAuth0 } from '@auth0/auth0-react';
import ConfirmModal from '../confirm-modals/ConfirmModal';
import { StatusContext } from './StatusProvider';
import './prospects.css';
import { ReactComponent as EmailSendIcon } from '../../icons/email-send.svg';

const GeneratedEmailStatus = ({
  oagentId,
  prospectId,
  generatedEmailId,
  onSend,
}) => {
  const { getAccessTokenSilently } = useAuth0();
  const [loading, setLoading] = useState(true);
  const [sending, setSending] = useState(false);
  const [status, setStatus] = useState({});
  const [error, setError] = useState('');
  const [showConfirmSendModal, setShowConfirmSendModal] = useState(false);
  const [showScheduleModal, setShowScheduleModal] = useState(false);
  const [showConfirmUnscheduleModal, setShowConfirmUnscheduleModal] =
    useState(false);

  // Initialize default schedule date and time in local time zone
  const getDefaultScheduleDateTime = () => {
    const now = new Date();
    const localDateTime = new Date(now.setMinutes(now.getMinutes() + 1)); // Default to 1 minute from now in local time

    const scheduleDate = localDateTime.toISOString().split('T')[0];
    const scheduleTime = localDateTime.toTimeString().substring(0, 5);

    return { scheduleDate, scheduleTime };
  };

  const [scheduleDate, setScheduleDate] = useState(
    getDefaultScheduleDateTime().scheduleDate,
  );
  const [scheduleTime, setScheduleTime] = useState(
    getDefaultScheduleDateTime().scheduleTime,
  );
  const [useProspectLocalTime, setUseProspectLocalTime] = useState(false);
  const { statusUpdateTrigger, triggerStatusUpdate } =
    useContext(StatusContext);

  // Function to fetch status
  const fetchStatus = async () => {
    setLoading(true);
    try {
      const token = await getAccessTokenSilently();
      const response = await api.get('/oagent-prospects/email-status', {
        params: {
          prospect_id: prospectId,
          oagent_id: oagentId,
          generated_email_id: generatedEmailId,
        },
        headers: { Authorization: `Bearer ${token}` },
      });
      setStatus(response.data);
    } catch (error) {
      setError('Problem fetching email status');
      console.error('Error fetching email status:', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchStatus();
  }, [
    oagentId,
    prospectId,
    generatedEmailId,
    getAccessTokenSilently,
    statusUpdateTrigger,
  ]);

  // Function to handle sending email now
  const handleSendNow = async () => {
    setSending(true);
    try {
      const token = await getAccessTokenSilently();
      await api.post(
        `/oagent-prospects/${generatedEmailId}/${oagentId}/send`,
        {},
        {
          headers: { Authorization: `Bearer ${token}` },
        },
      );
      setShowConfirmSendModal(false);
      triggerStatusUpdate();
      onSend();
    } catch (error) {
      setShowConfirmSendModal(false);
      setError(
        'Error sending email - please check the sending profile still has a linked email.',
      );
      console.error('Error sending email:', error);
    } finally {
      setSending(false);
    }
  };

  // Function to handle scheduling email
  const handleScheduleNow = async () => {
    setLoading(true);
    try {
      const token = await getAccessTokenSilently();
      await api.post(
        `/oagent-prospects/${generatedEmailId}/${oagentId}/schedule`,
        {
          scheduled_time: `${scheduleDate}T${scheduleTime}:00`,
          use_prospect_local_time: useProspectLocalTime,
        },
        {
          headers: { Authorization: `Bearer ${token}` },
        },
      );
      fetchStatus();
      triggerStatusUpdate();
    } catch (error) {
      console.error('Error scheduling email:', error);
      setError('Error Scheduling Email');
    } finally {
      setLoading(false);
      setShowScheduleModal(false);
    }
  };

  // Function to handle unscheduling email
  const handleUnschedule = async () => {
    setLoading(true);
    try {
      const token = await getAccessTokenSilently();
      await api.delete(
        `/oagent-prospects/${generatedEmailId}/${oagentId}/schedule`,
        {
          headers: { Authorization: `Bearer ${token}` },
        },
      );
      fetchStatus();
      triggerStatusUpdate();
      setShowConfirmUnscheduleModal(false);
    } catch (error) {
      console.error('Error unscheduling email:', error);
    } finally {
      setLoading(false);
    }
  };

  if (loading)
    return (
      <div className='email-status-loading-wrapper'>
        <Spinner
          className='email-status-loading'
          animation='border'
          size='sm'
        />
        Loading
      </div>
    );

  if (sending)
    return (
      <div className='email-status-loading-wrapper'>
        <Spinner
          className='email-status-loading'
          animation='border'
          size='sm'
        />
        Sending
      </div>
    );

  if (error) return <Alert variant='danger'>{error}</Alert>;

  const convertToLocalTime = (utcTime) => {
    const localTime = new Date(utcTime);
    return localTime.toLocaleString(); // Converts to a local time string
  };

  return (
    <div className='email-status campaigns'>
      {status.sent_time ? (
        <p>
          <strong>Sent:</strong> {convertToLocalTime(status.sent_time)}
        </p>
      ) : status.scheduled_time ? (
        <div className='scheduled-info-holder'>
          <div className='button-links'>
            <Button
              variant='secondary'
              onClick={() => setShowConfirmSendModal(true)}
            >
              <EmailSendIcon />{' '}
              <span className='more-complicated'>
                <b>Scheduled</b>
                <span>
                  {convertToLocalTime(status.scheduled_time)} (
                  {status.timezone_explanation || 'Your time'})
                </span>
              </span>
            </Button>
            <Button
              variant='primary'
              onClick={() => setShowConfirmUnscheduleModal(true)}
            >
              Re-schedule
            </Button>
          </div>
        </div>
      ) : status.can_be_scheduled ? (
        <Button variant='primary' onClick={() => setShowScheduleModal(true)}>
          <EmailSendIcon /> Schedule email now
        </Button>
      ) : (
        <></>
      )}

      <ConfirmModal
        show={showConfirmSendModal}
        handleClose={() => setShowConfirmSendModal(false)}
        handleConfirm={handleSendNow}
        title='Confirm Send Now'
        bodyText='Are you sure you want to send this email now?'
      />

      <ConfirmModal
        show={showConfirmUnscheduleModal}
        handleClose={() => setShowConfirmUnscheduleModal(false)}
        handleConfirm={handleUnschedule}
        title='Confirm Unschedule'
        bodyText='Are you sure you want to unschedule this email?'
      />

      <Modal
        show={showScheduleModal}
        onHide={() => setShowScheduleModal(false)}
      >
        <Modal.Header closeButton>
          <Modal.Title>Schedule Email</Modal.Title>
        </Modal.Header>
        <Modal.Body className='schedule-email-modal-body'>
          <p>Select a date and time to schedule this email.</p>
          <div className='dream-side-modal-form'>
            <Form.Group controlId='scheduleDate'>
              <Form.Label>Date</Form.Label>
              <Form.Control
                type='date'
                value={scheduleDate}
                onChange={(e) => setScheduleDate(e.target.value)}
                size='lg'
              />
            </Form.Group>
            <Form.Group controlId='scheduleTime'>
              <Form.Label>Time</Form.Label>
              <Form.Control
                type='time'
                value={scheduleTime}
                size='lg'
                onChange={(e) => setScheduleTime(e.target.value)}
              />
            </Form.Group>
            <Form.Group controlId='useProspectLocalTime'>
              <Form.Check
                type='checkbox'
                label={<span>Use prospect's local time (best-guess)</span>}
                checked={useProspectLocalTime}
                onChange={(e) => setUseProspectLocalTime(e.target.checked)}
              />
            </Form.Group>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant='outline-primary'
            onClick={() => setShowScheduleModal(false)}
          >
            Cancel
          </Button>
          <Button variant='primary' onClick={handleScheduleNow}>
            Schedule email
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default GeneratedEmailStatus;
