import { Button } from 'react-bootstrap';
import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import api from '../api';
import { ReactComponent as ListBuilderIcon } from '../icons/list-builder.svg';
import { ReactComponent as PlusIcon } from '../icons/plus.svg';
import MainPageLoader from './misc/MainPageLoader';
import CreateFunnel from './CreateFunnel'; // Import the new modal component
import functionManifests from '../utils/function-manifests';
import CreateCampaignModal from './campaigns/CreateCampaignModal';

import './funnel/funnel.css';

function FunnelDashboard() {
  const { getAccessTokenSilently } = useAuth0();
  const [funnels, setFunnels] = useState([]);
  const [loading, setLoading] = useState(true);
  const [showCreateModal, setShowCreateModal] = useState(false);
  const [showCreateCampaignModal, setShowCreateCampaignModal] = useState(false);
  const [selectedFunnels, setSelectedFunnels] = useState([]);
  const navigate = useNavigate();

  const fetchFunnels = async () => {
    const token = await getAccessTokenSilently();
    const response = await api.get('/funnels', {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    const fetchedFunnels = response.data.funnels || [];
    setFunnels(fetchedFunnels);

    setLoading(false);
  };

  const searchTypeIsImport = (searchType) => {
    const opt = functionManifests.company_search.find(
      (x) => x.type === searchType,
    );
    if (!opt) return false;
    return opt.import;
  };

  useEffect(() => {
    fetchFunnels();
  }, [getAccessTokenSilently]);

  const handleCheckboxChange = (id) => {
    setSelectedFunnels((prevSelected) =>
      prevSelected.includes(id)
        ? prevSelected.filter((fid) => fid !== id)
        : [...prevSelected, id],
    );
  };

  const handleSelectAllChange = () => {
    if (selectedFunnels.length === funnels.length) {
      setSelectedFunnels([]);
    } else {
      setSelectedFunnels(funnels.map((funnel) => funnel.id));
    }
  };

  const isAllSelected = selectedFunnels.length === funnels.length;
  const isSomeSelected = selectedFunnels.length > 0;

  const totalCompanies = funnels
    .filter((funnel) => selectedFunnels.includes(funnel.id))
    .reduce(
      (total, funnel) => total + (funnel.details?.filteredOrgsCount || 0),
      0,
    );

  return (
    <div className='page-wrapper'>
      <div className='page-inner-wrapper funnels-page funnel'>
        <div className='page-header-area'>
          <div className='title-and-text'>
            <h3>Targets</h3>
          </div>
          <div className='buttons-area'>
            <Button
              variant='outline-primary'
              onClick={() => setShowCreateModal('list')}
            >
              Import List
            </Button>
            <Button
              variant='primary'
              onClick={() => setShowCreateModal('agent')}
            >
              Create new List <FontAwesomeIcon icon={faPlus} />
            </Button>
          </div>
        </div>

        <div className='list-area'>
          {funnels.length === 0 && !loading && (
            <div className='main-page-empty-area'>
              <ListBuilderIcon />
              <h6>You don't currently have any lists setup</h6>
              <p>
                Get started by bringing your own list or creating a list
                building agent
              </p>
              <Button
                variant='primary'
                onClick={() => setShowCreateModal('agent')}
              >
                Create new List <FontAwesomeIcon icon={faPlus} />
              </Button>
            </div>
          )}
          {loading && <MainPageLoader />}
          {!loading && funnels.length > 0 && (
            <div className='psuedo-table-wrapper'>
              <div className='header-line'>
                <div className='checkbox-wrapper'>
                  <input
                    type='checkbox'
                    checked={isAllSelected}
                    onChange={handleSelectAllChange}
                  />
                </div>
                <div className='title'>Target list name</div>
                <div className='status'>Status</div>
                <div className='companies'>Companies</div>
                <div className='final'></div>
              </div>
              {isSomeSelected && (
                <div
                  className='selected-info'
                  onClick={() => setShowCreateCampaignModal(true)}
                >
                  <PlusIcon />
                  Create new Campaign with these Target Lists ({
                    totalCompanies
                  }{' '}
                  companies)
                </div>
              )}
              <div className='funnel-list-view'>
                {funnels
                  .sort((a, b) => b.id - a.id)
                  .map((funnel) => {
                    const isChecked = selectedFunnels.includes(funnel.id);
                    const logoSet = funnel.details?.logoSet || [];
                    const displayedLogos = logoSet.slice(0, 4);
                    const additionalLogosCount =
                      funnel?.details?.filteredOrgsCount -
                      displayedLogos.length;

                    return (
                      <div
                        className={`funnel-list-item ${
                          isChecked ? 'selected' : ''
                        }`}
                        key={funnel.id}
                        onClick={() => navigate(`/funnel/${funnel.id}`)}
                      >
                        <div className='checkbox-wrapper'>
                          <input
                            type='checkbox'
                            checked={isChecked}
                            className='sexy-checkbox'
                            onClick={(e) => e.stopPropagation()}
                            onChange={(e) => {
                              e.stopPropagation(); // Prevent triggering item click
                              handleCheckboxChange(funnel.id);
                            }}
                          />
                        </div>
                        <h5>
                          {funnel.name}
                          {funnel?.details?.searchTypes?.some((x) =>
                            searchTypeIsImport(x),
                          ) && <div className='import-tag'>Imported</div>}
                        </h5>
                        <div className='funnel-list-set'>
                          <p className='label'>Activity</p>
                          <p className='value'>
                            {funnel.details?.schedulingAllowed
                              ? 'Autopilot'
                              : 'Inactive'}
                          </p>
                        </div>
                        <div className='funnel-list-set'>
                          <p className='label'>Companies found</p>
                          <p className='value'>
                            {funnel.details?.filteredOrgsCount}
                          </p>
                        </div>
                        <div className='funnel-list-set with-logos'>
                          {logoSet.length > 0 && (
                            <div className='logo-set'>
                              <div className='logo-selection'>
                                {displayedLogos.map((logoUrl, index) => (
                                  <div
                                    style={{
                                      backgroundImage: `url(${logoUrl})`,
                                    }}
                                    key={index}
                                    className='logo'
                                  />
                                ))}
                              </div>
                              {additionalLogosCount > 0 && (
                                <div className='and-more'>
                                  +{additionalLogosCount} more
                                </div>
                              )}
                            </div>
                          )}
                        </div>
                      </div>
                    );
                  })}
              </div>
            </div>
          )}
        </div>
      </div>
      {showCreateCampaignModal && (
        <CreateCampaignModal
          show={showCreateCampaignModal}
          handleClose={() => setShowCreateCampaignModal(false)}
          initialTargetLists={selectedFunnels}
        />
      )}
      <CreateFunnel
        show={!!showCreateModal}
        type={showCreateModal}
        handleClose={() => setShowCreateModal(false)}
      />
    </div>
  );
}

export default FunnelDashboard;
