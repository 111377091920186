import React, { act } from 'react';
import './visual-loading.css';

import { ReactComponent as RightCaretIcon } from '../../../icons/right-caret.svg';
import { ReactComponent as EmailIcon } from '../../../icons/email.svg';
import { Spinner } from 'react-bootstrap';

const ShowActiveDraft = ({
  drafts,
  biography,
  selectedProfile,
  selectionReason,
}) => {
  const activeDraft = drafts.find((draft) => draft.active);
  const toShow = drafts.find((draft) => draft.id === activeDraft.id - 1);

  return (
    <div className='show-active-draft'>
      <div className='draft-which'>
        <div
          className={`draft-label biography ${selectedProfile ? 'done' : biography ? 'visible' : 'active'}`}
        >
          {!biography && <Spinner size='sm' />}
          <p>Bio</p>
        </div>
        {selectedProfile && <RightCaretIcon />}
        {selectedProfile && (
          <div
            className={`draft-label selected-profile ${toShow ? 'done' : selectedProfile ? 'visible' : 'active'}`}
          >
            <p>Sender</p>
          </div>
        )}
        {drafts.length > 0 && <RightCaretIcon />}
        {drafts.map((draft, index) => (
          <>
            <div
              className={`draft-label ${draft.id < toShow?.id && 'done'} ${draft.id === activeDraft?.id && 'active'} ${draft.id === toShow?.id && 'visible'}`}
            >
              {activeDraft.id === draft.id && <Spinner size='sm' />}
              <p>
                {index < 3 && <EmailIcon />}
                {index === 3 ? 'Final' : `${index + 1}`}
              </p>
            </div>
            {!!(index + 1 < drafts.length) && <RightCaretIcon />}
          </>
        ))}
      </div>

      {!toShow && !selectionReason && biography && (
        <div className='draft-display'>
          <div
            dangerouslySetInnerHTML={{
              __html: biography.replace(/\n/g, '<br />'),
            }}
          ></div>
        </div>
      )}
      {!toShow && selectionReason && (
        <div className='draft-display'>
          <p>
            <b>Selected:</b> {selectedProfile}
          </p>
          <p>{selectionReason}</p>
        </div>
      )}
      {toShow && (
        <div className='draft-display'>
          <div
            dangerouslySetInnerHTML={{
              __html: toShow.draft.replace(/\n/g, '<br />'),
            }}
          ></div>
        </div>
      )}
    </div>
  );
};

export default ShowActiveDraft;
