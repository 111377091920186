import React, { useState, useEffect } from 'react';
import { Button, Form, Alert } from 'react-bootstrap';
import { useAuth0 } from '@auth0/auth0-react';
import DreamModalInner from '../misc/DreamModalInner';
import api from '../../api';

import { ReactComponent as ResearchPointIcon } from '../../icons/research-point.svg';
import DreamSideModal from '../misc/DreamSideModal';
import DreamSideModalLoader from '../misc/DreamSideModalLoader'; // Assuming you have a loader component

const EditResearchPointModal = ({
  researchPointId,
  onClose,
  show,
  funnelId,
}) => {
  const { getAccessTokenSilently } = useAuth0();
  const [name, setName] = useState('');
  const [definition, setDefinition] = useState('');
  const [example, setExample] = useState('');
  const [reSyncOrgs, setReSyncOrgs] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [timeMeasure, setTimeMeasure] = useState('anytime');
  const [timeValue, setTimeValue] = useState('');

  const [error, setError] = useState('');

  useEffect(() => {
    const fetchResearchPoint = async () => {
      try {
        const token = await getAccessTokenSilently();
        const response = await api.get(
          `/analyst/research-point/${researchPointId}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          },
        );

        const data = response.data;
        setName(data.name);
        setDefinition(data.definition);
        setExample(data.example);
        setTimeMeasure(data.use_time_check ? data.time_measure : 'anytime');
        setTimeValue(data.time_value);
      } catch (error) {
        console.error('Error fetching research point:', error);
        setError('Failed to load research point data');
      }
    };

    fetchResearchPoint();
  }, [researchPointId, getAccessTokenSilently]);

  const handleSave = async () => {
    try {
      setIsLoading(true);
      setError('');
      const token = await getAccessTokenSilently();
      await api.put(
        `/analyst/research-point/${researchPointId}`,
        {
          name,
          definition,
          example,
          use_time_check: timeMeasure === 'anytime' ? false : true,
          time_measure: timeMeasure === 'anytime' ? null : timeMeasure,
          time_value: timeValue,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      );

      // Optionally re-sync organizations if selected
      if (reSyncOrgs) {
        await api.post(
          `/analyst/research-point/${researchPointId}/resync-orgs`,
          {
            funnel_id: funnelId,
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          },
        );
      }

      onClose();
    } catch (error) {
      console.error('Error updating research point:', error);
      setError('Failed to save research point data');
    } finally {
      setIsLoading(false);
    }
  };

  if (isLoading) {
    return (
      <DreamSideModal>
        <DreamModalInner>
          <DreamSideModalLoader>Saving</DreamSideModalLoader>
        </DreamModalInner>
      </DreamSideModal>
    );
  }

  return (
    <>
      <DreamSideModal show={show} handleClose={onClose}>
        <DreamModalInner>
          <DreamModalInner.Header>
            <div className='red-icon'>
              <ResearchPointIcon />
            </div>
            Edit Research
          </DreamModalInner.Header>
          <DreamModalInner.Body>
            {error && <Alert variant='danger'>{error}</Alert>}
            <div className='edit research-point-side-wrapper'>
              <div className='dream-side-modal-form'>
                <Form.Group>
                  <Form.Label>Datapoint name</Form.Label>
                  <Form.Text>e.g. "Key customers"</Form.Text>
                  <Form.Control
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                  />
                </Form.Group>

                <Form.Group>
                  <Form.Label>Description</Form.Label>
                  <Form.Text>
                    e.g. "An overview of five to ten recently announced
                    customers"
                  </Form.Text>
                  <Form.Control
                    as='textarea'
                    value={definition}
                    onChange={(e) => setDefinition(e.target.value)}
                  />
                </Form.Group>

                <Form.Group>
                  <Form.Label>Example</Form.Label>
                  <Form.Text>
                    e.g. "Nike, Walmart, Brex, Doordash, LSEG"
                  </Form.Text>
                  <Form.Control
                    value={example}
                    onChange={(e) => setExample(e.target.value)}
                  />
                </Form.Group>

                <>
                  <Form.Group>
                    <Form.Label>Timeframe</Form.Label>
                    <Form.Text>Include information/events from</Form.Text>
                    <Form.Select
                      value={timeMeasure}
                      onChange={(e) => setTimeMeasure(e.target.value)}
                    >
                      <option value='anytime'>Anytime</option>
                      <option value='before'>Before a certain date</option>
                      <option value='after'>After a certain date</option>
                    </Form.Select>
                  </Form.Group>

                  {timeMeasure !== 'anytime' && (
                    <Form.Group>
                      <Form.Label>Relevant Date</Form.Label>
                      <Form.Text>
                        The date to review information from/up-to
                      </Form.Text>
                      <Form.Control
                        type='date'
                        format='YYYY-MM-DD'
                        value={timeValue}
                        onChange={(e) => setTimeValue(e.target.value)}
                      />
                    </Form.Group>
                  )}
                </>

                <Form.Group>
                  <Form.Label>Re-Sync Data</Form.Label>
                  <Form.Text>
                    This will reload all research for this datapoint (
                    <b>uses credits</b>)
                  </Form.Text>
                  <Form.Check
                    type='checkbox'
                    label={<span>Yes, re-sync all</span>}
                    checked={reSyncOrgs}
                    onChange={(e) => setReSyncOrgs(e.target.checked)}
                  />
                </Form.Group>
              </div>
            </div>
          </DreamModalInner.Body>
          <DreamModalInner.Footer>
            <div className='analyst'>
              <Button
                variant='primary'
                onClick={handleSave}
                disabled={isLoading}
              >
                Save
              </Button>
            </div>
            <Button variant='outline-primary' onClick={onClose}>
              Cancel
            </Button>
          </DreamModalInner.Footer>
        </DreamModalInner>
      </DreamSideModal>
    </>
  );
};

export default EditResearchPointModal;
