import React, { useState } from 'react';
import { Typeahead, withAsync } from 'react-bootstrap-typeahead';
import api from '../../api';
import 'react-bootstrap-typeahead/css/Typeahead.css';

const AsyncTypeahead = withAsync(Typeahead);

const LocationAutocomplete = ({ onLocationSelected, size, value }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [suggestions, setSuggestions] = useState([]);

  const fetchSuggestions = async (term) => {
    setIsLoading(true);
    try {
      const response = await api.get(
        `/google-locations-autocomplete?q=${term}&limit=5`,
      );
      setSuggestions(
        response.data.map((location) => ({
          label: location.canonical_name.split(',').join(', '),
          id: location.canonical_name,
        })),
      );
    } catch (error) {
      console.error('Error fetching suggestions:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleSuggestionSelect = (selection) => {
    onLocationSelected(selection[0]?.id);
  };

  return (
    <AsyncTypeahead
      options={suggestions}
      onSearch={fetchSuggestions}
      isLoading={isLoading}
      onChange={handleSuggestionSelect}
      id='google-location-autocomplete'
      autoComplete='off'
      defaultSelected={[{ id: value, label: value.split(',').join(', ') }]}
      size={size}
    />
  );
};

export default LocationAutocomplete;
