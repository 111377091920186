import React, { useState, useEffect } from 'react';
import { Button, Form, Nav, Alert } from 'react-bootstrap';
import CreatableSelect from 'react-select/creatable';
import RangeSlider from 'react-bootstrap-range-slider';
import DreamSideModal from '../../misc/DreamSideModal';
import DreamModalInner from '../../misc/DreamModalInner';
import DreamInfoExpander from '../../misc/DreamInfoExpander';
import TestProspectSearch from './TestProspectSearch';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';

import Select from 'react-select';
import linkedinCountries from '../../../utils/linkedin-countries';

function KnownJobTitleModal({
  show,
  handleClose,
  oagentId,
  refreshProspectSearch,
  editMode,
  prospectSearchId,
  metadata,
}) {
  const defaultNumProspects = 5;
  const defaultAgentIntuition = false;
  const defaultJobTitleDiscretion = true;
  const [activeTab, setActiveTab] = useState('job_titles');
  const [selectedJobTitles, setSelectedJobTitles] = useState([]);
  const [numProspects, setNumProspects] = useState(defaultNumProspects);
  const [countries, setCountries] = useState([]);
  const [jobTitleDiscretion, setJobTitleDiscretion] = useState(
    defaultJobTitleDiscretion,
  );
  const [agentIntuition, setAgentIntuition] = useState(defaultAgentIntuition);

  const [excludedJobTitles, setExcludedJobTitles] = useState([]);
  const [showTestProspectSearch, setShowTestProspectSearch] = useState(false);
  const [formData, setFormData] = useState({});
  const [showWarning, setShowWarning] = useState(false);

  useEffect(() => {
    if (metadata) {
      setSelectedJobTitles(
        metadata?.job_titles.map((x) => ({ label: x, value: x })),
      );
      setNumProspects(metadata?.num_prospects);
      setJobTitleDiscretion(metadata?.job_title_discretion);
      setAgentIntuition(metadata?.agent_intuition);
      setCountries(metadata?.country);
    } else {
      setSelectedJobTitles([]);
      setNumProspects(defaultNumProspects);
      setJobTitleDiscretion(defaultJobTitleDiscretion);
      setAgentIntuition(defaultAgentIntuition);
      setCountries([]);
    }
  }, [metadata]);

  const handleTabSelect = (tab) => {
    setActiveTab(tab);
  };

  const handleSubmit = () => {
    if (selectedJobTitles.length === 0) {
      setShowWarning(true);
      return;
    }

    const formData = {
      job_titles: selectedJobTitles?.map((x) => x.value) || [],
      num_prospects: numProspects,
      job_title_discretion: jobTitleDiscretion,
      agent_intuition: agentIntuition,
      excluded_job_titles: excludedJobTitles,
      country: countries,
    };

    setFormData(formData);
    setShowTestProspectSearch(true);
  };

  return (
    <>
      <DreamSideModal show={show} onHide={handleClose}>
        <DreamModalInner>
          <DreamModalInner.Header>
            Add prospects by job title
          </DreamModalInner.Header>
          <DreamModalInner.Body>
            <div className='known-job-title-modal'>
              <Nav
                variant='tabs'
                className='dream-tabs'
                activeKey={activeTab}
                onSelect={handleTabSelect}
              >
                <Nav.Item>
                  <Nav.Link eventKey='job_titles'>Job title(s)</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey='agent_flexibility'>
                    Agent flexibility
                  </Nav.Link>
                </Nav.Item>
              </Nav>
              <div className='tab-lower'>
                {activeTab === 'job_titles' && (
                  <div className='job-titles-tab dream-side-modal-form'>
                    {showWarning && (
                      <DreamInfoExpander alwaysExpanded heading='Warning'>
                        Please add at least one job title to proceed.
                      </DreamInfoExpander>
                    )}
                    <Form.Group>
                      <Form.Label>Job title(s) to include</Form.Label>
                      <Form.Text>
                        Your agents will look for people with exactly these
                        titles
                      </Form.Text>
                      <CreatableSelect
                        isMulti
                        value={selectedJobTitles}
                        onChange={(titles) => {
                          setSelectedJobTitles(titles);
                          setShowWarning(false);
                        }}
                        classNames={{
                          control: () => `big-react-select`,
                        }}
                      />
                    </Form.Group>
                    <Form.Group>
                      <Form.Label>Countries</Form.Label>
                      <Form.Text>
                        (Optional) Which countries to retrieve prospects from
                      </Form.Text>
                      <Select
                        isMulti
                        className='big-react-select'
                        classNamePrefix='country-select'
                        value={linkedinCountries.filter((country) =>
                          countries?.includes(country.value),
                        )}
                        options={linkedinCountries}
                        onChange={(selected) => {
                          setCountries(selected.map((x) => x.value));
                        }}
                      />
                    </Form.Group>
                    <Form.Group className='range-flex'>
                      <Form.Label>
                        Maximum prospects to return per company
                      </Form.Label>
                      <Form.Text>
                        Each prospect added to a campaign costs 1 credit.
                      </Form.Text>
                      <RangeSlider
                        value={numProspects}
                        onChange={(e) => setNumProspects(e.target.value)}
                        min={1}
                        max={20}
                        tooltipLabel={(currentValue) => `${currentValue}`}
                        variant='info'
                        size='large'
                      />
                      <div className='indicator-line'>
                        <span>1</span>
                        <span>20</span>
                      </div>
                    </Form.Group>
                  </div>
                )}
                {activeTab === 'agent_flexibility' && (
                  <div className='agent-flexibility-tab dream-side-modal-form'>
                    <Form.Group>
                      <Form.Label>Allow for spelling variation</Form.Label>
                      <Form.Check
                        type='checkbox'
                        label={
                          <span>
                            Allow your agent to use common spelling changes for
                            job titles (e.g. VP of Sales, VP Sales, CEO, Chief
                            Executive Officer)
                          </span>
                        }
                        checked={jobTitleDiscretion}
                        onChange={(e) =>
                          setJobTitleDiscretion(e.target.checked)
                        }
                      />
                    </Form.Group>
                    <Form.Group>
                      <Form.Label>Agent intuition</Form.Label>
                      <Form.Check
                        type='checkbox'
                        label={
                          <span>
                            Allow your agent to give common alternatives for the
                            job-titles (e.g. Human Resources Director, VP
                            People)
                          </span>
                        }
                        checked={agentIntuition}
                        onChange={(e) => setAgentIntuition(e.target.checked)}
                      />
                    </Form.Group>
                    {(jobTitleDiscretion || agentIntuition) && (
                      <Form.Group>
                        <Form.Label>Job titles to exclude</Form.Label>
                        <CreatableSelect
                          isMulti
                          value={excludedJobTitles}
                          onChange={setExcludedJobTitles}
                        />
                      </Form.Group>
                    )}
                  </div>
                )}
              </div>
            </div>
          </DreamModalInner.Body>
          <DreamModalInner.Footer>
            <div className='campaigns'>
              <Button variant='primary' onClick={handleSubmit}>
                Next <FontAwesomeIcon icon={faArrowRight} />
              </Button>
            </div>
            <Button variant='outline-primary' onClick={handleClose}>
              Cancel
            </Button>
          </DreamModalInner.Footer>
        </DreamModalInner>
      </DreamSideModal>
      {showTestProspectSearch && (
        <TestProspectSearch
          show={showTestProspectSearch}
          handleClose={() => setShowTestProspectSearch(false)}
          metadata={formData}
          type='known_job_title'
          refreshProspectSearch={() => {
            refreshProspectSearch();
            handleClose();
          }}
          oagentId={oagentId}
          editMode={editMode}
          prospectSearchId={prospectSearchId}
        />
      )}
    </>
  );
}

export default KnownJobTitleModal;
