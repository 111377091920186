import React from 'react';
import linkedinCountries from '../linkedin-countries';
import linkedinIndustries from '../linkedin-industries';
import { ReactComponent as ClipboardIcon } from '../../icons/clipboard.svg';

const linkedinSearchManifest = {
  name: 'Criteria Search',
  type: 'linkedin_search',
  icon: <ClipboardIcon />,
  is_foundational_type: true,
  description:
    'Similar to LinkedIn Sales Navigator. Enter details about the businesses you’re looking for.',
  match_language: 'Companies matching criteria',
  list_language: 'Companies retrieved',
  auto_generate_filter: true,
  validate: ({
    industries,
    countries,
    minimum_founded_year,
    maximum_founded_year,
    minimum_headcount,
    maximum_headcount,
  }) => {
    const errors = [];

    const isValidYear = (year) => !year || /^\d{4}$/.test(year.toString());

    const hasAtLeastOneCriteria = () => {
      return (
        industries?.length > 0 ||
        countries?.length > 0 ||
        minimum_founded_year ||
        maximum_founded_year ||
        minimum_headcount ||
        maximum_headcount
      );
    };

    if (!hasAtLeastOneCriteria()) {
      errors.push('At least one search criteria must be provided.');
    }

    if (minimum_founded_year && !isValidYear(minimum_founded_year)) {
      errors.push('Minimum founded year must be a valid year.');
    }

    if (maximum_founded_year && !isValidYear(maximum_founded_year)) {
      errors.push('Maximum founded year must be a valid year.');
    }

    if (
      minimum_founded_year &&
      maximum_founded_year &&
      minimum_founded_year > maximum_founded_year
    ) {
      errors.push(
        'Minimum founded year cannot be greater than maximum founded year.',
      );
    }

    if (
      minimum_headcount &&
      maximum_headcount &&
      minimum_headcount > maximum_headcount
    ) {
      errors.push(
        'Minimum headcount cannot be greater than maximum headcount.',
      );
    }

    return errors.length > 0 ? errors : [];
  },
  additional_options: {
    industries: {
      description: 'Industries',
      long_description: 'Which industries should you retrieve companies from?',
      input_type: 'multiselect',
      options: linkedinIndustries,
      dev_name: 'industries',
    },
    countries: {
      description: 'Countries',
      long_description: 'Which countries should you retrieve companies from?',
      input_type: 'multiselect',
      options: linkedinCountries,
      dev_name: 'countries',
    },
    minimum_headcount: {
      description: 'Minimum headcount',
      long_description:
        'Companies with employee counts lower than this will be excluded',
      input_type: 'number',
      short: true,
      dev_name: 'minimum_headcount',
    },
    maximum_headcount: {
      description: 'Maximum headcount',
      long_description:
        'Companies with larger employee counts than this will be excluded',
      input_type: 'number',
      short: true,
      dev_name: 'maximum_headcount',
    },
    minimum_founded_year: {
      description: 'Minimum founded year',
      long_description: 'Companies founded before this year will be excluded',
      input_type: 'number',
      short: true,
      dev_name: 'minimum_founded_year',
    },
    maximum_founded_year: {
      description: 'Maximum founded year',
      long_description: 'Companies founded after this year will be excluded',
      input_type: 'number',
      short: true,
      dev_name: 'maximum_founded_year',
    },
  },
  tests: [],
  credit_cost: 1,
  speed_description: (
    <p>
      Agent will retrieve up to <b>300</b> companies per hour.
    </p>
  ),
};

export default linkedinSearchManifest;
