import React, { useState } from 'react';
import { Form, Button, Spinner, Alert } from 'react-bootstrap';
import { useAuth0 } from '@auth0/auth0-react';
import api from '../../api';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faGlobe,
  faInfoCircle,
  faPerson,
} from '@fortawesome/free-solid-svg-icons';
import StageAdditionalOptions from '../StageAdditionalOptions'; // Update the import path as necessary
import functionManifests from '../../utils/wizard-manifests'; // Ensure the manifests are imported

const DreamWizardAddFirstFilter = ({ funnelId, advance }) => {
  const { getAccessTokenSilently } = useAuth0();
  const [selectedFilter, setSelectedFilter] = useState(null);
  const [filterMetadata, setFilterMetadata] = useState({});
  const [isAdding, setIsAdding] = useState(false);
  const [alertMessage, setAlertMessage] = useState(null);

  const addFilter = async () => {
    if (selectedFilter) {
      setIsAdding(true);
      setAlertMessage(null);

      try {
        const token = await getAccessTokenSilently();
        const filterData = {
          stage: 'company_filter',
          option: {
            type: selectedFilter,
            metadata: filterMetadata,
          },
        };
        await api.post(`/funnels/${funnelId}/add-stage`, filterData, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        advance();
      } catch (error) {
        setAlertMessage('Error adding filter. Please try again.');
        console.error('Error adding filter:', error);
      } finally {
        setIsAdding(false);
      }
    } else {
      setAlertMessage('Please select a filter before adding.');
    }
  };

  const handleMetadataChange = (stage, index, option, value) => {
    setFilterMetadata((prevMetadata) => ({
      ...prevMetadata,
      [option]: value,
    }));
  };

  const additionalExplanations = {
    ai_filter:
      "Your agent is smart. It can filter companies relatively accurately just using it's own knowledge. This is good enough for most use cases and doesn't cost any credits to request.",
    basic_details:
      'You can also perform a hard filter based on company data we retrieve from data sources. This is good if you have hard requirements. Note that this uses more credits than using agent knowledge.',
    homepage_information_filter:
      'You can lookup the company website and filter based on what your agent sees there.',
  };

  return (
    <div className='add-first-filter-wrapper'>
      {alertMessage && <Alert variant='danger'>{alertMessage}</Alert>}
      <h4>Let's Filter These Companies</h4>
      <p>
        Once your agent has found a set of companies, we should then filter them
        to make sure nothing we're not interested in gets through.
      </p>
      <p>
        Dream has many different filters but for this example funnel, let's
        select one of the three main ones you'll work with.
      </p>

      <div className='form-area'>
        <div className='buttons-area'>
          <Button
            variant={
              selectedFilter === 'ai_filter' ? 'primary' : 'outline-primary'
            }
            onClick={() => setSelectedFilter('ai_filter')}
          >
            <span>
              <FontAwesomeIcon icon={faPerson} />
            </span>
            Agent Uses Own Knowledge to Filter
          </Button>
          <Button
            variant={
              selectedFilter === 'basic_details' ? 'primary' : 'outline-primary'
            }
            onClick={() => setSelectedFilter('basic_details')}
          >
            <span>
              <FontAwesomeIcon icon={faInfoCircle} />
            </span>
            Filter Based on Company Details
          </Button>
          <Button
            variant={
              selectedFilter === 'homepage_information_filter'
                ? 'primary'
                : 'outline-primary'
            }
            onClick={() => setSelectedFilter('homepage_information_filter')}
          >
            <span>
              <FontAwesomeIcon icon={faGlobe} />
            </span>
            Filter Based on Company Website
          </Button>
        </div>

        {selectedFilter && (
          <div className='additional-options-area'>
            <p className='initial-explanation'>
              {additionalExplanations[selectedFilter]}
            </p>
            <StageAdditionalOptions
              manifest={functionManifests}
              stage='filter'
              index={0}
              step={{ type: selectedFilter, metadata: filterMetadata }}
              handleMetadataChange={handleMetadataChange}
            />
          </div>
        )}
      </div>

      <div className='funnel-build-button-wrapper'>
        <Button variant='primary' onClick={addFilter} disabled={isAdding}>
          {isAdding ? <Spinner animation='border' size='sm' /> : 'Add Filter'}
        </Button>
      </div>
    </div>
  );
};

export default DreamWizardAddFirstFilter;
