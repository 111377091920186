import React, { useState, useEffect } from 'react';
import DreamSideModal from '../misc/DreamSideModal';
import DreamModalInner from '../misc/DreamModalInner';
import JobViewModal from './JobViewModal';
import './jobs.css';
import { ReactComponent as LinkedinIcon } from '../../icons/linkedin.svg';

function JobsListModal({ show, onHide, jobs, company }) {
  const [showJobViewModal, setShowJobViewModal] = useState(false);
  const [selectedJob, setSelectedJob] = useState(null);

  useEffect(() => {
    if (jobs.length === 1) {
      setSelectedJob(jobs[0]);
      setShowJobViewModal(true);
    }
  }, [jobs]);

  const handleViewJobClick = (job) => {
    setSelectedJob(job);
    setShowJobViewModal(true);
  };

  if (jobs.length === 1) {
    return (
      <JobViewModal
        show={show}
        onHide={onHide}
        job={jobs[0]}
        company={company}
      />
    );
  }

  return (
    <DreamSideModal show={show} handleClose={onHide}>
      <DreamModalInner>
        <DreamModalInner.Header>Jobs List</DreamModalInner.Header>
        <DreamModalInner.Body>
          <div className='jobs-modal-wrapper'>
            {jobs.length === 0 ? (
              <p>No jobs found.</p>
            ) : (
              jobs.map((job, index) => (
                <div key={index} className='job-item'>
                  <div className='job-left'>
                    <LinkedinIcon />
                    <span>{job.title}</span>
                  </div>
                  <span
                    className='view-job'
                    onClick={() => handleViewJobClick(job)}
                  >
                    View Job
                  </span>
                </div>
              ))
            )}
          </div>
        </DreamModalInner.Body>
      </DreamModalInner>

      {showJobViewModal && (
        <JobViewModal
          show={showJobViewModal}
          onHide={() => setShowJobViewModal(false)}
          job={selectedJob}
          company={company}
        />
      )}
    </DreamSideModal>
  );
}

export default JobsListModal;
