import React, { useState, useEffect } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { Link } from 'react-router-dom';
import { ReactComponent as TargetIcon } from '../../icons/target.svg';
import { ReactComponent as ProfilesIcon } from '../../icons/profiles.svg';
import { ReactComponent as LinkIcon } from '../../icons/link.svg';
import { ReactComponent as CampaignsIcon } from '../../icons/campaigns.svg';
import { ReactComponent as CheckCircleFilledIcon } from '../../icons/check-circle-filled.svg';
import api from '../../api';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';

import './campaigns.css';

const NoCampaignsView = ({ showCreateModal }) => {
  const { getAccessTokenSilently } = useAuth0();
  const [listBuilders, setListBuilders] = useState(false);
  const [linkedAccounts, setLinkedAccounts] = useState(false);
  const [profiles, setProfiles] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const token = await getAccessTokenSilently();

        // Fetch list builders
        const listBuildersResponse = await api.get('/funnels', {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        setListBuilders(listBuildersResponse.data.funnels.length > 0);

        // Fetch Google linked accounts
        const googleResponse = await api.get('/google-link', {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        // Fetch Outlook linked accounts
        const outlookResponse = await api.get('/microsoft-link', {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        setLinkedAccounts(
          googleResponse.data.linkedAccounts.length > 0 ||
            outlookResponse.data.linkedAccounts.length > 0,
        );

        // Fetch profiles
        const profilesResponse = await api.get('/profiles', {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        setProfiles(profilesResponse.data.length > 0);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, [getAccessTokenSilently]);

  return (
    <div className='no-campaigns-view'>
      <div className='getting-started-campaigns'>
        <div className='header'>
          <h4>Getting started</h4>
          <p>Complete these steps to start creating a campaign</p>
        </div>
        <div className='guidance-wrapper'>
          <div className={`guidance account ${linkedAccounts ? 'active' : ''}`}>
            <div className='icon-holder'>
              <div className='icon-wrapper'>
                <LinkIcon />
              </div>
              {linkedAccounts && (
                <div className='done-wrapper'>
                  <CheckCircleFilledIcon />{' '}
                </div>
              )}
            </div>
            <div>
              <h5>Setup your account</h5>
              <p>
                Connect your Calendar and Email so your Sales Agents can send
                email and book meetings on your behalf.
              </p>
              <div className='action-area'>
                <div className='do-wrapper'>
                  <Link to='/settings/'>Open Settings</Link>
                </div>
              </div>
            </div>
          </div>

          <div className={`guidance target ${listBuilders ? 'active' : ''}`}>
            <div className='icon-holder'>
              <div className='icon-wrapper'>
                <TargetIcon />
              </div>
              {listBuilders && (
                <div className='done-wrapper'>
                  <CheckCircleFilledIcon />
                </div>
              )}
            </div>
            <div>
              <h5>Create or import a Target List</h5>
              <p>
                Enhance your lists with Dream’s research points. Target the
                right people, at the right time, with the right message.
              </p>
              <div className='action-area'>
                <div className='do-wrapper'>
                  <Link to='/funnel'>Open Targets</Link>
                </div>
              </div>
            </div>
          </div>

          <div className={`guidance profiles ${profiles ? 'active' : ''}`}>
            <div className='icon-holder'>
              <div className='icon-wrapper'>
                <ProfilesIcon />
              </div>
              {profiles && (
                <div className='done-wrapper'>
                  <CheckCircleFilledIcon />
                </div>
              )}
            </div>
            <div>
              <h5>Set up your Sales Agents</h5>
              <p>
                Create the agents that will contact your prospects. Link to a
                real person, or create digital personas to sell in more places.
              </p>
              <div className='action-area'>
                <div className='do-wrapper'>
                  <Link to='/profiles'>Open Sales Agents</Link>
                </div>
              </div>
            </div>
          </div>
        </div>

        {listBuilders && profiles && (
          <div className='empty-state'>
            <div className='main-page-empty-area'>
              <CampaignsIcon />
              <h6>You have not created any campaigns yet</h6>
              <p>You’re all set and ready to create your first campaign</p>
              <button className='btn btn-primary' onClick={showCreateModal}>
                Create new Campaign <FontAwesomeIcon icon={faArrowRight} />
              </button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default NoCampaignsView;
