import React, { useState } from 'react';
import { Form, Button } from 'react-bootstrap';

const Step1 = ({ formData, handleChange, handleNext }) => {
  const [errors, setErrors] = useState({
    user_name: false,
    name: false,
    size: false,
  });

  const validateFields = () => {
    const newErrors = {
      user_name: !formData.user_name,
      name: !formData.name,
      size: !formData.size,
    };

    setErrors(newErrors);

    // Check if there are any errors
    const hasErrors = Object.values(newErrors).some((error) => error);
    return !hasErrors;
  };

  const onNextClick = () => {
    if (validateFields()) {
      handleNext();
    }
  };

  return (
    <Form className='company-form'>
      <h1>Create your account</h1>
      <Form.Group controlId='formUserName'>
        <Form.Label>Your Name</Form.Label>
        <Form.Control
          type='text'
          name='user_name'
          value={formData.user_name}
          onChange={handleChange}
          className='user_name'
          isInvalid={errors.user_name}
        />
        <Form.Control.Feedback type='invalid'>
          Your name is required.
        </Form.Control.Feedback>
      </Form.Group>
      <Form.Group controlId='formCompanyName'>
        <Form.Label>Company Name</Form.Label>
        <Form.Control
          type='text'
          name='name'
          value={formData.name}
          onChange={handleChange}
          className='name'
          isInvalid={errors.name}
        />
        <Form.Control.Feedback type='invalid'>
          Company name is required.
        </Form.Control.Feedback>
      </Form.Group>
      <Form.Group controlId='formCompanySize'>
        <Form.Label>Company Size</Form.Label>
        <Form.Control
          as='select'
          name='size'
          value={formData.size}
          onChange={handleChange}
          className='size'
          isInvalid={errors.size}
        >
          <option value=''>Select a size</option>
          <option value='1-10'>1-10</option>
          <option value='11-50'>11-50</option>
          <option value='51-200'>51-200</option>
          <option value='201-500'>201-500</option>
          <option value='501-1000'>501-1000</option>
          <option value='1001+'>1001+</option>
        </Form.Control>
        <Form.Control.Feedback type='invalid'>
          Company size is required.
        </Form.Control.Feedback>
      </Form.Group>
      <Form.Group controlId='formHowDidYouFindUs'>
        <Form.Label>How Did You Hear About Us</Form.Label>
        <Form.Control
          as='select'
          name='how_did_you_find_us'
          value={formData.how_did_you_find_us}
          onChange={handleChange}
          className='how_did_you_find_us'
        >
          <option value=''>Select an option</option>
          <option value='Search Engine'>Search Engine</option>
          <option value='Colleague'>Colleague</option>
          <option value='Article'>Article</option>
          <option value='Social Media'>Social Media</option>
          <option value='LinkedIn'>LinkedIn</option>
          <option value='Other'>Other</option>
        </Form.Control>
        {formData.how_did_you_find_us === 'Other' && (
          <Form.Group
            controlId='formHowDidYouFindUsOther'
            className='please-specify'
          >
            <Form.Label>Please Specify</Form.Label>
            <Form.Control
              type='text'
              name='how_did_you_find_us_other'
              value={formData.how_did_you_find_us_other}
              onChange={handleChange}
            />
          </Form.Group>
        )}
      </Form.Group>
      <div className='step-1-button-wrapper'>
        <Button variant='primary' onClick={onNextClick}>
          Create Account
        </Button>
      </div>
    </Form>
  );
};

export default Step1;
