import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Form } from 'react-bootstrap';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';

import { ReactComponent as ListBuilderIcon } from '../../icons/list-builder.svg';
import { ReactComponent as UseCaseSalesIcon } from '../../icons/use-case-sales.svg';
import { ReactComponent as UseCasePartnershipsIcon } from '../../icons/use-case-partnerships.svg';
import { ReactComponent as UseCaseOtherIcon } from '../../icons/use-case-other.svg';

import DreamModalInner from '../misc/DreamModalInner';

import './create-funnel.css';

function Step1({
  selectedType,
  setUseCaseType,
  funnelName,
  setFunnelName,
  useCaseOther,
  setUseCaseOther,
  onNext,
  onClose,
  isImport,
}) {
  return (
    <DreamModalInner className='funnel'>
      <DreamModalInner.Header>
        <ListBuilderIcon />
        {isImport ? 'Import list' : 'Create new list'}
      </DreamModalInner.Header>
      <DreamModalInner.Body>
        <div className='dream-side-modal-form step-one-form'>
          <Form.Group>
            <Form.Label>List name</Form.Label>
            <Form.Text>For example "UK Target List"</Form.Text>
            <Form.Control
              value={funnelName}
              size='lg'
              onChange={(e) => setFunnelName(e.target.value)}
            />
          </Form.Group>
          <Form.Group>
            <Form.Label>How are you going to use this list?</Form.Label>
            <div className='use-case-type-selectors'>
              <div
                className={`type ${selectedType === 'sales' && 'active'}`}
                onClick={() => setUseCaseType('sales')}
              >
                <UseCaseSalesIcon />
                <p>Sales</p>
                <small>Build a list of companies to sell to</small>
              </div>
              <div
                className={`type ${selectedType === 'partnerships' && 'active'}`}
                onClick={() => setUseCaseType('partnerships')}
              >
                <UseCasePartnershipsIcon />
                <p>Partnerships</p>
                <small>Build a list of potential partners</small>
              </div>
              <div
                className={`type ${selectedType === 'other' && 'active'}`}
                onClick={() => setUseCaseType('other')}
              >
                <UseCaseOtherIcon />
                <p>Other</p>
                <small>Something else</small>
              </div>
            </div>
          </Form.Group>
          {selectedType === 'other' && (
            <Form.Group>
              <Form.Label>Please specify</Form.Label>
              <Form.Text>For example "Companies to recruit from"</Form.Text>
              <Form.Control
                value={useCaseOther}
                onChange={(e) => setUseCaseOther(e.target.value)}
                size='lg'
              />
            </Form.Group>
          )}
        </div>
      </DreamModalInner.Body>
      <DreamModalInner.Footer>
        <Button
          variant='primary'
          onClick={onNext}
          disabled={!selectedType || !funnelName.trim()}
        >
          {isImport ? 'Import List' : 'Create List'}
          <FontAwesomeIcon icon={faArrowRight} />
        </Button>
        <Button variant='outline-primary' onClick={onClose}>
          Cancel
        </Button>
      </DreamModalInner.Footer>
    </DreamModalInner>
  );
}

export default Step1;
