import React, { useState, useEffect } from 'react';
import { Alert, Button, Spinner } from 'react-bootstrap';
import { useAuth0 } from '@auth0/auth0-react';
import { useNavigate } from 'react-router-dom';
import { useWindowSize } from 'react-use';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import api from '../../api';
import DreamSideModal from '../misc/DreamSideModal';
import DreamModalInner from '../misc/DreamModalInner';
import Confetti from 'react-confetti';

import { ReactComponent as EmailIcon } from '../../icons/email-send.svg';
import { ReactComponent as PlayIcon } from '../../icons/play.svg';
import { ReactComponent as ThumbsUpIcon } from '../../icons/thumbs-up.svg';

import DreamSideModalLoader from '../misc/DreamSideModalLoader'; // Assuming you have a loader component
import './campaigns.css';
import Settings from './steps/Settings';

const StartCampaignModal = ({ show, handleClose, createOptions }) => {
  const { getAccessTokenSilently } = useAuth0();
  const navigate = useNavigate();
  const [isSendingFirstEmail, setIsSendingFirstEmail] = useState(false);
  const [isTurningOnAutopilot, setIsTurningOnAutopilot] = useState(false);
  const [isFirstEmailSent, setIsFirstEmailSent] = useState(false);
  const [isAutopilotOn, setIsAutopilotOn] = useState(false);
  const [showConfetti, setShowConfetti] = useState(false);
  const [sendingError, setSendingError] = useState(false);
  const [isLoading, setIsLoading] = useState(true); // Loading state for initial set live call
  const { width, height } = useWindowSize();

  useEffect(() => {
    const setLive = async () => {
      setIsLoading(true);
      try {
        const token = await getAccessTokenSilently();
        await api.post(
          `/oagents/${createOptions.oagent_id}/set-live`,
          {},
          {
            headers: { Authorization: `Bearer ${token}` },
          },
        );
      } catch (error) {
        console.error('Error setting campaign live:', error);
        setSendingError('Error setting campaign live, please try again');
      } finally {
        setIsLoading(false);
      }
    };

    if (show) {
      setLive();
    }
  }, [show, createOptions.oagent_id, getAccessTokenSilently]);

  const handleSendFirstEmail = async () => {
    setIsSendingFirstEmail('Saving Email');
    setSendingError(false);

    try {
      const token = await getAccessTokenSilently();
      const response = await api.post(
        `/oagents/generated-email/${createOptions.email_id}`,
        {
          ...createOptions,
        },
        {
          headers: { Authorization: `Bearer ${token}` },
        },
      );

      if (response.data.generatedEmail) {
        const generatedEmailId = response.data.generatedEmail.id;

        setIsSendingFirstEmail('Checking timezone');

        await api.post(
          `/oagent-prospects/${generatedEmailId}/${createOptions.oagent_id}/schedule`,
          { scheduled_time: new Date(), use_prospect_local_time: true },
          {
            headers: { Authorization: `Bearer ${token}` },
          },
        );

        setIsSendingFirstEmail('Sending');

        await api.post(
          `/oagent-prospects/${generatedEmailId}/${createOptions.oagent_id}/send`,
          {},
          {
            headers: { Authorization: `Bearer ${token}` },
          },
        );

        setIsFirstEmailSent(true);
        setShowConfetti(true);
      }
    } catch (error) {
      console.error('Error sending first email:', error);
      setSendingError('Error sending email, please try again');
    } finally {
      setIsSendingFirstEmail(false);
      setTimeout(() => {
        setShowConfetti(false);
      }, 2000);
    }
  };

  const handleTurnOnAutopilot = async () => {
    setIsTurningOnAutopilot(true);
    if (isAutopilotOn) return;
    setShowConfetti(true);
    try {
      const token = await getAccessTokenSilently();
      await api.put(
        `/oagents/${createOptions.oagent_id}/set-autopilot/on`,
        {},
        {
          headers: { Authorization: `Bearer ${token}` },
        },
      );
      setIsAutopilotOn(true);
    } catch (error) {
      console.error('Error turning on autopilot:', error);
    } finally {
      setIsTurningOnAutopilot(false);
    }
  };

  const handleGoToCampaign = () => {
    navigate(`/campaign/${createOptions.oagent_id}`);
  };

  return (
    <>
      {showConfetti && (
        <Confetti
          width={width}
          height={height}
          numberOfPieces={4500}
          recycle={false}
        />
      )}
      <DreamSideModal
        show={show}
        handleClose={() => {
          if (isFirstEmailSent || isSendingFirstEmail) {
            return;
          }
          handleClose();
        }}
      >
        {isLoading ? (
          <DreamModalInner>
            <DreamSideModalLoader>Loading</DreamSideModalLoader>
          </DreamModalInner>
        ) : (
          <DreamModalInner>
            <DreamModalInner.Header>Start Campaign</DreamModalInner.Header>
            <DreamModalInner.Body>
              <div className='start-campaign'>
                {sendingError && (
                  <div className='error-wrapper'>
                    <Alert variant='danger'>{sendingError}</Alert>
                  </div>
                )}
                <div className='step-wrapper'>
                  <h6>Step 1</h6>
                  <div className='heading'>
                    <h5>Send your first email</h5>
                    <span>
                      Send your first email <b>"{createOptions.subject}"</b>
                    </span>
                  </div>
                  {isFirstEmailSent && (
                    <Button variant='outline-primary' className='done-button'>
                      Email sent <ThumbsUpIcon />
                    </Button>
                  )}
                  {isSendingFirstEmail && (
                    <Button variant='primary' disabled>
                      {isSendingFirstEmail} <Spinner size='sm' />
                    </Button>
                  )}
                  {!isSendingFirstEmail && !isFirstEmailSent && (
                    <Button variant='primary' onClick={handleSendFirstEmail}>
                      Send email now{' '}
                      <div className='yellow-icon'>
                        <EmailIcon />
                      </div>
                    </Button>
                  )}
                </div>
                <div
                  className={`step-wrapper ${!isFirstEmailSent ? 'transparent' : ''}`}
                >
                  <h6>Step 2</h6>
                  <div className='heading'>
                    <h5>Start your campaign</h5>
                    <span>
                      Enable your agents to start creating and sending emails
                      according to your approval settings
                    </span>
                  </div>

                  {isAutopilotOn && (
                    <Button variant='outline-primary' className='done-button'>
                      Autopilot on <ThumbsUpIcon />
                    </Button>
                  )}
                  {isTurningOnAutopilot && (
                    <Button variant='primary' disabled>
                      Switching On <Spinner size='sm' />
                    </Button>
                  )}
                  {!isAutopilotOn && !isTurningOnAutopilot && (
                    <Button variant='primary' onClick={handleTurnOnAutopilot}>
                      Turn on Autopilot{' '}
                      <div className='yellow-icon'>
                        <PlayIcon />
                      </div>
                    </Button>
                  )}
                  <div
                    className={`settings-holder ${!isFirstEmailSent ? 'transparent' : ''}`}
                  >
                    <Settings oagentId={createOptions.oagent_id} />
                  </div>
                </div>
              </div>
            </DreamModalInner.Body>
            <DreamModalInner.Footer>
              <Button
                variant='primary'
                disabled={isSendingFirstEmail || isTurningOnAutopilot}
                onClick={handleGoToCampaign}
              >
                Go to Campaign{' '}
                <div className='yellow-icon'>
                  <FontAwesomeIcon icon={faArrowRight} />
                </div>
              </Button>
            </DreamModalInner.Footer>
          </DreamModalInner>
        )}
      </DreamSideModal>
    </>
  );
};

export default StartCampaignModal;
