import React, { useState } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faArrowLeft,
  faArrowRight,
  faPlus,
} from '@fortawesome/free-solid-svg-icons';
import { useAuth0 } from '@auth0/auth0-react';
import { Button, Form, Spinner } from 'react-bootstrap';
import StageAdditionalOptions from '../StageAdditionalOptions';
import functionManifests from '../../utils/function-manifests';
import { ReactComponent as DocsIcon } from '../../icons/docs.svg';
import { ReactComponent as AgentFilterIcon } from '../../icons/agent-filter.svg';
import api from '../../api';
import './advanced-funnel-create.css';

import AgentFilterEditModal from './AgentFilterEditModal';
import DreamInfoExpander from '../misc/DreamInfoExpander';

function AdvancedMode() {
  const { getAccessTokenSilently } = useAuth0();
  const navigate = useNavigate();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const name = searchParams.get('name');
  const use_case_type = searchParams.get('use_case_type');
  const use_case_description = searchParams.get('use_case_description');

  const [companySearch, setCompanySearch] = useState({
    type: '',
    metadata: {},
  });
  const [companyFilters, setCompanyFilters] = useState([]);
  const [showFilterModal, setShowFilterModal] = useState(false);
  const [editingFilterIndex, setEditingFilterIndex] = useState(null);
  const [creating, setCreating] = useState(false);
  const [errors, setErrors] = useState([]);

  const handleSearchTypeChange = (type) => {
    setCompanySearch({ type, metadata: {} });
  };

  const handleSearchMetadataChange = (option, value) => {
    /* 
      IF CHANGING THIS YOU SHOULD PROBABLY CHANGE CREATEFUNNEL METADATA CHANGE
    */
    if (Array.isArray(value) && value.length > 0 && value[0].label) {
      value = value.map((x) => x.value);
    }

    setCompanySearch({
      ...companySearch,
      metadata: {
        ...companySearch.metadata,
        [option]: value,
      },
    });
  };

  const handleFilterMetadataChange = (option, value) => {
    const updatedFilters = [...companyFilters];
    updatedFilters[editingFilterIndex].metadata = {
      ...updatedFilters[editingFilterIndex].metadata,
      [option]: value,
    };
    setCompanyFilters(updatedFilters);
  };

  const addFilter = () => {
    const newFilter = { type: 'ai_filter', metadata: {} };
    setCompanyFilters((prevFilters) => [...prevFilters, newFilter]);
    setEditingFilterIndex(companyFilters.length);
    setShowFilterModal(true);
  };

  const editFilter = (index, filter) => {
    const newFilters = [...companyFilters];
    newFilters[index] = filter;
    setCompanyFilters(newFilters);
    setShowFilterModal(false);
    setEditingFilterIndex(null);
  };

  const removeFilter = (index) => {
    const newFilters = [...companyFilters];
    newFilters.splice(index, 1);
    setCompanyFilters(newFilters);
  };

  const validateForm = () => {
    /* 
      IF CHANGING THIS YOU SHOULD PROBABLY CHANGE CREATEMODE VALIDATE
    */
    const errorList = [];
    if (!companySearch.type) {
      errorList.push('Search method is required.');
    }

    // Validate company_search
    const searchManifest = functionManifests['company_search'].find(
      (opt) => opt.type === companySearch?.type,
    );

    const searchMetadata = companySearch.metadata;
    if (searchManifest && searchManifest.validate) {
      if (Array.isArray(searchManifest.validate)) {
        searchManifest.validate.forEach((entry) => {
          if (!searchMetadata[entry]) {
            errorList.push(`"${searchManifest.name}" - ${entry} is required`);
          }
        });
      } else if (typeof searchManifest.validate === 'function') {
        const validationErrors = searchManifest.validate(searchMetadata);
        if (!Array.isArray(validationErrors) && validationErrors)
          return errorList;
        errorList.push(...validationErrors);
      }
    }

    // Validate company_filters
    companyFilters.forEach((filter, index) => {
      const filterManifest = functionManifests['company_filter'].find(
        (opt) => opt.type === filter.type,
      );

      const filterMetadata = filter.metadata;
      if (filterManifest && filterManifest.validate) {
        if (Array.isArray(filterManifest.validate)) {
          filterManifest.validate.forEach((entry) => {
            if (!filterMetadata[entry]) {
              errorList.push(
                `Filter ${index + 1} "${filterManifest.name}" - ${entry} is required`,
              );
            }
          });
        } else if (typeof filterManifest.validate === 'function') {
          const validationErrors = filterManifest.validate(filterMetadata);
          if (!Array.isArray(validationErrors) && validationErrors) return;
          errorList.push(...validationErrors);
        }
      }
    });

    return errorList;
  };

  const handleFormSubmit = async () => {
    const token = await getAccessTokenSilently();

    const validationErrors = validateForm();
    if (validationErrors.length) {
      setErrors(validationErrors);
      return;
    }

    setErrors([]);
    setCreating(true);

    const funnelData = {
      name,
      use_case_type: use_case_type || 'other',
      use_case_description: use_case_description || '',
      stages: {
        company_search: [companySearch],
        company_filter: companyFilters,
      },
    };

    try {
      const response = await api.post('/funnels', funnelData, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (response.status === 200) {
        navigate(`/funnel/${response.data?.funnelId}`);
      } else {
        alert('An error occurred while creating the funnel.');
      }
    } catch (error) {
      alert(
        `An error occurred: ${error.response ? error.response.data.error : error.message}`,
      );
    } finally {
      setCreating(false);
    }
  };

  return (
    <div className='page-wrapper'>
      <div className='page-inner-wrapper advanced-funnel-create'>
        <div className='page-header-area'>
          <div className='title-and-text'>
            <div className='breadcrumbs'>
              <div className='set'>
                <Link to='/funnel'>
                  <FontAwesomeIcon icon={faArrowLeft} />
                  Back to Lists
                </Link>
              </div>
            </div>
            <h3>{name}</h3>
          </div>
        </div>
        <div className='advanced-lower-area'>
          <div className='lower-area-header'>
            <h5>Create your prompts</h5>
          </div>

          <div className='lower-area-form'>
            {creating && (
              <div className='loading-wrapper'>
                <Spinner />
              </div>
            )}
            {!creating && (
              <>
                <DreamInfoExpander
                  alwaysExpanded
                  heading='Getting started - Create your prompt'
                  icon={<DocsIcon />}
                >
                  <p>
                    Creating your own prompt allows you full control over how
                    your list is created. We recommend reading through our
                    documentation before you start.{' '}
                  </p>
                  <p>
                    <a
                      href='https://usedream.ai/docs'
                      target='_blank'
                      rel='noopener noreferrer'
                    >
                      View the docs
                    </a>
                  </p>
                </DreamInfoExpander>
                {errors.length > 0 && (
                  <div className='errors-wrapper'>
                    <div className='errors'>
                      {errors.map((error, index) => (
                        <p key={index}>{error}</p>
                      ))}
                    </div>
                  </div>
                )}
                <div className='advanced-form-wrapper dream-side-modal-form'>
                  <div className='form-group'>
                    <Form.Label>Search method</Form.Label>
                    <Form.Text>
                      How will your agent find companies for your list?
                    </Form.Text>
                    <div className='choices'>
                      {functionManifests.company_search
                        .filter((search) => search.is_foundational_type)
                        .map((search) => (
                          <div
                            key={search.type}
                            className={`choice ${
                              companySearch.type === search.type ? 'active' : ''
                            }`}
                            onClick={() => handleSearchTypeChange(search.type)}
                          >
                            {search.icon}
                            <span>{search.name}</span>
                          </div>
                        ))}
                    </div>
                  </div>
                  {companySearch.type && (
                    <>
                      <>
                        <StageAdditionalOptions
                          manifest={functionManifests.company_search}
                          stage={companySearch}
                          handleMetadataChange={handleSearchMetadataChange}
                          useDevNaming
                        />
                        <div className='form-group'>
                          <Form.Label>Add additional Agent Filter</Form.Label>
                          <Form.Text>
                            Provide additional filtering against specified
                            criteria
                          </Form.Text>
                          <div className='filters-wrapper'>
                            {companyFilters.map((filter, index) => (
                              <div key={index} className='filter-choice'>
                                <div className='choice-left'>
                                  <AgentFilterIcon />
                                  <span>
                                    {filter.metadata.ai_request || 'Filter'}
                                  </span>
                                </div>
                                <div className='links'>
                                  <span
                                    className='edit'
                                    onClick={() => {
                                      setShowFilterModal(true);
                                      setEditingFilterIndex(index);
                                    }}
                                  >
                                    Edit
                                  </span>
                                  <span
                                    className='remove'
                                    onClick={() => removeFilter(index)}
                                  >
                                    Remove
                                  </span>
                                </div>
                              </div>
                            ))}
                            <Button
                              className='add-agent-filter'
                              onClick={addFilter}
                            >
                              <FontAwesomeIcon icon={faPlus} />
                              Add agent filter
                            </Button>
                          </div>
                        </div>
                      </>
                      <div className='create-list-builder-wrapper funnel'>
                        <Button
                          variant='primary'
                          onClick={handleFormSubmit}
                          className='create-list-builder'
                          disabled={creating}
                        >
                          <>
                            Create List
                            <FontAwesomeIcon icon={faArrowRight} />
                          </>
                        </Button>
                      </div>
                    </>
                  )}
                </div>
              </>
            )}
          </div>
        </div>
      </div>
      <AgentFilterEditModal
        show={showFilterModal}
        onHide={() => {
          setShowFilterModal(false);
          setEditingFilterIndex(null);
        }}
        editingFilterIndex={editingFilterIndex}
        handleMetadataChange={handleFilterMetadataChange}
        companyFilters={companyFilters}
        editFilter={editFilter}
      />
    </div>
  );
}

export default AdvancedMode;
