import React, { useEffect, useState } from 'react';
import { Form, Button } from 'react-bootstrap';
import api from '../../api';
import { useAuth0 } from '@auth0/auth0-react';
import { debounce } from 'lodash';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';

import { ReactComponent as BasicInfoIcon } from '../../icons/basic-info.svg';
import { ReactComponent as HistoryIcon } from '../../icons/history.svg';
import { ReactComponent as KeyFeaturesIcon } from '../../icons/key-features.svg';
import { ReactComponent as BasicFeaturesIcon } from '../../icons/basic-features.svg';
import { ReactComponent as KnownMissingIcon } from '../../icons/known-missing-features.svg';
import SlideySection from './SlideySection';

const BasicInfo = ({ productId }) => {
  const { getAccessTokenSilently } = useAuth0();
  const [product, setProduct] = useState({
    description: '',
    history: '',
    features: [],
    known_missing_features: [],
  });
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchProduct = async () => {
      try {
        const token = await getAccessTokenSilently();
        const response = await api.get(`/products/${productId}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        setProduct(response.data);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching product:', error);
        setError('Failed to load product details');
        setLoading(false);
      }
    };

    fetchProduct();
  }, [getAccessTokenSilently, productId]);

  // Debounce function for auto-saving inputs
  const debounceSave = debounce((fields) => {
    saveProduct(fields);
  }, 500);

  // Debounce function for auto-saving features
  const debounceSaveFeatures = debounce((features) => {
    saveProductFeatures(features);
  }, 500);

  // Debounce function for auto-saving known missing features
  const debounceSaveMissingFeatures = debounce((missingFeatures) => {
    saveProductMissingFeatures(missingFeatures);
  }, 500);

  const handleInputChange = (key, value) => {
    const updatedProduct = { ...product, [key]: value };
    setProduct(updatedProduct);
    debounceSave({ [key]: value });
  };

  const handleFeatureChange = (originalIndex, key, value) => {
    const updatedFeatures = [...product.features];
    updatedFeatures[originalIndex][key] = value;
    setProduct({ ...product, features: updatedFeatures });
    debounceSaveFeatures(updatedFeatures);
  };

  const handleMissingFeatureChange = (index, key, value) => {
    const updatedMissingFeatures = [...product.known_missing_features];
    updatedMissingFeatures[index][key] = value;
    setProduct({ ...product, known_missing_features: updatedMissingFeatures });
    debounceSaveMissingFeatures(updatedMissingFeatures);
  };

  const addFeature = (isKey = false) => {
    const newFeature = isKey
      ? { is_key: true, name: '', appeal: '' }
      : { name: '', is_key: false };
    const updatedFeatures = [...product.features, newFeature];
    setProduct({ ...product, features: updatedFeatures });
    saveProductFeatures(updatedFeatures); // Save immediately after adding
  };

  const addMissingFeature = () => {
    const newMissingFeature = { name: '', additional_context: '' };
    const updatedMissingFeatures = [
      ...product.known_missing_features,
      newMissingFeature,
    ];
    setProduct({ ...product, known_missing_features: updatedMissingFeatures });
    saveProductMissingFeatures(updatedMissingFeatures); // Save immediately after adding
  };

  const saveProduct = async (fields) => {
    try {
      const token = await getAccessTokenSilently();
      await api.put(`/products/${productId}`, fields, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
    } catch (error) {
      console.error('Error saving product:', error);
    }
  };

  const saveProductFeatures = async (features) => {
    try {
      const token = await getAccessTokenSilently();
      await api.put(
        `/products/${productId}/features`,
        { features: features },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      );
    } catch (error) {
      console.error('Error saving product features:', error);
    }
  };

  const saveProductMissingFeatures = async (missingFeatures) => {
    try {
      const token = await getAccessTokenSilently();
      await api.put(
        `/products/${productId}/known-missing-features`,
        { known_missing_features: missingFeatures },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      );
    } catch (error) {
      console.error('Error saving product known missing features:', error);
    }
  };

  if (loading) return <p>Loading...</p>;
  if (error) return <p>{error}</p>;

  return (
    <div className='basic-info-wrapper product-form-page'>
      <div className='header'>
        <h2>Basic information</h2>
        <p>
          Give your agents information about your product or service so that
          they can reach their objectives.
        </p>
      </div>
      <div className='product-form-wrapper'>
        <SlideySection
          header='About this product'
          icon={<BasicInfoIcon />}
          description='Give the elevator pitch for your product or service as if describing
            it to a new employee on day one.'
        >
          <Form.Control
            as='textarea'
            value={product.description}
            onChange={(e) => handleInputChange('description', e.target.value)}
          />
        </SlideySection>
        <SlideySection
          header='History'
          icon={<HistoryIcon />}
          description='When was the product released on the market? Has it had any
            significant changes recently and if so when?'
        >
          <Form.Control
            as='textarea'
            value={product.history}
            onChange={(e) => handleInputChange('history', e.target.value)}
          />
        </SlideySection>
        <SlideySection
          icon={<KeyFeaturesIcon />}
          header='Key features'
          description='What are the products key features and their appeal?'
        >
          <div className='shared-list dream-list'>
            <div className='header-row'>
              <h5>Feature</h5>
              <h5>Customer value</h5>
            </div>
            <div className='body-rows'>
              {product.features.map(
                (feature, originalIndex) =>
                  feature.is_key && (
                    <div
                      key={originalIndex}
                      className='shared-list-row feature-row'
                    >
                      <Form.Control
                        type='text'
                        value={feature.name}
                        onChange={(e) =>
                          handleFeatureChange(
                            originalIndex,
                            'name',
                            e.target.value,
                          )
                        }
                        placeholder='e.g. Advanced Deduction Finder (ADF)'
                      />
                      <Form.Control
                        type='text'
                        value={feature.appeal}
                        onChange={(e) =>
                          handleFeatureChange(
                            originalIndex,
                            'appeal',
                            e.target.value,
                          )
                        }
                        placeholder='e.g. Allows customers to never worry about finding all their deductions'
                      />
                    </div>
                  ),
              )}
            </div>
            <div className='add-another-button-wrapper'>
              <Button onClick={() => addFeature(true)}>
                Add key feature{' '}
                <div className='yellow-icon'>
                  <FontAwesomeIcon icon={faPlus} />{' '}
                </div>
              </Button>
            </div>
          </div>
        </SlideySection>
        <SlideySection
          icon={<BasicFeaturesIcon />}
          header='Basic features'
          description='What are the other, more basic features of the product. This helps
              your agent answer questions about capabilities.'
        >
          <div className='shared-list dream-list'>
            <div className='body-rows'>
              {product.features.map(
                (feature, originalIndex) =>
                  !feature.is_key && (
                    <div className='shared-list-row' key={originalIndex}>
                      <Form.Control
                        type='text'
                        value={feature.name}
                        onChange={(e) =>
                          handleFeatureChange(
                            originalIndex,
                            'name',
                            e.target.value,
                          )
                        }
                        placeholder='e.g. CSV Import and Export'
                      />
                    </div>
                  ),
              )}
            </div>
            <div className='add-another-button-wrapper'>
              <Button onClick={() => addFeature(false)}>
                Add basic feature{' '}
                <div className='yellow-icon'>
                  <FontAwesomeIcon icon={faPlus} />
                </div>
              </Button>
            </div>
          </div>
        </SlideySection>
        <SlideySection
          icon={<KnownMissingIcon />}
          header='Known missing features'
          description='What features do you know are missing that the customer might ask
              about?'
        >
          <div className='shared-list dream-list'>
            <div className='header-row'>
              <h5>Name</h5>
              <h5>Context</h5>
            </div>
            <div className='body-rows'>
              {product.known_missing_features.map((feature, index) => (
                <div key={index} className='feature-row shared-list-row'>
                  <Form.Control
                    type='text'
                    value={feature.name}
                    onChange={(e) =>
                      handleMissingFeatureChange(index, 'name', e.target.value)
                    }
                    placeholder='e.g. Facebook Integration'
                  />
                  <Form.Control
                    type='text'
                    value={feature.additional_context}
                    onChange={(e) =>
                      handleMissingFeatureChange(
                        index,
                        'additional_context',
                        e.target.value,
                      )
                    }
                    placeholder='e.g. It will be added in Q3 2024, until then we can do manually.'
                  />
                </div>
              ))}
            </div>
            <div className='add-another-button-wrapper'>
              <Button onClick={addMissingFeature}>
                Add missing feature{' '}
                <div className='yellow-icon'>
                  <FontAwesomeIcon icon={faPlus} />
                </div>
              </Button>
            </div>
          </div>
        </SlideySection>
      </div>
    </div>
  );
};

export default BasicInfo;
