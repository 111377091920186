import React, { useEffect, useState } from 'react';
import { Form, Spinner } from 'react-bootstrap';
import api from '../../api';
import { useAuth0 } from '@auth0/auth0-react';
import { debounce } from 'lodash';
import SlideySection from './SlideySection';

const Pricing = ({ productId }) => {
  const { getAccessTokenSilently } = useAuth0();
  const [product, setProduct] = useState({
    pricing: '',
    pricing_discussion_strategy: '',
  });
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchProduct = async () => {
      try {
        const token = await getAccessTokenSilently();
        const response = await api.get(`/products/${productId}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        setProduct(response.data);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching product:', error);
        setError('Failed to load product details');
        setLoading(false);
      }
    };

    fetchProduct();
  }, [getAccessTokenSilently, productId]);

  // Debounce function for auto-saving inputs
  const debounceSave = debounce((fields) => {
    saveProduct(fields);
  }, 500);

  const handleInputChange = (key, value) => {
    const updatedProduct = { ...product, [key]: value };
    setProduct(updatedProduct);
    debounceSave({ [key]: value });
  };

  const saveProduct = async (fields) => {
    try {
      const token = await getAccessTokenSilently();
      await api.put(`/products/${productId}`, fields, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
    } catch (error) {
      console.error('Error saving product:', error);
    }
  };

  if (loading) return <p>Loading...</p>;
  if (error) return <p>{error}</p>;

  return (
    <div className='product-form-page'>
      <div className='header'>
        <h2>Pricing</h2>
        <p>
          Your agents need to know your pricing so that they can respond to
          customer questions and carry out tasks.
        </p>
      </div>
      <div className='product-form-wrapper'>
        <SlideySection
          header='Pricing discussion strategy'
          description='Select the strategy for discussing pricing with potential customers.'
        >
          <Form.Control
            as='select'
            value={product.pricing_discussion_strategy}
            onChange={(e) =>
              handleInputChange('pricing_discussion_strategy', e.target.value)
            }
          >
            <option value=''>--- Please Select ---</option>
            <option value='give_no_indication_before_demo'>
              Give no indication before demo
            </option>
            <option value='give_full_details_before_demo'>
              Give full details before demo
            </option>
          </Form.Control>
        </SlideySection>
        <SlideySection
          header='Pricing description'
          description='Provide an overview of the pricing tiers for your product.'
        >
          <Form.Control
            as='textarea'
            value={product.pricing}
            onChange={(e) => handleInputChange('pricing', e.target.value)}
            placeholder={`e.g. TurboTax offers three pricing tiers:

Basic Plan: $99/month – Ideal for individuals with basic tax needs. Includes standard tax filing and basic deduction assistance.

Pro Plan: $299/month – Perfect for freelancers and small business owners. Includes advanced deduction finder, real-time tax strategy suggestions, and multi-income tracking.

Enterprise Plan: $499/month – Designed for larger businesses. Includes all Pro features plus personalized support from certified tax professionals, and quarterly tax health reviews.

Additional seats can be added at $50/seat per month.`}
          />
        </SlideySection>
      </div>
    </div>
  );
};

export default Pricing;
