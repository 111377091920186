import React, { useState, useEffect } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { Spinner } from 'react-bootstrap';
import api from '../../api';
import './funnel.css';

const CreditCost = ({
  funnelId,
  stages,
  isEffectivelyEmpty,
  researchPointChanges,
}) => {
  const { getAccessTokenSilently } = useAuth0();
  const [creditCost, setCreditCost] = useState(null);
  const [searchCost, setSearchCost] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchCreditCost = async () => {
      try {
        setLoading(true);
        const token = await getAccessTokenSilently();
        const response = await api.get(`/funnels/${funnelId}/credit-cost`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        setCreditCost(response.data.credit_cost);
        setSearchCost(response.data.search_cost);
      } catch (error) {
        console.error('Error fetching credit cost:', error);
      } finally {
        setLoading(false);
      }
    };

    if (stages.company_search) {
      fetchCreditCost();
    } else {
      setLoading(false);
    }
  }, [funnelId, getAccessTokenSilently, stages, researchPointChanges]);

  const classNames = () =>
    `stage-entry ${isEffectivelyEmpty('company_filter') ? 'smaller' : 'medium'}`;

  if (loading) {
    return (
      <div className={classNames()}>
        <Spinner size='sm' />
      </div>
    );
  }

  if (creditCost === null) {
    return <div className={classNames()}>Error loading cost</div>;
  }

  if (creditCost === 0) {
    return <></>;
  }

  const message =
    creditCost === 1 ? (
      <div className={classNames()}>
        <p>
          It will cost <b>1</b> credit to source a company.
        </p>
      </div>
    ) : (
      <div className={classNames()}>
        <p>
          It will cost <b>{Number(creditCost).toFixed(2)}</b> credits to source
          a company.
        </p>
        {!isEffectivelyEmpty('company_filter') && (
          <p>
            Companies that don't pass your filter will cost{' '}
            <b>{Math.round(searchCost)}</b> credits
          </p>
        )}
      </div>
    );

  return message;
};

export default CreditCost;
