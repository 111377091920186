import React, { useState } from 'react';
import { Alert, Button } from 'react-bootstrap';

import StageAdditionalOptions from '../StageAdditionalOptions';
import functionManifests from '../../utils/function-manifests';
import DreamModalInner from '../misc/DreamModalInner';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';

import { ReactComponent as ListBuilderIcon } from '../../icons/list-builder.svg';
import { ReactComponent as MagicWandIcon } from '../../icons/magic-wand.svg';

import SearchVolumeEstimate from '../misc/SearchVolumeEstimate';
import { useNavigate } from 'react-router-dom';

function SearchConfigModal({
  searchStage,
  handleTypeChange,
  handleMetadataChange,
  isImport,
  handleBack,
  handleSubmit,
  errors,
  name,
  useCaseOther,
  useCaseType,
}) {
  const [advancedSelected, setAdvancedSelected] = useState(false);
  const [view, setView] = useState('type_select');

  const navigate = useNavigate();

  const selectAdvanced = () => {
    setAdvancedSelected(true);
    handleTypeChange(null);
  };

  const handleStepTypeSelect = (type) => {
    setAdvancedSelected(false);
    handleTypeChange(type);
  };

  const checkShouldShowForType = (currentStage, option) => {
    if (currentStage !== 'company_search') {
      return true;
    }
    return isImport ? option.import : !option.import;
  };

  return (
    <>
      <DreamModalInner className='funnel-create-modal funnel'>
        <DreamModalInner.Header>
          <ListBuilderIcon />{' '}
          <>{isImport ? 'Import List' : 'Create new List'}</>
        </DreamModalInner.Header>
        <DreamModalInner.Body>
          {errors?.length > 0 && (
            <div className='errors-area'>
              <Alert variant='danger'>
                {errors.map((x, i) => (
                  <p key={i}>{x}</p>
                ))}
              </Alert>
            </div>
          )}
          <>
            {view === 'type_select' && (
              <>
                <div className='search-type-outer-wrapper'>
                  <div className='basic-section'>
                    <h5>Popular Searches</h5>
                    <div className='search-type-select'>
                      {functionManifests['company_search']
                        ?.filter((option) => !option.hide_from_basic_selection)
                        .filter((option) =>
                          checkShouldShowForType('company_search', option),
                        )
                        .map((option) => (
                          <div
                            key={option.type}
                            className={`option-wrapper ${searchStage?.type === option.type ? 'active' : ''}`}
                            onClick={() => handleStepTypeSelect(option.type)}
                          >
                            <div className='icon-wrapper'>{option.icon}</div>
                            <div className='option-name'>
                              <p>{option.name}</p>
                              {option.experimental && (
                                <p className='tag'>Beta</p>
                              )}
                            </div>
                            {
                              <p className='long-description'>
                                {option.description}
                              </p>
                            }
                          </div>
                        ))}
                    </div>
                  </div>
                  <div className='advanced-seciton'>
                    <h5>I want to create my own search</h5>
                    <div
                      className={`setup-prompts-button ${advancedSelected && 'active'}`}
                      onClick={selectAdvanced}
                    >
                      <MagicWandIcon />
                      Setup my own prompts
                    </div>
                  </div>
                </div>
              </>
            )}
            {view === 'option_select' && (
              <div>
                <div className='options-tab-content'>
                  <div className='options-tab-content-header'>
                    <h4>
                      {
                        functionManifests['company_search']?.find(
                          (x) => x.type === searchStage?.type,
                        )?.name
                      }
                    </h4>
                    {searchStage?.type && (
                      <p className='long-description'>
                        {functionManifests['company_search'].find(
                          (x) => x.type === searchStage?.type,
                        )?.long_description ||
                          functionManifests['company_search'].find(
                            (x) => x.type === searchStage?.type,
                          )?.description}
                      </p>
                    )}
                  </div>
                  <StageAdditionalOptions
                    manifest={functionManifests['company_search']}
                    stage={searchStage}
                    handleMetadataChange={handleMetadataChange}
                  />
                </div>
                <div className='match-count'>
                  {searchStage?.type &&
                    functionManifests['company_search'].find(
                      (x) => x.type === searchStage?.type,
                    )?.match_language && (
                      <div className='search-step-matches-view'>
                        <b>
                          <SearchVolumeEstimate
                            type={searchStage.type}
                            metadata={searchStage?.metadata}
                          />
                        </b>{' '}
                        {functionManifests['company_search'].find(
                          (x) => x.type === searchStage?.type,
                        )?.match_sample_text || 'matches'}
                      </div>
                    )}
                </div>
              </div>
            )}
          </>
        </DreamModalInner.Body>
        <DreamModalInner.Footer>
          {view === 'type_select' && (
            <>
              <Button
                variant='primary'
                onClick={() => {
                  if (!advancedSelected) {
                    setView('option_select');
                  } else {
                    return navigate(
                      `/advanced-mode?name=${name}&use_case_type=${useCaseType}&use_case_description=${useCaseOther}`,
                    );
                  }
                }}
                disabled={!searchStage?.type && !advancedSelected}
              >
                Settings <FontAwesomeIcon icon={faArrowRight} />
              </Button>
              <Button variant='outline-primary' onClick={handleBack}>
                Back
              </Button>
            </>
          )}
          {view === 'option_select' && (
            <>
              <Button variant='primary' onClick={handleSubmit}>
                Create List
              </Button>
              <Button
                variant='outline-primary'
                onClick={() => setView('type_select')}
              >
                Back
              </Button>
            </>
          )}
          {view === 'test_select' && (
            <>
              <Button
                variant='outline-primary'
                onClick={() => setView('option_select')}
              >
                Back
              </Button>
            </>
          )}
        </DreamModalInner.Footer>
      </DreamModalInner>
    </>
  );
}

export default SearchConfigModal;
