import React, { useState, useEffect } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import DataTable from 'react-data-table-component';
import { Spinner, Alert, Button } from 'react-bootstrap';
import api from '../../../api';
import { usePusher } from '../../../contexts/PusherContext';
import FakePic from '../../feed/items/FakePic';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { ReactComponent as TrashIcon } from '../../../icons/trash.svg';

import '../campaign-new.css';

function ProspectsList({
  embedded,
  nextText,
  nextFn,
  openSettings,
  prospects,
  setProspects,
  isInitialCheck,
}) {
  const { id: oagentId } = useParams();
  const pusherChannel = usePusher();
  const navigate = useNavigate();

  const { getAccessTokenSilently } = useAuth0();
  const [oagent, setOagent] = useState(null);

  const [error, setError] = useState(null);
  const [runSummary, setRunSummary] = useState(null);
  const [selectedRows, setSelectedRows] = useState([]); // State to hold selected rows
  const [missingCompanies, setMissingCompanies] = useState([]); // State to hold missing companies

  const fetchDetails = async () => {
    try {
      const token = await getAccessTokenSilently();
      const [runSummaryResponse, missingCompaniesResponse] = await Promise.all([
        api.get(`/oagents/${oagentId}/import-run-summary/prospects`, {
          headers: { Authorization: `Bearer ${token}` },
        }),
        api.get(`/oagents/${oagentId}/companies-missing-prospects`, {
          headers: { Authorization: `Bearer ${token}` },
        }),
      ]);
      setRunSummary(runSummaryResponse.data);
      setMissingCompanies(missingCompaniesResponse.data);
    } catch (error) {
      console.error('Error fetching data:', error);
      setError('Error fetching data.');
    }
  };

  const fetchMissingCompanies = async () => {
    try {
      const token = await getAccessTokenSilently();
      const missingCompaniesResponse = await api.get(
        `/oagents/${oagentId}/companies-missing-prospects`,
        {
          headers: { Authorization: `Bearer ${token}` },
        },
      );
      setMissingCompanies(missingCompaniesResponse.data);
    } catch (error) {
      console.error('Error fetching data:', error);
      setError('Error fetching data');
    }
  };

  const fetchOagent = async () => {
    try {
      const token = await getAccessTokenSilently();
      const oagentResponse = await api.get(`/oagents/${oagentId}`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      setOagent(oagentResponse.data);
    } catch (error) {
      console.error('Error fetching oagent:', error);
      setError('Error fetching oagent.');
    }
  };

  useEffect(() => {
    fetchOagent();
    fetchDetails();

    if (!pusherChannel) return;
    const pusherCallback = (data) => {
      if (parseInt(data.oagentId, 10) === parseInt(oagentId, 10)) {
        if (data.sub_type === 'new_prospect' && data.prospect) {
          setProspects((prevOrgs) => {
            // Just check we haven't already listed them
            const prevEntry = prevOrgs.some((x) => x.id == data.prospect.id);

            if (!prevEntry) {
              return [...prevOrgs, data.prospect];
            } else if (prevEntry.status === 'inactive') {
              return [
                ...prevOrgs.filter((x) => x.id !== prevEntry.id),
                data.prospect,
              ];
            } else {
              console.log(prevEntry);
              return prevOrgs;
            }
          });
        } else if (data.sub_type === 'import_run_update') {
          setRunSummary(data.prospect_summary);
        }

        // We'll call this as we don't get the data back from the backend
        fetchMissingCompanies();
      }
    };

    pusherChannel.bind('oagent_status_update', pusherCallback);

    // Cleanup on unmount
    return () => {
      pusherChannel.unbind('oagent_status_update', pusherCallback);
    };
  }, [getAccessTokenSilently, oagentId, pusherChannel]);

  const handleDelete = async () => {
    setError(null); // Reset error before deleting
    try {
      const token = await getAccessTokenSilently();
      const idsToDelete = selectedRows.map((row) => row.id);

      // Optimistically remove the selected rows
      setProspects(
        prospects.filter((prospect) => !idsToDelete.includes(prospect.id)),
      );
      setSelectedRows([]); // Clear the selection

      await api.post(
        `/oagents/${oagentId}/deactivate-prospects`,
        { ids: idsToDelete },
        {
          headers: { Authorization: `Bearer ${token}` },
        },
      );
    } catch (error) {
      console.error('Error during delete:', error);
      setError('Error during delete.');
    }
  };

  const processingOrWaiting =
    runSummary &&
    runSummary.status_counts
      .filter(
        (item) => item.status === 'processing' || item.status === 'waiting',
      )
      .reduce((sum, item) => sum + parseInt(item.count, 10), 0);

  const clickedFn = embedded
    ? (row) => window.open(row.linkedin_url)
    : (row) => navigate(`/prospect/${row.id}?origin=${oagentId}&type=wizard`);

  const columns = [
    {
      name: '',
      selector: (row) => (
        <div className='logo-name' onClick={() => clickedFn(row)}>
          <FakePic url={row.logo_url} />
        </div>
      ),
      width: '70px',
    },
    {
      name: 'Name',
      selector: (row) => row.name,
      sortable: true,
      sortFunction: (rowA, rowB) => (rowA.name > rowB.name ? -1 : 1),
    },
    {
      name: 'Email',
      selector: (row) => row.email,
      sortable: true,
      sortFunction: (rowA, rowB) => (rowA.email > rowB.email ? -1 : 1),
    },
    {
      name: 'Title',
      selector: (row) => row.job_title,
      sortable: true,
      sortFunction: (rowA, rowB) => (rowA.job_title > rowB.job_title ? -1 : 1),
    },
    {
      name: 'Organization',
      selector: (row) => row.org_name,
      sortable: true,
      sortFunction: (rowA, rowB) => (rowA.org_name > rowB.org_name ? -1 : 1),
    },
    {
      name: 'Status',
      selector: (row) => {
        let statusText;
        if (row.status === 'placeholder') {
          return;
        }

        if (row.status === 'inactive') {
          return <p className='status-tag negative'>{row.status_explainer}</p>;
        }

        if (row.status === 'outreaching' && row.conversation_status !== null) {
          const cs =
            row.conversation_status.slice(0, 1).toUpperCase() +
            row.conversation_status.slice(1).toLowerCase();

          let add;
          if (cs === 'Fail') {
            add = 'negative';
          } else if (cs === 'Contacted') {
            add = 'neutral';
          } else if (cs === 'Replied') {
            add = 'positive';
          } else if (cs === 'Booked') {
            add = 'dream-positive';
          } else {
            add = 'positive';
          }

          return <p className={`status-tag ${add}`}>{cs}</p>;
        }

        if (row.status === 'outreaching' && row.conversation_status === null) {
          if (row.autopilot_status) {
            statusText = 'Outreaching';
          } else {
            statusText = 'Ready';
          }
          return <p className='status-tag neutral'>{statusText}</p>;
        }

        statusText = row.status.charAt(0).toUpperCase() + row.status.slice(1);
        if (row.status_explainer) {
          statusText += ` - ${row.status_explainer}`;
        }

        return <p className='status-tag neutral'>{statusText}</p>;
      },
      sortable: true,
      sortFunction: (rowA, rowB) => {
        const aStatus =
          rowA.status === 'inactive' ? 'zzz' : rowA.conversation_status;
        const bStatus =
          rowB.status === 'inactive' ? 'zzz' : rowB.conversation_status;

        if (!aStatus && bStatus) {
          return -1;
        }
        if (!bStatus && aStatus) {
          return 1;
        }

        if (!bStatus && !aStatus) {
          return -1;
        }

        return aStatus > bStatus ? -1 : 1;
      },
    },
  ];

  const handleRowSelected = (state) => {
    setSelectedRows(state.selectedRows);
  };

  const empty = {
    logo_url: null, // Trigger fakepic
    name: <div className='fake-placeholder'></div>,
    job_title: <div className='fake-placeholder'></div>,
    email: <div className='fake-placeholder'></div>,
    org_name: <div className='fake-placeholder'></div>,
    status: 'placeholder',
  };
  const placeholders = Array(5).fill(empty);

  return (
    <div className={`page-wrapper ${!embedded && 'prospect-list-page'}`}>
      <div className='page-inner-wrapper'>
        {!embedded && (
          <div className='page-header-area'>
            <div className='title-and-text'>
              <div className='breadcrumbs'>
                <div className='set'>
                  <Link to={`/campaign/${oagentId}`}>
                    <FontAwesomeIcon icon={faArrowLeft} /> Back to Campaign
                    Overview
                  </Link>
                </div>
              </div>
              <h3>
                <b>Prospects:</b> {oagent?.name}
              </h3>
            </div>
          </div>
        )}

        <div className='wrapper-of-hope-it-doesnt-break'>
          <div className='embedded-header embedded-list-header'>
            <div className='left'>
              <h5>Prospect list</h5>
              <Button
                variant='outline-primary'
                onClick={
                  embedded
                    ? openSettings
                    : () => navigate(`/campaign-prospect-settings/${oagentId}`)
                }
              >
                Edit target prospects
              </Button>
              {selectedRows.length > 0 && (
                <Button
                  className='table-delete-button'
                  onClick={handleDelete}
                  size='sm'
                >
                  <TrashIcon /> Delete Selected
                </Button>
              )}
            </div>
            {embedded && nextText && (
              <div className='right'>
                <Button variant='primary' onClick={nextFn}>
                  Next: {nextText} <FontAwesomeIcon icon={faArrowRight} />
                </Button>
              </div>
            )}
          </div>
          <div
            className={`processing-alert-wrapper ${processingOrWaiting !== 0 && processingOrWaiting ? 'visible' : 'hidden'}`}
          >
            <Alert variant='info'>
              <Spinner animation='border' size='sm' />
              <span>
                {`We’re searching your target companies for prospects.`}{' '}
                <b>{processingOrWaiting}</b>{' '}
                {processingOrWaiting === 1
                  ? 'company remains'
                  : 'companies remain.'}
              </span>
            </Alert>
          </div>
          <div className={`prospects-lower-area ${embedded && 'embedded'}`}>
            <div className='prospects-list-wrapper'>
              {error && <Alert variant='danger'>{error}</Alert>}
              <div className='dream-table-wrapper'>
                <DataTable
                  columns={columns}
                  data={
                    isInitialCheck
                      ? placeholders
                      : prospects.sort((a, b) =>
                          a.status === 'inactive' ? 1 : -1,
                        )
                  }
                  pagination
                  selectableRows
                  onSelectedRowsChange={handleRowSelected}
                  onRowClicked={clickedFn}
                  highlightOnHover
                  selectAllContextualLabel='Select All/De-select All'
                />
              </div>
              {processingOrWaiting !== null &&
                processingOrWaiting === 0 &&
                missingCompanies.length > 0 && (
                  <Alert variant='dream'>
                    <span>
                      We couldn't find prospects for{' '}
                      {missingCompanies.slice(0, 4).join(', ')}
                      {missingCompanies.length > 4
                        ? ` + ${missingCompanies.length - 4} more`
                        : ''}
                    </span>
                  </Alert>
                )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ProspectsList;
