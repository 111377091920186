import React from 'react';
import linkedinCountries from '../linkedin-countries';
import linkedinIndustries from '../linkedin-industries';
import { ReactComponent as RecruitmentIcon } from '../../icons/recruitment.svg';

const linkedinSearchManifest = {
  name: 'Hiring Search',
  type: 'job_search',
  icon: <RecruitmentIcon />,
  is_foundational_type: true,
  description: 'Search for companies that are currently hiring specific roles.',
  match_language: 'Companies matching criteria',
  match_sample_text: 'jobs found',
  list_language: 'Companies retrieved',
  auto_generate_filter: false,
  additional_options: {
    jobTitles: {
      description: 'Job Titles',
      long_description: 'Which job titles should we look for listings for',
      input_type: 'multiselect-jobtitle-help',
      dev_name: 'job_titles',
    },
    industries: {
      description: 'Industries',
      long_description: 'Which industries should you retrieve companies from?',
      input_type: 'multiselect',
      options: linkedinIndustries,
      dev_name: 'industries',
    },
    countries: {
      description: 'Countries',
      long_description: 'Which countries should you retrieve companies from?',
      input_type: 'multiselect',
      options: linkedinCountries,
      dev_name: 'countries',
    },
  },
  tests: [],
  credit_cost: 2,
  speed_description: (
    <p>
      Agent will retrieve up to <b>300</b> companies per hour.
    </p>
  ),
};

export default linkedinSearchManifest;
