import React from 'react';
import { useAuth0 } from '@auth0/auth0-react';

function GoLogin() {
  const { loginWithRedirect } = useAuth0();
  loginWithRedirect({
    appState: { path: window.location.pathname },
  });

  return <div className='go-login-wrapper'></div>;
}

export default GoLogin;
