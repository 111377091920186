import React, { useState } from 'react';
import { Modal, Button } from 'react-bootstrap';
import MicrosoftIcon from '../../icons/microsoft.png';
import GoogleIcon from '../../icons/google.png';
import { ReactComponent as CalendarIcon } from '../../icons/calendar.svg';
import { ReactComponent as EmailIcon } from '../../icons/email.svg';
import { ReactComponent as PlusIcon } from '../../icons/plus.svg';
import { useAuth0 } from '@auth0/auth0-react';

const AccountLinkingModal = ({ show, onHide }) => {
  const [selectedType, setSelectedType] = useState('');
  const [selectedScope, setSelectedScope] = useState('');
  const { user } = useAuth0();

  const types = [
    {
      type: 'google',
      icon: <img src={GoogleIcon} alt='Google' />,
      politeName: 'Google Account',
    },
    // {
    //   type: 'microsoft',
    //   icon: <img src={MicrosoftIcon} alt='Microsoft' />,
    //   politeName: 'Outlook Account',
    // },
  ];

  const scopes = [
    {
      value: 'Email & Calendar',
      label: 'Calendar & email',
      icon: (
        <>
          <CalendarIcon />
          <PlusIcon />
          <EmailIcon />
        </>
      ),
    },
    { value: 'Calendar', label: 'Calendar only', icon: <CalendarIcon /> },
  ];

  const handleTypeSelection = (type) => {
    setSelectedType(type);
    setSelectedScope(''); // Clear scope selection if type changes
  };

  const handleScopeSelection = (scope) => {
    setSelectedScope(scope);
  };

  const handleLinkAccount = async () => {
    try {
      const scope =
        selectedScope === 'Email & Calendar' ? 'email calendar' : 'calendar';

      const url = `/${selectedType}-link/initiate-${selectedType}-oauth?user_id=${user.sub}&scope=${scope}`;

      window.location.href = process.env.REACT_APP_API_URL + url;
    } catch (error) {
      console.error('Error initiating OAuth:', error);
    }
  };

  return (
    <Modal show={show} onHide={onHide} centered>
      <Modal.Header closeButton>
        <Modal.Title>Add new linked account</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className='account-linking-modal-wrapper'>
          <p>Select your linked account type and connection options.</p>
          <div className='account-type-select'>
            {types.map(({ type, icon, politeName }) => (
              <div
                key={type}
                className={`account-type ${selectedType === type ? 'selected' : ''}`}
                onClick={() => handleTypeSelection(type)}
              >
                {icon}
                <span>{politeName}</span>
              </div>
            ))}
          </div>

          {selectedType && (
            <div className='scope-options-outer-wrapper'>
              <div className='header'>
                <h6>Linking options</h6>
              </div>
              <div className='scope-options'>
                {scopes.map(({ value, label, icon }) => (
                  <div
                    key={value}
                    className={`scope-option ${selectedScope === value ? 'selected' : ''}`}
                    onClick={() => handleScopeSelection(value)}
                  >
                    <div className='icon-area'>{icon}</div>
                    <h6>{label}</h6>
                  </div>
                ))}
              </div>
              {selectedScope && selectedScope === 'Calendar' && (
                <p>
                  <b>Warning:</b> Without email you won't be able to send from
                  this account.
                </p>
              )}
            </div>
          )}
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button variant='outline-primary' onClick={onHide}>
          Cancel
        </Button>
        <Button
          variant='primary'
          disabled={!selectedType || !selectedScope}
          onClick={handleLinkAccount}
        >
          Link account
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default AccountLinkingModal;
