import React, { useEffect, useState } from 'react';
import { Form, Spinner } from 'react-bootstrap';
import { useAuth0 } from '@auth0/auth0-react';
import api from '../../api';

const FunnelSelect = ({ hasPleaseSelect, ...props }) => {
  const { getAccessTokenSilently } = useAuth0();
  const [funnels, setFunnels] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchFunnels = async () => {
      try {
        const token = await getAccessTokenSilently(); // Get the token using Auth0
        const response = await api.get('/funnels', {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        setFunnels(response.data.funnels);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching funnels:', error);
        setLoading(false);
      }
    };

    fetchFunnels();
  }, [getAccessTokenSilently]);

  if (loading) {
    return (
      <div className='d-flex align-items-center'>
        <Spinner animation='border' role='status' className='mr-2'>
          <span className='sr-only'>Loading...</span>
        </Spinner>
        <span>Loading Agent List</span>
      </div>
    );
  }

  return (
    <Form.Control as='select' {...props}>
      {hasPleaseSelect && <option>--- Please Select ---</option>}
      {funnels.map((funnel) => (
        <option key={funnel.id} value={funnel.id}>
          {funnel.name}
        </option>
      ))}
    </Form.Control>
  );
};

export default FunnelSelect;
