import React from 'react';
import { useEffect } from 'react';

const IntegrationsComplete = () => {
  useEffect(() => {
    // Close the browser tab/window
    window.close();

    // You can also redirect to a different page if closing is not supported
    // window.location.href = '/some-other-page';
  }, []);

  return <p>Close Window</p>;
};

export default IntegrationsComplete;
