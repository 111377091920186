import React, { useState, useEffect } from 'react';
import { Form } from 'react-bootstrap';
import { useAuth0 } from '@auth0/auth0-react';
import CampaignWizardStepHeader from './CampaignWizardStepHeader';
import api from '../../../api';
import './settings.css';

const Settings = ({ oagentId, advance, retreat, nextText }) => {
  const { getAccessTokenSilently } = useAuth0();
  const [supervised, setSupervised] = useState(true);
  const [responsesSupervised, setResponsesSupervised] = useState(true);
  const [crmMatchProspectBehaviour, setCrmMatchProspectBehaviour] =
    useState('DO_NOT_CONTACT');

  const loadSettings = async () => {
    try {
      const token = await getAccessTokenSilently();
      const { data } = await api.get(`/oagents/${oagentId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      setSupervised(data.supervised ?? true);
      setResponsesSupervised(data.responses_supervised ?? true);
      setCrmMatchProspectBehaviour(
        data.metadata.crm_match_prospect_behaviour ?? 'DO_NOT_CONTACT',
      );
    } catch (err) {
      console.error('Error loading settings:', err);
    }
  };

  useEffect(() => {
    loadSettings();
  }, []);

  const handleSupervisedChange = async (value) => {
    setSupervised(value === 'true');
    await updateSupervisedSettings(value === 'true', responsesSupervised);
  };

  const handleResponsesSupervisedChange = async (value) => {
    setResponsesSupervised(value === 'true');
    await updateSupervisedSettings(supervised, value === 'true');
  };

  const handleCrmMatchProspectBehaviourChange = async (value) => {
    setCrmMatchProspectBehaviour(value);
    await updateCrmMatchProspectBehaviour(value);
  };

  const updateSupervisedSettings = async (supervised, responsesSupervised) => {
    try {
      const token = await getAccessTokenSilently();
      await api.put(
        `/oagents/${oagentId}/toggle-supervised`,
        { supervised, responses_supervised: responsesSupervised },
        {
          headers: { Authorization: `Bearer ${token}` },
        },
      );
    } catch (err) {
      console.error('Error updating supervised settings:', err);
    }
  };

  const updateCrmMatchProspectBehaviour = async (crmMatchProspectBehaviour) => {
    try {
      const token = await getAccessTokenSilently();
      await api.put(
        `/oagents/${oagentId}/email-strategy`,
        { crm_match_prospect_behaviour: crmMatchProspectBehaviour },
        {
          headers: { Authorization: `Bearer ${token}` },
        },
      );
    } catch (err) {
      console.error('Error updating CRM match prospect behaviour:', err);
    }
  };

  return (
    <div className='campaign-settings dream-side-modal-form'>
      <div className='setting-group'>
        <Form.Label>Outbound approvals</Form.Label>
        <Form.Text>Do you want agents to auto-approve messages?</Form.Text>
        <div className='options-wrapper'>
          <Form.Check
            type='radio'
            label='No, I will approve all outgoing mail before sending.'
            value='true'
            checked={supervised === true}
            onChange={(e) => handleSupervisedChange(e.target.value)}
          />
          <Form.Check
            type='radio'
            label='Yes, agents should approve replies themselves.'
            value='false'
            checked={supervised === false}
            onChange={(e) => handleSupervisedChange(e.target.value)}
          />
        </div>
      </div>

      <div className='setting-group'>
        <Form.Label>Reply approvals</Form.Label>
        <Form.Text>Do you want agents to auto-approve replies?</Form.Text>
        <div className='options-wrapper'>
          <Form.Check
            type='radio'
            label='No, I want to approve replies before they are sent.'
            value='true'
            checked={responsesSupervised === true}
            onChange={(e) => handleResponsesSupervisedChange(e.target.value)}
          />
          <Form.Check
            type='radio'
            label='Yes, agents should approve replies themselves.'
            value='false'
            checked={responsesSupervised === false}
            onChange={(e) => handleResponsesSupervisedChange(e.target.value)}
          />
        </div>
      </div>

      <div className='setting-group'>
        <Form.Label>CRM has matching prospect</Form.Label>
        <Form.Text>
          Should your agents contact companies that have previously been
          contacted?
        </Form.Text>
        <div className='options-wrapper'>
          <Form.Check
            type='radio'
            label="Yes, they can contact companies we've previously contacted."
            value='CONTACT'
            checked={crmMatchProspectBehaviour === 'CONTACT'}
            onChange={(e) =>
              handleCrmMatchProspectBehaviourChange(e.target.value)
            }
          />
          <Form.Check
            type='radio'
            label='No, do not contact companies that have previously been contacted.'
            value='DO_NOT_CONTACT'
            checked={crmMatchProspectBehaviour === 'DO_NOT_CONTACT'}
            onChange={(e) =>
              handleCrmMatchProspectBehaviourChange(e.target.value)
            }
          />
        </div>
      </div>
    </div>
  );
};

export default Settings;
