import React, { useState, useEffect } from 'react';
import { Form, Spinner, Tabs, Tab } from 'react-bootstrap';
import { useAuth0 } from '@auth0/auth0-react';
import { useLocation, useNavigate } from 'react-router-dom';
import api from '../../api';
import ConfirmModal from '../confirm-modals/ConfirmModal';
import GeneratedEmails from './GeneratedEmails';
import Context from './Context';
import MessageHistory from './MessageHistory';
import ActiveConversationReminder from './ActiveConversationReminder';

import '../prospects/prospects.css';
import ProspectSettings from './ProspectSettings';

const EmailOAgentProspect = ({ oagentId, prospectId }) => {
  const { getAccessTokenSilently } = useAuth0();
  const navigate = useNavigate();
  const { search } = useLocation();
  const queryParams = new URLSearchParams(search);
  const initialTab = queryParams.get('contentTab') || 'strategy';

  const [status, setStatus] = useState(null);
  const [statusExplainer, setStatusExplainer] = useState(null);
  const [sequence, setSequence] = useState(null);
  const [loadingData, setLoadingData] = useState(false);
  const [scheduledEmails, setScheduledEmails] = useState([]);
  const [profiles, setProfiles] = useState([]);
  const [oagent, setOagent] = useState({});
  const [autopilotStatus, setAutopilotStatus] = useState(true); // Mocking this as true for now
  const [selectedProfile, setSelectedProfile] = useState(null);
  const [toConfirmProfileId, setToConfirmProfileId] = useState(null);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [conversationStatus, setConversationStatus] = useState(null);
  const [conversationStatusLoading, setConversationStatusLoading] =
    useState(true);
  const [activeTab, setActiveTab] = useState(initialTab);
  const [supervised, setSupervised] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const token = await getAccessTokenSilently();
        setLoadingData(true);

        // Get the email sequence ID
        const oagentResponse = await api.get(`/oagents/${oagentId}`, {
          headers: { Authorization: `Bearer ${token}` },
        });
        const sequenceId = oagentResponse.data.metadata.sequence_id;
        setOagent(oagentResponse.data);

        // Get the sequence details
        const sequenceResponse = await api.get(`/emails/${sequenceId}`, {
          headers: { Authorization: `Bearer ${token}` },
        });
        setSequence(sequenceResponse.data);

        // Get the scheduled emails for the prospect
        const emailsResponse = await api.get(
          `/oagents/${oagentId}/prospect/${prospectId}/emails`,
          {
            headers: { Authorization: `Bearer ${token}` },
          },
        );
        setScheduledEmails(emailsResponse.data.scheduledEmails);

        // Get the email strategy prospect data
        const strategyResponse = await api.get(
          `/oagents/email-strategy-prospect/${prospectId}`,
          {
            headers: { Authorization: `Bearer ${token}` },
          },
        );

        // Get profiles associated with the oagent
        const profilesResponse = await api.get(
          `/oagents/${oagentId}/profiles`,
          {
            headers: { Authorization: `Bearer ${token}` },
          },
        );

        // Update component state
        setSelectedProfile(strategyResponse.data.profile_id);
        setStatus(strategyResponse.data.status);
        setStatusExplainer(strategyResponse.data.status_explainer);
        setProfiles(profilesResponse.data);
        setSupervised(strategyResponse.data.supervised);
      } catch (error) {
        console.error('Error fetching data:', error);
      } finally {
        setLoadingData(false);
      }
    };
    fetchData();
  }, [oagentId, prospectId, getAccessTokenSilently]);

  useEffect(() => {
    const fetchConversationStatus = async () => {
      try {
        const token = await getAccessTokenSilently();
        const response = await api.get(
          `/oagent-prospects/${oagentId}/prospects/${prospectId}/status`,
          {
            headers: { Authorization: `Bearer ${token}` },
          },
        );
        setConversationStatus(response.data.status);
      } catch (error) {
        if (error.response && error.response.status === 404) {
          setConversationStatus(null);
        } else {
          console.error('Error fetching conversation status:', error);
        }
      } finally {
        setConversationStatusLoading(false);
      }
    };

    fetchConversationStatus();
  }, [oagentId, prospectId, getAccessTokenSilently]);

  const handleProfileChange = async (event) => {
    const newProfileId = event.target.value;
    setToConfirmProfileId(newProfileId);
    setShowConfirmModal(true);
  };

  const changeProfile = async (newProfileId) => {
    try {
      const token = await getAccessTokenSilently();
      await api.put(
        `/oagents/oagent-to-email-prospect/${oagentId}/profile/${newProfileId}`,
        { prospect_id: prospectId },
        {
          headers: { Authorization: `Bearer ${token}` },
        },
      );
      setSelectedProfile(newProfileId);
    } catch (error) {
      console.error('Error changing profile:', error);
    }
  };

  const handleConfirmModalClose = (confirmed) => {
    if (confirmed) {
      changeProfile(toConfirmProfileId);
    }
    setShowConfirmModal(false);
  };

  const handleTabSelect = (key) => {
    queryParams.set('contentTab', key);
    navigate({ search: queryParams.toString() });
    setActiveTab(key);
  };

  const renderStatus = () => {
    if (status === 'inactive') {
      return <p className='negative'>{statusExplainer}</p>;
    }

    if (status === 'outreaching' && conversationStatus !== null) {
      const cs =
        conversationStatus.slice(0, 1).toUpperCase() +
        conversationStatus.slice(1).toLowerCase();
      return <p className='positive'>{cs}</p>;
    }

    if (status === 'outreaching' && conversationStatus === null) {
      if (autopilotStatus) {
        return <p className='positive'>Outreaching</p>;
      } else {
        return <p className='positive'>Ready</p>;
      }
    }

    return <p className='positive'>{status}</p>;
  };

  if (loadingData || conversationStatusLoading) {
    return (
      <div className='overlay'>
        <Spinner animation='border' />
      </div>
    );
  }

  return (
    <div className='email-oagent-prospect'>
      <div className='key-options-wrapper'>
        <div className='set main-header'>
          <small>Campaign name</small>
          <h3>{oagent.name}</h3>
        </div>
        <div className='set'>
          <h5>Campaign Status</h5>
          <p>
            Determine who is sending mail to this prospect and if they're active
          </p>
        </div>
        <div className='options-set'>
          <Form.Group controlId='profileSelect' className='profile-select'>
            <Form.Label>Sending Agent</Form.Label>
            <Form.Control
              as='select'
              value={selectedProfile || ''}
              onChange={handleProfileChange}
            >
              <option value=''>No Agent Selected</option>
              {profiles
                .filter((x) => x.is_sdr)
                .map((profile) => (
                  <option key={profile.id} value={profile.id}>
                    {profile.name}
                  </option>
                ))}
            </Form.Control>
          </Form.Group>
          <div className='status-area'>
            <label>Current status</label>
            {renderStatus()}
          </div>
        </div>
      </div>

      <Tabs
        id='email-oagent-prospect-tabs'
        activeKey={activeTab}
        onSelect={handleTabSelect}
        className='dream-tabs'
      >
        <Tab eventKey='strategy' title='Strategy'>
          <div className='context-description-area'>
            <div className='set'>
              <h5>Strategy</h5>
              <p>
                This context describes how your agent will adapt messaging where
                possible to better meet the prospect.
              </p>
            </div>
            <Context oagentId={oagentId} prospectId={prospectId} />
          </div>
        </Tab>
        <Tab eventKey='next-outreach-email' title='Next outreach email'>
          {sequence && (
            <div className='emails-area'>
              <div className='set'>
                <h5>Next outreach email</h5>
                <p>
                  View and edit the next email in your sequence to this
                  prospect.
                </p>
              </div>
              <ActiveConversationReminder
                oagentId={oagentId}
                prospectId={prospectId}
                onTabSwitch={handleTabSelect}
              />
              <div className='emails-wrapper'>
                <GeneratedEmails
                  oagentId={oagentId}
                  prospectId={prospectId}
                  sequence={sequence}
                  profiles={profiles}
                  selectedProfile={selectedProfile}
                  setSelectedProfile={setSelectedProfile}
                />
              </div>
            </div>
          )}
        </Tab>
        <Tab eventKey='conversation' title='Conversation'>
          <div className='message-history-outer-wrapper'>
            <MessageHistory oagentId={oagentId} prospectId={prospectId} />
          </div>
        </Tab>
        <Tab eventKey='settings' title='Settings'>
          <ProspectSettings
            oagentId={oagentId}
            prospectId={prospectId}
            initialSupervised={supervised}
          />
        </Tab>
      </Tabs>

      <ConfirmModal
        show={showConfirmModal}
        handleClose={() => handleConfirmModalClose(false)}
        handleConfirm={() => handleConfirmModalClose(true)}
        title='Confirm Profile Change'
        bodyText='Changing the profile will delete all scheduled and generated emails for this prospect. Normally profile selection is done automatically. Do you want to proceed?'
        confirmLabel='Yes'
        cancelLabel='No'
      />
    </div>
  );
};

export default EmailOAgentProspect;
