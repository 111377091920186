import React, { useState, useEffect } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { useParams, useNavigate, Link } from 'react-router-dom';
import { Button, Tabs, Tab } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faPlay } from '@fortawesome/free-solid-svg-icons';
import Purpose from './steps/Purpose';
import Companies from './steps/Companies';
import Prospects from './steps/Prospects';
import SalesTeam from './steps/SalesTeam';
import Messaging from './steps/Messaging';
import Settings from './steps/Settings';
import MainPageLoader from '../misc/MainPageLoader';
import api from '../../api';

import { ReactComponent as RightCaretIcon } from '../../icons/right-caret.svg';

const steps = [
  'Purpose',
  'Companies',
  'Prospects',
  'Sales Team',
  'Messaging',
  'Settings',
];

const ManageCampaign = () => {
  const { id, step } = useParams(); // Extract step from URL parameters
  const { getAccessTokenSilently } = useAuth0();
  const navigate = useNavigate();
  const [oagent, setOagent] = useState(null);
  const [loading, setLoading] = useState(true);

  // Convert space to hyphen
  const getSafeURLSegment = (step) => step.toLowerCase().replace(/\s+/g, '-');

  useEffect(() => {
    const fetchOagent = async () => {
      try {
        const token = await getAccessTokenSilently();
        const response = await api.get(`/oagents/${id}`, {
          headers: { Authorization: `Bearer ${token}` },
        });
        setOagent(response.data);

        if (response.data.draft) {
          navigate('/campaign-wizard/' + response.data.id);
        }

        setLoading(false);
      } catch (error) {
        console.error('Error fetching oagent:', error);
        setLoading(false);
      }
    };

    fetchOagent();
  }, [id, getAccessTokenSilently]);

  const handleTabSelect = (selectedStepIndex) => {
    const selectedStep = steps[selectedStepIndex - 1];
    navigate(`/campaign-edit/${id}/${getSafeURLSegment(selectedStep)}`);
  };

  if (loading) {
    return <MainPageLoader />;
  }

  // Determine active step
  const activeStepIndex =
    steps.findIndex(
      (s) => getSafeURLSegment(s) === (step ? step.toLowerCase() : 'companies'),
    ) + 1;
  const activeStep = activeStepIndex || 1; // Default to CampaignOverview if no step is provided

  return (
    <div className='page-wrapper'>
      <div className='page-inner-wrapper campaigns campaign-view'>
        <div className='page-header-area'>
          <div className='title-and-text'>
            <div className='breadcrumbs'>
              <div className='set'>
                <Link to='/campaigns'>
                  <FontAwesomeIcon icon={faArrowLeft} /> Back to Campaign
                </Link>
              </div>
            </div>
            <h3>
              <b>Campaign:</b>
              {oagent.name}
            </h3>
          </div>
          <div className='buttons-area autopilot'>
            <Button variant='primary'>
              Start Autopilot <FontAwesomeIcon icon={faPlay} />
            </Button>
          </div>
        </div>
        <Tabs
          id='campaign-view-tabs'
          activeKey={activeStep}
          onSelect={handleTabSelect}
          className='campaign-view-tabs'
        >
          {steps.map((step, index) => {
            const stepIndex = index + 1;

            return (
              <Tab
                eventKey={stepIndex}
                title={
                  <div className='tab-immitate'>
                    <div className='tab-inner'>
                      <div className={`step`}>{step}</div>
                    </div>
                    <RightCaretIcon />
                  </div>
                }
                key={stepIndex}
              >
                <div className='step-content'>
                  {step === 'Purpose' && <Purpose oagentId={id} />}
                  {step === 'Companies' && <Companies oagentId={id} />}
                  {step === 'Prospects' && <Prospects oagentId={id} />}
                  {step === 'Sales Team' && <SalesTeam oagentId={id} />}
                  {step === 'Messaging' && <Messaging oagentId={id} />}
                  {step === 'Settings' && <Settings oagentId={id} />}
                </div>
              </Tab>
            );
          })}
        </Tabs>
      </div>
    </div>
  );
};

export default ManageCampaign;
