import React, { useState, useEffect } from 'react';
import { Button, Spinner } from 'react-bootstrap';
import api from '../../api';
import { useAuth0 } from '@auth0/auth0-react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';

import { ReactComponent as RegenerateIcon } from '../../icons/regenerate.svg';
import { ReactComponent as DiscussionIcon } from '../../icons/discussion.svg';
import { ReactComponent as ICPIcon } from '../../icons/icp.svg';

const Context = ({ oagentId, prospectId }) => {
  const { getAccessTokenSilently } = useAuth0();
  const [contexts, setContexts] = useState({});
  const [loading, setLoading] = useState(true);
  const [generatingContext, setGeneratingContext] = useState({});

  const contextList = ['historical', 'icp_match'];

  useEffect(() => {
    fetchContext();
  }, [oagentId, prospectId]);

  const fetchContext = async () => {
    try {
      const token = await getAccessTokenSilently();
      setLoading(true);
      const response = await api.get(
        `/oagent-prospects/${oagentId}/prospects/${prospectId}/context`,
        {
          headers: { Authorization: `Bearer ${token}` },
        },
      );
      setContexts(response.data.contexts);
    } catch (error) {
      console.error('Error fetching context:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleGenerateContext = async (contextType) => {
    try {
      const token = await getAccessTokenSilently();
      setGeneratingContext((prev) => ({ ...prev, [contextType]: true }));
      await api.post(
        `/oagent-prospects/${oagentId}/prospects/${prospectId}/generate-context`,
        { context_type: contextType },
        { headers: { Authorization: `Bearer ${token}` } },
      );
      fetchContext(); // Refetch all contexts
    } catch (error) {
      console.error('Error generating context:', error);
    } finally {
      setGeneratingContext((prev) => ({ ...prev, [contextType]: false }));
    }
  };

  if (loading) {
    return (
      <div className='empty-state'>
        <Spinner animation='border' />
      </div>
    );
  }

  const friendly = {
    historical: 'Historical Context',
    icp_match: 'Customer Fit',
  };

  const icons = {
    historical: <DiscussionIcon />,
    icp_match: <ICPIcon />,
  };

  const explanations = {
    historical: 'Generate the strategy for mentioning previous interactions',
    icp_match:
      'Generate the strategy for fitting the prospect to your product ICPs',
  };

  const renderContext = (type) => {
    const context = contexts[`${type}_context`];
    return (
      <div key={type} className='context-set'>
        <div className='header-wrapper'>
          <h6>{friendly[type]}</h6>
          {context && context.trim() !== '' && (
            <div
              className='regenerate'
              onClick={() => handleGenerateContext(type)}
            >
              <RegenerateIcon />
              <span>Regenerate</span>
            </div>
          )}
        </div>
        <div className='context-main'>
          {generatingContext[type] ? (
            <div className='loading-wrapper'>
              <Spinner animation='border' />
            </div>
          ) : context && context.trim() !== '' ? (
            <div>
              <div className='context-internal'>
                <div dangerouslySetInnerHTML={{ __html: context }} />
              </div>
            </div>
          ) : (
            <div className='start-generate campaigns'>
              {icons[type]}
              <p>{explanations[type]}</p>
              <Button onClick={() => handleGenerateContext(type)}>
                Generate {friendly[type].toLowerCase()}{' '}
                <FontAwesomeIcon icon={faPlus} />
              </Button>
            </div>
          )}
        </div>
      </div>
    );
  };

  return <div className='contexts'>{contextList.map(renderContext)}</div>;
};

export default Context;
