import React from 'react';
import { Spinner } from 'react-bootstrap';
import './MainPageLoader.css';

const MainPageLoader = () => {
  return (
    <div className='main-page-loader'>
      <Spinner animation='border' role='status' />
    </div>
  );
};

export default MainPageLoader;
