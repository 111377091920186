import React, { useState, useEffect, useRef, useCallback } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import { format } from 'date-fns';
import api from '../../api';
import ApprovalModal from './ApprovalModal';
import EscalationModal from './EscalationModal';
import TaskCountTag from '../misc/TaskCountTag';
import './task-list.css';
import FakePic from '../feed/items/FakePic';
import { ReactComponent as HornIcon } from '../../icons/horn.svg';
import { ReactComponent as ThumbsUpIcon } from '../../icons/thumbs-up.svg';
import { ReactComponent as DownCaretIcon } from '../../icons/little-down-caret.svg';
import { ReactComponent as RightCaretIcon } from '../../icons/right-caret.svg';
import { Spinner } from 'react-bootstrap';

function TaskListPage() {
  const { campaignId } = useParams();
  const { getAccessTokenSilently } = useAuth0();
  const navigate = useNavigate();
  const [tasks, setTasks] = useState([]);
  const [campaignStatuses, setCampaignStatuses] = useState([]);
  const [campaigns, setCampaigns] = useState({});
  const [loading, setLoading] = useState(true);
  const [selectedTask, setSelectedTask] = useState(null);
  const [isApproval, setIsApproval] = useState(false);
  const [view, setView] = useState('allActions');
  const [expandedSections, setExpandedSections] = useState({});

  // Ensures we don't show loading states for the reloads that happen by interval
  const hasShownLoadingRef = useRef(false);

  useEffect(() => {
    // We want to reset this when we get a new campaign
    hasShownLoadingRef.current = false;
  }, [campaignId]);

  const fetchTasks = async () => {
    if (!hasShownLoadingRef.current) {
      setLoading(true);
    }

    try {
      const token = await getAccessTokenSilently();
      const [approvals, escalations] = await Promise.all([
        api.get('/approvals', {
          params: { campaign_id: campaignId },
          headers: { Authorization: `Bearer ${token}` },
        }),
        api.get('/escalations', {
          params: { campaign_id: campaignId },
          headers: { Authorization: `Bearer ${token}` },
        }),
      ]);

      const processedApprovals = approvals.data.map((item) => ({
        ...item,
        type: 'approval',
        date: new Date(item.scheduled_time),
      }));

      const processedEscalations = escalations.data.map((item) => ({
        ...item,
        type: 'escalation',
        date: new Date(item.created_at),
      }));

      const mergedTasks = [...processedApprovals, ...processedEscalations].sort(
        (a, b) => a.date - b.date,
      );
      setTasks(mergedTasks);
    } catch (error) {
      console.error('Error fetching tasks:', error);
    } finally {
      setLoading(false);
      hasShownLoadingRef.current = true;
    }
  };

  const fetchCampaignStatuses = async () => {
    try {
      const token = await getAccessTokenSilently();
      const response = await api.get('/actions-required', {
        headers: { Authorization: `Bearer ${token}` },
      });
      setCampaignStatuses(response.data);
    } catch (error) {
      console.error('Error fetching campaign statuses:', error);
    }
  };

  const fetchCampaigns = async () => {
    try {
      const token = await getAccessTokenSilently();
      const response = await api.get('/oagents', {
        headers: { Authorization: `Bearer ${token}` },
      });
      const campaignMap = response.data.reduce((acc, campaign) => {
        acc[campaign.id] = campaign;
        return acc;
      }, {});
      setCampaigns(campaignMap);
    } catch (error) {
      console.error('Error fetching campaigns:', error);
    }
  };

  useEffect(() => {
    fetchTasks();
    fetchCampaigns();
    fetchCampaignStatuses();

    const intervalId = setInterval(() => {
      fetchTasks();
      fetchCampaignStatuses();
    }, 60000); // Reload tasks and statuses every 60 seconds

    return () => clearInterval(intervalId); // Clear the interval when the component is unmounted
  }, [campaignId]);

  useEffect(() => {
    if (campaignId) {
      setExpandedSections({
        ...expandedSections,
        [campaignId]: true,
      });
    } else {
      const allSections = tasks.reduce((acc, task) => {
        acc[task.campaign_id] = true;
        return acc;
      }, {});
      setExpandedSections(allSections);
    }
  }, [tasks, campaignId]);

  const openModal = (task) => {
    setSelectedTask(task);
    setIsApproval(task.type === 'approval');
  };

  const closeModal = () => {
    setSelectedTask(null);
    fetchTasks(); // Refresh the task list
    fetchCampaignStatuses(); // Refresh the campaign statuses
  };

  const toggleSection = (campaignId) => {
    setExpandedSections((prevState) => ({
      ...prevState,
      [campaignId]: !prevState[campaignId],
    }));
  };

  const renderTaskItem = (item) => (
    <div className='task-item-outer-wrapper-page' key={item.id}>
      <div className='task-item' onClick={() => openModal(item)}>
        <FakePic url={item.prospects[0].logo_url} />
        <div className='left-side'>
          <div>
            <p>
              {item.is_response
                ? `Reply to ${item.prospects[0].name}`
                : `Outreach to ${item.prospects[0].name}`}
              <small> • {item.company}</small>
            </p>
            <small>{format(item.date, 'PPpp')}</small>
          </div>
          <span className='view-span'>View</span>
        </div>
      </div>
    </div>
  );

  const renderCampaignItem = (campaign) => (
    <div className='task-item-outer-wrapper-page' key={campaign.id}>
      <div
        className='task-item campaign-action'
        onClick={() => navigate(`/campaign/${campaign.id}`)}
      >
        <div className='horn-holder'>
          <HornIcon />
        </div>
        <div className='left-side'>
          <p>
            {campaign.action_state === 'IMPORTING' && (
              <>
                <b>{campaign.name}</b> is importing prospects
              </>
            )}
            {campaign.action_state === 'DRAFT' && (
              <>
                Complete details for <b>{campaign.name}</b>
              </>
            )}
            {campaign.action_state === 'GENERATE_EMAIL' && (
              <>
                Generate your first email for <b>{campaign.name}</b>
              </>
            )}
            {campaign.action_state === 'AUTOPILOT_SWITCH_ON' && (
              <>
                Turn on autopilot for <b>{campaign.name}</b>
              </>
            )}
          </p>
          <span className='view-span'>Go to Campaign</span>
        </div>
      </div>
    </div>
  );

  // Helper function to group tasks by their campaign_id
  const groupTasksByCampaignId = useCallback((tasks) => {
    return tasks.reduce((acc, task) => {
      if (!acc[task.campaign_id]) {
        acc[task.campaign_id] = [];
      }
      acc[task.campaign_id].push(task);
      return acc;
    }, {});
  }, []);

  const filteredTasks = useCallback(() => {
    if (view === 'emailApprovals') {
      return tasks.filter((task) => task.type === 'approval');
    } else if (view === 'escalations') {
      return tasks.filter((task) => task.type === 'escalation');
    } else if (view === 'allActions') {
      return tasks;
    } else {
      return [];
    }
  }, [view, tasks]);

  const getViewContent = () => {
    const campaignTasks = groupTasksByCampaignId(filteredTasks());
    const campaignContent = campaignStatuses
      .filter((campaign) => campaign.action_state !== 'NO_ACTION')
      .map(renderCampaignItem);

    return (
      <>
        {Object.keys(campaignTasks).map((campaignId) => (
          <div key={campaignId}>
            <h6
              className={`expandable-section-header ${!expandedSections[campaignId] && 'collapsed'}`}
              onClick={() => toggleSection(campaignId)}
            >
              <div className='tag-and-text'>
                {campaigns[campaignId]
                  ? `Campaign: ${campaigns[campaignId].name}`
                  : `Campaign ${campaignId}`}
                <TaskCountTag campaignId={campaignId} />
              </div>
              {expandedSections[campaignId] ? (
                <DownCaretIcon />
              ) : (
                <RightCaretIcon />
              )}
            </h6>
            {expandedSections[campaignId] &&
              campaignTasks[campaignId].map(renderTaskItem)}
          </div>
        ))}
        {(view === 'allActions' || view === 'campaigns') &&
          !campaignId &&
          campaignContent.length > 0 && (
            <div>
              <h6
                className={`expandable-section-header ${!expandedSections['campaignRequirements'] && 'collapsed'}`}
                onClick={() => toggleSection('campaignRequirements')}
              >
                Campaign Requirements{' '}
                {expandedSections['campaignRequirements'] ? (
                  <DownCaretIcon />
                ) : (
                  <RightCaretIcon />
                )}
              </h6>
              {expandedSections['campaignRequirements'] && campaignContent}
            </div>
          )}
      </>
    );
  };

  const getQuantity = useCallback(() => {
    return (
      tasks.length +
      (campaignId
        ? 0
        : campaignStatuses.filter(
            (campaign) => campaign.action_state !== 'NO_ACTION',
          ).length)
    );
  }, [tasks, view, campaigns]);

  const shouldShowEmpty =
    getQuantity() === 0 ||
    (['escalations', 'emailApprovals'].includes(view) &&
      filteredTasks().length === 0) ||
    (view === 'campaigns' &&
      campaignStatuses.filter(
        (campaign) => campaign.action_state !== 'NO_ACTION',
      ).length === 0);

  return (
    <div className='page-wrapper'>
      <div className='page-wrapper-inner task-list-page'>
        <div className='page-header-area'>
          <div className='title-and-text'>
            <h3>Actions</h3>
          </div>
        </div>
        <div className={`task-list-page-lower ${loading ? 'loading' : ''}`}>
          <div className='left-panel'>
            <div className='actions-menu'>
              <div
                className={view === 'allActions' ? 'selected' : ''}
                onClick={() => setView('allActions')}
              >
                <span>All actions</span>
                <span>{getQuantity()}</span>
              </div>
              <div
                className={view === 'emailApprovals' ? 'selected' : ''}
                onClick={() => setView('emailApprovals')}
              >
                <span>Email approvals</span>
                <span>
                  {tasks.filter((task) => task.type === 'approval').length}
                </span>
              </div>
              <div
                className={view === 'escalations' ? 'selected' : ''}
                onClick={() => setView('escalations')}
              >
                <span>Escalations</span>
                <span>
                  {tasks.filter((task) => task.type === 'escalation').length}
                </span>
              </div>
              {!campaignId && (
                <div
                  className={view === 'campaigns' ? 'selected' : ''}
                  onClick={() => setView('campaigns')}
                >
                  <span>Campaigns</span>
                  <span>
                    {
                      campaignStatuses.filter(
                        (campaign) => campaign.action_state !== 'NO_ACTION',
                      ).length
                    }
                  </span>
                </div>
              )}
            </div>
          </div>
          <div className='right-panel'>
            {loading && (
              <div className='fake-items'>
                <div className='spinner-abs'>
                  <Spinner />
                </div>
                <div className='fake-item'>
                  <div className='fake-header'></div>
                  <div>
                    <div className='fake-profile-pic'></div>
                  </div>
                </div>
                <div className='fake-item'>
                  <div className='fake-header'></div>
                  <div>
                    <div className='fake-profile-pic'></div>
                  </div>
                </div>
                <div className='fake-item'>
                  <div className='fake-header'></div>
                  <div>
                    <div className='fake-profile-pic'></div>
                  </div>
                </div>
                <div className='fake-item'>
                  <div className='fake-header'></div>
                  <div>
                    <div className='fake-profile-pic'></div>
                  </div>
                </div>
              </div>
            )}
            {!shouldShowEmpty && (
              <div className='right-panel-inner'>{getViewContent()}</div>
            )}
            {shouldShowEmpty && (
              <div className='empty-state'>
                <ThumbsUpIcon />
                <div>
                  <h6>You don't have any actions to deal with</h6>
                  <p>Well done. You've caught up with all your actions.</p>
                </div>
              </div>
            )}
          </div>
          {isApproval ? (
            <ApprovalModal
              show={!!selectedTask}
              onHide={closeModal}
              task={selectedTask}
            />
          ) : (
            <EscalationModal
              show={!!selectedTask}
              onHide={closeModal}
              task={selectedTask}
            />
          )}
        </div>
      </div>
    </div>
  );
}

export default TaskListPage;
