import React, { useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import { useAuth0 } from '@auth0/auth0-react'; // Import useAuth0
import DreamSideModal from '../../misc/DreamSideModal';
import DreamModalInner from '../../misc/DreamModalInner';
import api from '../../../api';
import DreamSideModalLoader from '../../misc/DreamSideModalLoader';
import { ReactComponent as PersonCircleIcon } from '../../../icons/person-circle.svg';

function AddSalesTeamModal({
  oagentId,
  type,
  header,
  descriptionText,
  refreshTeam,
  handleClose,
  show,
}) {
  const { getAccessTokenSilently } = useAuth0(); // Get the auth0 hook

  const [profiles, setProfiles] = useState([]);
  const [loading, setLoading] = useState(true);
  const [associatedProfiles, setAssociatedProfiles] = useState([]);

  // Fetch all profiles for the company
  const fetchProfiles = async () => {
    try {
      const token = await getAccessTokenSilently();
      const response = await api.get('/profiles', {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setProfiles(response.data);
    } catch (error) {
      console.error('Error retrieving all profiles:', error);
    } finally {
      setLoading(false);
    }
  };

  // Fetch profiles associated with the oagent
  const fetchAssociatedProfiles = async () => {
    try {
      const token = await getAccessTokenSilently();
      const response = await api.get(`/oagents/${oagentId}/profiles`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setAssociatedProfiles(response.data);
    } catch (error) {
      console.error('Error retrieving associated profiles:', error);
    }
  };

  useEffect(() => {
    fetchProfiles();
    fetchAssociatedProfiles();
  }, [show]);

  const associateProfile = async (profileId) => {
    try {
      const token = await getAccessTokenSilently();
      await api.post(
        `/oagents/${oagentId}/profile/${profileId}/${type.toLowerCase()}`,
        {},
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      );
      setAssociatedProfiles((prev) => [
        ...prev,
        { id: profileId, [`is_${type.toLowerCase()}`]: true },
      ]);
      refreshTeam();
    } catch (error) {
      console.error('Error associating profile:', error);
    }
  };

  const dissociateProfile = async (profileId) => {
    try {
      const token = await getAccessTokenSilently();
      await api.delete(
        `/oagents/${oagentId}/profile/${profileId}/${type.toLowerCase()}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      );
      setAssociatedProfiles((prev) =>
        prev.filter((profile) => profile.id !== profileId),
      );
      refreshTeam();
    } catch (error) {
      console.error('Error dissociating profile:', error);
    }
  };

  // Filter profiles based on type
  const filteredProfiles = profiles
    .filter((p) => p.status === 'active')
    .filter((profile) => {
      if (type === 'SDR') {
        return profile.oauth_id;
      }
      if (type === 'AE') {
        return profile.calendar_oauth_id;
      }
      return false;
    });

  if (loading) {
    return (
      <DreamSideModal>
        <DreamModalInner>
          <DreamSideModalLoader>Loading Team</DreamSideModalLoader>
        </DreamModalInner>
      </DreamSideModal>
    );
  }

  return (
    <DreamSideModal onHide={handleClose} show={show}>
      <DreamModalInner>
        <DreamModalInner.Header>{header}</DreamModalInner.Header>
        <DreamModalInner.Body>
          <div className='ast-modal'>
            <p>{descriptionText}</p>
            <div className='ast-profiles-wrapper'>
              {filteredProfiles.map((profile) => {
                const isAssociated = associatedProfiles.find(
                  (p) =>
                    p.id === profile.id && (type === 'AE' ? p.is_ae : p.is_sdr),
                );
                return (
                  <div
                    className={`profile ${isAssociated ? 'active' : ''}`}
                    key={profile.id}
                  >
                    <div className='left-side'>
                      {profile.profile_image_url && (
                        <img
                          src={profile.profile_image_url}
                          alt={profile.name}
                        />
                      )}
                      {!profile.profile_image_url && (
                        <div className='pseudo-pic'>
                          <PersonCircleIcon />
                        </div>
                      )}
                      <div className='mid-section'>
                        <h6>{profile.name}</h6>
                        <p>{profile.linked_email}</p>
                      </div>
                    </div>
                    <Button
                      variant={isAssociated ? 'primary' : 'outline-primary'}
                      onClick={() =>
                        isAssociated
                          ? dissociateProfile(profile.id)
                          : associateProfile(profile.id)
                      }
                    >
                      {isAssociated ? 'Added' : 'Add'}
                    </Button>
                  </div>
                );
              })}
            </div>
          </div>
        </DreamModalInner.Body>
        <DreamModalInner.Footer>
          <Button onClick={handleClose} variant='primary'>
            Save profiles
          </Button>
          <Button onClick={handleClose} variant='outline-primary'>
            Close
          </Button>
        </DreamModalInner.Footer>
      </DreamModalInner>
    </DreamSideModal>
  );
}

export default AddSalesTeamModal;
