import React, { useEffect, useState } from 'react';
import { Nav, Modal, Button } from 'react-bootstrap';
import { Link, useSearchParams } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { usePusher } from '../contexts/PusherContext';
import api from '../api';

const Credits = ({ updateCredits }) => {
  const { getAccessTokenSilently } = useAuth0();
  const [searchParams, setSearchParams] = useSearchParams();

  const [refresh] = useState(searchParams.get('f')); // We set this on first load to get credits.
  const pusherChannel = usePusher();
  const [credits, setCredits] = useState(null);
  const [isFreeTier, setIsFreeTier] = useState(false);
  const [previousCredits, setPreviousCredits] = useState(null);
  const [showModal, setShowModal] = useState(false);

  const handleShowModal = () => setShowModal(true);
  const handleCloseModal = () => setShowModal(false);

  const fetchCredits = async () => {
    try {
      const token = await getAccessTokenSilently();
      const response = await api.get('/credit-balance', {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      const newCredits = response.data.credits_remaining;
      setPreviousCredits(credits);
      setCredits(newCredits);
      if (updateCredits) {
        updateCredits(newCredits); // Update the credits in the Navigation component
      }
    } catch (error) {
      console.error('Error fetching credits:', error);
      // Handle error appropriately
    }
  };

  const fetchSubscriptionDetails = async () => {
    try {
      const token = await getAccessTokenSilently();
      const response = await api.get('/subscription-details', {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setIsFreeTier(!response.data.curent_subscription_product);
    } catch (error) {
      console.error('Error in subscription data ', error);
    }
  };

  useEffect(() => {
    fetchCredits();
    fetchSubscriptionDetails();
  }, [getAccessTokenSilently, refresh]);

  useEffect(() => {
    if (previousCredits !== null) {
      if (isFreeTier && previousCredits > 0 && credits === 0) {
        handleShowModal();
      } else if (previousCredits >= 40 && credits < 40 && credits >= 10) {
        toast.warn(
          'Your credit balance has dipped below 40, top-up credits to keep finding leads.',
        );
      } else if (previousCredits >= 10 && credits < 10) {
        toast.error('Your credit balance is below 10, top-up credits.');
      }
    }
  }, [previousCredits, credits, isFreeTier]);

  // Set up Pusher event handling for credit_update
  useEffect(() => {
    if (pusherChannel) {
      const creditUpdateHandler = () => {
        fetchCredits(); // Fetch credits when a credit update notification is received
      };

      pusherChannel.bind('credit_update', creditUpdateHandler);

      // Unbind events on cleanup
      return () => {
        pusherChannel.unbind('credit_update', creditUpdateHandler);
      };
    }
  }, [pusherChannel, fetchCredits]);

  return (
    <>
      <Nav.Item as={Link} to='/settings/analytics' className='credits'>
        {credits !== null ? (
          <span>
            Credits: <b>{Math.round(credits)}</b>
          </span>
        ) : (
          ''
        )}
      </Nav.Item>

      <Modal
        dialogClassName='credits-modal-dialog'
        show={showModal}
        onHide={handleCloseModal}
        backdropClassName='credits-modal-backdrop'
      >
        <Modal.Header closeButton>
          <Modal.Title>You're out of credits!</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>
            We're glad you and your agents are enjoying using Dream but
            unfortunately you've used up all your credits for the month.
          </p>
          <p>
            Don't worry though, you can either wait another month for your
            credits to renew or buy more below.
          </p>
          <div className='buy-credits-button-wrapper'>
            <Button
              variant='primary'
              onClick={() => (window.location.href = '/settings/subscription')}
            >
              Subscribe Now
            </Button>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default Credits;
