import React, { useState, useEffect } from 'react';
import { Button, Spinner, Alert } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import api from '../../api';
import { useAuth0 } from '@auth0/auth0-react';
import ResearchPointModal from './ResearchPointModal';
import { ReactComponent as ResearchPointIcon } from '../../icons/research-point.svg';
import MainPageLoader from '../misc/MainPageLoader'; // Ensure to import MainPageLoader
import './analyst.css';

const CampaignTriggers = () => {
  const { getAccessTokenSilently } = useAuth0();
  const [campaignTriggers, setCampaignTriggers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    const fetchCampaignTriggers = async () => {
      try {
        const token = await getAccessTokenSilently();
        const response = await api.get('/analyst/research-points', {
          headers: { Authorization: `Bearer ${token}` },
        });

        const triggers = response.data.filter(
          (rp) => rp.type === 'campaign_trigger',
        );
        setCampaignTriggers(triggers);
      } catch (error) {
        console.error('Error fetching campaign triggers:', error);
        setError('Failed to load campaign triggers');
      } finally {
        setLoading(false);
      }
    };

    fetchCampaignTriggers();
  }, [getAccessTokenSilently]);

  const handleShowModal = (type) => {
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  if (loading) {
    return <MainPageLoader />;
  }

  return (
    <div className='research-point-solo-wrapper'>
      <div className='header'>
        <div className='left-area'>
          <h4>Your Campaign Triggers</h4>
          <p>Choose when to message companies in your Campaigns</p>
        </div>
        <div className='buttons-area'>
          <Button onClick={() => handleShowModal('campaign_trigger')}>
            Add Campaign Trigger <FontAwesomeIcon icon={faPlus} />
          </Button>
        </div>
      </div>
      <div className='campaigns-area'>
        {error && <Alert variant='danger'>{error}</Alert>}
        {campaignTriggers.length === 0 && (
          <div className='empty-state'>
            <ResearchPointIcon />
            <p>You have not created any campaign triggers yet</p>
            <Button
              variant='primary'
              className='add-button'
              onClick={() => handleShowModal('campaign_trigger')}
            >
              Add Campaign Trigger <FontAwesomeIcon icon={faPlus} />
            </Button>
          </div>
        )}
        {campaignTriggers.length > 0 && (
          <div className='campaigns-wrapper'>
            {campaignTriggers.map((campaign_trigger, index) => (
              <div key={index} className='campaign-trigger'>
                <p>
                  <b>{campaign_trigger.name}</b> - {campaign_trigger.definition}
                </p>
                <div className='info-area'>
                  <div className='set'>
                    <p className='label'>Max. credits per company</p>
                    <p className='value'>{campaign_trigger.credits}</p>
                  </div>
                  <div className='set'>
                    <p className='label'># Companies Filled</p>
                    <p className='value'>{campaign_trigger.filled_count}</p>
                  </div>
                  <div className='set'>
                    <p className='label'># Companies Processing</p>
                    <p className='value'>{campaign_trigger.processing_count}</p>
                  </div>
                </div>
              </div>
            ))}
          </div>
        )}
      </div>

      <ResearchPointModal
        show={showModal}
        handleClose={handleCloseModal}
        type='campaign_trigger'
      />
    </div>
  );
};

export default CampaignTriggers;
