import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import FakePic from './FakePic';
import { formatDistanceToNow, parseISO } from 'date-fns';
import ConversationModal from '../../misc/ConversationModal';

const BaseType = ({
  url,
  name,
  nameTo,
  tagType,
  tagText,
  tagTo,
  time,
  conversationId,
  idIsScheduledEmail,
  isOrg,
}) => {
  const navigate = useNavigate();
  const [showModal, setShowModal] = useState(false);

  const handleTagClick = () => {
    if (conversationId) {
      setShowModal(true);
    } else {
      if (typeof tagTo === 'function') {
        tagTo();
      } else {
        navigate(tagTo);
      }
    }
  };

  return (
    <div className='feed-item'>
      <div className='main-name'>
        <FakePic url={url} type={isOrg ? 'org' : 'prospect'} />
        <Link to={nameTo}>{name}</Link>
      </div>
      <div className='tag-section'>
        <div className={`tag ${tagType}`} onClick={handleTagClick}>
          {tagText}
        </div>
      </div>
      <small>
        {formatDistanceToNow(parseISO(time), {
          addSuffix: true,
        })}
      </small>
      {conversationId && showModal && (
        <ConversationModal
          show={showModal}
          onHide={() => setShowModal(false)}
          id={conversationId}
          idIsScheduledEmail={idIsScheduledEmail}
        />
      )}
    </div>
  );
};

export default BaseType;
