import React from 'react';
import { Button } from 'react-bootstrap';
import { ReactComponent as DownloadIcon } from '../../icons/download.svg';
import './funnel.css';

const DownloadCSVButton = ({ name, items, researchPoints }) => {
  const handleDownload = () => {
    const delimiter = ',';
    const quote = '"';

    // Create header row
    const headers = ['Name', ...researchPoints.map((rp) => rp.name)];

    // Create rows
    const rows = items.map((item) => {
      const researchPointValues = researchPoints.map((rp) => {
        const rpData = item.research_points.find((r) => r.id === rp.id);
        return rpData ? rpData.value || '-' : '-';
      });

      return [item.name, ...researchPointValues];
    });

    // Combine headers and rows into a single CSV string
    const csvContent = [headers, ...rows]
      .map((row) =>
        row.map((cell) => `${quote}${cell}${quote}`).join(delimiter),
      )
      .join('\n');

    // Create a Blob from the CSV
    const blob = new Blob([csvContent], { type: 'text/csv' });

    // Format the current date and time for the filename
    const now = new Date();
    const formattedDateTime = now
      .toISOString()
      .replace(/[T:.-]/g, '')
      .slice(0, 14);

    // Create a link to download the Blob
    const a = document.createElement('a');
    a.href = URL.createObjectURL(blob);
    a.download = `${name}-${formattedDateTime}.csv`;
    a.click();
  };

  return (
    <Button
      variant='outline-primary'
      className='download-csv-button'
      onClick={handleDownload}
    >
      <DownloadIcon /> Download CSV
    </Button>
  );
};

export default DownloadCSVButton;
