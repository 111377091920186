import React, { useState, useEffect } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { useVault } from '@apideck/vault-react';
import { Button, Spinner, Form } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleNodes } from '@fortawesome/free-solid-svg-icons';
import api from '../../api'; // Ensure this path is correct

const Integrations = () => {
  const { getAccessTokenSilently } = useAuth0();
  const [isCreatingSession, setIsCreatingSession] = useState(false);
  const [token, setToken] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const [crmData, setCrmData] = useState(null);
  const [isSaving, setIsSaving] = useState(false);
  const [syncProspects, setSyncProspects] = useState(false);
  const [syncCompanies, setSyncCompanies] = useState(false);

  const { open } = useVault();

  const fetchSettings = async () => {
    try {
      setIsLoading(true);
      const accessToken = await getAccessTokenSilently();
      const response = await api.get('/unify-link/integration-settings', {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });

      const { crms, settings } = response.data;
      setCrmData(crms);
      if (settings.length > 0) {
        const setting = settings[0];
        setSyncProspects(setting.sync_prospect_to_crm);
        setSyncCompanies(setting.sync_org_to_crm);
      }
    } catch (error) {
      console.error('Error fetching CRM settings:', error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchSettings();
  }, [getAccessTokenSilently]);

  const handleClose = (opts) => {
    fetchSettings();
  };

  const openVault = () => {
    if (token) open({ token, onClose: handleClose });
    else createSession();
  };

  const createSession = async () => {
    setIsCreatingSession(true);
    try {
      const accessToken = await getAccessTokenSilently();
      const response = await api.post(
        '/unify-link',
        {
          returnToUrl: window.location.href,
        },
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        },
      );

      const data = response.data;
      if (data.session_token) {
        open({ token: data.session_token, onClose: handleClose });
        setToken(data.session_token);
      }
    } catch (error) {
      console.error('Error creating session:', error);
    } finally {
      setIsCreatingSession(false);
    }
  };

  const saveSettings = async () => {
    setIsSaving(true);
    try {
      const accessToken = await getAccessTokenSilently();
      await api.post(
        '/unify-link/integration-settings',
        {
          sync_prospect_to_crm: syncProspects,
          sync_org_to_crm: syncCompanies,
        },
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        },
      );
    } catch (error) {
      console.error('Error saving CRM settings:', error);
    } finally {
      setIsSaving(false);
    }
  };

  if (isLoading) {
    return (
      <div className='integrations-page'>
        <h2>Integrations</h2>
        <div className='overlay'>
          <Spinner animation='border' role='status'>
            <span className='sr-only'>Loading...</span>
          </Spinner>
        </div>
      </div>
    );
  }

  return (
    <div className='integrations-page'>
      <h2>Integrations</h2>
      <p>
        Dream works best when connected to your CRM so that your agents and the
        rest of your team can work together.
      </p>
      <div className='link-set'>
        <Button
          variant='primary'
          onClick={openVault}
          disabled={isCreatingSession}
        >
          {isCreatingSession ? (
            <>
              <Spinner
                as='span'
                animation='border'
                size='sm'
                role='status'
                aria-hidden='true'
              />
              {' Loading...'}
            </>
          ) : (
            <>
              <FontAwesomeIcon icon={faCircleNodes} />
              {crmData && crmData.length > 0
                ? ' Edit Your CRM Connections'
                : ' Connect Your CRM'}
            </>
          )}
        </Button>
        <p>
          {crmData && crmData.length > 0
            ? 'Dream is synced to your CRM'
            : 'You do not currently have a CRM connected'}
        </p>
      </div>
      {crmData && crmData.length > 0 && (
        <>
          <div className='crm-settings'>
            <h4>CRM Sync</h4>
            <p>
              Update the settings below to change when your agent will add
              companies and prospects to your CRM.
            </p>
            <div className='checkboxes'>
              <div className='checkbox-form'>
                <Form.Check
                  type='checkbox'
                  id='syncProspectsCheckbox'
                  label='Sync prospects to CRM when found by agent (added as Lead if contact is new)'
                  checked={syncProspects}
                  onChange={(e) => setSyncProspects(e.target.checked)}
                />
              </div>
              <div className='checkbox-form'>
                <Form.Check
                  type='checkbox'
                  id='syncCompaniesCheckbox'
                  label='Sync companies to CRM when found by agent'
                  checked={syncCompanies}
                  onChange={(e) => setSyncCompanies(e.target.checked)}
                />
              </div>
            </div>
          </div>
          <Button variant='primary' onClick={saveSettings} disabled={isSaving}>
            {isSaving ? (
              <>
                <Spinner
                  as='span'
                  animation='border'
                  size='sm'
                  role='status'
                  aria-hidden='true'
                />
                {' Saving...'}
              </>
            ) : (
              'Save Settings'
            )}
          </Button>
        </>
      )}
    </div>
  );
};

export default Integrations;
