import React, { useState, useEffect } from 'react';
import { Modal, Button, Form, Spinner, Alert } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faTrash } from '@fortawesome/free-solid-svg-icons';
import { useAuth0 } from '@auth0/auth0-react';
import api from '../../api';
import ConfirmModal from '../confirm-modals/ConfirmModal';
import DreamModalInner from '../misc/DreamModalInner';
import DreamSideModal from '../misc/DreamSideModal';

const ICPs = ({ productId }) => {
  const { getAccessTokenSilently } = useAuth0();
  const [icps, setIcps] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [currentIcp, setCurrentIcp] = useState({
    description: '',
    hallmarks: [],
    value_proposition: '',
    features_to_highlight: [],
    false_friends: '',
  });
  const [icpToBeDeleted, setIcpToBeDeleted] = useState(null);

  const fetchIcps = async () => {
    try {
      const token = await getAccessTokenSilently();
      const response = await api.get(`/products/${productId}/icps`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setIcps(response.data);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching ICPs:', error);
      setError('Failed to load ideal customer profiles');
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchIcps();
  }, [getAccessTokenSilently, productId]);

  const handleSaveIcp = async () => {
    try {
      const token = await getAccessTokenSilently();
      if (currentIcp.id) {
        await api.put(
          `/products/${productId}/icp/${currentIcp.id}`,
          currentIcp,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          },
        );
      } else {
        await api.post(`/products/${productId}/icp`, currentIcp, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
      }
      setShowModal(false);
      fetchIcps();
    } catch (error) {
      console.error('Error saving ICP:', error);
      setError('Failed to save ideal customer profile');
    }
  };

  const handleEditIcp = (icp) => {
    setCurrentIcp(icp);
    setShowModal(true);
  };

  const handleAddIcp = () => {
    setCurrentIcp({
      description: '',
      hallmarks: [],
      value_proposition: '',
      features_to_highlight: [],
      false_friends: '',
    });
    setShowModal(true);
  };

  const handleArrayChange = (arrayName, index, value) => {
    const updatedArray = [...currentIcp[arrayName]];
    updatedArray[index] = value;
    setCurrentIcp({ ...currentIcp, [arrayName]: updatedArray });
  };

  const addArrayItem = (arrayName) => {
    const updatedArray = [...currentIcp[arrayName], ''];
    setCurrentIcp({ ...currentIcp, [arrayName]: updatedArray });
  };

  const handleDeleteIcpRequest = (icp) => {
    setIcpToBeDeleted(icp);
    setShowConfirmModal(true);
  };

  const handleDeleteIcp = async (icpId) => {
    try {
      const token = await getAccessTokenSilently();
      await api.delete(`/products/${productId}/icp/${icpId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      fetchIcps();
    } catch (error) {
      console.error('Error deleting ICP:', error);
      setError('Failed to delete ideal customer profile');
    }
  };

  const confirmDeleteIcp = () => {
    if (icpToBeDeleted && icpToBeDeleted.id) {
      handleDeleteIcp(icpToBeDeleted.id);
    }
    setShowConfirmModal(false);
    setIcpToBeDeleted(null);
  };

  return (
    <div>
      {loading ? (
        <Spinner animation='border' />
      ) : error ? (
        <Alert variant='danger'>{error}</Alert>
      ) : (
        <div className='dream-list thin-headers'>
          {icps.map((icp) => (
            <div key={icp.id} className='dream-list-item has-action-icons'>
              <h5 onClick={() => handleEditIcp(icp)}>{icp.description}</h5>
              <Button
                className='remove-button'
                onClick={() => handleDeleteIcpRequest(icp)}
                variant='outline-primary'
              >
                Remove
              </Button>
            </div>
          ))}
          <div className='add-another-button-wrapper'>
            <Button onClick={handleAddIcp}>
              Add new ICP{' '}
              <div className='yellow-icon'>
                <FontAwesomeIcon icon={faPlus} />{' '}
              </div>
            </Button>
          </div>
        </div>
      )}

      <DreamSideModal show={showModal} onHide={() => setShowModal(false)}>
        <DreamModalInner>
          <DreamModalInner.Header>
            {currentIcp.id
              ? 'Edit Ideal Customer Profile'
              : 'Add Ideal Customer Profile'}
          </DreamModalInner.Header>
          <DreamModalInner.Body>
            {error && <Alert variant='danger'>{error}</Alert>}
            <div className='product-form-wrapper dream-side-modal-form'>
              <Form.Group>
                <Form.Label>Description</Form.Label>
                <Form.Text>
                  Provide a brief description of the ideal customer profile.
                </Form.Text>
                <Form.Control
                  as='textarea'
                  value={currentIcp.description}
                  onChange={(e) =>
                    setCurrentIcp({
                      ...currentIcp,
                      description: e.target.value,
                    })
                  }
                  placeholder='e.g. Small business owners using cloud-based accounting software.'
                />
              </Form.Group>
              <Form.Group>
                <Form.Label>Hallmarks</Form.Label>
                <Form.Text>
                  Key characteristics of this ideal customer profile.
                </Form.Text>
                <div className='shared-list dream-list'>
                  <div className='body-rows'>
                    {currentIcp.hallmarks.map((hallmark, index) => (
                      <div key={index} className='shared-list-row'>
                        <Form.Control
                          type='text'
                          value={hallmark}
                          onChange={(e) =>
                            handleArrayChange(
                              'hallmarks',
                              index,
                              e.target.value,
                            )
                          }
                          placeholder='e.g. Finance teams that are smaller than 3 people'
                        />
                      </div>
                    ))}
                  </div>
                  <div className='add-another-button-wrapper'>
                    <Button onClick={() => addArrayItem('hallmarks')}>
                      Add new hallmark
                      <div className='yellow-icon'>
                        <FontAwesomeIcon icon={faPlus} />
                      </div>
                    </Button>
                  </div>
                </div>
              </Form.Group>
              <Form.Group>
                <Form.Label>Value Proposition</Form.Label>
                <Form.Text>
                  The unique value proposition for this ideal customer profile.
                </Form.Text>
                <Form.Control
                  as='textarea'
                  value={currentIcp.value_proposition}
                  onChange={(e) =>
                    setCurrentIcp({
                      ...currentIcp,
                      value_proposition: e.target.value,
                    })
                  }
                  placeholder='e.g. Streamlined tax preparation with AI-driven insights'
                />
              </Form.Group>
              <Form.Group>
                <Form.Label>Features to Highlight</Form.Label>
                <Form.Text>
                  Key features to highlight for this ideal customer profile.
                </Form.Text>
                <div className='shared-list dream-list'>
                  <div className='body-rows'>
                    {currentIcp.features_to_highlight.map((feature, index) => (
                      <div key={index} className='shared-list-row'>
                        <Form.Control
                          type='text'
                          value={feature}
                          onChange={(e) =>
                            handleArrayChange(
                              'features_to_highlight',
                              index,
                              e.target.value,
                            )
                          }
                          placeholder='e.g. CSV Export'
                        />
                      </div>
                    ))}
                  </div>
                  <div className='add-another-button-wrapper'>
                    <Button
                      onClick={() => addArrayItem('features_to_highlight')}
                    >
                      Add new highlight
                      <div className='yellow-icon'>
                        <FontAwesomeIcon icon={faPlus} />
                      </div>
                    </Button>
                  </div>
                </div>
              </Form.Group>
            </div>
          </DreamModalInner.Body>
          <DreamModalInner.Footer>
            <Button onClick={handleSaveIcp}>
              {currentIcp.id ? 'Save Changes' : 'Add ICP'}
            </Button>
            <Button
              onClick={() => setShowModal(false)}
              variant='outline-primary'
            >
              Cancel
            </Button>
          </DreamModalInner.Footer>
        </DreamModalInner>
      </DreamSideModal>

      <ConfirmModal
        show={showConfirmModal}
        handleClose={() => setShowConfirmModal(false)}
        handleConfirm={confirmDeleteIcp}
        title='Confirm Deletion'
        bodyText='Are you sure you want to delete this Ideal Customer Profile?'
      />
    </div>
  );
};

export default ICPs;
