import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import { Nav } from 'react-bootstrap';
import { useAuth0 } from '@auth0/auth0-react';

import Credits from './Credits';

import { ReactComponent as HouseIcon } from '../icons/house.svg';
import { ReactComponent as CampaignsIcon } from '../icons/campaigns.svg';
import { ReactComponent as TargetIcon } from '../icons/target.svg';
import { ReactComponent as ProfilesIcon } from '../icons/profiles-plain.svg';
import { ReactComponent as SettingsIcon } from '../icons/settings.svg';
import { ReactComponent as LogoutIcon } from '../icons/logout.svg';
import { ReactComponent as TagIcon } from '../icons/tag.svg';
import TaskCountTag from './misc/TaskCountTag';

function Navigation() {
  const { logout } = useAuth0();
  const [credits, setCredits] = useState(null);

  const updateCredits = (newCredits) => {
    setCredits(newCredits);
  };

  let barClassName = '';
  let barContent = '';

  if (credits !== null) {
    if (credits <= 0) {
      barClassName = 'credit-danger-bar';
      barContent = (
        <>
          <p>
            You're out of credits - you won't be able to test new agents and
            your existing agents won't function.{' '}
            <NavLink to='/settings/subscription'>Top up here now</NavLink>.
          </p>
        </>
      );
    } else if (credits < 10) {
      barClassName = 'credit-danger-bar';
      barContent = (
        <>
          <p>
            Your credit balance is below 10. Your agents will stop working
            imminently.{' '}
            <NavLink to='/settings/subscription'>Top up here now</NavLink>.
          </p>
        </>
      );
    } else if (credits < 40) {
      barClassName = 'credit-alert-bar';
      barContent = (
        <>
          <p>
            Your credit balance is below 40. You will soon experience agents
            ceasing to run as they are out of credits.{' '}
            <NavLink to='/settings/subscription'>Top up here now</NavLink>.
          </p>
        </>
      );
    }
  }

  return (
    <>
      <div className={`navbar-inner`}>
        <div className='dream-logo-wrapper'>
          <Nav.Link as={NavLink} to='/'>
            <img
              className='dream-logo'
              alt='Dream Logo'
              src='/dream-logo-black.png'
            />
          </Nav.Link>
        </div>
        <div className='main-links'>
          <div className='link-group dashboard'>
            <Nav.Link as={NavLink} to='/' className='dash-link'>
              <HouseIcon /> Dashboard{' '}
              <div className='tag-wrapper'>
                <TaskCountTag />
              </div>
            </Nav.Link>
            <Nav.Link as={NavLink} to='/funnel'>
              <TargetIcon /> Targets
            </Nav.Link>
            <Nav.Link as={NavLink} to='/campaigns'>
              <CampaignsIcon /> Campaigns
            </Nav.Link>
          </div>
          <div className='link-group dashboard'>
            <h4>Agents</h4>
            <Nav.Link as={NavLink} to='/profiles'>
              <ProfilesIcon /> Sales Agents
            </Nav.Link>
          </div>
          <div className='link-group plain'>
            <h4>Knowledge</h4>
            {/* <Nav.Link as={NavLink} to='/analyst'>
              <AnalystIcon /> Companies
            </Nav.Link> */}
            <Nav.Link as={NavLink} to='/products'>
              <TagIcon />
              Products
            </Nav.Link>
          </div>
        </div>
        <div className='cheeky-spacer-sorry-matt'></div>
        <div className='bottom-links'>
          <div className='link-group plain'>
            <Nav.Link as={NavLink} to='/settings'>
              <SettingsIcon />
              Settings
            </Nav.Link>
            <Nav.Link
              onClick={() =>
                logout({ logoutParams: { returnTo: window.location.origin } })
              }
            >
              <LogoutIcon />
              Log Out
            </Nav.Link>
          </div>
          <Credits updateCredits={updateCredits} />
        </div>
      </div>
      {/* {barClassName && (
        <div className={`credit-bar ${barClassName}`}>{barContent}</div>
      )} */}
    </>
  );
}

export default Navigation;
