import React, { useState, useEffect } from 'react';
import { useParams, useLocation, Link, useNavigate } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { Tabs, Tab } from 'react-bootstrap';
import api from '../api';
import MainPageLoader from './misc/MainPageLoader';
import ProspectDetails from './prospects/ProspectDetails';
import './prospects/prospects.css';
import EmailOAgentProspect from './prospects/EmailOAgentProspect';
import FakePic from './feed/items/FakePic';
import { format } from 'date-fns';

function Prospect() {
  const { id } = useParams();
  const { search } = useLocation();
  const navigate = useNavigate();
  const queryParams = new URLSearchParams(search);
  const origin = queryParams.get('origin');
  const wizard = queryParams.get('wizard');
  const tabParam = queryParams.get('tab') || 'profile';

  const { getAccessTokenSilently } = useAuth0();
  const [prospect, setProspect] = useState(null);
  const [oagents, setOagents] = useState([]);
  const [loading, setLoading] = useState(true);
  const [activeTab, setActiveTab] = useState(tabParam);

  const fetchProspectData = async () => {
    try {
      const token = await getAccessTokenSilently();
      const [prospectResponse, oagentsResponse] = await Promise.all([
        api.get(`/prospects/${id}`, {
          headers: { Authorization: `Bearer ${token}` },
        }),
        api.get(`/prospects/${id}/oagents`, {
          headers: { Authorization: `Bearer ${token}` },
        }),
      ]);
      setProspect(prospectResponse.data);
      setOagents(oagentsResponse.data);
    } catch (error) {
      console.error('Error fetching prospect data:', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchProspectData();
  }, [getAccessTokenSilently, id]);

  useEffect(() => {
    setActiveTab(tabParam);
  }, [tabParam]);

  const handleSelect = (key) => {
    queryParams.set('tab', key);
    navigate({ search: queryParams.toString() });
    setActiveTab(key);
  };

  if (loading) {
    return <MainPageLoader />;
  }

  return (
    <div className='page-wrapper'>
      <div className='page-inner-wrapper prospect-view'>
        <div className='page-header-area'>
          <div className='title-and-text'>
            {origin && !isNaN(parseInt(origin)) && (
              <div className='breadcrumbs'>
                <div className='set'>
                  <Link
                    to={`/${wizard ? 'campaign-wizard' : 'campaign-prospects'}/${origin}`}
                  >
                    <FontAwesomeIcon icon={faArrowLeft} />
                    Back to Prospects
                  </Link>
                </div>
              </div>
            )}
            {origin && isNaN(parseInt(origin)) && (
              <div className='breadcrumbs'>
                <div className='set'>
                  <Link to={`/${origin}`}>
                    <FontAwesomeIcon icon={faArrowLeft} />
                    Back to {origin.slice(0, 1).toUpperCase() + origin.slice(1)}
                  </Link>
                </div>
              </div>
            )}
            {!origin && (
              <div className='breadcrumbs'>
                <div className='set'>
                  <Link to='/prospects'>
                    <FontAwesomeIcon icon={faArrowLeft} />
                    Back to All Prospects
                  </Link>
                </div>
              </div>
            )}
            <h3>{prospect.name}</h3>
          </div>
        </div>

        <div className='prospect-lower-area'>
          <div className='top-header-area'>
            <div className='logo-set'>
              <FakePic url={prospect?.metadata?.logo_url} />
              <div>
                <h4>{prospect?.name}</h4>
                <p>{prospect?.job_title}</p>
                <small>{prospect?.email}</small>
              </div>
            </div>
            <div className='info-sets'>
              <div className='set'>
                <label>Organisation</label>
                <p>
                  <Link to={`/analyst/${prospect?.known_org?.id}`}>
                    {prospect?.known_org?.name}
                  </Link>
                </p>
              </div>
              <div className='set'>
                <label>Location</label>
                <p>{prospect?.location}</p>
              </div>
              <div className='set'>
                <label>LinkedIn page</label>
                <p>
                  <Link to={prospect.linkedin_url}>View page</Link>
                </p>
              </div>
              <div className='set'>
                <label>Added</label>
                <p>{new Date(prospect.created_at).toLocaleDateString()}</p>
              </div>
            </div>
          </div>

          <div className='campaigns'>
            <h5>Campaigns</h5>
            <div className='campaigns-wrapper'>
              {oagents.map((oagent) => (
                <EmailOAgentProspect
                  key={oagent.id}
                  oagentId={oagent.id}
                  prospectId={id}
                />
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Prospect;
