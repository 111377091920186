import React, { useState, useEffect } from 'react';
import { Button, Form, Spinner } from 'react-bootstrap';
import CreatableSelect from 'react-select/creatable';
import DreamSideModal from '../../misc/DreamSideModal';
import DreamModalInner from '../../misc/DreamModalInner';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import DreamInfoExpander from '../../misc/DreamInfoExpander';
import TestAgentProspectSearch from './TestAgentProspectSearch';
import api from '../../../api';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { useAuth0 } from '@auth0/auth0-react';

function AgentGuidanceJobTitleModal({
  show,
  handleClose,
  oagentId,
  refreshProspectSearch,
  metadata,
  prospectSearchId,
  editMode,
}) {
  const { getAccessTokenSilently } = useAuth0();
  const [guidance, setGuidance] = useState('');
  const [jobTitles, setJobTitles] = useState([]);
  const [loadingTitles, setLoadingTitles] = useState(false);
  const [showTestModal, setShowTestModal] = useState(false);
  const [formData, setFormData] = useState({});

  useEffect(() => {
    if (metadata?.steps) {
      const initialJobTitles = metadata?.steps[0]
        ? metadata.steps[0].titles_to_search.map((title) => ({
            value: title,
            label: title,
          }))
        : [];
      setJobTitles(initialJobTitles);
      setGuidance(metadata.guidance);
    } else {
      setJobTitles([]);
      setGuidance('');
    }
  }, [metadata]);
  const handleGenerateJobTitles = async () => {
    setLoadingTitles(true);
    try {
      const token = await getAccessTokenSilently();
      const response = await api.post(
        '/prospect-search-test/agent-guidance-job-titles',
        { guidance },
        {
          headers: { Authorization: `Bearer ${token}` },
        },
      );
      const generatedJobTitles = response.data.map((title) => ({
        value: title,
        label: title,
      }));
      setJobTitles(generatedJobTitles);
      setLoadingTitles(false);
    } catch (error) {
      console.error('Error generating job titles from guidance:', error);
      setLoadingTitles(false);
    }
  };

  const handleSubmit = () => {
    const formData = {
      guidance,
      steps: [
        {
          titles_to_search: jobTitles.map((title) => title.value),
          if_not_matched: 'return_best_match',
        },
      ],
    };
    setFormData(formData);
    setShowTestModal(true);
  };

  return (
    <>
      <DreamSideModal show={show} onHide={handleClose}>
        <DreamModalInner>
          <DreamModalInner.Header>
            Prospects based on org structure
          </DreamModalInner.Header>
          <DreamModalInner.Body>
            <div className='ag-form dream-side-modal-form'>
              <Form.Group>
                <Form.Label>Guidance</Form.Label>
                <Form.Text>Describe what you are looking for.</Form.Text>
                <Form.Control
                  as='textarea'
                  value={guidance}
                  onChange={(e) => setGuidance(e.target.value)}
                  placeholder={`We're looking for the person in charge of internal events and their senior leader.

Typically this will be an Events Manager and the Chief People Officer of the company but often that isn't the case.

If there is no Events Manager then it will be an Office Manager or an EA where the EA is reporting into someone at the C-Level that organises a lot of events (CEO, COO, Founders).

We also want to include the senior C-Level exec with responsibility which is probably the Chief People Officer or equivalent.`}
                  rows={3}
                />
              </Form.Group>
              <div>
                <Form.Label>Job Titles</Form.Label>
                {jobTitles.length === 0 && !loadingTitles ? (
                  <div className='job-titles-generate-area'>
                    <Button variant='primary' onClick={handleGenerateJobTitles}>
                      Generate from guidance
                    </Button>
                  </div>
                ) : loadingTitles ? (
                  <div className='job-titles-generate-area'>
                    <Spinner animation='border' />
                  </div>
                ) : (
                  <CreatableSelect
                    isMulti
                    value={jobTitles}
                    onChange={setJobTitles}
                  />
                )}
              </div>
              {jobTitles.length > 0 && (
                <DreamInfoExpander heading='Information' alwaysExpanded>
                  {`Your agent will look up alternate spellings for the job titles you
                include (VP Sales -> VP of Sales, CEO -> Chief Executive Officer).`}
                </DreamInfoExpander>
              )}
            </div>
          </DreamModalInner.Body>
          <DreamModalInner.Footer>
            <div className='campaigns'>
              <Button variant='primary' onClick={handleSubmit}>
                Next <FontAwesomeIcon icon={faArrowRight} />
              </Button>
            </div>
            <Button variant='outline-primary' onClick={handleClose}>
              Cancel
            </Button>
          </DreamModalInner.Footer>
        </DreamModalInner>
      </DreamSideModal>

      <TestAgentProspectSearch
        show={showTestModal}
        handleClose={() => {
          setShowTestModal(false);
          handleClose();
        }}
        metadata={formData}
        type='agent_guidance'
        oagentId={oagentId}
        refreshProspectSearch={refreshProspectSearch}
        editMode={editMode}
        prospectSearchId={prospectSearchId}
      />
    </>
  );
}

export default AgentGuidanceJobTitleModal;
