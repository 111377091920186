import React, { useState, useMemo, useEffect } from 'react';
import DataTable from 'react-data-table-component';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash, faXmark } from '@fortawesome/free-solid-svg-icons';
import ConfirmModal from '../confirm-modals/ConfirmModal';
import api from '../../api';
import { Button, Spinner, OverlayTrigger, Popover } from 'react-bootstrap';
import { useAuth0 } from '@auth0/auth0-react';
import { usePusher } from '../../contexts/PusherContext';
import EditResearchPointModal from '../analyst/EditResearchPointModal';

import { ReactComponent as TruncIcon } from '../../icons/trunc.svg';
import { ReactComponent as BoltIcon } from '../../icons/bolt.svg';
import { ReactComponent as PlusIcon } from '../../icons/plus.svg';
import { ReactComponent as BulbIcon } from '../../icons/bulb.svg';

import './funnel.css';
import FakePic from '../feed/items/FakePic';
import JobsListModal from '../misc/JobsListModal';

import { marked, use } from 'marked';

import NoAnswer from './NoAnswer';
import AnswerError from './AnswerError';
import Processing from './Processing';

import { useProcessing } from '../../contexts/ProcessingContext';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

const OrgList = ({
  orgs,
  refreshOrgs,
  view,
  funnelId,
  isImport,
  hasFilter,
  researchPointChanges, // We use this to track if there have been changes to research points for the funnel although we retrieve ourself
  showResearchPointChooser,
  setDownloadData,
  refreshResearchPoints,
}) => {
  const { getAccessTokenSilently } = useAuth0();
  const pusherChannel = usePusher(); // Get the Pusher channel
  const [selectedOrgIds, setSelectedOrgIds] = useState([]);
  const [isSelectAll, setIsSelectAll] = useState(false);
  const [isConfirmModalVisible, setConfirmModalVisible] = useState(false);
  const [researchPoints, setResearchPoints] = useState([]);
  const [researchPointsLoading, setResearchPointsLoading] = useState(true);
  const [researchPointStatuses, setResearchPointStatuses] = useState([]);
  const [statusesLoading, setStatusesLoading] = useState(true);
  const [expandedResearchPoints, setExpandedResearchPoints] = useState({});
  const [confirmDeleteModalVisible, setConfirmDeleteModalVisible] =
    useState(false);
  const [selectedResearchPointId, setSelectedResearchPointId] = useState(null);
  const [isEditModalVisible, setEditModalVisible] = useState(false);
  const [editResearchPointId, setEditResearchPointId] = useState(null);

  const [showJobsListModal, setShowJobsListModal] = useState(false);
  const [selectedJobData, setSelectedJobData] = useState([]);
  const [selectJobCompanyData, setSelectedJobCompanyData] = useState(null);
  const [additionalDataKeys, setAdditionalDataKeys] = useState([]);

  const { setOldestProcessingJob } = useProcessing();

  const onDragEnd = async (result) => {
    if (!result.destination) return;

    const newResearchPoints = Array.from(researchPoints);
    const [movedItem] = newResearchPoints.splice(result.source.index, 1);
    newResearchPoints.splice(result.destination.index, 0, movedItem);

    setResearchPoints(newResearchPoints);

    // Update positions in the database
    const updatedPositions = newResearchPoints.map((rp, index) => ({
      researchPointId: rp.id,
      position: index + 1,
    }));

    try {
      const token = await getAccessTokenSilently();
      await api.put(
        `/funnels/${funnelId}/research-points/positions`,
        {
          positions: updatedPositions,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      );
    } catch (error) {
      console.error('Error updating research point positions:', error);
    }
  };

  const items = useMemo(
    () =>
      isImport && !hasFilter
        ? orgs.searched
        : view === 'filtered'
          ? orgs.filtered
          : orgs.searched,
    [orgs, view, isImport, hasFilter],
  );

  const handleJobDataClick = (jobData, company) => {
    setSelectedJobData(jobData);
    setSelectedJobCompanyData(company);
    setShowJobsListModal(true);
  };

  const fetchResearchPoints = async () => {
    try {
      const token = await getAccessTokenSilently();
      const response = await api.get(`/funnels/${funnelId}/research-points`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      const sortedResearchPoints = response.data.sort((a, b) => {
        if (a.position !== b.position) {
          return a.position - b.position;
        }
        return a.name.localeCompare(b.name);
      });

      setResearchPoints(sortedResearchPoints);
      setDownloadData({
        items,
        researchPoints: sortedResearchPoints,
      });
    } catch (error) {
      console.error('Error fetching research points:', error);
    } finally {
      setResearchPointsLoading(false);
    }
  };

  useEffect(() => {
    fetchResearchPointStatuses();
  }, [orgs]);

  // Inside OrgList.js
  const fetchResearchPointStatuses = async () => {
    try {
      const token = await getAccessTokenSilently();
      const response = await api.get(
        `/funnels/${funnelId}/research-point-status`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      );
      setResearchPointStatuses(response.data);

      // Check if any research points are in the 'processing' state
      const processingJobs = response.data
        .flatMap((item) => item.research_points)
        .filter((point) => point.status === 'processing')
        .flatMap((point) => point.jobDetails);

      setOldestProcessingJob(
        funnelId,
        processingJobs.length > 0 ? processingJobs : null,
      );
    } catch (error) {
      console.error('Error fetching research point statuses:', error);
    } finally {
      setStatusesLoading(false);
    }
  };

  const showDeleteConfirmModal = (researchPointId) => {
    setSelectedResearchPointId(researchPointId);
    setConfirmDeleteModalVisible(true);
  };

  const handleConfirmDelete = async () => {
    try {
      const token = await getAccessTokenSilently();
      await api.delete(`/funnels/${funnelId}/research-points`, {
        data: { research_point_id: selectedResearchPointId },
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      // Optimistically update the state
      setResearchPoints((prev) =>
        prev.filter((rp) => rp.id !== selectedResearchPointId),
      );
      refreshResearchPoints();
      setConfirmDeleteModalVisible(false); // Hide the confirmation modal
    } catch (error) {
      console.error('Error deleting research point:', error);
    } finally {
      setSelectedResearchPointId(null);
    }
  };

  const showEditModal = (researchPointId) => {
    setEditResearchPointId(researchPointId);
    setEditModalVisible(true);
  };

  const handleAnalystStatusUpdate = (data) => {
    const { known_org_id, research_point_id, job, status } = data;

    setResearchPointStatuses((prevRPStatuses) => {
      return prevRPStatuses.map((statusObj) => {
        if (statusObj.known_org_id !== known_org_id) {
          return statusObj;
        }
        const researchPoints = Array.isArray(statusObj.research_points)
          ? statusObj.research_points
          : [];

        const researchPointIndex = researchPoints.findIndex(
          (rp) => rp.id === research_point_id,
        );

        /*
          One thing we do here is overwrite whatever the jobDetails were even if we're talking about a different research_item_id. This is because currently we don't care what the research_item is that triggered this processing, any processing item is the one we care about.
        */
        if (researchPointIndex === -1) {
          researchPoints.push({
            id: research_point_id,
            status,
            jobDetails: [job],
          });
        } else {
          researchPoints[researchPointIndex] = {
            id: research_point_id,
            status,
            jobDetails: [job],
          };
        }

        return {
          ...statusObj,
          research_points: researchPoints,
        };
      });
    });
  };

  useEffect(() => {
    fetchResearchPoints();
    fetchResearchPointStatuses();

    if (pusherChannel) {
      pusherChannel.bind('analyst_status_update', handleAnalystStatusUpdate);
      return () => {
        pusherChannel.unbind(
          'analyst_status_update',
          handleAnalystStatusUpdate,
        );
      };
    }
  }, [funnelId, pusherChannel, researchPointChanges]);

  const toggleSelectAll = () => {
    if (!isSelectAll) {
      setSelectedOrgIds(items.map((org) => org.id));
    } else {
      setSelectedOrgIds([]);
    }
    setIsSelectAll(!isSelectAll);
  };

  const handleCheckboxChange = (orgId) => {
    setSelectedOrgIds((prevSelected) =>
      prevSelected.includes(orgId)
        ? prevSelected.filter((id) => id !== orgId)
        : [...prevSelected, orgId],
    );
  };

  useEffect(() => {
    if (selectedOrgIds.length > 0 && selectedOrgIds.length === items.length) {
      setIsSelectAll(true);
    } else {
      setIsSelectAll(false);
    }
  }, [selectedOrgIds, items]);

  const politeStatus = (status, statusObject) => {
    if (status === 'not-scheduled') {
      return '';
    } else if (status === 'processing') {
      return <Processing statusObject={statusObject} />;
    } else if (status === 'error') {
      return <AnswerError />;
    } else {
      return <span data-status={status}>?</span>;
    }
  };

  const toggleExpand = (orgId, researchPointId) => {
    const key = `${orgId}-${researchPointId}`;
    setExpandedResearchPoints((prev) => ({
      ...prev,
      [key]: !prev[key],
    }));
  };

  // Create additional data columns based on the keys in the additional_data
  useEffect(() => {
    const allAdditionalKeys = Array.from(
      new Set(
        items.flatMap((item) => item?.additional_data?.map((data) => data.key)),
      ),
    ).filter((x) => !!x);

    setAdditionalDataKeys(allAdditionalKeys);
  }, [items]);

  const columns = useMemo(() => {
    let baseColumns = [
      {
        name: '',
        width: '60px',
        selector: (row) => {
          if (row.placeholder) return;
          if (view === 'searched' && !row.has_synced_org) {
            return (
              <Spinner
                animation='border'
                className='org-syncing-spinner'
                size='sm'
              />
            );
          } else if (row.linkedin_url) {
            return (
              <span className='linkedin-icon'>
                <a href={row.linkedin_url} target='_blank' rel='noreferrer'>
                  <FakePic type='org' url={row.logo_url} />
                </a>
              </span>
            );
          } else {
            return '-';
          }
        },
      },
      {
        name: 'Name',
        width: '200px',
        selector: (row) => <div className='wrappable'>{row.name}</div>,
        sortable: true,
        sortFunction: (rowA, rowB) =>
          rowA.name.toLowerCase() < rowB.name.toLowerCase() ? -1 : 1,
      },
    ];

    const checkboxColumn = {
      name: (
        <input
          type='checkbox'
          onChange={toggleSelectAll}
          checked={isSelectAll}
        />
      ),
      cell: (row) => {
        if (row.placeholder) return;
        return (
          <input
            type='checkbox'
            checked={selectedOrgIds.includes(row.id)}
            onChange={() => handleCheckboxChange(row.id)}
          />
        );
      },
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
      width: '50px',
    };

    const addResearchPointColumn = {
      name: (
        <div
          className='add-research-column'
          onClick={() => showResearchPointChooser(true)}
        >
          Add Research <PlusIcon />
        </div>
      ),
      selector: () => '',
    };

    const additionalDataColumns = additionalDataKeys.map((key) => ({
      name: key,
      selector: (row) => {
        if (key === 'Job Data') {
          const jobData = row.additional_data.find(
            (data) => data.key === 'Job Data',
          );
          let jobCount, jobDataValue;
          try {
            jobCount = jobData ? JSON.parse(jobData.value).length : 0;
            jobDataValue = jobData ? JSON.parse(jobData.value) : [];
          } catch (e) {
            jobCount = 0;
            jobDataValue = [];
          }

          return jobData ? (
            <Button
              variant='link'
              className='tiny-link-button'
              onClick={() => handleJobDataClick(jobDataValue, row)}
            >
              {`${jobCount} ${jobCount === 1 ? 'job' : 'jobs'} found`}
            </Button>
          ) : (
            '-'
          );
        }

        const additionalData = row.additional_data.find(
          (data) => data.key === key,
        );
        return additionalData ? (
          <div
            dangerouslySetInnerHTML={{ __html: marked(additionalData.value) }}
          />
        ) : (
          '-'
        );
      },
      sortable: true,
      sortFunction: (rowA, rowB) => {
        if (key !== 'Job Data') return -1;

        const jobDataA = rowA.additional_data.find(
          (data) => data.key === 'Job Data',
        );
        const jobDataB = rowB.additional_data.find(
          (data) => data.key === 'Job Data',
        );
        let jobCountA, jobCountB;
        try {
          jobCountA = jobDataA ? JSON.parse(jobDataA.value).length : 0;
          jobCountB = jobDataB ? JSON.parse(jobDataB.value).length : 0;
        } catch (e) {
          jobCountA = 0;
          jobCountB = 0;
        }
        return jobCountA < jobCountB ? 1 : -1;
      },
      width: key === 'Job Data' ? '150px' : '300px',
    }));

    const researchPointColumns =
      isImport || view === 'filtered'
        ? researchPoints.map((rp) => ({
            name: (
              <div className='research-point-tag'>
                {rp.type === 'datapoint' ? <BulbIcon /> : <BoltIcon />}
                {rp.name}
              </div>
            ),
            selector: (row) => {
              if (row.placeholder) return;
              const researchPoint = row.research_points?.find(
                (r) => r.id === rp.id,
              );

              const getTooltipText = (rp) => {
                if (!rp || !rp.value_context) return 'No context available';
                return (
                  <div className='tooltip-context'>
                    <p
                      dangerouslySetInnerHTML={{
                        __html: marked(rp.value_context),
                      }}
                    />
                  </div>
                );
              };

              /*
              LOGIC:
              If we have a research point value and it's truthy, we show it.
              If we have one and it's falsy, we show '-'.

              If we don't have one and status is queued then we show a Spinner
              If we don't have one and status is error we show Error.
              If we don't have one and status is not-scheduled then we show nothing. This could happen temporarily as a race condition.
              Otherwise we show '?' <- Should never get here so it's for debugging.
            */

              let rpStatus;
              const filteredOrgStatus = researchPointStatuses.find(
                (status) => status.searched_org_id === row.id,
              );
              if (filteredOrgStatus) {
                rpStatus = filteredOrgStatus.research_points.find(
                  (rps) => rps.id === rp.id,
                );
              }

              const truncateAt = 32;

              // If we're processing then we should show that rather than the exsiting value
              if (rpStatus?.status === 'processing') {
                return politeStatus(rpStatus.status, rpStatus);
              }

              // Otherwise let's show the value that we currently have
              if (researchPoint) {
                /*
                 If we get here then we have the research point listed for the org
                 which means there is a researchPoint value entry for the organisation.

                 It could still be falsey however.
                */
                const key = `${row.id}-${rp.id}`;
                const isExpanded = expandedResearchPoints[key];
                let displayValue = isExpanded
                  ? String(researchPoint.value || '')
                  : String(researchPoint.value || '').substring(0, truncateAt);

                if (displayValue?.length < researchPoint?.value?.length) {
                  displayValue = `${displayValue}...`;
                }

                if (
                  researchPoint.value &&
                  researchPoint.value?.length < truncateAt
                ) {
                  return (
                    <OverlayTrigger
                      placement='top'
                      trigger='click'
                      overlay={
                        <Popover id={`tooltip-${rp.id}`}>
                          <Popover.Body>
                            {getTooltipText(researchPoint)}
                          </Popover.Body>
                        </Popover>
                      }
                    >
                      <span className='wrappable'>
                        {displayValue}
                        {researchPoint.value &&
                          researchPoint.value.length > truncateAt && (
                            <span
                              className='trunc'
                              onClick={() => toggleExpand(row.id, rp.id)}
                            >
                              {isExpanded ? <TruncIcon /> : <TruncIcon />}
                            </span>
                          )}
                      </span>
                    </OverlayTrigger>
                  );
                } else if (researchPoint.value) {
                  return (
                    <OverlayTrigger
                      placement='top'
                      trigger='click'
                      overlay={
                        <Popover id={`tooltip-${rp.id}`}>
                          <Popover.Body>
                            {getTooltipText(researchPoint)}
                          </Popover.Body>
                        </Popover>
                      }
                    >
                      <span className='wrappable'>
                        {displayValue}
                        <span
                          className='trunc'
                          onClick={() => toggleExpand(row.id, rp.id)}
                        >
                          {isExpanded ? <TruncIcon /> : <TruncIcon />}
                        </span>
                      </span>
                    </OverlayTrigger>
                  );
                } else {
                  return <NoAnswer />;
                }
              } else {
                // If we don't have an existing value and we're not processing then try and show the value
                rpStatus = rpStatus
                  ? politeStatus(rpStatus.status, rpStatus)
                  : '?';
              }
            },
            sortable: true,
            sortFunction: (a, b) => {
              const aPoint = a.research_points?.find((r) => r.id === rp.id);
              const bPoint = b.research_points?.find((r) => r.id === rp.id);
              let aVal =
                aPoint?.value === '-' || !aPoint?.value ? '' : aPoint?.value;
              let bVal =
                bPoint?.value === '-' || !bPoint?.value ? '' : bPoint?.value;

              // Check if both values are valid floats
              const aIsFloat = !isNaN(aVal) && !isNaN(parseFloat(aVal));
              const bIsFloat = !isNaN(bVal) && !isNaN(parseFloat(bVal));

              if (aIsFloat && bIsFloat) {
                // Compare as floats
                const aFloat = parseFloat(aVal);
                const bFloat = parseFloat(bVal);
                return aFloat > bFloat ? -1 : 1;
              } else {
                // Fall back to string comparison
                return aVal > bVal ? -1 : 1;
              }
            },
            width: rp.name === 'Headcount' ? '170px' : '300px',
          }))
        : [];

    if (isImport || view === 'filtered') {
      baseColumns.unshift(checkboxColumn);
    }

    return [
      ...baseColumns,
      ...additionalDataColumns,
      ...researchPointColumns,
      addResearchPointColumn,
    ];
  }, [
    isSelectAll,
    selectedOrgIds,
    view,
    isImport,
    researchPoints,
    researchPointStatuses,
    expandedResearchPoints,
    additionalDataKeys,
  ]);

  const handleDelete = async () => {
    try {
      const token = await getAccessTokenSilently();
      await api.delete(`/funnels/${funnelId}/orgs`, {
        data: { searched_org_ids: selectedOrgIds },
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setSelectedOrgIds([]); // Clear selection after deletion
      refreshOrgs();
      setConfirmModalVisible(false); // Hide the confirmation modal
    } catch (error) {
      console.error('Error deleting organizations:', error);
    }
  };

  const relevantItems = items
    .filter((x) => x.active)
    .sort((a, b) => b.id - a.id);

  const dataToShow =
    relevantItems.length > 0
      ? relevantItems
      : [
          { placeholder: true },
          { placeholder: true },
          { placeholder: true },
          { placeholder: true },
          { placeholder: true },
          { placeholder: true },
          { placeholder: true },
          { placeholder: true },
          { placeholder: true },
          { placeholder: true },
          { placeholder: true },
          { placeholder: true },
          { placeholder: true },
          { placeholder: true },
          { placeholder: true },
          { placeholder: true },
          { placeholder: true },
          { placeholder: true },
          { placeholder: true },
          { placeholder: true },
          { placeholder: true },
          { placeholder: true },
          { placeholder: true },
          { placeholder: true },
        ];

  return (
    <div className='orgs-area dream-table-wrapper'>
      <div className='datapoints-line'>
        <div className='inner-wrapper-dp'>
          {selectedOrgIds.length > 0 && (
            <div className='selected-actions'>
              <Button
                variant='outline-danger'
                className='list-delete-button'
                onClick={() => setConfirmModalVisible(true)}
              >
                <FontAwesomeIcon icon={faTrash} />
                Delete Selected
              </Button>
            </div>
          )}
          <div
            className='datapoint-button'
            onClick={() => showResearchPointChooser()}
          >
            Add Research <PlusIcon />
          </div>
          <div className='separator'></div>
          <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId='researchPoints' direction='horizontal'>
              {(provided) => (
                <div
                  className='datapoint-buttons'
                  {...provided.droppableProps}
                  ref={provided.innerRef}
                >
                  {researchPoints.map((researchPoint, index) => (
                    <Draggable
                      key={researchPoint.id}
                      draggableId={researchPoint.id.toString()}
                      index={index}
                    >
                      {(provided) => (
                        <div
                          className='datapoint-button light'
                          key={researchPoint.id}
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          onClick={() => showEditModal(researchPoint.id)}
                        >
                          {researchPoint.type === 'datapoint' ? (
                            <BulbIcon />
                          ) : (
                            <BoltIcon />
                          )}
                          {researchPoint.name}
                          <span
                            className='delete'
                            onClick={(e) => {
                              e.stopPropagation();
                              showDeleteConfirmModal(researchPoint.id);
                            }}
                          >
                            <FontAwesomeIcon icon={faXmark} />
                          </span>
                        </div>
                      )}
                    </Draggable>
                  ))}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>
        </div>
      </div>
      <div className={`dream-table-container`}>
        <DataTable
          columns={columns}
          data={dataToShow}
          responsive
          fixedHeader
          conditionalRowStyles={[
            {
              when: (row) => {
                return row.animate_in;
              },
              classNames: ['animate-in-row'],
            },
          ]}
          fixedHeaderScrollHeight='calc(100vh - 320px)'
        />
      </div>

      <ConfirmModal
        show={isConfirmModalVisible}
        handleClose={() => setConfirmModalVisible(false)}
        handleConfirm={handleDelete}
        confirmIsPrimary={false} // Use 'danger' as the variant for delete action
        title='Confirm Deletion'
        bodyText={
          <>
            <p>Are you sure you want to delete the selected organizations?</p>
          </>
        }
      />

      <ConfirmModal
        show={confirmDeleteModalVisible}
        handleClose={() => setConfirmDeleteModalVisible(false)}
        handleConfirm={handleConfirmDelete}
        title='Confirm Deletion'
        bodyText={<>Are you sure you want to delete this information?</>}
        confirmLabel='Delete'
        cancelLabel='Cancel'
      />

      {isEditModalVisible && (
        <EditResearchPointModal
          show={isEditModalVisible}
          researchPointId={editResearchPointId}
          funnelId={funnelId}
          onClose={() => {
            setEditModalVisible(false);
            refreshOrgs();
            refreshResearchPoints();
            fetchResearchPoints(); // Refresh research points after editing
          }}
        />
      )}

      {showJobsListModal && (
        <JobsListModal
          show={showJobsListModal}
          onHide={() => setShowJobsListModal(false)}
          jobs={selectedJobData}
          company={selectJobCompanyData}
        />
      )}
    </div>
  );
};

export default OrgList;
