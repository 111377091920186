import React, { useState, useEffect } from 'react';
import { Tab, Nav, Button, Spinner, Alert } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import api from '../api';

import './emails/emails.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';

const Emails = () => {
  const { getAccessTokenSilently } = useAuth0();
  const [sequences, setSequences] = useState([]);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchSequences = async () => {
      try {
        const token = await getAccessTokenSilently();
        const response = await api.get('/emails', {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        setSequences(response.data);
      } catch (error) {
        console.error('Error fetching sequences:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchSequences();
  }, [getAccessTokenSilently]);

  const createNewSequence = async () => {
    try {
      const token = await getAccessTokenSilently();
      const date = new Date().toLocaleString();
      const response = await api.post(
        '/emails',
        {
          name: `Draft Sequence (${date})`,
          subject_type: 'fixed',
          subject: 'Draft Subject',
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      );
      navigate(`/emails/${response.data.id}/edit`);
    } catch (error) {
      console.error('Error creating new sequence:', error);
    }
  };

  return (
    <div className='page-wrapper'>
      <div className='page-inner-wrapper sequence-list-page'>
        <Tab.Container defaultActiveKey='your-sequences'>
          <Nav variant='tabs' className='dream-tabs'>
            <Nav.Item>
              <Nav.Link eventKey='your-sequences'>Your Sequences</Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link eventKey='email-settings'>Email Settings</Nav.Link>
            </Nav.Item>
          </Nav>
          <Tab.Content>
            <Tab.Pane eventKey='your-sequences'>
              <div className='sequences-wrapper'>
                <div className='sequences-inner'>
                  {loading ? (
                    <div className='loading-state'>
                      <Spinner animation='border' />
                      <span>Loading your sequences...</span>
                    </div>
                  ) : (
                    <>
                      {sequences.length === 0 ? (
                        <div className='empty-state'>
                          You don't currently have any email sequences set up.
                        </div>
                      ) : (
                        <div className='sequence-list'>
                          {sequences.map((sequence) => (
                            <div className='sequence' key={sequence.id}>
                              <h4
                                onClick={() =>
                                  navigate(`/emails/${sequence.id}/edit`)
                                }
                              >
                                {sequence.name} (
                                {sequence.emails.filter((x) => x.active).length}{' '}
                                emails)
                              </h4>
                            </div>
                          ))}
                        </div>
                      )}
                      <Button
                        className='wide-add-button'
                        onClick={createNewSequence}
                      >
                        <FontAwesomeIcon icon={faPlus} />
                        Add Sequence
                      </Button>
                    </>
                  )}
                </div>
              </div>
            </Tab.Pane>
            <Tab.Pane eventKey='email-settings'>
              {/* Email Settings content will go here in the future */}
            </Tab.Pane>
          </Tab.Content>
        </Tab.Container>
      </div>
    </div>
  );
};

export default Emails;
