// src/components/create-company/NoMatchWizard.jsx
import React from 'react';
import { Button } from 'react-bootstrap';

const NoMatchWizard = ({ goBack, getStarted }) => {
  return (
    <div className='no-match-wizard dream-panel-inner'>
      <h2>{"Don't"} Panic</h2>
      <p>
        Dream supports thousands of use-cases not listed here, we just{' '}
        {"haven't"} yet built a snappy walkthrough demo for yours.
      </p>
      <p>
        If {"you'd"} like to get help from one of our experts for free then{' '}
        <a
          rel='noreferrer'
          target='_blank'
          href='https://calendly.com/ben-bwestephenson/30min'
        >
          schedule a session
        </a>
        . In the meantime, you can easily try things out for yourself.
      </p>
      <div className='forward-and-back-buttons'>
        <Button variant='link' onClick={goBack}>
          Select a different demo
        </Button>
        <Button variant='primary' onClick={getStarted}>
          Get Started Solo!
        </Button>
      </div>
    </div>
  );
};

export default NoMatchWizard;
