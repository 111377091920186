import React from 'react';
import BaseType from './BaseType';

const ProspectAdded = ({ item }) => {
  return (
    <BaseType
      url={item.metadata?.prospect?.logo_url}
      name={item.metadata?.prospect?.name}
      tagType='neutral'
      tagText={
        <>
          Added to <b>{item?.metadata?.oagent.name}</b>
        </>
      }
      tagTo={`/campaign/${item?.metadata?.oagent?.id}`}
      time={item.time}
    />
  );
};

export default ProspectAdded;
