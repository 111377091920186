import React, { useState, useEffect } from 'react';
import { Button, Spinner } from 'react-bootstrap';
import { useAuth0 } from '@auth0/auth0-react';
import { ReactComponent as EmailIcon } from '../../icons/email.svg';
import api from '../../api';

const ActiveConversationReminder = ({ oagentId, prospectId, onTabSwitch }) => {
  const { getAccessTokenSilently } = useAuth0();
  const [loading, setLoading] = useState(true);
  const [hasMessageHistory, setHasMessageHistory] = useState(false);

  useEffect(() => {
    const fetchMessageHistory = async () => {
      try {
        const token = await getAccessTokenSilently();
        setLoading(true);

        // Check if there is a message history
        const messageHistoryResponse = await api.get(
          `/oagent-prospects/${oagentId}/prospects/${prospectId}/emails`,
          {
            headers: { Authorization: `Bearer ${token}` },
          },
        );

        setHasMessageHistory(
          messageHistoryResponse.data.messageTexts &&
            messageHistoryResponse.data.messageTexts.length > 0,
        );
      } catch (error) {
        console.error('Error fetching message history:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchMessageHistory();
  }, [oagentId, prospectId, getAccessTokenSilently]);

  if (loading) {
    return null; // Render nothing while loading
  }

  return hasMessageHistory ? (
    <div className='small-reminder-wrapper'>
      <div className='small-reminder'>
        <EmailIcon />
        You have an active conversation
        <Button
          variant='link'
          onClick={() => {
            onTabSwitch('conversation');
          }}
        >
          (view)
        </Button>
      </div>
    </div>
  ) : null;
};

export default ActiveConversationReminder;
