import React, { useState, useRef, useEffect, useCallback } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { Modal, Button, Spinner } from 'react-bootstrap';
import api from '../../api';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faArrowRight,
  faCircle,
  faStop,
} from '@fortawesome/free-solid-svg-icons';
import { ReactComponent as MicrophoneIcon } from '../../icons/microphone.svg';
import DreamSideModalLoader from '../misc/DreamSideModalLoader';
import DreamSideModal from '../misc/DreamSideModal';
import DreamModalInner from '../misc/DreamModalInner';
import DreamInfoExpander from '../misc/DreamInfoExpander';

const BeInterviewedModal = ({ show, handleClose, profileId, setBio }) => {
  const { getAccessTokenSilently } = useAuth0();
  const [questions, setQuestions] = useState([]);
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [loadingQuestions, setLoadingQuestions] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [recording, setRecording] = useState(false);
  const [mediaRecorder, setMediaRecorder] = useState(null);
  const [displayInterview, setDisplayInterview] = useState(false);
  const [firstLoad, setFirstLoad] = useState(true);
  const audioChunksRef = useRef([]);
  const audioRef = useRef();

  const loadUnansweredQuestions = useCallback(async () => {
    if (firstLoad) {
      setLoadingQuestions(true);
    }
    try {
      const token = await getAccessTokenSilently();
      const response = await api.get(`/bio-assessment/${profileId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setFirstLoad(false);
      setQuestions(response.data.unansweredQuestions || []);
    } catch (error) {
      console.error('Error fetching bio assessment:', error);
    } finally {
      setLoadingQuestions(false);
    }
  }, [getAccessTokenSilently, profileId]);

  const loadAdditionalQuestions = useCallback(async () => {
    try {
      const token = await getAccessTokenSilently();
      const response = await api.get(`/bio-assessment/${profileId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setQuestions((prevQuestions) => [
        ...prevQuestions,
        ...response.data.unansweredQuestions,
      ]);
    } catch (error) {
      console.error('Error fetching additional questions:', error);
    }
  }, [getAccessTokenSilently, profileId]);

  const startRecording = async () => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
      const recorder = new MediaRecorder(stream);
      audioChunksRef.current = []; // Clear audio chunks ref before starting
      recorder.ondataavailable = (event) => {
        if (event.data.size > 0) {
          audioChunksRef.current.push(event.data); // Push chunk to ref
        } else {
          console.warn('Received empty audio data'); // Log warning for empty data
        }
      };

      recorder.onstop = handleStop;
      recorder.start();
      setMediaRecorder(recorder);
      setRecording(true);
    } catch (error) {
      console.error('Error starting recording:', error);
    }
  };

  const stopRecording = () => {
    if (mediaRecorder) {
      mediaRecorder.stop();
    }
    setRecording(false);
    setIsLoading(true);
  };

  const handleStop = async () => {
    const audioBlob = new Blob(audioChunksRef.current, { type: 'audio/webm' });
    const audioUrl = URL.createObjectURL(audioBlob);
    if (audioRef.current) {
      audioRef.current.src = audioUrl;
    }

    try {
      const formData = new FormData();
      formData.append('audio', audioBlob, 'recording.webm');
      formData.append('question', questions[currentQuestionIndex]); // Add the currentQuestion to FormData

      const token = await getAccessTokenSilently();
      api
        .post(`/bio-response/${profileId}`, formData, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((response) => {
          return setBio(response.data.bio);
        });

      setCurrentQuestionIndex((prevIndex) => prevIndex + 1);
      if (currentQuestionIndex >= questions.length - 2) {
        loadAdditionalQuestions();
      }
    } catch (error) {
      console.error('Error parsing audio:', error);
    } finally {
      audioChunksRef.current = []; // Clear the audio chunks ref after use
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (show) {
      loadUnansweredQuestions();
    }
  }, [show, loadUnansweredQuestions]);

  if (loadingQuestions) {
    return (
      <DreamSideModal show={show} onHide={handleClose}>
        <DreamModalInner>
          <DreamSideModalLoader>Loading Questions</DreamSideModalLoader>
        </DreamModalInner>
      </DreamSideModal>
    );
  }

  const currentQuestion = questions[currentQuestionIndex];

  return (
    <DreamSideModal show={show} onHide={handleClose} centered>
      <DreamModalInner>
        <DreamModalInner.Header closeButton>
          <MicrophoneIcon /> SDR Interview
        </DreamModalInner.Header>
        <DreamModalInner.Body>
          {!displayInterview ? (
            <div className='interview-intro-section'>
              <p>
                "Interview Mode" allows you to build a biography without a lot
                of typing.
              </p>
              <p>
                You will be asked a series of questions to help uncover more
                about this SDR, especially facts that will help create messages
                that are relatable
              </p>
              <p>
                Note that the questions <b>do not stop</b> and so you should
                stop when you get repeated questions.
              </p>
              <DreamInfoExpander heading='Why are biographies important?'>
                <p>
                  Biographies are how Dream will match the right agent to the
                  right prospects.
                </p>
                <p>
                  For example if you have an agent from Washington D.C. and a
                  prospect that is currently based there, that agent will be
                  chosen.
                </p>
                <p>
                  Further, if you choose to in your templates, you can ask your
                  agent to personalize emails where relevant and this bio will
                  be used for that.
                </p>
              </DreamInfoExpander>
            </div>
          ) : (
            <div className='interview-section'>
              <>
                {recording && (
                  <>
                    <h5>{currentQuestion}</h5>
                    <Button onClick={stopRecording} variant='outline-primary'>
                      <FontAwesomeIcon icon={faStop} /> Save Answer
                    </Button>
                    <p className='muted'>
                      Questions are continuous - for best results answer 15 to
                      20.
                    </p>
                  </>
                )}
                {!recording && !isLoading && (
                  <>
                    <h5>{currentQuestion}</h5>
                    <Button onClick={startRecording} variant='primary'>
                      <MicrophoneIcon /> Record Answer
                    </Button>
                    <p>
                      Questions are continuous - for best results answer 15 to
                      20.{' '}
                    </p>
                    <p>
                      <b>You can stop at any point and answers are saved.</b>
                    </p>
                  </>
                )}
                {isLoading && (
                  <div className='spinner-wrapper'>
                    <Spinner animation='border' />
                    <span className='spinner-loader'>Analysing Response</span>
                  </div>
                )}
              </>
            </div>
          )}
        </DreamModalInner.Body>
        <DreamModalInner.Footer>
          {!displayInterview ? (
            <>
              <div className='sdrs'>
                <Button
                  variant='primary'
                  onClick={() => setDisplayInterview(true)}
                >
                  Next <FontAwesomeIcon icon={faArrowRight} />
                </Button>
              </div>
              <Button variant='outline-primary' onClick={handleClose}>
                Close
              </Button>
            </>
          ) : (
            <Button variant='primary' onClick={handleClose}>
              End the Interview
            </Button>
          )}
        </DreamModalInner.Footer>
      </DreamModalInner>
    </DreamSideModal>
  );
};

export default BeInterviewedModal;
