import React, { useState } from 'react';
import { Form, Button, Spinner, Alert } from 'react-bootstrap';
import { useAuth0 } from '@auth0/auth0-react';

import GoogleLocationAutoComplete from '../form-components/GoogleLocationAutoComplete';
import GoogleCountrySelect from '../form-components/GoogleCountrySelect';
import CsvUploader from '../form-components/CsvUploader'; // Import the CsvUploader component

import api from '../../api';

const politeTitles = {
  google_search: 'Google Search',
  local_businesses: 'Local Search',
  news_stories: 'News Search',
  list: 'List Upload',
};

const DreamWizardDemo = ({ useCase, goBack, advanceToFunnelBuild }) => {
  const { getAccessTokenSilently } = useAuth0();
  const [searchTerm, setSearchTerm] = useState('');
  const [country, setCountry] = useState('UK'); // Default country for news_stories
  const [location, setLocation] = useState('');
  const [aiGuidance, setAiGuidance] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [createError, setCreateError] = useState(false);
  const [csvData, setCsvData] = useState(null);

  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsLoading(true);
    setCreateError(false);

    try {
      const token = await getAccessTokenSilently();
      const metadata = {
        ...(useCase === 'google_search' && { search_term: searchTerm }),
        ...(useCase === 'news_stories' && {
          search_term: searchTerm,
          country,
          ai_guidance: aiGuidance,
        }),
        ...(useCase === 'local_businesses' && {
          search_term: searchTerm,
          location,
        }),
        ...(useCase === 'list' && { list_of_companies: csvData }),
        max_companies: 15,
      };

      const data = {
        name: 'Demo Funnel',
        stages: {
          company_filter: [{ type: 'no_company_filter', metadata: {} }],
          company_ranker: [{ type: 'no_company_ranker', metadata: {} }],
          prospect_search: [{ type: 'no_prospect_search', metadata: {} }],
          prospect_ranker: [{ type: 'no_prospect_ranker', metadata: {} }],
          company_search: [
            {
              type:
                useCase === 'google_search'
                  ? 'google_then_diffbot_articles'
                  : useCase === 'news_stories'
                    ? 'google_news_search'
                    : useCase === 'local_businesses'
                      ? 'google_local_search'
                      : 'list',
              metadata: metadata,
            },
          ],
        },
        duplicate_use_case_id: 'default', // Includes the use_case we created in the earlier step
      };

      const response = await api.post('/funnels', data, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (response.status === 200) {
        advanceToFunnelBuild(response.data.funnelId);
      }
    } catch (error) {
      setCreateError('Error creating funnel. Please try again later.');
      console.error('Error creating funnel:', error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className='position-relative dream-wizard-demo dream-panel-inner'>
      {isLoading && (
        <div className='overlay'>
          <Spinner animation='border' size='lg' />
        </div>
      )}
      <Form
        className={`demo-wizard-form ${isLoading && 'loading'}`}
        onSubmit={handleSubmit}
      >
        {!!createError && <Alert variant='danger'>{createError}</Alert>}

        <h2>Prospecting from a {politeTitles[useCase] || useCase}</h2>
        <p>Dream finds leads for you using funnels.</p>
        <p>
          This is a set of steps that you define that explains how to identify
          potential customers. {"We're"} building a funnel now.
        </p>
        <p>Every funnel starts with a search (or list upload).</p>
        <p>
          Searches can have many different types, you've chosen to start with a{' '}
          {politeTitles[useCase] || useCase}.
        </p>

        {useCase === 'google_search' && (
          <Form.Group controlId='formSearchTerm'>
            <Form.Label>What would you search for on Google?</Form.Label>
            <Form.Control
              type='text'
              name='search_term'
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              size='lg'
            />
            <div className='examples'>
              <Form.Text muted>
                e.g. &quot;Top SaaS companies in London&quot;
              </Form.Text>
              <Form.Text muted>
                e.g. &quot;Largest steel manufacturers&quot;
              </Form.Text>
              <Form.Text muted>e.g. &quot;TV OEMs&quot;</Form.Text>
            </div>
          </Form.Group>
        )}
        {useCase === 'news_stories' && (
          <>
            <Form.Group controlId='formSearchTerm' className='breathing-room'>
              <Form.Label>
                What search term would you use to find news stories?
              </Form.Label>
              <Form.Control
                type='text'
                name='search_term'
                value={searchTerm}
                size='lg'
                onChange={(e) => setSearchTerm(e.target.value)}
              />
              <div className='examples'>
                <Form.Text muted>
                  e.g. "Profit warnings", "Fundraise", "IPO Listing"
                </Form.Text>
              </div>
            </Form.Group>
            <Form.Group controlId='formAiGuidance' className='breathing-room'>
              <Form.Label>
                Additional help to get the right company in the story (optional)
              </Form.Label>
              <Form.Control
                as='textarea'
                name='ai_guidance'
                value={aiGuidance}
                size='lg'
                onChange={(e) => setAiGuidance(e.target.value)}
              />
              <div className='examples'>
                <Form.Text muted>
                  e.g. "Pick the company that raised money not the company they
                  raised money from"
                </Form.Text>
              </div>
            </Form.Group>
            <Form.Group controlId='formCountry' className='breathing-room'>
              <Form.Label>
                Which country are you looking for news from?
              </Form.Label>
              <GoogleCountrySelect
                hasPleaseSelect
                name='country'
                size='lg'
                value={country}
                onChange={(value) => setCountry(value)}
              />
            </Form.Group>
          </>
        )}
        {useCase === 'local_businesses' && (
          <>
            <Form.Group controlId='formSearchTerm' className='breathing-room'>
              <Form.Label>
                What kind of business are you looking for?
              </Form.Label>
              <Form.Control
                type='text'
                name='search_term'
                size='lg'
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
              />
              <div className='examples'>
                <Form.Text muted>
                  e.g "Architects", "Design Studios", "Plumbers"
                </Form.Text>
              </div>
            </Form.Group>
            <Form.Group controlId='formLocation' className='breathing-room'>
              <Form.Label>What location are you searching in?</Form.Label>
              <GoogleLocationAutoComplete
                name='location'
                id='local_search_autocomplete'
                size='lg'
                onLocationSelected={(val) => setLocation(val)}
              />
              <div className='examples'>
                <Form.Text muted>
                  Google uses some funky locations, if you can't find an exact
                  place try the nearest town or state.
                </Form.Text>
              </div>
            </Form.Group>
          </>
        )}
        {useCase === 'list' && <CsvUploader onCsvDataChange={setCsvData} />}

        <div className='forward-and-back-buttons'>
          <Button variant='link' onClick={goBack}>
            Choose a different type of search
          </Button>
          <Button variant='primary' type='submit' disabled={isLoading}>
            Find Leads
          </Button>
        </div>
      </Form>
    </div>
  );
};

export default DreamWizardDemo;
