import React from 'react';
import { Form } from 'react-bootstrap';
import countries from '../../utils/google-countries.json';

const GoogleCountrySelect = ({ hasPleaseSelect, ...props }) => {
  return (
    <Form.Control as='select' {...props}>
      {hasPleaseSelect && <option>--- Please Select ---</option>}
      {countries.map((country) => (
        <option key={country.country_code} value={country.country_code}>
          {country.country_name}
        </option>
      ))}
    </Form.Control>
  );
};

export default GoogleCountrySelect;
