import React from 'react';
import { Modal, Button } from 'react-bootstrap';

function ConfirmModal({
  show,
  handleClose,
  handleConfirm,
  title,
  bodyText,
  confirmLabel,
  cancelLabel,
  ...rest
}) {
  return (
    <Modal show={show} onHide={handleClose} {...rest}>
      <Modal.Header closeButton>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>{bodyText}</Modal.Body>
      <Modal.Footer>
        <Button variant='outline-primary' onClick={handleClose}>
          {cancelLabel || 'Cancel'}
        </Button>
        <Button variant='primary' onClick={handleConfirm}>
          {confirmLabel || 'Confirm'}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default ConfirmModal;
