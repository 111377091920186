import React from 'react';
import DreamSideModal from '../misc/DreamSideModal';
import DreamModalInner from '../misc/DreamModalInner';
import './jobs.css';
import { Button } from 'react-bootstrap';
import { ReactComponent as LinkedinIcon } from '../../icons/linkedin.svg';
import { marked } from 'marked';
import FakePic from '../feed/items/FakePic';

const LocalizedDateTime = ({ dateTimeString }) => {
  if (!dateTimeString) return null; // Add null check and return
  const [datePart, timePart] = dateTimeString.split(' ');
  const formattedString = `${datePart}T${timePart}`;
  const date = new Date(formattedString);
  const localeString = date.toLocaleString();

  return <span>Posted {localeString}</span>;
};

function JobViewModal({ show, onHide, job, company }) {
  const handleVisitButtonClick = () => {
    window.open(job.url, '_blank'); // Open job.url in a new tab
  };

  return (
    <DreamSideModal show={show} handleClose={onHide}>
      <DreamModalInner>
        <DreamModalInner.Header>{job.title}</DreamModalInner.Header>
        <DreamModalInner.Body>
          <div className='job-view-wrapper'>
            <div className='header'>
              <div>
                <div className='set'>
                  <p className='label'>Seniority</p>
                  <p className='value'>{job.seniority}</p>
                </div>
                <div className='set'>
                  <p className='label'>Location</p>
                  <p className='value'>{job.location}</p>
                </div>
              </div>
              <Button
                className='visit-button'
                variant='outline-primary'
                onClick={handleVisitButtonClick} // Add onClick handler
              >
                <LinkedinIcon />
                View Listing
              </Button>
            </div>
            <div className='description-wrapper'>
              <div className='description-header'>
                <div>
                  <FakePic url={company.logo_url} type='org' />
                  <p>{company.name}</p>
                </div>
                <small>
                  <LocalizedDateTime dateTimeString={job.created} />
                </small>
              </div>
              <div
                className='main-content-area'
                dangerouslySetInnerHTML={{ __html: marked(job.description) }}
              />
            </div>
          </div>
        </DreamModalInner.Body>
      </DreamModalInner>
    </DreamSideModal>
  );
}

export default JobViewModal;
