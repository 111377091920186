import React, { useEffect, useState } from 'react';
import { useParams, Link, useLocation, useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { useAuth0 } from '@auth0/auth0-react';
import { Spinner } from 'react-bootstrap';
import Messaging from './steps/Messaging';
import api from '../../api';

import './campaign-edit-messaging.css';
import MessageList from './MessageList';

const CampaignEditMessaging = () => {
  const { id } = useParams(); // oagentId from the params
  const location = useLocation();
  const navigate = useNavigate();
  const { getAccessTokenSilently } = useAuth0();
  const [oagent, setOagent] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  // Extract 'view' query parameter from the location search string
  const queryParams = new URLSearchParams(location.search);
  const view = queryParams.get('view');

  // Determine if the view is editing or listing
  const isEditView = view === 'edit';

  useEffect(() => {
    // Fetch the oagent details when the component mounts
    const getOagent = async () => {
      try {
        const token = await getAccessTokenSilently(); // Get the access token
        const response = await api.get(`/oagents/${id}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        setOagent(response.data);
      } catch (error) {
        console.error('Failed to fetch oagent details:', error);
      } finally {
        setIsLoading(false);
      }
    };

    getOagent();
  }, [id, getAccessTokenSilently]);

  const handleToggleView = () => {
    // Toggle view by updating the query string
    navigate(isEditView ? '?view=list' : '?view=edit');
  };

  if (isLoading) {
    return (
      <div className='page-wrapper campaign-edit-general'>
        <div className='page-inner-wrapper'>
          <div className='page-header-area'>
            <div className='title-and-text'>
              <div className='breadcrumbs'>
                <div className='set'>
                  <Link to={isEditView ? '?view=list' : `/campaign/${id}`}>
                    <FontAwesomeIcon icon={faArrowLeft} /> Back to{' '}
                    {isEditView ? 'Message List' : 'Campaign Overview'}
                  </Link>
                </div>
              </div>
              <h3>
                <b>Message List</b>
              </h3>
            </div>
          </div>
        </div>
        <div className='page-lower-area'>
          <div className='middle-loading-view'>
            <Spinner animation='border' role='status' />
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className='page-wrapper campaign-edit-messaging'>
      <div className='page-inner-wrapper'>
        <div className='page-header-area'>
          <div className='title-and-text'>
            <div className='breadcrumbs'>
              <div className='set'>
                <Link to={isEditView ? '?view=list' : `/campaign/${id}`}>
                  <FontAwesomeIcon icon={faArrowLeft} /> Back to{' '}
                  {isEditView ? 'Message List' : 'Campaign Overview'}
                </Link>
              </div>
            </div>
            <h3>
              <b>Messaging:</b> <small>{oagent?.name}</small>
            </h3>
          </div>
        </div>
      </div>

      <div className='edit-messaging-lower-area'>
        {isEditView ? (
          <Messaging oagentId={id} enableSend={() => {}} />
        ) : (
          <MessageList
            oagentId={id}
            openSettings={() => navigate('?view=edit')}
          />
        )}
      </div>
    </div>
  );
};

export default CampaignEditMessaging;
