import React, { useState, useEffect } from 'react';
import { Form, Button, Spinner, Tabs, Tab, Alert } from 'react-bootstrap';
import { useAuth0 } from '@auth0/auth0-react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBolt, faExpand } from '@fortawesome/free-solid-svg-icons';
import { useNavigate, useSearchParams } from 'react-router-dom';

import api from '../../api';
import functionManifests from '../../utils/function-manifests'; // Import function manifests
import DreamWizardAddFirstFilter from './DreamWizardAddFirstFilter'; // Import the new component

const DreamWizardFunnelBuild = ({ funnelId }) => {
  const { getAccessTokenSilently } = useAuth0();
  const [funnel, setFunnel] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [isFilterResetLoading, setIsFilterResetLoading] = useState(false);
  const [funnelBuildStage, setFunnelBuildStage] = useState('');
  const [activeTab, setActiveTab] = useState('explanation');
  const [agentTabEnabled, setAgentTabEnabled] = useState(true);
  const [isRunningSearch, setIsRunningSearch] = useState(false);
  const [isRunningFunnel, setIsRunningFunnel] = useState(false);
  const [isUpdatingStage, setIsUpdatingStage] = useState(false);
  const [alertMessage, setAlertMessage] = useState(null);
  const [orgs, setOrgs] = useState([]);
  const [orgsPostFilter, setOrgsPostFilter] = useState({});
  const [newSearchTerm, setNewSearchTerm] = useState('');
  const [aiGuidance, setAiGuidance] = useState('');
  const [companySearchManifest, setCompanySearchManifest] = useState(null);

  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();

  const updateFunnelBuildStage = (newStage) => {
    setSearchParams({ funnel_build_stage: newStage });
  };

  useEffect(() => {
    const initialStage =
      searchParams.get('funnel_build_stage') || 'initial_search';
    setFunnelBuildStage(initialStage);
  }, [searchParams]);

  const fetchFunnel = async () => {
    try {
      const token = await getAccessTokenSilently();
      const response = await api.get(`/funnels/${funnelId}/full-page-details`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (response.status === 200) {
        setFunnel(response.data);
        const companySearchStage = response.data.stages.company_search?.[0];
        if (companySearchStage) {
          // Set default ai_guidance from metadata if available
          if (companySearchStage.metadata?.ai_guidance) {
            setAiGuidance(companySearchStage.metadata.ai_guidance);
          }
          if (companySearchStage.metadata?.search_term) {
            setNewSearchTerm(companySearchStage.metadata.search_term);
          }
          // Retrieve the function manifest for company_search
          const manifest = functionManifests.company_search.find(
            (f) => f.type === companySearchStage.type,
          );
          setCompanySearchManifest(manifest);
        }
      }
    } catch (error) {
      console.error('Error fetching funnel:', error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (funnelId) {
      fetchFunnel();
    }
  }, [funnelId, getAccessTokenSilently]);

  const handleRunSearch = () => {
    setAgentTabEnabled(true);
    setActiveTab('agent');
  };

  const handleAddFilterDone = () => {
    fetchFunnel();
    setAgentTabEnabled(true);
    setActiveTab('agent');
  };

  const handleNowRunSearch = async () => {
    setIsRunningSearch(true);
    setActiveTab('agent');
    try {
      const token = await getAccessTokenSilently();
      await api.post(
        '/run-funnel',
        { funnel_id: funnelId },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      );
      updateFunnelBuildStage('running_search');
      pollFunnelRunStatus([fetchOrgsPostSearch]);
    } catch (error) {
      console.error('Error running funnel:', error);
    }
  };

  const handleNowRunFunnel = async (start_from, new_stage, afterCalls) => {
    setIsRunningFunnel(true);
    setActiveTab('agent');
    try {
      const token = await getAccessTokenSilently();
      await api.post(
        '/run-funnel',
        { funnel_id: funnelId, start_from, reset: true },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      );
      updateFunnelBuildStage(new_stage);
      pollFunnelRunStatus(afterCalls);
    } catch (error) {
      alert('There was an error running the funnel, please try again');
      console.error('Error running funnel:', error);
    }
  };

  const pollFunnelRunStatus = async (afterCalls) => {
    const token = await getAccessTokenSilently();
    const interval = setInterval(async () => {
      try {
        const response = await api.get(`/funnel-run-status/${funnelId}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        if (response.data.status === 'completed') {
          clearInterval(interval);
          afterCalls.map((x) => x());
        } else if (response.data.status === 'error') {
          clearInterval(interval);
          setAlertMessage(
            'There was an error running the funnel. Please try again.',
          );
          setIsRunningSearch(false);
        }
      } catch (error) {
        console.error('Error polling funnel run status:', error);
      }
    }, 1000);
  };

  const fetchOrgsPostSearch = async () => {
    try {
      const token = await getAccessTokenSilently();
      const response = await api.get(`/funnels/${funnelId}/orgs`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (response.status === 200) {
        const { searched } = response.data.orgs;
        setOrgs(searched);
        setIsRunningSearch(false);
        setActiveTab('explanation');
        updateFunnelBuildStage(
          searched.length === 0 ? 'zero_orgs_found' : 'orgs_found',
        );
      }
    } catch (error) {
      console.error('Error fetching organizations:', error);
    }
  };

  const fetchOrgsPostFilter = async () => {
    try {
      const token = await getAccessTokenSilently();
      const response = await api.get(`/funnels/${funnelId}/orgs`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (response.status === 200) {
        setOrgsPostFilter(response.data.orgs);
        setIsRunningFunnel(false);
        setActiveTab('explanation');
        updateFunnelBuildStage(
          response.data.orgs.filtered.length > 0
            ? 'filter_orgs_found'
            : 'filter_zero_orgs',
        );
      }
    } catch (error) {
      console.error('Error fetching organizations:', error);
    }
  };

  const handleChangeSearchTerm = async () => {
    const stageId = funnel.stages.company_search[0].id;
    setIsUpdatingStage(true);
    try {
      const token = await getAccessTokenSilently();
      await api.put(
        `/update-stage/${stageId}/company-search`,
        { search_term: newSearchTerm },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      );

      await fetchFunnel();
      await handleNowRunSearch();
    } catch (error) {
      console.error('Error updating search term:', error);
    } finally {
      setIsUpdatingStage(false);
    }
  };

  const handleUpdateAiGuidance = async () => {
    const stageId = funnel.stages.company_search[0].id;
    setIsUpdatingStage(true);
    try {
      const token = await getAccessTokenSilently();
      await api.put(
        `/update-stage/${stageId}/company-search`,
        { ai_guidance: aiGuidance },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      );

      await api.delete(`/funnels/${funnelId}/clear-funnel`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      await handleNowRunSearch();
    } catch (error) {
      console.error('Error updating AI guidance:', error);
    } finally {
      setIsUpdatingStage(false);
    }
  };

  const renderStageContent = (stage, title, buildStage) => {
    if (!Array.isArray(buildStage)) buildStage = [buildStage];
    const isHighlighted = buildStage.includes(funnelBuildStage);

    return (
      <div
        className={`funnel-stage ${isHighlighted ? 'highlighted' : 'greyed-out'}`}
      >
        {isUpdatingStage && isHighlighted && (
          <div className='overlay'>
            <Spinner animation='border' />
          </div>
        )}
        <h4>{title}</h4>
        {funnel?.stages[stage].map((stageData) => {
          const metadata = stageData.metadata;
          const functionManifest = functionManifests[stage]?.find(
            (f) => f.type === stageData.type,
          );

          return (
            <div key={stageData.id}>
              <p>{functionManifest?.name || stageData.type}</p>
              {functionManifest?.display && functionManifest.display.show && (
                <p>"{metadata[functionManifest.display.show]}"</p>
              )}
            </div>
          );
        })}
      </div>
    );
  };

  const handleResetFilters = async () => {
    const filterStageId = funnel.stages.company_filter[0].id;

    try {
      const token = await getAccessTokenSilently();
      setIsFilterResetLoading(true);
      await api.delete(`/funnels/${funnelId}/remove-stage`, {
        data: { stage: 'company_filter', option_id: filterStageId },
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      setIsFilterResetLoading(false);

      updateFunnelBuildStage('initial_filters');

      await fetchFunnel();
    } catch (error) {
      console.error('Error resetting filters:', error);
    }
  };

  const renderExplanationContent = () => {
    const showImportantNote =
      companySearchManifest?.additional_options?.max_companies;
    const showNotGoodMatches =
      companySearchManifest?.additional_options?.ai_guidance ||
      companySearchManifest?.additional_options?.guidance;

    switch (funnelBuildStage) {
      case 'initial_search':
        return (
          <>
            <h4>Let's Find Leads</h4>
            <p>
              You've setup your first search, you can see on the left that this
              is the first stage of your funnel (we'll add the others later).
            </p>
            <p>
              Now it's time to see how it works. Press the button below that
              says "Run Search".
            </p>
            <div className='funnel-build-button-wrapper'>
              <Button variant='primary' onClick={handleRunSearch}>
                Run Search
              </Button>
            </div>
          </>
        );
      case 'running_search':
        return (
          <>
            <h4>Search In Progress</h4>
            <p>Click on the "Agent" tab to view progress.</p>
          </>
        );
      case 'running_filters':
        return (
          <>
            <h4>Funnel Run In Progress</h4>
            <p>
              Your agent is running your funnel. Click on the "Agent" tab to
              view progess.
            </p>
          </>
        );
      case 'filter_zero_orgs':
        return (
          <>
            <h4>No Companies Passed Your Filter</h4>
            <p>
              Unfortunately you were a little strict on your filtering and we
              need some companies to show you how they can be ranked and how you
              can find leads.
            </p>
            <div className='funnel-build-button-wrapper'>
              {isFilterResetLoading ? (
                <div className='overlay'>
                  <Spinner animation='border' />
                </div>
              ) : (
                <>
                  <Button variant='link' onClick={() => navigate('/dashboard')}>
                    Skip onboarding
                  </Button>
                  <Button variant='primary' onClick={handleResetFilters}>
                    Choose New Filters
                  </Button>
                </>
              )}
            </div>
          </>
        );
      case 'filter_orgs_found':
        return (
          <>
            <h4>Filter Results</h4>
            {orgsPostFilter?.filtered?.length > 0 ? (
              <>
                <p>
                  <b>Fantastic</b>, some of the companies that we found passed
                  your filter. Check them out below.
                </p>
                <div className='orgs-list'>
                  {orgsPostFilter.filtered.map((org) => (
                    <span key={org.id} className='org-tag'>
                      {org.name}
                    </span>
                  ))}
                </div>
              </>
            ) : (
              <p>Unfortunately, none of the companies passed your filter.</p>
            )}

            {orgsPostFilter?.searched?.filter(
              (searchedOrg) =>
                !orgsPostFilter.filtered.some(
                  (filteredOrg) => filteredOrg.name === searchedOrg.name,
                ),
            ).length > 0 ? (
              <>
                <p>Here are the ones that failed to pass the filter:</p>
                <div className='orgs-list'>
                  {orgsPostFilter.searched
                    .filter(
                      (searchedOrg) =>
                        !orgsPostFilter.filtered.some(
                          (filteredOrg) =>
                            filteredOrg.name === searchedOrg.name,
                        ),
                    )
                    .map((org) => (
                      <span key={org.id} className='negative org-tag'>
                        {org.name}
                      </span>
                    ))}
                </div>
              </>
            ) : (
              <p>No companies were filtered out.</p>
            )}

            <div className='funnel-build-button-wrapper'>
              <Button
                variant='primary'
                onClick={() =>
                  updateFunnelBuildStage('initial_company_rankers')
                }
              >
                Next Step
              </Button>
            </div>
          </>
        );
      case 'zero_orgs_found':
        return (
          <>
            <h4>Oh no, your search retrieved zero results.</h4>
            <p>This usually only happens if we searched for the wrong thing.</p>
            <p>
              You can confirm that your search term works by Googling it
              yourself. Does this produce articles about the type of company
              you're looking for?
            </p>
            <div className='funnel-build-button-wrapper'>
              <Button variant='link' onClick={() => navigate('/dashboard')}>
                Skip onboarding
              </Button>
              <Button
                variant='primary'
                onClick={() => updateFunnelBuildStage('change_search')}
              >
                Change Search Term
              </Button>
            </div>
          </>
        );
      case 'orgs_found':
        return (
          <>
            <h4>Great news, you have some results.</h4>
            {showImportantNote && (
              <p>
                <b>IMPORTANT NOTE:</b> Normally we'll find hundreds of results
                per search but for this tutorial, we have limited it to 15.
              </p>
            )}
            <div className='orgs-list'>
              {orgs.map((org) => (
                <span key={org.id} className='org-tag'>
                  {org.name}
                </span>
              ))}
            </div>
            <div className='funnel-build-button-wrapper'>
              {showNotGoodMatches && (
                <Button
                  variant='link'
                  onClick={() => updateFunnelBuildStage('not_good_matches')}
                >
                  These aren't good matches
                </Button>
              )}
              <Button
                variant='primary'
                onClick={() => updateFunnelBuildStage('initial_filters')}
              >
                Next Step
              </Button>
            </div>
          </>
        );
      case 'change_search':
        return (
          <>
            <h4>Change Search</h4>
            <p>
              One way to ensure you're finding results is to be more general
              (you can filter later on).
            </p>
            <Form.Group
              controlId='formNewSearchTerm'
              className='company-form in-wizard'
            >
              <Form.Label> Search Term</Form.Label>
              <Form.Control
                type='text'
                value={newSearchTerm}
                onChange={(e) => setNewSearchTerm(e.target.value)}
              />
            </Form.Group>
            <div className='funnel-build-button-wrapper'>
              {isUpdatingStage ? (
                <div className='overlay'>
                  <Spinner animation='border' />
                </div>
              ) : (
                <Button
                  variant='primary'
                  onClick={handleChangeSearchTerm}
                  disabled={isUpdatingStage}
                >
                  Redo Search
                </Button>
              )}
            </div>
          </>
        );
      case 'not_good_matches':
        return (
          <>
            <h4>Not Good Matches</h4>
            <p>
              Just like any SDR, our agent can get things wrong if it doesn't
              have enough information.
            </p>
            <p>
              To address this you can always add "guidance" to any type of
              search. Let's try this again by adding some guidance:
            </p>
            <Form.Group
              controlId='formAiGuidance'
              className='company-form in-wizard'
            >
              <Form.Label>Guidance for the Agent:</Form.Label>
              <Form.Control
                as='textarea'
                placeholder="e.g. Don't return companies that are not primarily tech companies."
                value={aiGuidance}
                onChange={(e) => setAiGuidance(e.target.value)}
              />
            </Form.Group>
            <div className='funnel-build-button-wrapper'>
              {isUpdatingStage ? (
                <div className='overlay'>
                  <Spinner animation='border' />
                </div>
              ) : (
                <Button
                  variant='primary'
                  onClick={handleUpdateAiGuidance}
                  disabled={isUpdatingStage}
                >
                  Redo Search
                </Button>
              )}
            </div>
          </>
        );
      case 'initial_filters':
        return (
          <DreamWizardAddFirstFilter
            funnelId={funnelId}
            advance={handleAddFilterDone}
          />
        );
      default:
        return null;
    }
  };

  const renderAgentContent = () => {
    switch (funnelBuildStage) {
      case 'initial_search':
      case 'running_search':
      case 'orgs_found':
      case 'zero_orgs_found':
      case 'change_search':
      case 'not_good_matches':
        return (
          <>
            <p>Ah we should introduce you to your sales agent.</p>
            <p>
              This is the friendly AI that will be doing all of your searching,
              filtering and ranking for you. Whenever you see the{' '}
              <FontAwesomeIcon icon={faExpand} /> icon you can see what your
              agent is doing.
            </p>
            <iframe
              src={`/agent/${funnelId}?iframed=true`}
              title='Agent'
              style={{ width: '100%', height: '500px' }}
            ></iframe>
            <div className='funnel-build-button-wrapper'>
              {isRunningSearch ? (
                <Button
                  className='running-button'
                  variant='secondary'
                  onClick={handleNowRunSearch}
                  disabled
                >
                  <Spinner animation='border' /> Running Search
                </Button>
              ) : (
                <Button variant='primary' onClick={handleNowRunSearch}>
                  <FontAwesomeIcon icon={faBolt} /> Run Search
                </Button>
              )}
            </div>
          </>
        );
      // Add cases for other agent-specific stages if available in the future.
      case 'initial_filters':
      case 'running_filters':
        return (
          <>
            <p>
              This is where you left your agent, they'd just found your initial
              results.
            </p>
            <p>
              Press the Run Funnel button below to run your companies through
              the filter stage you've just added.
            </p>
            <iframe
              src={`/agent/${funnelId}?iframed=true`}
              title='Agent'
              style={{ width: '100%', height: '500px' }}
            ></iframe>
            <div className='funnel-build-button-wrapper'>
              {isRunningFunnel ? (
                <Button className='running-button' variant='secondary' disabled>
                  <Spinner animation='border' /> Running Funnel
                </Button>
              ) : (
                <Button
                  variant='primary'
                  onClick={() =>
                    handleNowRunFunnel('company_filter', 'running_filters', [
                      fetchOrgsPostFilter,
                    ])
                  }
                >
                  <FontAwesomeIcon icon={faBolt} /> Run Funnel
                </Button>
              )}
            </div>
          </>
        );
      default:
        return null;
    }
  };

  if (isLoading) {
    return (
      <div className='overlay'>
        <Spinner animation='border' size='lg' />
      </div>
    );
  }

  return (
    <div className='dream-wizard-funnel-build'>
      {alertMessage && <Alert variant='danger'>{alertMessage}</Alert>}
      <div className='container'>
        <div className='row'>
          <div className='col-md-4'>
            {renderStageContent('company_search', 'Company Search', [
              'initial_search',
              'running_search',
              'orgs_found',
              'zero_orgs_found',
              'change_search',
              'not_good_matches',
            ])}
            {renderStageContent('company_filter', 'Company Filters', [
              'initial_filters',
              'running_filters',
              'filter_orgs_found',
              'filter_zero_orgs',
            ])}
            {renderStageContent(
              'company_ranker',
              'Company Rankers',
              'initial_ranking',
            )}
            {renderStageContent(
              'prospect_search',
              'Prospect Searches',
              'initial_prospects',
            )}
            {renderStageContent(
              'prospect_ranker',
              'Prospect Rankers',
              'initial_ranking',
            )}
          </div>
          <div className='col-md-8'>
            <Tabs
              id='funnel-tabs'
              activeKey={activeTab}
              onSelect={(k) => setActiveTab(k)}
            >
              <Tab eventKey='explanation' title='Explanation'>
                <div className='funnel-build-explanation'>
                  {renderExplanationContent()}
                </div>
              </Tab>
              <Tab
                eventKey='agent'
                title={
                  <span>
                    <FontAwesomeIcon icon={faExpand} /> &nbsp;Agent
                  </span>
                }
                disabled={!agentTabEnabled}
              >
                <div className='agent-wrapper'>{renderAgentContent()}</div>
              </Tab>
            </Tabs>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DreamWizardFunnelBuild;
