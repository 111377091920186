// src/components/MessageText.js

import React from 'react';
import { ReactComponent as ReplyIcon } from '../../icons/reply.svg';
import { ReactComponent as NewThreadIcon } from '../../icons/new-thread.svg';
import '../prospects/prospects.css';

const escapeHtml = (unsafe) => {
  return unsafe
    .replace(/&/g, '&amp;')
    .replace(/</g, '&lt;')
    .replace(/>/g, '&gt;')
    .replace(/"/g, '&quot;')
    .replace(/'/g, '&#039;');
};

const parseHeadersAndBody = (text) => {
  const lines = text.split('\n');
  const headers = [];
  let bodyStartIndex = 0;

  lines.forEach((line, index) => {
    if (/^(From|To|Cc|Bcc|Subject|Date):/i.test(line)) {
      const wrappedLine = line.replace(/^([^:]+):/, '$1:');
      headers.push(wrappedLine);
      bodyStartIndex = index + 1;
    }
  });

  // Slice 1 removes a \n that always gets added.
  const body = lines.slice(bodyStartIndex).join('\n').slice(1);
  return { headers: headers.join('\n'), body };
};

const getReplyHeader = (headers) => {
  if (!headers) return '';
  if (!headers.split('\n').length) return '';

  const header = headers.split('\n').find((x) => x.indexOf('From') !== -1);

  if (!header) return '';
  const [pre, post] = header.split(':');

  return post ? <b>{post}</b> : '';
};

const MessageText = ({ message, isOutbound, shortHeader, count, showTime }) => {
  const { headers, body } = parseHeadersAndBody(message);

  return (
    <div className={`message ${isOutbound ? 'outbound' : 'inbound'}`}>
      {!shortHeader && (
        <h6>
          {isOutbound ? <NewThreadIcon /> : <ReplyIcon />}
          {isOutbound ? 'Outbound Email' : 'Reply'}
        </h6>
      )}
      <div className='headers'>
        <div>
          {!shortHeader &&
            headers.split('\n').map((header) => {
              const [pre, ...post] = header.split(':');
              return (
                <div key={pre} className='header'>
                  <b>{pre}:</b>
                  {post.join(':')}
                </div>
              );
            })}
          {shortHeader && (
            <div className='header'>
              <b>
                Reply from{' '}
                {
                  headers
                    .split('\n')
                    .find((x) => x.indexOf('From') !== -1)
                    ?.split(':')[1]
                }
              </b>
            </div>
          )}
        </div>
        {(count || showTime) && (
          <div className='right-info-holder'>
            {count && <span className='count'>{count}</span>}
          </div>
        )}
      </div>
      <hr />
      <div className='body'>
        <div
          dangerouslySetInnerHTML={{
            __html: escapeHtml(body).replace(/\n/g, '<br />'),
          }}
        ></div>
      </div>
    </div>
  );
};

export default MessageText;
