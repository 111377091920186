import React, { useState, useEffect } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { Alert, Button, Spinner } from 'react-bootstrap';
import { ReactComponent as ListBuilderIcon } from '../../../icons/list-builder.svg';
import { ReactComponent as ResearchPointIcon } from '../../../icons/research-point.svg';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight, faPlus } from '@fortawesome/free-solid-svg-icons';
import api from '../../../api';
import MainPageLoader from '../../misc/MainPageLoader';
import EditCompaniesModal from './EditCompaniesModal';
import CampaignTriggersModal from './CampaignTriggersModal';

function CompaniesSettings({ oagentId, wizard, exitWithoutSaving, nextFn }) {
  const { getAccessTokenSilently } = useAuth0();
  const [funnels, setFunnels] = useState([]);
  const [loading, setLoading] = useState(true);
  const [showEditCompaniesModal, setShowEditCompaniesModal] = useState(false);
  const [showCampaignTriggersModal, setShowCampaignTriggersModal] =
    useState(false);
  const [campaignTriggers, setCampaignTriggers] = useState([]);
  const [stepView, setStepView] = useState('lists');
  const [datapoints, setDatapoints] = useState([]);
  const [additionalData, setAdditionalData] = useState([]);
  const [loadingCampaignTriggers, setLoadingCampaignTriggers] = useState(true);

  const fetchFunnels = async () => {
    try {
      const token = await getAccessTokenSilently();
      const response = await api.get(`/oagents/${oagentId}/funnels`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      setFunnels(response.data);
    } catch (error) {
      console.error('Error fetching funnels:', error);
    } finally {
      setLoading(false);
    }
  };

  const fetchResearchPoints = async () => {
    try {
      const token = await getAccessTokenSilently();
      const response = await api.get(`/oagents/${oagentId}/research-points`, {
        headers: { Authorization: `Bearer ${token}` },
      });

      const triggers = response.data.filter(
        (point) => point.type === 'campaign_trigger',
      );
      const datapoints = response.data.filter(
        (point) => point.type === 'datapoint',
      );

      setCampaignTriggers(triggers);
      setDatapoints(datapoints);
    } catch (error) {
      console.error('Error fetching campaign triggers:', error);
    } finally {
      setLoadingCampaignTriggers(false);
    }
  };

  const fetchAdditionalData = async () => {
    try {
      const token = await getAccessTokenSilently();
      const response = await api.get(`/oagents/${oagentId}/additional-data`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      setAdditionalData(response.data);
    } catch (error) {
      console.error('Error fetching additional data:', error);
    }
  };

  const refreshCampaignTriggers = async () => {
    setLoadingCampaignTriggers(true);
    await fetchResearchPoints();
  };

  useEffect(() => {
    fetchFunnels();
    fetchResearchPoints();
    fetchAdditionalData();
  }, [getAccessTokenSilently, oagentId]);

  const totalCompaniesCount = funnels.reduce(
    (sum, funnel) => sum + parseInt(funnel.company_count, 10),
    0,
  );

  return (
    <div className='campaign-step-wrapper'>
      <div className='companies-step'>
        <div className='companies-settings-header'>
          <div className='left'>
            <h5>Companies</h5>
            <p>
              Add companies to your Campaign, set filters and Campaign Triggers
              to define who to target and when.
            </p>
          </div>
          <div className='right'>
            {exitWithoutSaving && (
              <>
                <Button variant='outline-primary' onClick={exitWithoutSaving}>
                  Exit without re-import
                </Button>
                <Button variant='primary' onClick={nextFn}>
                  Re-import
                </Button>
              </>
            )}
            {!exitWithoutSaving && (
              <>
                <Button
                  variant='primary'
                  onClick={nextFn}
                  disabled={funnels.length === 0}
                >
                  Next: Import companies from lists
                  <FontAwesomeIcon icon={faArrowRight} />
                </Button>
              </>
            )}
          </div>
        </div>

        <div className='companies-area'>
          {!loading && (
            <>
              {funnels.length === 0 ? (
                <div className='empty-state'>
                  <ListBuilderIcon />
                  <p>You’ve not added any targets lists</p>
                  <Button
                    variant='primary'
                    onClick={() => setShowEditCompaniesModal(true)}
                  >
                    Add targets <FontAwesomeIcon icon={faPlus} />
                  </Button>
                </div>
              ) : (
                <div className='list-builders-wrapper'>
                  <div className='header'>
                    <span>Up to {totalCompaniesCount} companies added</span>
                    <div className='button-area'>
                      <Button
                        variant='primary'
                        onClick={() => {
                          setStepView('lists');
                          setShowEditCompaniesModal(true);
                        }}
                      >
                        Edit sources
                      </Button>
                    </div>
                  </div>
                  <div className='lists-overview'>
                    {funnels.map((funnel) => (
                      <div className='funnel' key={funnel.id}>
                        <p>{funnel.name}</p>
                        <p>{funnel.company_count} companies on list</p>
                      </div>
                    ))}
                  </div>
                  {datapoints.length > 0 && (
                    <div className='filter-footer'>
                      <div className='header'>
                        <span>Filtering companies by these datapoints</span>
                        <div className='button-area'>
                          <Button
                            variant='primary'
                            onClick={() => {
                              setStepView('datapoints');
                              setShowEditCompaniesModal(true);
                            }}
                          >
                            Edit datapoints
                          </Button>
                        </div>
                      </div>
                      <div className='dp-list'>
                        {' '}
                        {additionalData.map((data) => (
                          <div className='dp' key={data.key}>
                            <p className='label'>{data.key}</p>
                            <p className='value'>{data.criteria}</p>
                          </div>
                        ))}
                        {datapoints.map((datapoint) => (
                          <div className='dp' key={datapoint.id}>
                            <p className='label'>{datapoint.name}</p>
                            <p className='value'>{datapoint.criteria}</p>
                          </div>
                        ))}
                      </div>
                    </div>
                  )}
                </div>
              )}
            </>
          )}
          {loading && (
            <div className='list-builders-wrapper'>
              <div className='header'>
                <span>Loading company settings</span>
                <div className='button-area'>
                  <Button variant='primary' disabled>
                    Edit sources
                  </Button>
                </div>
              </div>
              <div className='lists-overview'>
                <div className='funnel fake-funnel'></div>
              </div>
            </div>
          )}
        </div>
        <div className='campaign-triggers-area'>
          <div className='step-form-header'>
            <h4>Campaign Triggers</h4>
            <p>
              Setup criteria for which companies from your lists should be
              messaged and when.
            </p>
          </div>
          {loadingCampaignTriggers ? (
            <></>
          ) : campaignTriggers.length === 0 ? (
            <div className='empty-state'>
              <div className='red-icon'>
                <ResearchPointIcon />
              </div>
              {funnels.length > 0 && (
                <>
                  <p>
                    Add Campaign Triggers to make sure your message lands at the
                    right time
                  </p>
                  <Button
                    variant='primary'
                    onClick={() => setShowCampaignTriggersModal(true)}
                  >
                    Add Campaign Trigger{' '}
                    <div className='red-icon'>
                      <FontAwesomeIcon icon={faPlus} />
                    </div>
                  </Button>
                </>
              )}
              {funnels.length === 0 && (
                <p>You need to add target lists before you can add triggers</p>
              )}
            </div>
          ) : (
            <div className='campaign-triggers-wrapper'>
              <div className='triggers-header'>
                <p className='triggers-rule'>
                  A company won't be contacted unless{' '}
                  {campaignTriggers.length > 1
                    ? 'one of these triggers are'
                    : 'this trigger is'}{' '}
                  matched.
                </p>
                <p className='triggers-check'>
                  We'll check companies on your list every month until they
                  match.
                </p>
              </div>
              <div className='triggers-list'>
                {campaignTriggers.map((trigger) => (
                  <div className='campaign-trigger' key={trigger.id}>
                    <div className='left'>
                      <p className='trigger-name'>{trigger.name}</p>
                      <p className='trigger-definition'>{trigger.definition}</p>
                    </div>
                    <div className='right'>
                      <p>
                        This trigger will use a maximum of{' '}
                        <b>
                          {Math.round(trigger.credits * totalCompaniesCount)}
                        </b>{' '}
                        credits per month for all companies
                      </p>
                    </div>
                  </div>
                ))}
              </div>
              <Button
                variant='primary'
                onClick={() => setShowCampaignTriggersModal(true)}
              >
                Manage Campaign Triggers
              </Button>
            </div>
          )}
        </div>
      </div>{' '}
      <EditCompaniesModal
        show={showEditCompaniesModal}
        handleClose={() => setShowEditCompaniesModal(false)}
        refreshFunnels={fetchFunnels}
        refreshDatapoints={fetchResearchPoints}
        oagentId={oagentId}
        stepView={stepView}
      />
      <CampaignTriggersModal
        show={showCampaignTriggersModal}
        handleClose={() => setShowCampaignTriggersModal(false)}
        oagentId={oagentId}
        refreshTriggers={refreshCampaignTriggers}
      />
    </div>
  );
}

export default CompaniesSettings;
