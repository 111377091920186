import React, { useState } from 'react';
import { Modal, Button, Form, Alert } from 'react-bootstrap';

const PrototypeResearch = ({ show, handleClose }) => {
  const [question, setQuestion] = useState('');
  const [answerMethod, setAnswerMethod] = useState('');
  const [linkedinField, setLinkedinField] = useState('');
  const [requiresInterpretation, setRequiresInterpretation] = useState(false);
  const [interpretation, setInterpretation] = useState('');
  const [subPage, setSubPage] = useState('');
  const [interpretNotFoundPage, setInterpretNotFoundPage] = useState('');
  const [interpretFoundPage, setInterpretFoundPage] = useState('');
  const [noBlogFound, setNoBlogFound] = useState('');
  const [interpretBlogPosts, setInterpretBlogPosts] = useState('');
  const [searchTerm, setSearchTerm] = useState('');
  const [searchCountry, setSearchCountry] = useState('');
  const [interpretNews, setInterpretNews] = useState('');

  const handleMethodChange = (event) => {
    setAnswerMethod(event.target.value);
    setLinkedinField('');
    setSubPage('');
    setInterpretNotFoundPage('');
    setInterpretFoundPage('');
    setNoBlogFound('');
    setInterpretBlogPosts('');
    setSearchTerm('');
    setSearchCountry('');
    setInterpretNews('');
  };

  const handleSave = () => {
    // Handle save logic here
    console.log({
      question,
      answerMethod,
      linkedinField,
      requiresInterpretation,
      interpretation,
      subPage,
      interpretNotFoundPage,
      interpretFoundPage,
      noBlogFound,
      interpretBlogPosts,
      searchTerm,
      searchCountry,
      interpretNews,
    });
    handleClose();
  };

  const getSampleOutput = () => {
    switch (linkedinField) {
      case 'headcount':
        return '500';
      case 'industry':
        return 'Fisheries';
      case 'country':
        return 'UK';
      default:
        return '';
    }
  };

  return (
    <Modal show={true} onHide={handleClose} size='lg'>
      <Modal.Header closeButton>
        <Modal.Title>Prototype Research</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Form.Group>
            <Form.Label>Question</Form.Label>
            <Form.Control
              type='text'
              value={question}
              onChange={(e) => setQuestion(e.target.value)}
              placeholder='Enter your question'
            />
          </Form.Group>

          <Form.Group>
            <Form.Label>Answer Method</Form.Label>
            <Form.Control
              as='select'
              value={answerMethod}
              onChange={handleMethodChange}
            >
              <option value=''>Select Answer Method</option>
              <option value='linkedin'>LinkedIn Data</option>
              <option value='researchAgent'>Research Agent Knowledge</option>
              <option value='websiteInfo'>Website Information</option>
              <option value='recentBlogs'>Recent Blog Posts</option>
              <option value='newsSearch'>News Search</option>
            </Form.Control>
          </Form.Group>

          {answerMethod === 'linkedin' && (
            <Form.Group>
              <Form.Label>LinkedIn Field</Form.Label>
              <Form.Control
                as='select'
                value={linkedinField}
                onChange={(e) => setLinkedinField(e.target.value)}
              >
                <option value=''>Select Field</option>
                <option value='headcount'>Headcount</option>
                <option value='industry'>Industry</option>
                <option value='country'>Country</option>
              </Form.Control>
            </Form.Group>
          )}

          {linkedinField && (
            <Alert variant='info'>
              Sample Output: <strong>{getSampleOutput()}</strong>
            </Alert>
          )}

          {answerMethod === 'websiteInfo' && (
            <>
              <Form.Group>
                <Form.Label>Sub-page to look at</Form.Label>
                <Form.Control
                  as='select'
                  value={subPage}
                  onChange={(e) => setSubPage(e.target.value)}
                >
                  <option value=''>Select Sub-page</option>
                  <option value='homepage'>Homepage</option>
                  <option value='custom'>Custom</option>
                </Form.Control>
              </Form.Group>

              {subPage === 'custom' && (
                <Form.Group>
                  <Form.Label>Enter custom sub-page</Form.Label>
                  <Form.Control
                    type='text'
                    value={subPage}
                    onChange={(e) => setSubPage(e.target.value)}
                    placeholder='Enter sub-page URL'
                  />
                </Form.Group>
              )}

              <Form.Group>
                <Form.Label>How to interpret not finding this page?</Form.Label>
                <Form.Control
                  type='text'
                  value={interpretNotFoundPage}
                  onChange={(e) => setInterpretNotFoundPage(e.target.value)}
                  placeholder='Enter interpretation instructions'
                />
              </Form.Group>

              <Form.Group>
                <Form.Label>
                  How to interpret what it finds if page found?
                </Form.Label>
                <Form.Control
                  type='text'
                  value={interpretFoundPage}
                  onChange={(e) => setInterpretFoundPage(e.target.value)}
                  placeholder='Enter interpretation instructions'
                />
              </Form.Group>
            </>
          )}

          {answerMethod === 'recentBlogs' && (
            <>
              <Form.Group>
                <Form.Label>How to interpret if no blog found?</Form.Label>
                <Form.Control
                  type='text'
                  value={noBlogFound}
                  onChange={(e) => setNoBlogFound(e.target.value)}
                  placeholder='Enter interpretation instructions'
                />
              </Form.Group>

              <Form.Group>
                <Form.Label>
                  How to interpret what it finds in blog posts?
                </Form.Label>
                <Form.Control
                  type='text'
                  value={interpretBlogPosts}
                  onChange={(e) => setInterpretBlogPosts(e.target.value)}
                  placeholder='Enter interpretation instructions'
                />
              </Form.Group>
            </>
          )}

          {answerMethod === 'newsSearch' && (
            <>
              <Form.Group>
                <Form.Label>Search Term</Form.Label>
                <Form.Control
                  type='text'
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                  placeholder='Enter search term'
                />
              </Form.Group>

              <Form.Group>
                <Form.Label>Search Country</Form.Label>
                <Form.Control
                  type='text'
                  value={searchCountry}
                  onChange={(e) => setSearchCountry(e.target.value)}
                  placeholder='Enter search country'
                />
              </Form.Group>

              <Form.Group>
                <Form.Label>How to interpret what news it finds?</Form.Label>
                <Form.Control
                  type='text'
                  value={interpretNews}
                  onChange={(e) => setInterpretNews(e.target.value)}
                  placeholder='Enter interpretation instructions'
                />
              </Form.Group>
            </>
          )}

          <Form.Group>
            <Form.Check
              type='checkbox'
              label='Requires Interpretation'
              checked={requiresInterpretation}
              onChange={(e) => setRequiresInterpretation(e.target.checked)}
            />
          </Form.Group>

          {requiresInterpretation && (
            <Form.Group>
              <Form.Label>
                How should your agent interpret what has been found?
              </Form.Label>
              <Form.Control
                type='text'
                value={interpretation}
                onChange={(e) => setInterpretation(e.target.value)}
                placeholder='Enter interpretation instructions'
              />
            </Form.Group>
          )}
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant='secondary' onClick={handleClose}>
          Close
        </Button>
        <Button variant='primary' onClick={handleSave}>
          Save
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default PrototypeResearch;
