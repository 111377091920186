import React, { useState } from 'react';
import { Form, Alert } from 'react-bootstrap';
import Select from 'react-select';
import Papa from 'papaparse';

const CsvUploader = ({ onCsvDataChange, formGroupClassName }) => {
  const [csvData, setCsvData] = useState(null);
  const [csvHeaders, setCsvHeaders] = useState([]);
  const [selectedNameColumn, setSelectedNameColumn] = useState('');
  const [selectedLinkedInColumn, setSelectedLinkedInColumn] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  const handleCsvFileChange = (event) => {
    const file = event.target.files[0];

    if (file && file.type === 'text/csv') {
      Papa.parse(file, {
        header: true,
        complete: (results) => {
          const headers = Object.keys(results.data[0]);
          setCsvData(results.data);
          console.log(results.data);
          setCsvHeaders(
            headers.map((header) => ({ value: header, label: header })),
          );
          setErrorMessage('');
          setSelectedNameColumn('');
          setSelectedLinkedInColumn('');
        },
        error: (error) => {
          setErrorMessage(`Error parsing CSV: ${error.message}`);
        },
      });
    } else {
      setErrorMessage('Please upload a valid CSV file.');
    }
  };

  const handleCsvColumnSelect = (selectedOption) => {
    setSelectedNameColumn(selectedOption);

    const selectedData = csvData.map((row) => ({
      name: row[selectedOption ? selectedOption.value : ''],
      linkedin_url:
        row[selectedLinkedInColumn ? selectedLinkedInColumn.value : ''],
    }));
    onCsvDataChange(selectedData);
  };

  const handleLinkedInColumnSelect = (selectedOption) => {
    setSelectedLinkedInColumn(selectedOption);

    const selectedData = csvData.map((row) => ({
      name: row[selectedNameColumn ? selectedNameColumn.value : ''],
      linkedin_url: row[selectedOption ? selectedOption.value : ''],
    }));
    onCsvDataChange(selectedData);
  };

  return (
    <Form.Group
      className={`inner-opt-group csv-uploader ${formGroupClassName}`}
      controlId='csvUploader'
    >
      <div>
        <Form.Label>Upload File</Form.Label>
        <Form.Text>Ensure that this is in csv format.</Form.Text>
        <Form.Control
          type='file'
          accept='.csv'
          onChange={handleCsvFileChange}
          size='lg'
        />
      </div>
      {errorMessage && <Alert variant='danger'>{errorMessage}</Alert>}
      {csvHeaders.length > 0 && (
        <>
          <div>
            <Form.Group
              className={`inner-opt-group ${formGroupClassName}`}
              controlId='selectCsvNameColumn'
            >
              <Form.Label>Name Column</Form.Label>
              <Form.Text>
                Select the column that contains the company name
              </Form.Text>
              <Select
                options={csvHeaders}
                onChange={handleCsvColumnSelect}
                className='additional-options-select'
                classNamePrefix='additional-options-select'
                placeholder='Select a column'
                value={selectedNameColumn}
                size='lg'
              />
            </Form.Group>
          </div>
          <div>
            <Form.Group
              className={`inner-opt-group ${formGroupClassName}`}
              controlId='selectCsvLinkedInColumn'
            >
              <Form.Label>LinkedIn URL Column (Optional)</Form.Label>
              <Form.Text>
                Select the column that contains the LinkedIn URL
              </Form.Text>
              <Select
                options={csvHeaders}
                onChange={handleLinkedInColumnSelect}
                className='additional-options-select'
                classNamePrefix='additional-options-select'
                placeholder='Select a column'
                value={selectedLinkedInColumn}
                size='lg'
              />
            </Form.Group>
          </div>
        </>
      )}
    </Form.Group>
  );
};

export default CsvUploader;
