import React from 'react';
import ReplyReceived from './ReplyReceived';
import Viewed from './Viewed';
import MeetingBooked from './MeetingBooked';
import FirstContact from './FirstContact';
import FollowUp from './FollowUp';
import CompanyAdded from './CompanyAdded';
import ProspectAdded from './ProspectAdded';
import ScheduledEmail from './ScheduledEmail';

const FeedItem = ({ item, ...props }) => {
  switch (item.type) {
    case 'REPLY_RECEIVED':
      return <ReplyReceived item={item} {...props} />;
    case 'VIEWED':
      return <Viewed item={item} {...props} />;
    case 'MEETING_BOOKED':
      return <MeetingBooked item={item} {...props} />;
    case 'FIRST_CONTACT':
      return <FirstContact item={item} {...props} />;
    case 'FOLLOW_UP':
      return <FollowUp item={item} {...props} />;
    case 'COMPANY_ADDED_TO_OAGENT':
      return <CompanyAdded item={item} {...props} />;
    case 'PROSPECT_ADDED_TO_OAGENT':
      return <ProspectAdded item={item} {...props} />;
    case 'SCHEDULED_EMAIL':
      return <ScheduledEmail item={item} {...props} />;
    default:
      return null;
  }
};

export default FeedItem;
