import manifest from './function-manifests';

const cloneDeepWith = (obj, customizer) => {
  if (Array.isArray(obj)) {
    return obj.map((item) => cloneDeepWith(item, customizer));
  } else if (typeof obj === 'object' && obj !== null) {
    const newObj = {};
    for (const key in obj) {
      newObj[key] =
        customizer(obj[key], key, obj) || cloneDeepWith(obj[key], customizer);
    }
    return newObj;
  }
  return obj;
};

// Clone the company_filter section
const companyFilterManifest = cloneDeepWith(
  manifest.company_filter,
  (value, key) => {
    // This customizer function allows us to avoid cloning JSX directly
    if (typeof value === 'object' && value !== null && value.$$typeof) {
      return value;
    }
    return undefined;
  },
);

// // Modify the ai_filter fields
// Example
// companyFilterManifest.forEach((filter) => {
//   if (filter.type === 'ai_filter') {
//     filter.additional_options.ai_request.description = 'How would you like the agent to filter?';
//     filter.additional_options.ai_request.example = 'e.g. Filter out any companies that are multi-nationals';
//   }
// });

export default companyFilterManifest;
