import React from 'react';
import BaseType from './BaseType';

const MeetingBooked = ({ item }) => {
  return (
    <BaseType
      url={item.metadata?.known_org?.logo_url}
      name={item.metadata?.known_org?.name}
      tagType='dream-positive'
      tagText={
        <>
          📞&nbsp;&nbsp;Meeting Booked with{' '}
          <b>{item?.metadata?.prospect?.name}!</b>
        </>
      }
      tagTo={`/prospect/${item?.metadata?.prospect?.id}`}
      time={item.time}
      conversationId={item.id}
    />
  );
};

export default MeetingBooked;
