import React from 'react';
import ExistingCustomers from './ExistingCustomers';
import ICPs from './ICPs';
import { Form } from 'react-bootstrap';

const Market = ({ productId }) => {
  return (
    <div className='product-form-page'>
      <div className='header'>
        <h2>Market</h2>
        <p>
          Add details about existing and ideal customers to improve targeting
        </p>
      </div>
      <div className='product-form-wrapper market'>
        <div className='group'>
          <h5>Existing Customers</h5>
          <Form.Text>
            Tell your agents about key existing customers so they can
            strategize.
          </Form.Text>
          <ExistingCustomers productId={productId} />
        </div>

        <div className='group'>
          <h5>Ideal Customer Profiles</h5>
          <Form.Text>
            Detail the Ideal Customer Profiles for your product to help your
            agents target customers.
          </Form.Text>
          <ICPs productId={productId} />
        </div>
      </div>
    </div>
  );
};

export default Market;
