// src/components/NewLeads.js
import React, { useEffect, useState, useRef } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { useNavigate } from 'react-router-dom';
import api from '../../api';
import './dashboard.css';
import { ReactComponent as LicenseIcon } from '../../icons/license.svg';
import NewLeadsModal from './NewLeadsModal'; // Import the new modal component

import FakePic from '../feed/items/FakePic';
import LeadStatus from './LeadStatus';

const NewLeads = () => {
  const { getAccessTokenSilently } = useAuth0();
  const [newLeads, setNewLeads] = useState([]);
  const [loading, setLoading] = useState(true);
  const [showModal, setShowModal] = useState(false); // State to control the modal
  const hasShownLoadingRef = useRef(false);

  const navigate = useNavigate();

  useEffect(() => {
    const fetchNewLeads = async () => {
      if (!hasShownLoadingRef.current) {
        setLoading(true);
      }
      const token = await getAccessTokenSilently();
      try {
        const response = await api.get('/dashboard/new-prospects', {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        setNewLeads(response.data);
      } catch (error) {
        console.error('Error fetching new prospects:', error);
      } finally {
        setLoading(false);
        hasShownLoadingRef.current = true;
      }
    };

    fetchNewLeads();
  }, [getAccessTokenSilently]);

  const handleRowClick = (id) => {
    navigate(`/prospect/${id}?origin=dashboard`);
  };

  const handleViewAllClick = () => {
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  return (
    <div className={`new-leads ${loading ? 'loading' : ''}`}>
      <h4>Hot Leads</h4>
      {newLeads.length > 0 && (
        <>
          {newLeads.slice(0, 6).map((prospect) => (
            <div
              className='new-lead'
              key={prospect.id}
              onClick={() => handleRowClick(prospect.id)}
            >
              <div className='pic-area'>
                <FakePic url={prospect.logo_url} />
              </div>
              <div className='left'>
                <p>{prospect.name}</p>
                <small>{prospect.org_name}</small>
              </div>
              <div className='status-wrapper'>
                <div>
                  <LeadStatus prospect={prospect} />
                </div>
              </div>
            </div>
          ))}
          <div className='view-all-wrapper' onClick={handleViewAllClick}>
            View all
          </div>
        </>
      )}
      {newLeads.length === 0 && (
        <div className='empty-state'>
          <LicenseIcon />
          <h6>No hot leads yet</h6>
          <p>Leads appear as replies are received</p>
        </div>
      )}
      <NewLeadsModal
        show={showModal}
        onHide={handleCloseModal}
        newLeads={newLeads} // Pass newLeads to the modal
      />
    </div>
  );
};

export default NewLeads;
