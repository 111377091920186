import React, { useState, useEffect } from 'react';
import { Form } from 'react-bootstrap';
import { useAuth0 } from '@auth0/auth0-react';
import api from '../../api';
import EmailList from './EmailList';

import './emails.css';
import { useParams } from 'react-router-dom';

const EditSequence = () => {
  const { getAccessTokenSilently } = useAuth0();
  const { sequenceId } = useParams();
  const [sequence, setSequence] = useState({
    name: '',
    subject_type: 'fixed',
    subject: '',
    subject_guidance: '',
    emails: [],
  });

  useEffect(() => {
    const fetchSequence = async () => {
      try {
        const token = await getAccessTokenSilently();
        const response = await api.get(`/emails/${sequenceId}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        setSequence(response.data);
      } catch (error) {
        console.error('Error fetching sequence:', error);
      }
    };

    fetchSequence();
  }, [sequenceId, getAccessTokenSilently]);

  return (
    <div className='page-wrapper'>
      <div className='page-inner-wrapper edit-sequence-page'>
        <>
          <h2>Edit Sequence</h2>
          <p>
            Sequences in Dream are different to other sequence providers in that
            they do not have fixed emails but rather emails that are generated
            by outbound agents based on the context of the prospect, the sender,
            and the guidance you've given.
          </p>
          <p>
            If you want your outbound agent to send an exact message then tell
            it to in the guidance.
          </p>
        </>

        <Form className={'edit-sequence-form'}>
          <div className='sequence-info'>
            <Form.Group>
              <Form.Label>Sequence Name</Form.Label>
              <Form.Control size='lg' type='text' value={sequence.name} />
            </Form.Group>
          </div>
          <EmailList
            sequenceId={sequenceId}
            emails={sequence.emails.filter((x) => x.active)}
            setEmails={(updatedEmails) =>
              setSequence((prev) => ({ ...prev, emails: updatedEmails }))
            }
          />
        </Form>
      </div>
    </div>
  );
};

export default EditSequence;
