import React from 'react';
import './MatchUseCaseDescription.css';

const escapeRegExp = (string) => {
  return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'); // $& means the whole matched string
};

const HighlightedText = ({ text, matches, variant }) => {
  if (!text || !matches) return <div className='highlighted-use-case' />;

  const highlightMatches = (text, matches) => {
    let result = text;

    matches.forEach((match) => {
      match.text.forEach((matchedText) => {
        const escapedText = escapeRegExp(matchedText);
        const regex = new RegExp(`(${escapedText})`, 'gi');
        result = result.replace(regex, '<span class="match">$1</span>');
      });
    });

    // Dangerously set inner HTML is used to replace text with React elements
    // Make sure this is safe as it can expose to XSS attacks
    return (
      <div
        className={`highlighted-use-case ${variant}`}
        dangerouslySetInnerHTML={{ __html: result }}
      />
    );
  };

  return highlightMatches(text, matches);
};

export default HighlightedText;
