import React from 'react';
import { formatDistanceToNow, parseISO } from 'date-fns';

const BaseType = ({
  logos,
  mainText,
  time,
  children,
  onClick,
  tagText,
  tagType,
  expanded,
}) => {
  return (
    <div className={`group-wrapper ${expanded && 'expanded'}`}>
      <div className='group-item'>
        <div className='main-name'>
          <div className='collected-logos'>
            {logos
              .filter((x) => !!x)
              .slice(0, 3)
              .map((logo, i) => (
                <img src={logo} key={i} />
              ))}
          </div>
          <div className='expansion-area'>
            {mainText}
            {expanded && <span onClick={onClick}>(Hide)</span>}
            {!expanded && <span onClick={onClick}>(Show all)</span>}
          </div>
        </div>
        <div className='tag-section'>
          <div className={`tag ${tagType}`}>{tagText}</div>
        </div>
        <small>
          {formatDistanceToNow(parseISO(time), {
            addSuffix: true,
          })}
        </small>
      </div>
      {children}
    </div>
  );
};

export default BaseType;
