import React, { useState, useEffect } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { Form, Spinner } from 'react-bootstrap';
import api from '../../api';

const ProspectSettings = ({ oagentId, prospectId, initialSupervised }) => {
  const { getAccessTokenSilently } = useAuth0();
  const [supervised, setSupervised] = useState(initialSupervised || false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (typeof initialSupervised !== 'undefined') {
      setSupervised(initialSupervised);
    }
  }, [initialSupervised]);

  const handleRadioChange = async (value) => {
    setLoading(true);
    try {
      const token = await getAccessTokenSilently();
      const updatedSupervised = value === 'true';
      await api.put(
        `/oagent-prospects/${oagentId}/prospect/${prospectId}/toggle-supervised`,
        {
          supervised: updatedSupervised,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      );
      setSupervised(updatedSupervised);
    } catch (error) {
      console.error('Error toggling supervised status:', error);
      alert('Failed to update supervision status.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className='settings-wrapper'>
      <Form>
        <Form.Group controlId='formSupervisedRadio'>
          <Form.Label>Approval settings</Form.Label>

          <Form.Check
            type='radio'
            label='Yes, approve all emails for this prospect.'
            name='supervisedRadios'
            value='true'
            checked={supervised === true}
            onChange={(e) => handleRadioChange(e.target.value)}
            disabled={loading}
          />
          <Form.Check
            type='radio'
            label='No, use general campaign settings to decide whether to request approval.'
            name='supervisedRadios'
            value='false'
            checked={supervised === false}
            onChange={(e) => handleRadioChange(e.target.value)}
            disabled={loading}
          />
        </Form.Group>
      </Form>
    </div>
  );
};

export default ProspectSettings;
