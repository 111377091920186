import React from 'react';
import ReactDOM from 'react-dom';
import './custom.scss';
import Dream from './Dream';
import { BrowserRouter } from 'react-router-dom';

// eslint-disable-next-line no-undef
ReactDOM.createRoot(document.getElementById('root')).render(
  <BrowserRouter>
    <Dream />
  </BrowserRouter>,
);
