import React, { useState } from 'react';
import { Button, Modal, Form, Alert } from 'react-bootstrap';
import { useAuth0 } from '@auth0/auth0-react';
import './settings.css';

import DreamSideModalLoader from '../misc/DreamSideModalLoader';

import api from '../../api';

const AddTeamMemberModal = ({ show, handleClose, handleAddMember }) => {
  const { getAccessTokenSilently } = useAuth0();
  const [email, setEmail] = useState('');
  const [name, setName] = useState('');
  const [role, setRole] = useState('member');
  const [inviting, setInviting] = useState(false);
  const [error, setError] = useState(null);

  const handleSubmit = async () => {
    try {
      setError(null);
      setInviting(true);
      const token = await getAccessTokenSilently();
      const response = await api.post(
        '/users',
        { email, role, name },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      );
      handleAddMember();
      handleClose();
    } catch (error) {
      setError(error);
      console.error('Error adding team member:', error);
    } finally {
      setInviting(false);
    }
  };

  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Add new team member</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {error && (
          <Alert variant='danger'>Error inviting user, try again.</Alert>
        )}
        {inviting && <DreamSideModalLoader>Inviting</DreamSideModalLoader>}
        {!inviting && (
          <Form className='dream-side-modal-form add-team-member'>
            <Form.Group controlId='formName'>
              <Form.Label>Name</Form.Label>
              <Form.Control
                type='text'
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
            </Form.Group>
            <Form.Group controlId='formEmail'>
              <Form.Label>Email address</Form.Label>
              <Form.Control
                type='email'
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </Form.Group>
            <Form.Group controlId='formRole'>
              <Form.Label>Account type</Form.Label>
              <Form.Text>
                All users have full access to Dream’s features. Admins can edit
                billing details, subscriptions, and buy booster credits.
              </Form.Text>
              <div className='rapid-switch'>
                <div
                  onClick={() => setRole('admin')}
                  className={`option ${role === 'admin' && 'active'}`}
                >
                  Admin
                </div>
                <div
                  onClick={() => setRole('member')}
                  className={`option ${role === 'member' && 'active'}`}
                >
                  Member
                </div>
              </div>
            </Form.Group>
          </Form>
        )}
      </Modal.Body>
      {!inviting && (
        <Modal.Footer>
          <Button variant='outline-primary' onClick={handleClose}>
            Cancel
          </Button>
          <Button
            variant='primary'
            onClick={handleSubmit}
            disabled={!email || !name}
          >
            Send invite
          </Button>
        </Modal.Footer>
      )}
    </Modal>
  );
};

export default AddTeamMemberModal;
