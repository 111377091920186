import React, { useState, useEffect } from 'react';
import { Spinner, FormCheck, Button } from 'react-bootstrap';
import { useAuth0 } from '@auth0/auth0-react';
import api from '../../api';
import HighlightedText from './HighlightedText';
import DreamSideModal from '../misc/DreamSideModal';
import DreamModalInner from '../misc/DreamModalInner';
import './MatchUseCaseDescription.css';
import DreamSideModalLoader from './DreamSideModalLoader';

const MatchUseCaseDescription = ({
  show,
  handleClose,
  useCaseDescription,
  onAdvance,
}) => {
  const { getAccessTokenSilently } = useAuth0();
  const [isLoading, setIsLoading] = useState(true);
  const [matches, setMatches] = useState([]);
  const [checkedMatches, setCheckedMatches] = useState([]);

  useEffect(() => {
    const fetchMatches = async () => {
      try {
        setIsLoading(true);
        setMatches([]);
        setCheckedMatches([]);

        const token = await getAccessTokenSilently();
        const response = await api.post(
          '/use-cases/matches',
          { use_case_description: useCaseDescription },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          },
        );

        const { product_matches } = response.data;

        if (product_matches.length === 0) {
          onAdvance({ products: [] });
        } else {
          setMatches(product_matches);
          setCheckedMatches(product_matches); // Store full match objects initially
        }
      } catch (error) {
        console.error('Error fetching matches:', error);
      } finally {
        setIsLoading(false);
      }
    };

    if (show) {
      fetchMatches();
    }
  }, [show, useCaseDescription, getAccessTokenSilently]);

  const handleConfirm = () => {
    onAdvance({ products: checkedMatches });
    handleClose();
  };

  const handleCheckboxChange = (match) => {
    if (checkedMatches.includes(match)) {
      setCheckedMatches(checkedMatches.filter((item) => item !== match));
    } else {
      setCheckedMatches([...checkedMatches, match]);
    }
  };

  return (
    <DreamSideModal show={show} handleClose={handleClose} position='right'>
      <DreamModalInner>
        <DreamModalInner.Body>
          {isLoading ? (
            <DreamSideModalLoader>Analysing Purpose</DreamSideModalLoader>
          ) : (
            <div className='match-use-case-body'>
              <div className='inner-use-case-description'>
                <p>Purpose</p>
                <HighlightedText text={useCaseDescription} matches={matches} />
              </div>
              <div className='matches-confirm'>
                <p>
                  It looks like you mentioned{' '}
                  {matches.length === 1 ? 'one' : 'some'} of your products, when
                  prospecting your agent will discern between companies based on
                  your product information. Untick any that were incorrect
                  matches.
                </p>
                {matches.length !== 0 ? (
                  <div className='match-list'>
                    {matches.map((match) => (
                      <div
                        key={match.product_id}
                        className='match-checkbox-holder'
                      >
                        <FormCheck
                          type='checkbox'
                          label={match.name}
                          checked={checkedMatches.includes(match)}
                          onChange={() => handleCheckboxChange(match)}
                        />
                      </div>
                    ))}
                  </div>
                ) : (
                  <div className='empty-state'>
                    <p>No matches found</p>
                  </div>
                )}
              </div>
            </div>
          )}
        </DreamModalInner.Body>
        {!isLoading && (
          <DreamModalInner.Footer>
            <Button variant='primary' onClick={handleConfirm}>
              Confirm Matches
            </Button>
            <Button variant='outline-primary' onClick={handleClose}>
              Back
            </Button>
          </DreamModalInner.Footer>
        )}
      </DreamModalInner>
    </DreamSideModal>
  );
};

export default MatchUseCaseDescription;
