import React, { useEffect, useState } from 'react';
import {
  ComposedChart,
  Line,
  Area,
  Tooltip,
  ResponsiveContainer,
} from 'recharts';
import { format, parseISO, startOfWeek } from 'date-fns';
import { useAuth0 } from '@auth0/auth0-react';
import api from '../../api';
import './campaign-overview-stats.css';

const CampaignOverviewStats = () => {
  const { getAccessTokenSilently } = useAuth0();

  const [data, setData] = useState([]);
  const [totalReplyRate, setTotalReplyRate] = useState(0);
  const [totalBookedRate, setTotalBookedRate] = useState(0);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const token = await getAccessTokenSilently();
        const response = await api.get('/campaign-dashboard', {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        const fetchedData = response.data;

        // Calculate total reply rate and booked rate
        const totalMessagesSent = fetchedData.reduce(
          (acc, week) => acc + week.total_first_messages_sent_in_week,
          0,
        );

        const totalReplies = fetchedData.reduce(
          (acc, week) =>
            acc + week.reply_rate * week.total_first_messages_sent_in_week,
          0,
        );

        const totalBooked = fetchedData.reduce(
          (acc, week) =>
            acc + week.booked_rate * week.total_first_messages_sent_in_week,
          0,
        );

        const totalReplyRate = totalReplies / totalMessagesSent || 0;
        const totalBookedRate = totalBooked / totalMessagesSent || 0;

        setData(fetchedData);
        setTotalReplyRate(totalReplyRate);
        setTotalBookedRate(totalBookedRate);
      } catch (error) {
        console.error('Error fetching campaign overview stats:', error);
      }
    };

    fetchData();
  }, [getAccessTokenSilently]);

  return (
    <div className='campaign-overview-stats-outer-wrapper'>
      <div className='campaign-overview-stats'>
        <div className='entry'>
          <div className='heading'>
            <h6>Reply rate</h6>
            <h4>{(totalReplyRate * 100).toFixed(2)}%</h4>
          </div>
          <div className='chart-holder'>
            <ResponsiveContainer>
              <ComposedChart data={data}>
                <defs>
                  <linearGradient
                    id='colorReplyRate'
                    x1='0'
                    y1='0'
                    x2='0'
                    y2='1'
                  >
                    <stop offset='0%' stopColor='#07A0C3' stopOpacity={0.8} />
                    <stop offset='90%' stopColor='#07A0C3' stopOpacity={0} />
                  </linearGradient>
                </defs>

                <Tooltip content={<CustomTooltip />} />
                <Area
                  type='monotone'
                  dataKey='reply_rate'
                  stroke='#07A0C3'
                  fill='url(#colorReplyRate)'
                />
                <Line
                  isAnimationActive={false}
                  type='monotone'
                  dataKey='reply_rate'
                  stroke='#07A0C3'
                />
              </ComposedChart>
            </ResponsiveContainer>
          </div>
        </div>

        <div className='entry'>
          <div className='heading'>
            <h6>Overall booked rate</h6>
            <h4>{(totalBookedRate * 100).toFixed(2)}%</h4>
          </div>
          <div className='chart-holder'>
            <ResponsiveContainer>
              <ComposedChart data={data}>
                <defs>
                  <linearGradient
                    id='colorBookedRate'
                    x1='0'
                    y1='0'
                    x2='0'
                    y2='1'
                  >
                    <stop offset='0%' stopColor='#82ca9d' stopOpacity={0.8} />
                    <stop offset='90%' stopColor='#82ca9d' stopOpacity={0} />
                  </linearGradient>
                </defs>

                <Tooltip content={<CustomTooltip />} />
                <Area
                  type='monotone'
                  dataKey='booked_rate'
                  stroke='#82ca9d'
                  fill='url(#colorBookedRate)'
                />
                <Line
                  isAnimationActive={false}
                  type='monotone'
                  dataKey='booked_rate'
                  stroke='#82ca9d'
                />
              </ComposedChart>
            </ResponsiveContainer>
          </div>
        </div>
      </div>
      <small>
        Data shows previous 8 weeks, reply and booked rate for prospects first
        contacted in week
      </small>
    </div>
  );
};

function CustomTooltip({ active, payload }) {
  const formatTime = (tickItem) => {
    const startOfWeekDate = startOfWeek(parseISO(tickItem));
    return `w/c ${format(startOfWeekDate, 'dd MMM')}`;
  };

  if (active && payload && payload.length) {
    const uniqueDataKeys = new Set();
    return (
      <div className='custom-tooltip'>
        {payload.map((entry, index) => {
          if (uniqueDataKeys.has(entry.dataKey)) {
            return null;
          }
          uniqueDataKeys.add(entry.dataKey);
          return (
            <div key={index} className='value-wrapper'>
              <div
                className='dot'
                style={{ backgroundColor: entry.stroke }}
              ></div>
              <p className='label'>
                {formatTime(entry?.payload?.week_commencing)}:&nbsp;&nbsp;
                {(entry.value * 100).toFixed(2)}%
              </p>
            </div>
          );
        })}
      </div>
    );
  }

  return null;
}

export default CampaignOverviewStats;
