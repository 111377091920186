import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPen } from '@fortawesome/free-solid-svg-icons';
import { Button, Form } from 'react-bootstrap';

const EditControl = ({
  value,
  onSave,
  placeholder,
  description,
  type = 'text',
  size = 'md',
}) => {
  const [editing, setEditing] = useState(false);
  const [inputValue, setInputValue] = useState(value);

  const handleSave = () => {
    onSave(inputValue);
    setEditing(false);
  };

  return (
    <div className={`edit-control ${editing ? 'edit-on' : 'edit-off'}`}>
      {editing ? (
        <div className='editing-form'>
          <div className='input-and-desc'>
            <Form.Control
              type={type}
              size={size}
              value={inputValue}
              placeholder={placeholder}
              onChange={(e) => setInputValue(e.target.value)}
              onBlur={handleSave}
              onKeyPress={(e) => e.key === 'Enter' && handleSave()}
              autoFocus
            />
            {description && (
              <Form.Text className='text-muted'>{description}</Form.Text>
            )}
          </div>
          <Button variant='primary' onClick={handleSave} className='mt-2'>
            Save
          </Button>
        </div>
      ) : (
        <span>
          {value}{' '}
          <FontAwesomeIcon
            icon={faPen}
            className='edit-icon'
            onClick={() => setEditing(true)}
          />
        </span>
      )}
    </div>
  );
};

export default EditControl;
