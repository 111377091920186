// src/components/MessageHistory.js

import React, { useState, useEffect } from 'react';
import { Spinner } from 'react-bootstrap';
import { useAuth0 } from '@auth0/auth0-react';
import api from '../../api';
import MessageText from '../misc/MessageText';

const MessageHistory = ({ oagentId, prospectId }) => {
  const { getAccessTokenSilently } = useAuth0();
  const [loading, setLoading] = useState(true);
  const [messageHistory, setMessageHistory] = useState({ messageTexts: [] });
  const [emailProfile, setEmailProfile] = useState(null);

  useEffect(() => {
    const fetchMessageHistory = async () => {
      try {
        const token = await getAccessTokenSilently();
        setLoading(true);

        // Fetch message history
        const response = await api.get(
          `/oagent-prospects/${oagentId}/prospects/${prospectId}/emails`,
          {
            headers: { Authorization: `Bearer ${token}` },
          },
        );
        setMessageHistory(response.data);

        // Fetch email profile
        const profileResponse = await api.get(
          `/profiles/${response.data.email_profile_id}`,
          {
            headers: { Authorization: `Bearer ${token}` },
          },
        );
        setEmailProfile(profileResponse.data);
      } catch (error) {
        setLoading(false);
        console.error('Error fetching message history:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchMessageHistory();
  }, [oagentId, prospectId, getAccessTokenSilently]);

  if (loading) {
    return (
      <div className='empty-state'>
        <Spinner animation='border' />
      </div>
    );
  }

  if (!messageHistory || !emailProfile) {
    return (
      <div className='empty-state'>
        <p>No emails sent or received</p>
      </div>
    );
  }

  const escapeHtml = (unsafe) => {
    return unsafe
      .replace(/&/g, '&amp;')
      .replace(/</g, '&lt;')
      .replace(/>/g, '&gt;')
      .replace(/"/g, '&quot;')
      .replace(/'/g, '&#039;');
  };

  return (
    <div className='conversation-wrapper'>
      <div className='set'>
        <h5>Conversation</h5>
        <p>This is the thread view of your conversation</p>
      </div>
      <div className='messages'>
        {messageHistory.messageTexts.map((message, index) => {
          const isOutbound = new RegExp(
            `From:.*<${escapeHtml(emailProfile.oauth_email)}>`,
          ).test(message);
          return (
            <MessageText
              key={index}
              message={message}
              emailProfile={emailProfile}
              isOutbound={isOutbound}
            />
          );
        })}
      </div>
    </div>
  );
};

export default MessageHistory;
