import React from 'react';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button } from 'react-bootstrap';

function CampaignWizardStepHeader({
  advance,
  nextText,
  heading,
  retreat,
  backText = 'Back',
}) {
  return (
    <div className='header-area'>
      <h3>{heading}</h3>
      <div className='buttons-area'>
        {retreat && (
          <Button variant='outline-primary' onClick={retreat}>
            {backText}
          </Button>
        )}
        {advance && (
          <Button variant='primary' onClick={advance}>
            {nextText} <FontAwesomeIcon icon={faArrowRight} />
          </Button>
        )}
      </div>
    </div>
  );
}

export default CampaignWizardStepHeader;
