import React, { useState, useEffect } from 'react';
import { Button, Spinner, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { usePusher } from '../../contexts/PusherContext';
import { useAuth0 } from '@auth0/auth0-react';
import api from '../../api';

const CampaignTrigger = ({ trigger, knownOrgId, refresh }) => {
  const { getAccessTokenSilently } = useAuth0();
  const pusherChannel = usePusher();
  const [status, setStatus] = useState('not-scheduled');
  const [loading, setLoading] = useState(true);
  const [isRequesting, setIsRequesting] = useState(false);
  const [expectedTime, setExpectedTime] = useState(null);
  const [isFetchingTime, setIsFetchingTime] = useState(false);

  useEffect(() => {
    fetchStatus();
  }, [trigger.id, getAccessTokenSilently]);

  useEffect(() => {
    if (pusherChannel && knownOrgId) {
      const statusUpdateHandler = async (data) => {
        if (data && data.research_point_id === parseInt(trigger.id, 10)) {
          fetchStatus();
          refresh();
        }
      };

      pusherChannel.bind('analyst_status_update', statusUpdateHandler);

      return () => {
        pusherChannel.unbind('analyst_status_update', statusUpdateHandler);
      };
    }
  }, [pusherChannel, trigger, knownOrgId, getAccessTokenSilently]);

  const fetchStatus = async () => {
    try {
      const token = await getAccessTokenSilently();
      const response = await api.get(
        `/analyst/research-point/${trigger.id}/status/${knownOrgId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      );
      setStatus(response.data.status);
    } catch (error) {
      console.error('Error fetching status:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleRequestAnswer = async () => {
    setIsRequesting(true);
    try {
      const token = await getAccessTokenSilently();
      await api.post(
        `/analyst/research-point/${trigger.id}/queue`,
        { known_org_id: knownOrgId },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      );
      setStatus('queued');
      fetchStatus();
    } catch (error) {
      console.error('Error adding to queue:', error);
    } finally {
      setIsRequesting(false);
    }
  };

  const handleExpectedTimeFetch = async () => {
    setIsFetchingTime(true);
    try {
      const token = await getAccessTokenSilently();
      const response = await api.get(
        `/analyst/research-point/${trigger.id}/expected-time/${knownOrgId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      );

      const addedTime = new Date();
      addedTime.setSeconds(
        addedTime.getSeconds() + response.data.expected_time,
      );

      const now = new Date();
      const isToday = addedTime.toDateString() === now.toDateString();

      const hours = addedTime.getHours();
      const formattedHours = hours < 10 ? '0' + hours : hours;

      const minutes = addedTime.getMinutes().toString().padStart(2, '0');
      const seconds = addedTime.getSeconds().toString().padStart(2, '0');
      const day = isToday ? '' : ` on ${addedTime.toLocaleDateString()}`;

      const displayTime = `${formattedHours}:${minutes}:${seconds}${day}`;

      setExpectedTime(displayTime);
    } catch (error) {
      console.error('Error fetching expected time:', error);
    } finally {
      setIsFetchingTime(false);
    }
  };

  if (loading) {
    return null;
  }

  return (
    <div className='campaign-trigger'>
      <div className='campaign-trigger-wrapper'>
        <div className='left-side'>
          {trigger.last_checked ? (
            <p className='time'>
              Added on {new Date(trigger.last_checked).toLocaleDateString()}
            </p>
          ) : (
            <p className='time'>Never checked</p>
          )}
          <p className='title'>{trigger.name}</p>
          <p className='definition'>{trigger.definition}</p>
        </div>
        <div className='right-side'>
          {status === 'queued' ? (
            <OverlayTrigger
              placement='top'
              overlay={
                <Tooltip id={`tooltip-queued-${trigger.id}`}>
                  {isFetchingTime ? (
                    <Spinner size='sm' animation='border' />
                  ) : (
                    `Expected completion: ${expectedTime}`
                  )}
                </Tooltip>
              }
              onEnter={handleExpectedTimeFetch}
            >
              <div>
                <Button variant='outline-primary' disabled>
                  <>
                    <Spinner size='sm' animation='border' /> Answering
                  </>
                </Button>
              </div>
            </OverlayTrigger>
          ) : (
            <Button
              variant='outline-primary'
              onClick={handleRequestAnswer}
              disabled={isRequesting}
            >
              {isRequesting ? (
                <>
                  <Spinner size='sm' animation='border' /> Answering
                </>
              ) : (
                'Request answer'
              )}
            </Button>
          )}
        </div>
      </div>
      <div className='answer-area'>
        <p className='label'>Triggered?</p>
        <p className='value'>
          <b>{!trigger.value ? 'No' : trigger.value}</b>
        </p>
      </div>
      {trigger.value_context && (
        <div className='answer-area'>
          <p className='label'>Details</p>
          <p className='value'>{trigger.value_context}</p>
        </div>
      )}
    </div>
  );
};

export default CampaignTrigger;
