import React, { useEffect, useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';

import AddProfileModal from './profiles/AddProfileModal';
import ConfirmModal from './confirm-modals/ConfirmModal';

import { ReactComponent as PersonCircleIcon } from '../icons/person-circle.svg';
import { ReactComponent as EditIcon } from '../icons/edit.svg';
import { ReactComponent as TrashIcon } from '../icons/trash.svg';

import MainPageLoader from './misc/MainPageLoader';

import api from '../api';
import './profiles/profiles.css';
import { useNavigate } from 'react-router-dom';

const Profiles = () => {
  const { getAccessTokenSilently } = useAuth0();
  const [profiles, setProfiles] = useState([]);
  const [loading, setLoading] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [modalType, setModalType] = useState('');
  const [selectedProfile, setSelectedProfile] = useState(null);
  const navigate = useNavigate();

  const fetchProfiles = async () => {
    setLoading(true);
    try {
      const token = await getAccessTokenSilently();
      const response = await api.get('/profiles', {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setProfiles(response.data);
    } catch (error) {
      console.error('Error fetching profiles:', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchProfiles();
  }, [getAccessTokenSilently]);

  const handleShowModal = (type) => {
    setModalType(type);
    setShowModal(true);
  };

  const handleCloseModal = () => setShowModal(false);

  const handleShowConfirmModal = (profile) => {
    setSelectedProfile(profile);
    setShowConfirmModal(true);
  };

  const handleCloseConfirmModal = () => setShowConfirmModal(false);

  const handleConfirmRemove = async () => {
    if (!selectedProfile) return;

    try {
      const token = await getAccessTokenSilently();
      await api.delete(`/profiles/${selectedProfile.id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      // Optimistically update the profile status to inactive
      setProfiles((prevProfiles) =>
        prevProfiles.map((profile) =>
          profile.id === selectedProfile.id
            ? { ...profile, status: 'inactive' }
            : profile,
        ),
      );
      handleCloseConfirmModal();
    } catch (error) {
      console.error('Error removing profile:', error);
    }
  };

  const goToViewProfile = (profile) => {
    navigate(`/profiles/${profile.id}`);
  };

  if (loading) {
    return <MainPageLoader />;
  }

  return (
    <div className='page-wrapper'>
      <div className='page-inner-wrapper sdrs-index'>
        <div className='page-header-area'>
          <div className='title-and-text'>
            <h3>Sales Agents</h3>
          </div>
          <div className='buttons-area'>
            <Button onClick={() => handleShowModal('internal')}>
              Add new Agent{' '}
              <div className='yellow-icon'>
                <FontAwesomeIcon icon={faPlus} />
              </div>
            </Button>
          </div>
        </div>
        <div className='internal-profiles'>
          <div className='internal-profiles-list'>
            <div className='internal-profile empty'>
              <div className='backer'>
                <div className='dotter'></div>
              </div>
              <div className='content-area'>
                <div className='image-wrapper'>
                  <PersonCircleIcon />
                </div>
                <h5>Create new SDR</h5>
                <p>Add a new SDR for your campaigns to use.</p>
                <div className='button-area'>
                  <Button onClick={() => handleShowModal('internal')}>
                    Add new SDR{' '}
                    <div className='yellow-icon'>
                      <FontAwesomeIcon icon={faPlus} />
                    </div>
                  </Button>
                </div>
              </div>
            </div>
            {profiles
              .filter((x) => x.status !== 'inactive')
              .map((profile) => (
                <div className='internal-profile' key={profile.id}>
                  <div className='backer'>
                    <div className='dotter'></div>
                  </div>
                  <div className='silly-design-wrapper'>
                    <div className='content-area'>
                      <div className='image-wrapper'>
                        {profile.profile_image_url && (
                          <img src={profile.profile_image_url} alt='Profile' />
                        )}
                        {!profile.profile_image_url && (
                          <div className='image-wrapper'>
                            <PersonCircleIcon />
                          </div>
                        )}
                      </div>
                      <h5>{profile.name}</h5>
                      <p>
                        {profile.bio.slice(0, 150)}
                        {profile.bio.length > 150 && '...'}
                      </p>
                    </div>
                    <div className='dual-options-wrapper'>
                      <div className='dual-options-area'>
                        <Button
                          variant='link'
                          className='remove'
                          onClick={() => handleShowConfirmModal(profile)}
                        >
                          <TrashIcon /> Remove
                        </Button>
                        <Button
                          variant='link'
                          onClick={() => goToViewProfile(profile)}
                        >
                          <EditIcon /> Edit
                        </Button>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
          </div>
        </div>
      </div>
      <AddProfileModal
        show={showModal}
        handleClose={handleCloseModal}
        fetchProfiles={fetchProfiles}
      />

      {selectedProfile && (
        <ConfirmModal
          show={showConfirmModal}
          handleClose={handleCloseConfirmModal}
          handleConfirm={handleConfirmRemove}
          title='Confirm Profile Removal'
          bodyText={
            selectedProfile.type === 'internal'
              ? `You are about to remove the profile for ${selectedProfile.name}. If you do so, no new emails will be sent as this individual. The email account your team member has associated will still receive responses from historic emails.`
              : 'Are you sure you want to remove this connector?'
          }
          confirmLabel='Confirm'
          cancelLabel='Cancel'
        />
      )}
    </div>
  );
};

export default Profiles;
