// src/components/Congratulations.jsx
import React from 'react';
import { Container, Row, Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight, faCheckCircle } from '@fortawesome/free-solid-svg-icons';

const Congratulations = () => {
  // Must use window.location.href to trigger reloading credits etc.
  // Do not switch to navigate unless you know what you're doing.
  const goToHome = () => (window.location.href = '/?f=1');

  return (
    <Container>
      <Row className='justify-content-center text-center congratulations'>
        <div className='congrats-inner'>
          <h1>Welcome to Dream!</h1>
          <p>
            {"You're"} currently on the <b>Free</b> plan which means that{' '}
            {"you'll"} be able to have <b>1</b> lead funnel and use up to{' '}
            <b>100</b> credits per month.
          </p>
          <p>This should allow you to contact up to 25 prospects per month.</p>
          <div className='get-started'>
            <Button onClick={goToHome}>
              Get Started
              <FontAwesomeIcon icon={faArrowRight} />
            </Button>
          </div>
        </div>
      </Row>
    </Container>
  );
};

export default Congratulations;
