import React, { useEffect, useState, useRef } from 'react';
import { Slide, toast } from 'react-toastify';
import { usePusher } from '../contexts/PusherContext';
import { useProcessing } from '../contexts/ProcessingContext';
import { ReactComponent as BulbIcon } from '../icons/bulb.svg';
import { ReactComponent as ResearchPointIcon } from '../icons/research-point.svg';
import './progress-toast.css';
import { useNavigate, useLocation } from 'react-router-dom';

const SECONDS_TO_SHOW_DONT_PANIC = 10;

const useCheckProcessingStatus = () => {
  const { funnelStates, setFunnelStates, funnelNames } = useProcessing();

  const navigate = useNavigate();
  const location = useLocation();

  const pusherChannel = usePusher();
  const timersRef = useRef({});
  const [notifiedFunnels, setNotifiedFunnels] = useState({});

  const key = (j) => `${j?.data.research_item_id}-${j?.data.known_org_id}`;

  const handleAnalystStatusUpdate = (data) => {
    const { status, affected_funnels, job } = data;

    setFunnelStates((prevStates) => {
      const updatedStates = { ...prevStates };
      affected_funnels.forEach((funnelId) => {
        if (!prevStates[funnelId]) {
          return;
        }

        const currentJobs = updatedStates[funnelId]?.oldestProcessing || [];
        let updatedJobs;

        if (status === 'processing') {
          if (!currentJobs.some((j) => key(j) === key(job))) {
            updatedJobs = [...currentJobs, job];
          } else {
            updatedJobs = currentJobs;
          }
        } else {
          updatedJobs = currentJobs.filter((j) => key(j) !== key(job));
        }

        updatedStates[funnelId] = {
          ...updatedStates[funnelId],
          oldestProcessing: updatedJobs.length ? updatedJobs : null,
        };
      });
      return updatedStates;
    });
  };

  const toastDefaults = {
    position: 'bottom-right',
    autoClose: false,
    hideProgressBar: true,
    closeOnClick: true,
    transition: Slide,
    className: 'progress-toast',
  };

  const handleProcessingTooLong = (funnelId) => {
    setNotifiedFunnels((prev) => {
      if (!prev[funnelId]) {
        toast(
          <div className='progress-toast-content'>
            <div className='inner-wrapper'>
              <div className='icon-wrapper'>
                <BulbIcon />
              </div>
              <div className='right'>
                <p>
                  <b>Some research may take a few more minutes.</b>
                </p>
                <p>
                  This is normal, we'll let you know when it's complete (you can
                  leave the page).
                </p>
              </div>
            </div>
          </div>,
          toastDefaults,
        );

        return {
          ...prev,
          [funnelId]: true,
          [`${funnelId}-completed`]: false,
        };
      }
      return prev;
    });
  };

  const handleProcessingComplete = (funnelId) => {
    setNotifiedFunnels((prev) => {
      if (!prev[`${funnelId}-completed`]) {
        toast(
          <div className='progress-toast-content'>
            <div className='inner-wrapper'>
              <div className='icon-wrapper'>
                <ResearchPointIcon />
              </div>
              <div className='right'>
                <p>
                  <b>{funnelNames[funnelId] || 'Your target list'}</b>
                </p>
                <p>We've finished all requested research.</p>
              </div>
            </div>
            {location.pathname !== `/funnel/${funnelId}` && (
              <div className='action-row'>
                <span onClick={() => navigate(`/funnel/${funnelId}`)}>
                  View my list
                </span>
                <span>Close</span>
              </div>
            )}
          </div>,
          toastDefaults,
        );

        return {
          ...prev,
          [`${funnelId}-completed`]: true,
          [funnelId]: false,
        };
      }
      return prev;
    });
  };

  useEffect(() => {
    Object.keys(funnelStates).forEach((funnelId) => {
      if (!funnelStates[funnelId]) {
        return false;
      }

      const oldestProcessingJobs =
        funnelStates[funnelId]?.oldestProcessing || [];
      const now = Date.now();

      if (oldestProcessingJobs.length > 0) {
        const oldestJobProcessedOn = Math.min(
          ...oldestProcessingJobs.map((job) => job.processedOn),
        );
        const processingTime = (now - oldestJobProcessedOn) / 1000; // in seconds

        // Clear any existing timer for this funnel
        if (timersRef.current[funnelId]) {
          clearTimeout(timersRef.current[funnelId]);
        }

        // If processing time is already more than SECONDS_TO_SHOW_DONT_PANIC, notify immediately
        if (processingTime > SECONDS_TO_SHOW_DONT_PANIC) {
          handleProcessingTooLong(funnelId);
        } else {
          // Otherwise, set a timer to notify after the remaining time
          timersRef.current[funnelId] = setTimeout(
            () => {
              const updatedOldestJobProcessedOn = Math.min(
                ...oldestProcessingJobs.map((job) => job.processedOn),
              );
              if (
                (Date.now() - updatedOldestJobProcessedOn) / 1000 >
                SECONDS_TO_SHOW_DONT_PANIC
              ) {
                handleProcessingTooLong(funnelId);
              }
            },
            (SECONDS_TO_SHOW_DONT_PANIC - processingTime) * 1000,
          );
        }
      } else {
        if (
          notifiedFunnels[funnelId] &&
          !notifiedFunnels[`${funnelId}-completed`]
        ) {
          handleProcessingComplete(funnelId);
        }
        // Always clear the timer when there are no processing jobs
        if (timersRef.current[funnelId]) {
          clearTimeout(timersRef.current[funnelId]);
          delete timersRef.current[funnelId];
        }
      }
    });

    return () => {
      // Cleanup all timers when component unmounts or dependencies change
      Object.keys(timersRef.current).forEach((funnelId) => {
        clearTimeout(timersRef.current[funnelId]);
        delete timersRef.current[funnelId];
      });
    };
  }, [funnelStates]);

  useEffect(() => {
    if (pusherChannel) {
      pusherChannel.bind('analyst_status_update', handleAnalystStatusUpdate);
      return () => {
        pusherChannel.unbind(
          'analyst_status_update',
          handleAnalystStatusUpdate,
        );
      };
    }
  }, [pusherChannel]);

  return null;
};

export default useCheckProcessingStatus;
