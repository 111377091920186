import React, { useState } from 'react';
import { ReactComponent as UpCaretIcon } from '../../icons/up-caret.svg';
import { ReactComponent as DownCaretIcon } from '../../icons/down-caret.svg';
import { ReactComponent as BasicInfoIcon } from '../../icons/basic-info.svg';

const SlideySection = ({ header, description, children, icon }) => {
  const [isExpanded, setIsExpanded] = useState(false);
  return (
    <div className={`slidey-section ${isExpanded && 'expanded'}`}>
      <div className='header' onClick={() => setIsExpanded(!isExpanded)}>
        <div className='icon-frame'>{icon || <BasicInfoIcon />}</div>
        <div className='main-content'>
          <h4>{header}</h4>
          <p>{description}</p>
        </div>
        <div className='change' onClick={() => setIsExpanded(!isExpanded)}>
          {isExpanded ? <UpCaretIcon /> : <DownCaretIcon />}
        </div>
      </div>
      {isExpanded && <div className='contents'>{children}</div>}
    </div>
  );
};

export default SlideySection;
