// src/components/create-company/DreamWizardChoose.jsx
import React from 'react';
import { Button, ListGroup } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGoogle, faLinkedinIn } from '@fortawesome/free-brands-svg-icons';
import {
  faList,
  faMap,
  faNewspaper,
  faQuestion,
} from '@fortawesome/free-solid-svg-icons';

const DreamWizardChoose = ({ skipWizard, selectChoice }) => {
  return (
    <div className='dream-wizard-choose'>
      <h2>{"Let's"} Show You How Dream Works</h2>
      <p>
        The first step is to find relevant companies for you. Dream supports
        many different ways of finding companies.
      </p>
      <p>
        Pick a <b>Company Search</b> option below to get started.
      </p>
      <ListGroup>
        <ListGroup.Item action onClick={() => selectChoice('google_search')}>
          <div className='wizard-item'>
            <div className='icon-box'>
              <FontAwesomeIcon icon={faGoogle} />
            </div>
            <div className='wizard-description'>
              <h5>Use a search engine.</h5>
              <small>Great for SME to Enterprise sales/partnerships.</small>
            </div>
          </div>
        </ListGroup.Item>
        <ListGroup.Item action onClick={() => selectChoice('local_businesses')}>
          <div className='wizard-item'>
            <div className='icon-box'>
              <FontAwesomeIcon icon={faMap} />
            </div>
            <div className='wizard-description'>
              <h5>Search for local businesses</h5>
              <small>
                Best for finding smaller, bricks & mortar companies.
              </small>
            </div>
          </div>
        </ListGroup.Item>
        <ListGroup.Item action onClick={() => selectChoice('news_stories')}>
          <div className='wizard-item'>
            <div className='icon-box'>
              <FontAwesomeIcon icon={faNewspaper} />
            </div>
            <div className='wizard-description'>
              <h5>Search for relevant news stories</h5>
              <small>
                Top if a specific event (like an IPO) triggers your process.
              </small>
            </div>
          </div>
        </ListGroup.Item>
        <ListGroup.Item action onClick={() => selectChoice('list')}>
          <div className='wizard-item'>
            <div className='icon-box'>
              <FontAwesomeIcon icon={faList} />
            </div>
            <div className='wizard-description'>
              <h5>Upload a list of target companies.</h5>
              <small>
                Best if you already have a full set of possible prospects
              </small>
            </div>
          </div>
        </ListGroup.Item>
        <ListGroup.Item action onClick={() => selectChoice('none')}>
          <div className='wizard-item'>
            <div className='icon-box'>
              <FontAwesomeIcon icon={faQuestion} />
            </div>
            <div className='wizard-description'>
              <h5>None of these apply</h5>
            </div>
          </div>
        </ListGroup.Item>
      </ListGroup>
      <div className='button-wrapper'>
        <Button className='already-know' variant='link' onClick={skipWizard}>
          I already know how Dream works
        </Button>
      </div>
    </div>
  );
};

export default DreamWizardChoose;
