// components/analyst/KnownOrg.js
import React, { useState, useEffect } from 'react';
import { Tabs, Tab, Button, Spinner } from 'react-bootstrap';
import { Link, useParams } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import api from '../../api';
import CampaignTrigger from './CampaignTrigger';
import Datapoint from './Datapoint';
import ResearchPointModal from './ResearchPointModal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faPlus } from '@fortawesome/free-solid-svg-icons';
import { ReactComponent as ResearchPointIcon } from '../../icons/research-point.svg';
import DreamInfoExpander from '../misc/DreamInfoExpander';

import '../analyst/analyst.css';
import FakePic from '../feed/items/FakePic';

const KnownOrg = () => {
  const { id } = useParams();
  const { getAccessTokenSilently } = useAuth0();
  const [knownOrg, setKnownOrg] = useState(null);
  const [researchPoints, setResearchPoints] = useState([]);
  const [loading, setLoading] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const [modalType, setModalType] = useState('');

  const handleShowModal = (type) => {
    setModalType(type);
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
    fetchData();
  };

  const fetchData = async () => {
    try {
      const token = await getAccessTokenSilently();

      const [orgResponse, pointsResponse] = await Promise.all([
        api.get(`/analyst/known-orgs/${id}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }),
        api.get(`/analyst/known-orgs/${id}/research-points`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }),
      ]);

      setKnownOrg(orgResponse.data);
      setResearchPoints(pointsResponse.data);
    } catch (error) {
      console.error('Error fetching known org data:', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, [id, getAccessTokenSilently]);

  if (loading) {
    return (
      <div className='dream-loading-state'>
        <Spinner animation='border' />
      </div>
    );
  }

  const campaignTriggers = researchPoints.filter(
    (point) => point.type === 'campaign_trigger',
  );
  const dataPoints = researchPoints.filter(
    (point) => point.type === 'datapoint',
  );

  return (
    <div className='page-wrapper'>
      <div className='page-inner-wrapper analyst analyst-view'>
        <div className='page-header-area'>
          <div className='image-wrapper'>
            <div className='title-and-text'>
              <div className='breadcrumbs'>
                <div className='set'>
                  <Link to='/analyst'>
                    <FontAwesomeIcon icon={faArrowLeft} />
                    Back to All Companies
                  </Link>
                </div>
              </div>
              <h1>
                <FakePic url={knownOrg.logo_url} type='org' />
                {knownOrg.name}
              </h1>
            </div>
          </div>
          <div className='links-area'>
            {knownOrg.domain && (
              <a
                href={`http://${knownOrg.domain}`}
                target='_blank'
                rel='noopener noreferrer'
              >
                View Website
              </a>
            )}
            {knownOrg.linkedin_url && (
              <a
                href={knownOrg.linkedin_url}
                target='_blank'
                rel='noopener noreferrer'
              >
                View LinkedIn
              </a>
            )}
          </div>
        </div>
        <div className='main-area'>
          <div className='about-area'>
            <h2>About this company</h2>
            <Tabs className='dream-tabs'>
              <Tab eventKey='company-datapoints' title='Company Datapoints'>
                <div className='datapoints-wrapper'>
                  {dataPoints.map((dp) => (
                    <Datapoint
                      refresh={fetchData}
                      knownOrgId={id}
                      key={dp.id}
                      datapoint={dp}
                    />
                  ))}
                </div>
                {dataPoints.length === 0 && (
                  <div className='empty-state'>
                    <ResearchPointIcon />
                    <p>You have not created any datapoints yet</p>
                    <Button
                      variant='primary'
                      className='add-button'
                      onClick={() => handleShowModal('datapoint')}
                    >
                      Add Datapoint <FontAwesomeIcon icon={faPlus} />
                    </Button>
                  </div>
                )}
                {dataPoints.length > 0 && (
                  <div className='create-new-datapoint'>
                    <Button
                      variant='primary'
                      className='add-button'
                      onClick={() => handleShowModal('datapoint')}
                    >
                      Create new datapoint <FontAwesomeIcon icon={faPlus} />
                    </Button>
                  </div>
                )}
              </Tab>
              <Tab eventKey='knowledge-base' title='Knowledge Base'>
                <div className='knowledge-base-for-company'>
                  <DreamInfoExpander heading='How is this updated?'>
                    This knowledge is updated as your agent reads more and more
                    about companies during the course of it's research.
                  </DreamInfoExpander>
                  <div className='knowledge-area'>
                    {knownOrg.knowledge?.general_knowledge && (
                      <p>{knownOrg.knowledge.general_knowledge}</p>
                    )}
                    {!!knownOrg.knowledge?.distinct_products?.length && (
                      <>
                        <h4>Product Lines</h4>
                        <ul>
                          {knownOrg.knowledge.distinct_products.map(
                            (product, i) => (
                              <li key={i}>
                                <strong>{product.name}:</strong>{' '}
                                {product.general_knowledge}
                              </li>
                            ),
                          )}
                        </ul>
                      </>
                    )}
                    {!!knownOrg.knowledge?.timeline?.length && (
                      <>
                        <h4>Timeline</h4>
                        <ul>
                          {knownOrg.knowledge.timeline.map((event, i) => (
                            <li key={i}>
                              <strong>{event.when}:</strong> {event.event}
                            </li>
                          ))}
                        </ul>
                      </>
                    )}
                    {knownOrg.knowledge?.personnel && (
                      <>
                        <h4>Key Personnel</h4>
                        <p>{knownOrg.knowledge.personnel}</p>
                      </>
                    )}
                  </div>
                </div>
              </Tab>
            </Tabs>
          </div>
          <div className='trigger-area'>
            <div className='header'>
              <h2>Your Campaign Triggers</h2>
              <p>
                Request an answer to your Campaign Triggers. This information
                will be usable in your Campaigns.
              </p>
            </div>
            {campaignTriggers.length > 0 && (
              <div className='campaign-triggers'>
                {campaignTriggers.map((trigger) => (
                  <CampaignTrigger
                    refresh={fetchData}
                    key={trigger.id}
                    knownOrgId={id}
                    trigger={trigger}
                  />
                ))}
              </div>
            )}
            {campaignTriggers.length === 0 && (
              <div className='empty-state'>
                <ResearchPointIcon />
                <p>You have not created any campaign triggers yet</p>
                <Button
                  variant='primary'
                  className='add-button'
                  onClick={() => handleShowModal('campaign_trigger')}
                >
                  Add Campaign Trigger <FontAwesomeIcon icon={faPlus} />
                </Button>
              </div>
            )}
          </div>
        </div>
      </div>
      <ResearchPointModal
        show={showModal}
        handleClose={handleCloseModal}
        type={modalType}
        knownOrgId={id}
      />
    </div>
  );
};

export default KnownOrg;
