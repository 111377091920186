/* eslint-disable react/display-name */
import React from 'react';
import useCheckProcessingStatus from '../../hooks/useCheckProcessingStatus';

// Memoized component to initialize the useCheckProcessingStatus hook
const StatusCheckWrapper = React.memo(() => {
  useCheckProcessingStatus();
  return null;
});

export default StatusCheckWrapper;
