import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faInfo,
  faCaretDown,
  faCaretUp,
} from '@fortawesome/free-solid-svg-icons';
import './DreamInfoExpander.css';

const DreamInfoExpander = ({ heading, children, alwaysExpanded, icon }) => {
  const [isExpanded, setIsExpanded] = useState(alwaysExpanded);

  const handleToggle = () => {
    if (alwaysExpanded) return;
    setIsExpanded(!isExpanded);
  };

  return (
    <div className='dream-agent-context'>
      <div className='info-spot'>
        <div
          className='header'
          onClick={handleToggle}
          style={{ cursor: 'pointer' }}
        >
          <div>
            {icon && icon}
            <h6>{heading}</h6>
          </div>
          {!alwaysExpanded && (
            <FontAwesomeIcon icon={isExpanded ? faCaretUp : faCaretDown} />
          )}
        </div>
        {isExpanded && <div className='content'>{children}</div>}
      </div>
    </div>
  );
};

export default DreamInfoExpander;
