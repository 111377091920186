import React, { useState, useEffect } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { Button, Form } from 'react-bootstrap';
import api from '../../api';
import { ReactComponent as ThumbsUpIcon } from '../../icons/green-thumb-up.svg';
import './task-list.css';
import DreamModalInner from '../misc/DreamModalInner';
import DreamSideModalLoader from '../misc/DreamSideModalLoader';
import DreamSideModal from '../misc/DreamSideModal';
import { Link } from 'react-router-dom';
import MessageText from '../misc/MessageText';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import FakePic from '../feed/items/FakePic';
import { format } from 'date-fns';

function ApprovalModal({ show, onHide, task }) {
  const { getAccessTokenSilently } = useAuth0();
  const [approval, setApproval] = useState(null);
  const [loading, setLoading] = useState(true);
  const [guidance, setGuidance] = useState('');
  const [showGuidanceForm, setShowGuidanceForm] = useState(false);

  useEffect(() => {
    const fetchApprovalDetails = async () => {
      setLoading(true);
      setGuidance('');
      try {
        const token = await getAccessTokenSilently();
        const response = await api.get(`/approvals/${task.id}`, {
          headers: { Authorization: `Bearer ${token}` },
        });
        setApproval(response.data);
      } catch (error) {
        console.error('Error fetching approval details:', error);
      } finally {
        setLoading(false);
      }
    };

    if (task) {
      fetchApprovalDetails();
    }
  }, [task, getAccessTokenSilently]);

  const handleAction = async (action) => {
    if (loading) return;
    setLoading(true);

    try {
      const token = await getAccessTokenSilently();
      await api.post(
        `/approvals/${task.id}/action`,
        { action, guidance },
        {
          headers: { Authorization: `Bearer ${token}` },
        },
      );

      // Dispatch custom event when modal is closed
      const event = new Event('modalClose');
      window.dispatchEvent(event);
      onHide(); // Close modal and refresh queue
    } catch (error) {
      console.error(`Error performing action ${action}:`, error);
    } finally {
      setLoading(false);
    }
  };

  if (loading || !approval) {
    return (
      <DreamSideModal show={show} onHide={onHide}>
        <DreamModalInner>
          <DreamSideModalLoader>Loading</DreamSideModalLoader>
        </DreamModalInner>
      </DreamSideModal>
    );
  }

  const mailLang = approval.is_response ? 'reply' : 'message';

  return (
    <DreamSideModal show={show} handleClose={onHide}>
      <DreamModalInner>
        <DreamModalInner.Header>Approval required</DreamModalInner.Header>
        <DreamModalInner.Body>
          <div className='approvals-wrapper'>
            <div className='campaign-link-topper'>
              <p>
                <b>Campaign:</b> {approval.campaign_name}
              </p>
              <Link to={`/campaigns/${approval.id}`}>View campaign</Link>
            </div>
            <div className='heading'>
              <h6>You need to approve this {mailLang}</h6>
              <p>
                Your campaign settings require approval for messages.{' '}
                <Link to={`/campaign/${approval.oagent_id}/settings`}>
                  Change settings
                </Link>
                .
              </p>
            </div>
            {approval.is_response && approval.messageTexts.length > 0 && (
              <div className='responding-to-wrapper'>
                <MessageText
                  message={
                    approval.messageTexts[approval.messageTexts.length - 1]
                  }
                  shortHeader
                />
              </div>
            )}
            <div className='staged-wrapper'>
              <div className='banner'>
                {!approval.approved && (
                  <>
                    <b>This {mailLang} hasn't been sent.</b> You can approve or
                    request amendments below.
                  </>
                )}
              </div>
              <div className='to-who-area'>
                <div className='left-side'>
                  <FakePic url={approval?.prospects[0]?.logo_url} />
                  <div className='title'>
                    <p>
                      {approval?.prospects[0]?.name}{' '}
                      <small>• {approval?.prospects[0]?.company}</small>
                    </p>
                    <p>{approval?.prospects[0]?.title}</p>
                  </div>
                </div>
                <div className='right-side'>
                  <Link to={`/prospect/${approval?.prospects[0]?.id}`}>
                    View prospect
                  </Link>
                </div>
              </div>
              <div className='message-wrapper'>
                <div className='body-area'>
                  <div className='header'>
                    <h6>Agent {mailLang}</h6>
                    <small>
                      Created{' '}
                      {format(
                        new Date(approval.scheduled_time),
                        'EEE, d MMM yyyy',
                      )}
                    </small>
                  </div>
                  <hr />
                  <div
                    className='body'
                    dangerouslySetInnerHTML={{
                      __html: approval.content.replace(/\n/g, '<br />'),
                    }}
                  ></div>
                  <div className='buttons-area'>
                    {!approval.approved ? (
                      <React.Fragment>
                        <Button
                          onClick={() => handleAction('approve')}
                          disabled={loading}
                        >
                          Approve {mailLang}
                        </Button>
                        <Button
                          onClick={() => setShowGuidanceForm(true)}
                          disabled={loading}
                        >
                          Request changes
                        </Button>
                        <Button
                          onClick={() => handleAction('reject')}
                          disabled={loading}
                        >
                          Don't {mailLang}
                        </Button>
                      </React.Fragment>
                    ) : (
                      <div className='approved_wrapper'>
                        <ThumbsUpIcon />
                        <h6>You approved this {mailLang}.</h6>
                        <p>Changed your mind?</p>
                        <Button
                          variant='danger'
                          onClick={() => handleAction('ignore')}
                          disabled={loading}
                        >
                          Cancel {mailLang}
                        </Button>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
            {showGuidanceForm && !approval.approved && (
              <div className='guidance-area dream-side-modal-form'>
                <Form.Group>
                  <Form.Label>Guidance</Form.Label>
                  <Form.Text>
                    How should your agent re-write the email
                  </Form.Text>
                  <Form.Control
                    as='textarea'
                    value={guidance}
                    onChange={(e) => setGuidance(e.target.value)}
                    placeholder='e.g. "Make sure to highlight our annual savings guarantee'
                    rows={3}
                  />
                  <div className='button-wrapper campaigns'>
                    <Button onClick={() => handleAction('improve')}>
                      Request changes <FontAwesomeIcon icon={faArrowRight} />
                    </Button>
                  </div>
                </Form.Group>
              </div>
            )}
          </div>
        </DreamModalInner.Body>
      </DreamModalInner>
    </DreamSideModal>
  );
}

export default ApprovalModal;
