// UserSelect.js
import React, { useState, useEffect } from 'react';
import { Form } from 'react-bootstrap';
import { useAuth0 } from '@auth0/auth0-react';
import api from '../../api';
import { Link } from 'react-router-dom';

const AccountSelect = ({
  hasPleaseSelect,
  value,
  onChange,
  mustHaveEmail,
  exclude,
  warnEmpty,
}) => {
  const { getAccessTokenSilently } = useAuth0();
  const [accounts, setAccounts] = useState([]);
  const [accountsLoading, setAccountsLoading] = useState(false);

  useEffect(() => {
    if (exclude && exclude.includes(value)) {
      onChange({ target: { value: '' } });
    }
  }, [exclude]);

  useEffect(() => {
    const fetchUsers = async () => {
      setAccountsLoading(true);
      const token = await getAccessTokenSilently();
      try {
        const googleAccounts = await api.get('/google-link', {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        const microsoftAccounts = await api.get('/microsoft-link', {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        setAccountsLoading(false);
        setAccounts(
          [
            ...googleAccounts.data.linkedAccounts,
            ...microsoftAccounts.data.linkedAccounts,
          ]
            .sort((a, b) => {
              return a.email - b.email;
            })
            .filter((account) => {
              if (mustHaveEmail) {
                return account.scope.indexOf('Email') !== -1;
              }
              return true;
            })
            .filter((account) => {
              const safeExclude = exclude?.map((x) => parseInt(x, 10));
              if (exclude && safeExclude.includes(account.id)) return false;
              return true;
            }),
        );
      } catch (error) {
        console.error('Error fetching users:', error);
      }
    };

    fetchUsers();
  }, [getAccessTokenSilently, exclude]);

  if (warnEmpty && !accountsLoading && accounts.length === 0) {
    return (
      <div className='empty-warning'>
        You do not have any linked accounts with email sending capabilities.
        Visit <Link to='/settings/account-links'>account links</Link> to set
        this up.
      </div>
    );
  }
  return (
    <Form.Control
      as='select'
      name='oauth_id'
      value={value}
      onChange={onChange}
      required
      size='lg'
      disabled={accountsLoading}
    >
      {accountsLoading && <option value=''>Loading Accounts...</option>}
      {!accountsLoading && (
        <>
          {hasPleaseSelect && typeof hasPleaseSelect !== 'string' && (
            <option value=''>Choose a linked account</option>
          )}
          {hasPleaseSelect && typeof hasPleaseSelect === 'string' && (
            <option value=''>{hasPleaseSelect}</option>
          )}
          {accounts.map((account) => (
            <option key={account.id} value={account.id}>
              {account.email}
            </option>
          ))}
        </>
      )}
    </Form.Control>
  );
};

export default AccountSelect;
