import React, { useEffect, useState, useRef } from 'react';
import { useParams } from 'react-router-dom';
import ReactMarkdown from 'react-markdown';
import { useAuth0 } from '@auth0/auth0-react';

import { usePusher } from '../contexts/PusherContext'; // Import the Pusher context hook
import api from '../api';
import { Alert, Spinner } from 'react-bootstrap';

const Agent = ({ funnelId: propFunnelId, onStatusChange, onClick }) => {
  const { getAccessTokenSilently } = useAuth0();
  const pusherChannel = usePusher(); // Get the Pusher channel

  const { id: routeFunnelId } = useParams();
  const funnelId = propFunnelId || routeFunnelId;

  const [narratives, setNarratives] = useState([]);
  const [queuedNarratives, setQueuedNarratives] = useState([]);
  const latestEntryRef = useRef(null);
  const [funnelRunStatus, setFunnelRunStatus] = useState(null);

  const fetchFunnelRunStatus = async () => {
    try {
      const token = await getAccessTokenSilently();
      const response = await api.get(`/funnel-run-status/${funnelId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setFunnelRunStatus(response.data.status);
    } catch (err) {
      console.error('Error fetching funnel run status:', err);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const token = await getAccessTokenSilently();

        const response = await api.get(`/narratives/${funnelId}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        const sortedNarratives = response.data.narratives.sort(
          (a, b) => new Date(b.created_at) - new Date(a.created_at),
        );
        setNarratives(sortedNarratives);
      } catch (error) {
        console.error('Error fetching narratives:', error);
      }
    };

    fetchData();
    fetchFunnelRunStatus();
  }, [funnelId, getAccessTokenSilently]);

  // Set up Pusher event bindings
  useEffect(() => {
    if (pusherChannel && funnelId) {
      const funnelUpdateHandler = (data) => {
        if (data && data.funnel_id === parseInt(funnelId, 10)) {
          setQueuedNarratives((prevQueue) => [...prevQueue, data]);
        }
      };

      const funnelStatusUpdateHandler = async (data) => {
        if (data && data.funnel_id === parseInt(funnelId, 10)) {
          await fetchFunnelRunStatus();
        }
      };

      pusherChannel.bind('new_narrative', funnelUpdateHandler);
      pusherChannel.bind('funnel_status_update', funnelStatusUpdateHandler);

      // Unbind events on cleanup
      return () => {
        pusherChannel.unbind('new_narrative', funnelUpdateHandler);
        pusherChannel.unbind('funnel_status_update', funnelStatusUpdateHandler);
      };
    }
  }, [pusherChannel, funnelId, getAccessTokenSilently]);

  useEffect(() => {
    if (queuedNarratives.length > 0) {
      const timer = setTimeout(() => {
        setNarratives((prevNarratives) => {
          const [nextNarrative, ...remainingQueue] = queuedNarratives;
          if (
            !prevNarratives.find(
              (n) => n.created_at === nextNarrative.created_at,
            )
          ) {
            const updatedNarratives = [nextNarrative, ...prevNarratives].sort(
              (a, b) => new Date(b.created_at) - new Date(a.created_at),
            );
            setQueuedNarratives(remainingQueue);
            return updatedNarratives;
          }
          setQueuedNarratives(remainingQueue);
          return prevNarratives;
        });
      }, 100);

      return () => clearTimeout(timer);
    }
  }, [queuedNarratives]);

  useEffect(() => {
    if (onStatusChange) {
      onStatusChange(funnelRunStatus);
    }
  }, [funnelRunStatus, onStatusChange]);

  return (
    <div
      className={`alert-framing-wrapper ${funnelRunStatus !== 'processing' ? 'hidden' : 'visible'}`}
    >
      <Alert variant='info'>
        <div className='action-happening'>
          {funnelRunStatus === 'processing' && <Spinner size='sm' />}
          {narratives.slice(0, 1).map((narrative, idx) => (
            <p
              key={idx}
              className='narrative-entry'
              ref={idx === narratives.length - 1 ? latestEntryRef : null}
            >
              <ReactMarkdown>{narrative.entry.content}</ReactMarkdown>
            </p>
          ))}
          <span onClick={onClick}>Stop Sourcing</span>
        </div>
      </Alert>
    </div>
  );
};

export default Agent;
