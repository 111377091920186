import React, { useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { Form, Button, Alert } from 'react-bootstrap';
import { ReactComponent as PersonCircleIcon } from '../../icons/person-circle.svg';
import { ReactComponent as RobotFaceIcon } from '../../icons/robot-face.svg';
import { ReactComponent as ProfilesIcon } from '../../icons/profiles.svg';
import api from '../../api';
import AccountSelect from '../form-components/AccountSelect';
import DreamSideModal from '../misc/DreamSideModal';
import DreamModalInner from '../misc/DreamModalInner';
import DreamInfoExpander from '../misc/DreamInfoExpander';
import DreamSideModalLoader from '../misc/DreamSideModalLoader';

import './add-profile-modal.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';

function AddProfileModal({ show, handleClose, fetchProfiles }) {
  const { getAccessTokenSilently } = useAuth0();
  const [step, setStep] = useState(1);
  const [loading, setLoading] = useState(false);
  const [type, setType] = useState('');
  const [name, setName] = useState('');
  const [linkedinUrl, setLinkedinUrl] = useState('');
  const [task, setTask] = useState('accept-meetings');
  const [oauthId, setOauthId] = useState('');
  const [calendarOauthId, setCalendarOauthId] = useState('');
  const [error, setError] = useState(null);

  const handleNextStep = () => setStep(step + 1);
  const handlePrevStep = () => setStep(step - 1);

  const handleSubmit = async () => {
    setLoading(true);
    try {
      const token = await getAccessTokenSilently();
      const response = await api.post(
        '/profiles',
        {
          type,
          name,
          linkedin_url: linkedinUrl,
          oauth_id: oauthId,
          calendar_oauth_id: calendarOauthId,
          timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      );
      // Handle successful creation, e.g., close the modal, refresh data
      fetchProfiles();
      handleClose();
      console.log(response.data);
    } catch (error) {
      setError('Error creating profile');
      console.error('Error creating profile:', error);
    } finally {
      setLoading(false);
    }
  };

  if (loading) {
    return (
      <DreamSideModal show={show} handleClose={handleClose}>
        <DreamModalInner>
          <DreamSideModalLoader>Creating Agent</DreamSideModalLoader>
        </DreamModalInner>
      </DreamSideModal>
    );
  }

  return (
    <DreamSideModal show={show} handleClose={handleClose}>
      <DreamModalInner>
        <DreamModalInner.Header>
          <ProfilesIcon /> Add new sales agent
        </DreamModalInner.Header>
        <DreamModalInner.Body>
          {error && (
            <Alert variant='danger'>
              There was an error creating the agent. Please try again.
            </Alert>
          )}
          <div className='add-profile-modal'>
            {step === 1 && (
              <>
                <div className='profile-type-select'>
                  <Form.Label>This profile is:</Form.Label>
                  <div className='split-selector'>
                    <div
                      className={`option ${type === 'real' ? 'active' : ''}`}
                      onClick={() => setType('real')}
                    >
                      <PersonCircleIcon />
                      <h5>A real profile</h5>
                      <p>
                        This profile represents a real person. You'll need to
                        add at least one real profile as this is who calls will
                        be booked with by digital agents. You can also send as
                        these profiles.
                      </p>
                    </div>
                    <div
                      className={`option ${type === 'digital' ? 'active' : ''}`}
                      onClick={() => setType('digital')}
                    >
                      <RobotFaceIcon />
                      <h5>A digital profile</h5>
                      <p>
                        This profile is digital-only. Invent who you like to
                        join your sales team. You can send your campaigns as
                        these individuals and then they'll book calls with real
                        profiles.
                      </p>
                    </div>
                  </div>
                </div>
                <div className='dream-side-modal-form'>
                  <Form.Group>
                    <Form.Label>Display name</Form.Label>
                    <Form.Text>
                      We’ll use this on any messages or calendar bookings
                    </Form.Text>
                    <Form.Control
                      type='text'
                      value={name}
                      size='lg'
                      onChange={(e) => setName(e.target.value)}
                    />
                  </Form.Group>
                </div>
              </>
            )}

            {step === 2 && type === 'real' && (
              <>
                <div className='step-one-choices-wrapper'>
                  <div className='set'>
                    <p className='label'>Profile type:</p>
                    <p className='value'>
                      {type === 'real' ? 'Real profile' : 'Digital profile'}
                    </p>
                  </div>
                  <div className='set'>
                    <p className='label'>Display name:</p>
                    <p className='value'>{name}</p>
                  </div>
                </div>
                <div className='dream-side-modal-form step-lower'>
                  <div className='group'>
                    <Form.Label>LinkedIn URL</Form.Label>
                    <Form.Text>
                      Optional, we’ll use this to help generate a biography
                    </Form.Text>
                    <Form.Control
                      type='text'
                      value={linkedinUrl}
                      size='lg'
                      onChange={(e) => setLinkedinUrl(e.target.value)}
                    />
                  </div>
                  <div className='group radio'>
                    <Form.Label>
                      What do you want this profile to do?
                    </Form.Label>
                    <Form.Check
                      type='radio'
                      label='Only accept meeting bookings'
                      name='task'
                      value='accept-meetings'
                      checked={task === 'accept-meetings'}
                      onChange={() => setTask('accept-meetings')}
                    />
                    <Form.Check
                      type='radio'
                      label='Send messages & accept meeting bookings'
                      name='task'
                      value='send-messages'
                      checked={task === 'send-messages'}
                      onChange={() => setTask('send-messages')}
                    />
                  </div>
                </div>
              </>
            )}

            {step === 2 && type === 'digital' && handleNextStep()}

            {step === 3 && (
              <>
                <div className='step-one-choices-wrapper'>
                  <div className='set'>
                    <p className='label'>Profile type:</p>
                    <p className='value'>
                      {type === 'real' ? 'Real profile' : 'Digital profile'}
                    </p>
                  </div>
                  <div className='set'>
                    <p className='label'>Display name:</p>
                    <p className='value'>{name}</p>
                  </div>
                </div>
                <div className='dream-side-modal-form step-lower'>
                  {type === 'digital' && (
                    <div className='group'>
                      <Form.Label>Email account</Form.Label>
                      <Form.Text>
                        Which account should this agent send emails from
                      </Form.Text>
                      <AccountSelect
                        hasPleaseSelect='Choose a linked account'
                        value={oauthId}
                        onChange={(e) => setOauthId(e.target.value)}
                        warnEmpty
                        mustHaveEmail
                      />
                    </div>
                  )}
                  {task === 'accept-meetings' && type !== 'digital' && (
                    <div className='group'>
                      <Form.Label>Calendar account</Form.Label>
                      <Form.Text>
                        Which calendar account should we book meetings on
                      </Form.Text>
                      <AccountSelect
                        hasPleaseSelect='Choose a linked account'
                        value={calendarOauthId}
                        onChange={(e) => setCalendarOauthId(e.target.value)}
                        warnEmpty
                      />
                    </div>
                  )}
                  {task === 'send-messages' && type !== 'digital' && (
                    <>
                      <div className='group'>
                        <Form.Label>Email account</Form.Label>
                        <Form.Text>
                          Which account should we send emails from for this
                          profile.
                        </Form.Text>
                        <AccountSelect
                          hasPleaseSelect='Choose an email account'
                          value={oauthId}
                          onChange={(e) => {
                            setOauthId(e.target.value);
                            setCalendarOauthId(e.target.value);
                          }}
                          warnEmpty
                          mustHaveEmail
                        />
                      </div>
                      <DreamInfoExpander alwaysExpanded heading='Email warning'>
                        <p>
                          You should <b>NOT</b> attach someone's primary email
                          account to a profile as the agent will read all email.
                        </p>
                        <p>
                          Instead, create a lookalike email that the agent can
                          use exclusively (e.g. if the main account is
                          charles.windsor@royal.com you might create
                          cw@royal.com)
                        </p>
                      </DreamInfoExpander>
                      <div className='group'>
                        <Form.Label>Calendar account</Form.Label>
                        <Form.Text>
                          Change this if you want this agent to book its
                          meetings on a different calendar (if you've used a
                          lookalike address you probably want your main
                          account's calendar).
                        </Form.Text>
                        <AccountSelect
                          value={calendarOauthId}
                          onChange={(e) => setCalendarOauthId(e.target.value)}
                        />
                      </div>
                    </>
                  )}
                </div>
              </>
            )}
          </div>
        </DreamModalInner.Body>
        <DreamModalInner.Footer>
          <div className='sdrs button-wrapper'>
            {step === 1 && (
              <>
                <Button
                  variant='primary'
                  onClick={handleNextStep}
                  disabled={!type || !name}
                >
                  Next <FontAwesomeIcon icon={faArrowRight} />
                </Button>
                <Button variant='outline-primary' onClick={handleClose}>
                  Cancel
                </Button>
              </>
            )}
            {step === 2 && (
              <>
                <Button variant='primary' onClick={handleNextStep}>
                  Next <FontAwesomeIcon icon={faArrowRight} />
                </Button>
                <Button variant='outline-primary' onClick={handlePrevStep}>
                  Back
                </Button>
              </>
            )}
            {step === 3 && (
              <>
                <Button
                  variant='primary'
                  onClick={handleSubmit}
                  disabled={
                    (type === 'digital' && !oauthId) ||
                    (type === 'real' && !oauthId && !calendarOauthId)
                  }
                >
                  Create new profile <FontAwesomeIcon icon={faArrowRight} />
                </Button>
                <Button
                  variant='outline-primary'
                  onClick={() =>
                    type === 'digital' ? setStep(1) : handlePrevStep()
                  }
                >
                  Back
                </Button>
              </>
            )}
          </div>
        </DreamModalInner.Footer>
      </DreamModalInner>
    </DreamSideModal>
  );
}

export default AddProfileModal;
