import React, { useEffect, useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { Button, Spinner } from 'react-bootstrap';

import { ReactComponent as ResearchPointIcon } from '../../../icons/research-point.svg';
import { useNavigate } from 'react-router-dom';
import api from '../../../api';
import DreamSideModalLoader from '../../misc/DreamSideModalLoader';
import DreamSideModal from '../../misc/DreamSideModal';
import DreamModalInner from '../../misc/DreamModalInner';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';

function CampaignTriggersModal({
  show,
  handleClose,
  oagentId,
  refreshTriggers,
}) {
  const { getAccessTokenSilently } = useAuth0();
  const navigate = useNavigate();
  const [campaignTriggers, setCampaignTriggers] = useState([]);
  const [associatedTriggers, setAssociatedTriggers] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchCampaignTriggers = async () => {
      try {
        const token = await getAccessTokenSilently();

        // Fetch all research points
        const allPointsResponse = await api.get(
          `/analyst/compatible-research-points/${oagentId}`,
          {
            headers: { Authorization: `Bearer ${token}` },
          },
        );
        const triggers = allPointsResponse.data.filter(
          (dp) => dp.type === 'campaign_trigger',
        );
        setCampaignTriggers(triggers);

        // Fetch associated research points for the oagent
        const associatedPointsResponse = await api.get(
          `/oagents/${oagentId}/research-points`,
          {
            headers: { Authorization: `Bearer ${token}` },
          },
        );
        setAssociatedTriggers(associatedPointsResponse.data);
      } catch (error) {
        console.error('Error fetching campaign triggers:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchCampaignTriggers();
  }, [getAccessTokenSilently, oagentId]);

  const isTriggerAssociated = (triggerId) =>
    associatedTriggers.some((assoc) => assoc.id === triggerId);

  const handleToggleTrigger = async (trigger) => {
    const token = await getAccessTokenSilently();

    try {
      if (isTriggerAssociated(trigger.id)) {
        setAssociatedTriggers(
          associatedTriggers.filter((assoc) => assoc.id !== trigger.id),
        );
        await api.delete(`/oagents/${oagentId}/research-point/${trigger.id}`, {
          headers: { Authorization: `Bearer ${token}` },
        });
      } else {
        setAssociatedTriggers([
          ...associatedTriggers,
          { id: trigger.id, name: trigger.name, criteria: 'Include all' },
        ]);
        await api.post(
          `/oagents/${oagentId}/research-points`,
          {
            research_points: [
              { research_point_id: trigger.id, criteria: 'Include all' },
            ],
          },
          { headers: { Authorization: `Bearer ${token}` } },
        );
      }
      refreshTriggers();
    } catch (error) {
      console.error(
        `Error ${isTriggerAssociated(trigger.id) ? 'removing' : 'adding'} campaign trigger:`,
        error,
      );
    }
  };

  const handleAddNew = () => {
    navigate('/analyst');
  };

  if (loading) {
    return (
      <DreamSideModal show={show} handleClose={handleClose}>
        <DreamModalInner>
          <DreamSideModalLoader>Loading</DreamSideModalLoader>
        </DreamModalInner>
      </DreamSideModal>
    );
  }

  return (
    <DreamSideModal show={show} handleClose={handleClose}>
      <DreamModalInner>
        <DreamModalInner.Header>
          <div className='red-icon'>
            <ResearchPointIcon />
          </div>{' '}
          Edit Campaign Triggers
        </DreamModalInner.Header>
        <DreamModalInner.Body>
          <div className='campaign-triggers-edit-modal analyst'>
            <label>Your Campaign Triggers</label>
            {campaignTriggers.length == 0 && (
              <div className='empty-state'>
                <div className='red-icon'>
                  <ResearchPointIcon />
                </div>
                <p>You haven't added any campaign triggers to your lists.</p>
                <p>
                  Go to your chosen lists and add desired campaign triggers.
                </p>
              </div>
            )}
            {campaignTriggers.length > 0 && (
              <>
                <div className='cts'>
                  {campaignTriggers.map((trigger) => (
                    <div
                      key={trigger.id}
                      className={`ct ${isTriggerAssociated(trigger.id) ? 'active' : ''}`}
                    >
                      <div className='left'>
                        <p className='name'>{trigger.name}</p>
                        <p className='definition'>{trigger.definition}</p>
                        <p>
                          Max credits per company to answer{' '}
                          <b>{trigger.credits} credits</b>
                        </p>
                      </div>
                      <div className='right'>
                        <Button
                          variant={
                            isTriggerAssociated(trigger.id)
                              ? 'primary'
                              : 'outline-primary'
                          }
                          onClick={() => handleToggleTrigger(trigger)}
                        >
                          {isTriggerAssociated(trigger.id) ? 'Added' : 'Add'}
                        </Button>
                      </div>
                    </div>
                  ))}
                </div>
                <Button
                  className='create-new-button'
                  variant='outline-primary'
                  onClick={handleAddNew}
                >
                  Add new Campaign Trigger
                </Button>
              </>
            )}
          </div>
        </DreamModalInner.Body>
        <DreamModalInner.Footer>
          <Button variant='primary' onClick={handleClose}>
            Save & Close
          </Button>
        </DreamModalInner.Footer>
      </DreamModalInner>
    </DreamSideModal>
  );
}

export default CampaignTriggersModal;
