/* eslint-disable react/display-name */
import React from 'react';
import './DreamModal.css';

const DreamModalInner = ({ children, className }) => {
  return <div className={`dream-modal-inner ${className}`}>{children}</div>;
};

DreamModalInner.Header = ({ children }) => {
  return <div className='dream-modal-inner-header'>{children}</div>;
};

DreamModalInner.Body = ({ children }) => {
  return <div className='dream-modal-inner-body'>{children}</div>;
};

DreamModalInner.Footer = ({ children }) => {
  return <div className='dream-modal-inner-footer'>{children}</div>;
};

export default DreamModalInner;
