import React, { useState, useEffect } from 'react';
import { Button, Form } from 'react-bootstrap';
import HighlightedText from '../../misc/HighlightedText';
import MatchUseCaseDescription from '../../misc/MatchUseCaseDescription';

import './editable-field-with-matches.css';

const EditableFieldWithMatches = ({
  label,
  description,
  text,
  useMatches,
  matches = [],
  onSave,
}) => {
  const [isEditing, setIsEditing] = useState(false);
  const [currentText, setCurrentText] = useState(text);
  const [showMatchModal, setShowMatchModal] = useState(false);

  useEffect(() => {
    setCurrentText(text);
  }, [text]);

  const handleSave = () => {
    if (useMatches) {
      setShowMatchModal(true);
    } else {
      onSave(currentText, []);
      setIsEditing(false);
    }
  };

  const handleAdvance = (matches) => {
    onSave(currentText, matches);
    setIsEditing(false);
    setShowMatchModal(false);
  };

  return (
    <div className='editable-field'>
      <div className='editable-field-header'>
        <div className='title-and-text'>
          <div className='label-area'>
            <h5>{label}</h5>
          </div>
          {description && (
            <div className='explanation-area'>
              <Form.Text>{description}</Form.Text>
            </div>
          )}
        </div>

        {!isEditing && currentText && (
          <div className='buttons-area'>
            <Button variant='outline-link' onClick={() => setIsEditing(true)}>
              Edit
            </Button>
          </div>
        )}
        {isEditing && (
          <div className='buttons-area'>
            <Button variant='outline-primary' onClick={handleSave}>
              Save
            </Button>
          </div>
        )}
      </div>
      <div className='edit-control'>
        {isEditing && (
          <>
            <Form.Control
              as='textarea'
              rows={5}
              value={currentText}
              onChange={(e) => setCurrentText(e.target.value)}
            />
          </>
        )}
        {!isEditing && currentText && (
          <HighlightedText
            text={currentText}
            matches={matches.products || []}
          />
        )}
        {!isEditing && !currentText && (
          <div className='phantom-highlighted'>
            <Button variant='primary' onClick={() => setIsEditing(true)}>
              Add {label.toLowerCase()}
            </Button>
          </div>
        )}
      </div>
      {useMatches && (
        <MatchUseCaseDescription
          show={showMatchModal}
          handleClose={() => setShowMatchModal(false)}
          useCaseDescription={currentText}
          onAdvance={handleAdvance}
        />
      )}
    </div>
  );
};

export default EditableFieldWithMatches;
