import React, { useEffect, useState } from 'react';
import { Spinner, Form, Button, Alert } from 'react-bootstrap';
import Select from 'react-select';
import DreamSideModal from '../../misc/DreamSideModal';
import DreamModalInner from '../../misc/DreamModalInner';
import DreamSideModalLoader from '../../misc/DreamSideModalLoader';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLinkedin } from '@fortawesome/free-brands-svg-icons';
import api from '../../../api';
import { usePusher } from '../../../contexts/PusherContext';
import { useAuth0 } from '@auth0/auth0-react';

function TestAgentProspectSearch({
  show,
  handleClose,
  metadata,
  type,
  oagentId,
  refreshProspectSearch,
  editMode = false,
  prospectSearchId = null,
}) {
  const { getAccessTokenSilently } = useAuth0();
  const [knownOrgs, setKnownOrgs] = useState([]);
  const [selectedKnownOrg, setSelectedKnownOrg] = useState(null);
  const [loadingOrgs, setLoadingOrgs] = useState(true);
  const [prospects, setProspects] = useState([]);
  const [loadingProspects, setLoadingProspects] = useState(false);
  const [savingSettings, setSavingSettings] = useState(false);
  const [noResults, setNoResults] = useState(false);
  const [error, setError] = useState(null);
  const pusherChannel = usePusher();

  useEffect(() => {
    fetchKnownOrgs();
  }, [oagentId, metadata]);

  const fetchKnownOrgs = async () => {
    setLoadingOrgs(true);
    try {
      const token = await getAccessTokenSilently();
      const response = await api.get(
        `/oagents/${oagentId}/possible-known-orgs`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      );
      setKnownOrgs(response.data);
    } catch (error) {
      console.error('Error fetching known orgs:', error);
    } finally {
      setLoadingOrgs(false);
    }
  };

  const handleProspectSearch = async () => {
    setLoadingProspects(true);
    try {
      const token = await getAccessTokenSilently();
      const response = await api.post(
        '/prospect-search-test/agent-prospect-search',
        {
          known_org_id: selectedKnownOrg.value,
          metadata,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      );

      const searchId = response.data.search_id;

      // Listen for the Pusher event
      pusherChannel.bind(`prospect_search_test_update`, (data) => {
        if (data.search_id === searchId) {
          if (data.error) {
            setError(data.error);
            setLoadingProspects(false);
          } else {
            setProspects(data.results);
            setLoadingProspects(false);
            if (data.results.length === 0) {
              setNoResults(true);
            } else {
              setNoResults(false);
            }
          }
        }
      });

      // Clean up the Pusher event bindings when the component unmounts
      return () => {
        pusherChannel.unbind(`prospect_search_test_update`);
      };
    } catch (error) {
      console.error('Error starting prospect search:', error);
      setLoadingProspects(false);
    }
  };

  const handleSaveSettings = async () => {
    setSavingSettings(true);
    const httpMethod = editMode && prospectSearchId ? 'put' : 'post';

    try {
      const token = await getAccessTokenSilently();
      const endpoint =
        editMode && prospectSearchId
          ? `/oagents/${oagentId}/prospect-search/${prospectSearchId}`
          : `/oagents/${oagentId}/prospect-search`;

      await api[httpMethod](
        endpoint,
        {
          type,
          metadata,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      );

      refreshProspectSearch();

      setSavingSettings(false);
      handleClose();
    } catch (error) {
      console.error(
        `Error saving prospect search (${httpMethod.toUpperCase()}):`,
        error,
      );
      setSavingSettings(false);
      setError('Failed to save settings. Please try again.');
    }
  };

  if (loadingProspects || savingSettings) {
    return (
      <DreamSideModal show={show} onHide={handleClose}>
        <DreamModalInner>
          <DreamSideModalLoader>
            {loadingProspects ? 'Finding Prospects' : 'Saving Settings'}
          </DreamSideModalLoader>
        </DreamModalInner>
      </DreamSideModal>
    );
  }

  return (
    <DreamSideModal show={show} onHide={handleClose}>
      <DreamModalInner>
        <DreamModalInner.Header>
          Test Agent Prospect Search
        </DreamModalInner.Header>
        <DreamModalInner.Body>
          <div className='test-prospect-search dream-side-modal-form'>
            {error && <Alert variant='danger'>{error}</Alert>}
            <div className='known-orgs-section'>
              <Form.Label>Test your settings (optional)</Form.Label>
              <Form.Text>
                Select a company from your list to see what prospects would be
                returned
              </Form.Text>
              {loadingOrgs ? (
                <div className='loading'>
                  <Spinner animation='border' />
                </div>
              ) : (
                <div className='test-company-wrapper'>
                  <Select
                    value={selectedKnownOrg}
                    onChange={setSelectedKnownOrg}
                    options={knownOrgs.map((org) => ({
                      value: org.id,
                      label: org.name,
                    }))}
                    classNames={{
                      control: () => `big-react-select`,
                    }}
                    isDisabled={loadingOrgs}
                  />
                  <Button
                    variant='outline-primary'
                    onClick={handleProspectSearch}
                  >
                    Run test
                  </Button>
                </div>
              )}
            </div>
            {noResults && (
              <div className='empty-state'>
                <h6>No results found</h6>
              </div>
            )}
            {prospects.length > 0 && !noResults && (
              <div className='prospect-test-area'>
                <label>Returned prospects</label>
                <div className='prospects'>
                  {prospects.map((prospect, index) => (
                    <div key={index} className='prospect-wrapper'>
                      <div className='icon-area'>
                        <a
                          href={prospect.linkedin_url}
                          target='_blank'
                          rel='noopener noreferrer'
                        >
                          <FontAwesomeIcon icon={faLinkedin} />
                        </a>
                      </div>
                      <div className='main-text-area'>
                        <p>
                          {prospect.name} - <strong>{prospect.title}</strong>
                        </p>
                        <p className='reason'>{prospect.reason}</p>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            )}
          </div>
        </DreamModalInner.Body>
        <DreamModalInner.Footer>
          <Button variant='primary' onClick={handleSaveSettings}>
            Save Settings
          </Button>
          <Button variant='outline-primary' onClick={handleClose}>
            Change Settings
          </Button>
        </DreamModalInner.Footer>
      </DreamModalInner>
    </DreamSideModal>
  );
}

export default TestAgentProspectSearch;
