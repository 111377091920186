import React, { useState, useEffect } from 'react';
import { Alert, Button, Form, Spinner } from 'react-bootstrap';
import { useAuth0 } from '@auth0/auth0-react';
import { ReactComponent as LockIcon } from '../../icons/lock.svg';
import { ReactComponent as CheckCircleIcon } from '../../icons/check-circle-filled.svg';
import api from '../../api';

const YourDetails = () => {
  const { getAccessTokenSilently } = useAuth0();
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [triggerLoading, setTriggerLoading] = useState(false);
  const [resetTriggered, setResetTriggered] = useState(false);
  const [triggerResetError, setTriggerResetError] = useState(null);
  const [savingDetails, setSavingsDetails] = useState(false);
  const [saveError, setSaveError] = useState(null);

  useEffect(() => {
    const fetchUserDetails = async () => {
      try {
        const token = await getAccessTokenSilently();
        const response = await api.get('/user-details', {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        setName(response.data.name);
        setEmail(response.data.email);
      } catch (error) {
        console.error('Error fetching user details:', error);
      }
    };

    fetchUserDetails();
  }, [getAccessTokenSilently]);

  const handleSaveChanges = async () => {
    try {
      setSaveError(null);
      setSavingsDetails(true);
      const token = await getAccessTokenSilently();
      await api.put(
        '/user-details',
        { name, email },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      );
    } catch (error) {
      setSaveError(error);
      console.error('Error saving user details:', error);
    } finally {
      setSavingsDetails(false);
    }
  };

  const handleTriggerReset = async () => {
    try {
      setTriggerLoading(true);
      setTriggerResetError(null);

      const token = await getAccessTokenSilently();
      await api.post(
        '/user-details/trigger-reset',
        {},
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      );
      setResetTriggered(true);
    } catch (error) {
      setTriggerResetError(error);
      console.error('Error triggering password reset:', error);
    } finally {
      setTriggerLoading(false);
    }
  };

  return (
    <div className='section your-details'>
      {saveError && <Alert variant='danger'>Error saving details</Alert>}
      <div className='wrapped-heading'>
        <h5>Your details</h5>
        <Button
          variant='primary'
          onClick={handleSaveChanges}
          disabled={savingDetails}
        >
          {!savingDetails ? (
            `Save Changes`
          ) : (
            <>
              <Spinner size='sm' /> Saving
            </>
          )}
        </Button>
      </div>
      <Form className='dream-side-modal-form'>
        <Form.Group controlId='formName' className='thin-form-group'>
          <Form.Label>Your name</Form.Label>
          <Form.Control
            type='text'
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
        </Form.Group>
        <Form.Group controlId='formEmail' className='thin-form-group'>
          <Form.Label>Your email address</Form.Label>
          <Form.Text className='text-muted'>
            This isn't used to send messages, we only use it to log you in.
          </Form.Text>
          <Form.Control
            type='email'
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
        </Form.Group>
      </Form>
      <div
        className='change-password'
        onClick={!triggerLoading ? handleTriggerReset : () => {}}
      >
        {!triggerLoading && !triggerResetError && resetTriggered && (
          <>
            <CheckCircleIcon /> Check email for password reset email
          </>
        )}
        {!triggerLoading && !triggerResetError && !resetTriggered && (
          <>
            <LockIcon /> Change your password
          </>
        )}
        {triggerLoading && (
          <>
            <Spinner size='sm' /> Sending reset email
          </>
        )}
        {triggerResetError && (
          <span className='danger'>
            <LockIcon /> Error resetting, click to try again.
          </span>
        )}
      </div>
    </div>
  );
};

export default YourDetails;
