import React, { useState } from 'react';
import { Button } from 'react-bootstrap';
import { useAuth0 } from '@auth0/auth0-react';
import StageAdditionalOptions from '../StageAdditionalOptions';
import { ReactComponent as FunnelIcon } from '../../icons/list-builder.svg';
import functionManifests from '../../utils/function-manifests';
import api from '../../api';
import DreamSideModal from '../misc/DreamSideModal';
import DreamModalInner from '../misc/DreamModalInner';
import SearchVolumeEstimate from '../misc/SearchVolumeEstimate';

const StageEditModal = ({ show, onHide, entry, refresh }) => {
  const { getAccessTokenSilently } = useAuth0();
  const [metadata, setMetadata] = useState(entry.metadata);
  const [saving, setSaving] = useState(false);

  const handleMetadataChange = (option, value) => {
    if (Array.isArray(value) && value.length > 0 && value[0].label) {
      value = value.map((x) => x.value);
    }

    setMetadata({
      ...metadata,
      [option]: value,
    });
  };

  const handleSave = async () => {
    setSaving(true);
    try {
      const token = await getAccessTokenSilently();
      const response = await api.put(
        `/update-stage/${entry.id}/${entry.stage}`,
        metadata,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      );
      if (response.status === 200) {
        refresh();
        onHide();
      } else {
        alert('An error occurred while saving the stage.');
      }
    } catch (error) {
      alert(
        `An error occurred: ${error.response ? error.response.data.error : error.message}`,
      );
    } finally {
      setSaving(false);
    }
  };

  const politeName = entry.stage === 'company_filter' ? 'Filter' : 'Search';

  return (
    <DreamSideModal show={show} onHide={onHide} centered>
      <DreamModalInner>
        <DreamModalInner.Header closeButton>
          <div className='funnel-icon-wrapper'>
            <FunnelIcon />
          </div>
          {`Edit ${politeName}`}
        </DreamModalInner.Header>
        <DreamModalInner.Body>
          <p>{`Make changes to your ${politeName.toLowerCase()}`}</p>
          <StageAdditionalOptions
            manifest={functionManifests[entry.stage]}
            stage={{ type: entry.type, metadata }}
            handleMetadataChange={handleMetadataChange}
          />
          <div className='match-count'>
            {entry.type &&
              functionManifests['company_search'].find(
                (x) => x.type === entry.type,
              )?.match_language && (
                <div className='search-step-matches-view'>
                  <b>
                    <SearchVolumeEstimate
                      type={entry.type}
                      metadata={metadata}
                    />
                  </b>{' '}
                  {functionManifests['company_search'].find(
                    (x) => x.type === entry.type,
                  )?.match_sample_text || 'matches'}
                </div>
              )}
          </div>
        </DreamModalInner.Body>
        <DreamModalInner.Footer>
          <Button variant='primary' onClick={handleSave} disabled={saving}>
            Save Changes
          </Button>
          <Button variant='outline-primary' onClick={onHide}>
            Cancel
          </Button>
        </DreamModalInner.Footer>
      </DreamModalInner>
    </DreamSideModal>
  );
};

export default StageEditModal;
