// src/components/create-company/SupportedUseCase.jsx
import React from 'react';
import { Button } from 'react-bootstrap';

const SupportedUseCase = ({ goBack, useCase, getStarted }) => {
  const getMessage = () => {
    if (useCase === 'list' || useCase === 'sales_nav') {
      return (
        <div className='dream-panel-inner'>
          <h2>{"We've got you covered"}</h2>
          <p>
            You can get started using Dream today without any further setup.
          </p>
        </div>
      );
    }
    return null;
  };

  return (
    <div className='supported-use-cases'>
      {getMessage()}
      <div className='forward-and-back-buttons single-button'>
        <Button variant='primary' onClick={getStarted}>
          Build Your Dream Pipeline!
        </Button>
      </div>
    </div>
  );
};

export default SupportedUseCase;
