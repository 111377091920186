import React, { useState, useEffect } from 'react';
import { Tabs, Tab, Spinner } from 'react-bootstrap';
import {
  useNavigate,
  useSearchParams,
  useParams,
  Link,
} from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import EditControl from '../oagents/EditControl';
import BasicInfo from './BasicInfo';
import Pricing from './Pricing';
import Market from './Market';
import FAQs from './FAQs';
import api from '../../api';
import './products.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';

function Product() {
  const { id: productId } = useParams(); // Extract the productId from the route
  const { getAccessTokenSilently } = useAuth0();
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();
  const queryTab = searchParams.get('tab') || 'basic-info';

  const [productName, setProductName] = useState('');
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const handleSelect = (key) => {
    setSearchParams({ tab: key });
  };

  const fetchProduct = async () => {
    setLoading(true);
    setError(null);

    try {
      const token = await getAccessTokenSilently();
      const response = await api.get(`/products/${productId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setProductName(response.data.name);
    } catch (error) {
      console.error('Error fetching product:', error);
      setError('Failed to load product details');
    } finally {
      setLoading(false);
    }
  };

  const saveProductName = async (newName) => {
    try {
      const token = await getAccessTokenSilently();
      await api.put(
        `/products/${productId}`,
        { name: newName },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      );
      setProductName(newName);
    } catch (error) {
      console.error('Error saving product name:', error);
    }
  };

  useEffect(() => {
    fetchProduct();
  }, [productId]);

  if (loading) {
    return (
      <div className='overlay'>
        <Spinner />
      </div>
    );
  }

  return (
    <div className='page-wrapper'>
      <div className='page-inner-wrapper product-page'>
        <div className='page-header-area'>
          <div className='title-and-text'>
            <div className='breadcrumbs'>
              <div className='set'>
                <Link to='/funnel'>
                  <FontAwesomeIcon icon={faArrowLeft} />
                  Back to Products
                </Link>
              </div>
            </div>
            <h3>
              <EditControl
                value={productName}
                placeholder='Enter product name'
                onSave={saveProductName}
                size='lg'
              />
            </h3>
          </div>
        </div>
        <div className='product-page-lower-area'>
          <Tabs
            activeKey={queryTab}
            onSelect={handleSelect}
            className='dream-left-tabs'
          >
            <Tab eventKey='basic-info' title='Basic Information'>
              <BasicInfo productId={productId} />
            </Tab>
            <Tab eventKey='pricing' title='Pricing'>
              <Pricing productId={productId} />
            </Tab>
            <Tab eventKey='market' title='Market'>
              <Market productId={productId} />
            </Tab>
          </Tabs>
        </div>
      </div>
    </div>
  );
}

export default Product;
