import React, { useState, useEffect } from 'react';
import { Button, Spinner, Alert } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import api from '../../api';
import { useAuth0 } from '@auth0/auth0-react';
import ResearchPointModal from './ResearchPointModal';
import { ReactComponent as AnalystIcon } from '../../icons/analyst.svg';
import MainPageLoader from '../misc/MainPageLoader'; // Ensure to import MainPageLoader
import './analyst.css';

const CampaignTriggers = () => {
  const { getAccessTokenSilently } = useAuth0();
  const [campaignTriggers, setCampaignTriggers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    const fetchDatapoints = async () => {
      try {
        const token = await getAccessTokenSilently();
        const response = await api.get('/analyst/research-points', {
          headers: { Authorization: `Bearer ${token}` },
        });

        const triggers = response.data.filter((rp) => rp.type === 'datapoint');
        setCampaignTriggers(triggers);
      } catch (error) {
        console.error('Error fetching Datapoints:', error);
        setError('Failed to load Datapoints');
      } finally {
        setLoading(false);
      }
    };

    fetchDatapoints();
  }, [getAccessTokenSilently]);

  const handleShowModal = (type) => {
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  if (loading) {
    return <MainPageLoader />;
  }

  return (
    <div className='research-point-solo-wrapper'>
      <div className='header'>
        <div className='left-area'>
          <h4>Your Datapoints</h4>
          <p>
            Datapoints are information known about the companies on your lists.
            You can use these to filter companies in your campaigns.
          </p>
        </div>
        <div className='buttons-area'>
          <Button onClick={() => handleShowModal('datapoint')}>
            Add Datapoint <FontAwesomeIcon icon={faPlus} />
          </Button>
        </div>
      </div>
      <div className='campaigns-area datapoint'>
        {error && <Alert variant='danger'>{error}</Alert>}
        {campaignTriggers.length === 0 && (
          <div className='empty-state'>
            <AnalystIcon />
            <p>You have not created any Datapoints yet</p>
            <Button
              variant='primary'
              className='add-button'
              onClick={() => handleShowModal('datapoint')}
            >
              Add Datapoint <FontAwesomeIcon icon={faPlus} />
            </Button>
          </div>
        )}
        {campaignTriggers.length > 0 && (
          <div className='datapoints-outer-wrapper'>
            <h6>View and edit your datapoints</h6>
            <div className='datapoints-wrapper'>
              {campaignTriggers.map((datapoint, index) => (
                <div key={index} className='datapoint-pill'>
                  {datapoint.name}
                </div>
              ))}
            </div>
          </div>
        )}
      </div>

      <ResearchPointModal
        show={showModal}
        handleClose={handleCloseModal}
        type='datapoint'
      />
    </div>
  );
};

export default CampaignTriggers;
