import React, { useState, useEffect } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { useParams, useNavigate, Link } from 'react-router-dom';
import { Button, Tab, TabContent } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faCheck } from '@fortawesome/free-solid-svg-icons';
import Companies from './steps/Companies';
import Prospects from './steps/Prospects';
import Messaging from './steps/Messaging';
import CongratsModal from '../misc/CongratsModal';
import './campaigns.css';

import { ReactComponent as RightCaretIcon } from '../../icons/right-caret.svg';
import { ReactComponent as SettingsIcon } from '../../icons/settings.svg';
import { ReactComponent as NewThreadIcon } from '../../icons/new-thread.svg';

import MainPageLoader from '../misc/MainPageLoader';
import api from '../../api';
import Purpose from './steps/Purpose';
import StartCampaignModal from './StartCampaignModal';

// Updated steps excluding "Purpose"
const steps = ['Companies', 'Prospects', 'Messaging'];

const CampaignWizard = () => {
  const { id } = useParams();
  const { getAccessTokenSilently } = useAuth0();
  const navigate = useNavigate();
  const [oagent, setOagent] = useState(null);
  const [activeStep, setActiveStep] = useState(null);
  const [loading, setLoading] = useState(true);
  const [showCongratsModal, setShowCongratsModal] = useState(false);
  const [maxActiveStep, setMaxActiveStep] = useState(null);
  const [showSettings, setShowSettings] = useState(false);
  const [bigSendButtonDisabled, setBigSendButtonDisabled] = useState(true);
  const [createOptions, setCreateOptions] = useState({});
  const [showStartCampaignModal, setShowStartCampaignModal] = useState(false); // State for showing the modal

  useEffect(() => {
    const fetchOagent = async () => {
      try {
        const token = await getAccessTokenSilently();
        const response = await api.get(`/oagents/${id}`, {
          headers: { Authorization: `Bearer ${token}` },
        });
        setOagent(response.data);
        setActiveStep(
          response.data.wizard_step ? response.data.wizard_step - 1 : 0,
        );
        setMaxActiveStep(
          response.data.wizard_step ? response.data.wizard_step - 1 : 0,
        );
        setLoading(false);
      } catch (error) {
        console.error('Error fetching oagent:', error);
        // Handle error appropriately
        setLoading(false);
      }
    };

    fetchOagent();
  }, [id, getAccessTokenSilently]);

  const updateStep = async (newStep) => {
    try {
      const token = await getAccessTokenSilently();
      setActiveStep(newStep);

      // We only update if we have a larger step
      if (newStep < maxActiveStep) return;
      setMaxActiveStep(newStep);
      await api.put(`/oagents/${id}/wizard-step/${newStep + 1}`, null, {
        headers: { Authorization: `Bearer ${token}` },
      });
    } catch (error) {
      console.error('Error updating wizard step:', error);
      // Handle error appropriately
    }
  };

  const saveAndExit = () => {
    navigate('/campaigns');
  };

  const handleSetLive = async () => {
    setLoading(true);
    try {
      const token = await getAccessTokenSilently();
      await api.post(`/oagents/${id}/set-live`, null, {
        headers: { Authorization: `Bearer ${token}` },
      });
      setShowCongratsModal(true);
    } catch (error) {
      console.error('Error setting campaign live:', error);
      // Handle error appropriately
    } finally {
      setLoading(false);
    }
  };

  const handleCloseCongratsModal = () => {
    setShowCongratsModal(false);
    navigate('/campaigns');
  };

  if (loading) {
    return <MainPageLoader />;
  }

  return (
    <>
      <div className='page-wrapper'>
        <div className='page-inner-wrapper campaigns campaign-wizard'>
          <div className='page-header-area'>
            <div className='title-and-text'>
              <div className='breadcrumbs'>
                <div className='set'>
                  <Link to='/campaigns'>
                    <FontAwesomeIcon icon={faArrowLeft} /> Back to Campaigns
                  </Link>
                </div>
              </div>
              <h3>
                {oagent?.name ? (
                  <>
                    <b>Campaign:</b> {oagent.name}
                  </>
                ) : (
                  <>Create campaign</>
                )}
              </h3>
            </div>
            <div className='buttons-area'>
              <Button variant='outline-primary' onClick={saveAndExit}>
                Save and exit campaign
              </Button>
            </div>
          </div>
          <Tab.Container>
            <div className='campaign-wizard-tabs'>
              <div className='main-tabs'>
                {steps.map((step, index) => {
                  const stepIndex = index;
                  const isCompletedStep = stepIndex <= maxActiveStep;
                  const completedClass =
                    stepIndex <= maxActiveStep ? 'completed' : '';
                  const activeClass =
                    stepIndex === activeStep && !showSettings ? 'active' : '';
                  const disabledClass =
                    stepIndex > maxActiveStep ? 'disabled' : '';

                  const classSet = `${completedClass} ${disabledClass} ${activeClass}`;

                  const icon = stepIndex + 1;

                  return (
                    <div
                      className='tab-immitate'
                      key={index}
                      onClick={() => {
                        if (index <= maxActiveStep) {
                          setShowSettings(false);
                          updateStep(index);
                        }
                      }}
                    >
                      <div className={`tab-inner ${classSet}`}>
                        <div className={`step ${classSet}`}>
                          <div className='step-number-sig'>{icon}</div>
                          {step}
                        </div>
                      </div>
                      <RightCaretIcon />
                    </div>
                  );
                })}
                {steps[activeStep] === 'Messaging' && (
                  <>
                    <Button
                      variant='primary'
                      className='big-start-campaign-button'
                      disabled={bigSendButtonDisabled}
                      onClick={() => setShowStartCampaignModal(true)} // Open the modal on click
                    >
                      Send & start campaign <NewThreadIcon />
                    </Button>
                  </>
                )}
              </div>
              <div
                className={`settings-tab ${showSettings && 'active'}`}
                onClick={() => setShowSettings(true)}
              >
                <SettingsIcon /> Settings
              </div>
            </div>
            <TabContent
              className={`${steps[activeStep] === 'Messaging' && 'messaging'}`}
            >
              <div className='step-content'>
                {steps[activeStep] === 'Companies' && !showSettings && (
                  <Companies
                    oagentId={id}
                    advance={() => updateStep(1)}
                    nextText='Add your target prospects'
                    wizard
                  />
                )}
                {steps[activeStep] === 'Prospects' && !showSettings && (
                  <Prospects
                    oagentId={id}
                    advance={() => updateStep(2)}
                    retreat={() => updateStep(0)}
                    nextText='Add messaging'
                    wizard
                  />
                )}
                {steps[activeStep] === 'Messaging' && !showSettings && (
                  <Messaging
                    oagentId={id}
                    enableSend={(opts) => {
                      if (
                        !opts.profile_id ||
                        !opts.prospect_id ||
                        !opts.content ||
                        !opts.subject ||
                        !opts.oagent_id ||
                        !opts.profile_name ||
                        !opts.email_id
                      ) {
                        console.error(`Missing arguments for enabling send`);
                        return;
                      }
                      setCreateOptions(opts);
                      setBigSendButtonDisabled(false);
                    }}
                    wizard
                  />
                )}
                {showSettings && (
                  <Purpose oagentId={id} nextText='Create campaign' wizard />
                )}
              </div>
            </TabContent>
          </Tab.Container>
        </div>
        <CongratsModal
          show={showCongratsModal}
          onHide={handleCloseCongratsModal}
          header='Congratulations!'
        >
          <p>Congratulations on creating the new campaign!</p>
        </CongratsModal>
      </div>
      {showStartCampaignModal && ( // Render the StartCampaignModal when the state is true
        <StartCampaignModal
          show={showStartCampaignModal}
          handleClose={() => setShowStartCampaignModal(false)}
          createOptions={createOptions}
        />
      )}
    </>
  );
};

export default CampaignWizard;
