import React, { useState, useEffect } from 'react';
import { useParams, Link, useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { Button, Form, Tabs, Tab, Spinner } from 'react-bootstrap';
import { useAuth0 } from '@auth0/auth0-react';
import ReactMarkdown from 'react-markdown';
import { ReactComponent as MicrophoneIcon } from '../../icons/microphone.svg';
import { ReactComponent as PersonCircleIcon } from '../../icons/person-circle.svg';
import { ReactComponent as EditManuallyIcon } from '../../icons/edit-manually.svg';
import { ReactComponent as TrashIcon } from '../../icons/trash.svg';
import { ReactComponent as CheckCircleIcon } from '../../icons/check-circle.svg';
import { ReactComponent as CheckCircleFilledIcon } from '../../icons/check-circle-filled.svg';
import TimezoneSelect from 'react-timezone-select';

import ConfirmModal from '../confirm-modals/ConfirmModal';
import BeInterviewedModal from './BeInterviewedModal';
import api from '../../api';
import DreamInfoExpander from '../misc/DreamInfoExpander';

import { BtnLink, BtnBold, BtnItalic } from './Buttons';
import { Editor, EditorProvider, Toolbar } from 'react-simple-wysiwyg';

const ProfileView = () => {
  const { id } = useParams();
  const { getAccessTokenSilently } = useAuth0();
  const [profile, setProfile] = useState({});
  const [editedName, setEditedName] = useState('');
  const [editedMaxEmailsPerDay, setEditedMaxEmailsPerDay] = useState(0);
  const [editedBio, setEditedBio] = useState('');
  const [bioEditMode, setBioEditMode] = useState(false);
  const [loading, setLoading] = useState(false);
  const [emailForSigTest, setEmailForSigTest] = useState('');
  const [showEmailSigModal, setShowEmailSigModal] = useState(false);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [showInterviewModal, setShowInterviewModal] = useState(false);
  const [selectedProfile, setSelectedProfile] = useState({});
  const [testEmailSending, setTestEmailSending] = useState(false);
  const [testEmailSent, setTestEmailSent] = useState(false);
  const [interviewEdited, setInterviewEdited] = useState(false);
  const [loader, setLoader] = useState(false);

  // New state variables
  const [sendingDays, setSendingDays] = useState({
    sends_monday: true,
    sends_tuesday: true,
    sends_wednesday: true,
    sends_thursday: true,
    sends_friday: true,
    sends_saturday: false,
    sends_sunday: false,
  });

  const [signature, setSignature] = useState('');
  const [sendsStartTime, setSendsStartTime] = useState('08:00');
  const [sendsEndTime, setSendsEndTime] = useState('18:00');
  const [timezone, setTimezone] = useState('GMT');

  const navigate = useNavigate();

  const sendTestEmail = async () => {
    try {
      setTestEmailSending(true);
      setShowEmailSigModal(false);

      await handleSaveMessagingSettings();
      const token = await getAccessTokenSilently();
      await api.post(
        `/profiles/${id}/sig-test-email`,
        { to: emailForSigTest },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      );
      setTestEmailSent(true);
    } catch (error) {
      alert(`Error sending test email`, error);
      console.error(error);
    } finally {
      setTestEmailSending(false);
    }
  };

  useEffect(() => {
    const fetchProfile = async () => {
      try {
        const token = await getAccessTokenSilently();
        const response = await api.get(`/profiles/${id}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        setProfile(response.data);
        setEditedName(response.data.name);
        setEditedBio(response.data.bio);
        setEditedMaxEmailsPerDay(response.data.max_emails_per_day);

        // Set updated state variables
        setSendingDays({
          sends_monday: response.data.sends_monday,
          sends_tuesday: response.data.sends_tuesday,
          sends_wednesday: response.data.sends_wednesday,
          sends_thursday: response.data.sends_thursday,
          sends_friday: response.data.sends_friday,
          sends_saturday: response.data.sends_saturday,
          sends_sunday: response.data.sends_sunday,
        });
        setSendsStartTime(response.data.sends_start_time || '08:00');
        setSendsEndTime(response.data.sends_end_time || '18:00');
        setTimezone(response.data.timezone || 'GMT');
        setSignature(response.data.signature);
      } catch (error) {
        console.error('Error fetching profile:', error);
      }
    };

    fetchProfile();
  }, [id, getAccessTokenSilently]);

  const handleMaxEmailsPerDayChange = (e) =>
    setEditedMaxEmailsPerDay(e.target.value);
  const handleNameChange = (e) => setEditedName(e.target.value);
  const handleBioChange = (e) => setEditedBio(e.target.value);
  const handleSendsStartTimeChange = (e) => setSendsStartTime(e.target.value);
  const handleSendsEndTimeChange = (e) => setSendsEndTime(e.target.value);
  const handleTimezoneChange = (e) => {
    setTimezone(e.value);
  };

  const handleSendingDayToggle = (day) => {
    setSendingDays((prevDays) => ({
      ...prevDays,
      [day]: !prevDays[day],
    }));
  };

  const handleShowConfirmModal = (profile) => {
    setSelectedProfile(profile);
    setShowConfirmModal(true);
  };

  const handleCloseConfirmModal = () => setShowConfirmModal(false);

  const handleConfirmRemove = async () => {
    try {
      const token = await getAccessTokenSilently();
      await api.delete(`/profiles/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      navigate('/profiles');
    } catch (error) {
      console.error('Error deleting profile:', error);
    } finally {
      setShowConfirmModal(false);
    }
  };

  const handleSaveMessagingSettings = async () => {
    try {
      const token = await getAccessTokenSilently();
      setLoading(true);
      await api.put(
        `/profiles/${id}`,
        {
          name: editedName,
          max_emails_per_day: editedMaxEmailsPerDay,
          sends_monday: sendingDays.sends_monday,
          sends_tuesday: sendingDays.sends_tuesday,
          sends_wednesday: sendingDays.sends_wednesday,
          sends_thursday: sendingDays.sends_thursday,
          sends_friday: sendingDays.sends_friday,
          sends_saturday: sendingDays.sends_saturday,
          sends_sunday: sendingDays.sends_sunday,
          sends_start_time: sendsStartTime,
          sends_end_time: sendsEndTime,
          timezone,
          signature,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      );
      setProfile((prev) => ({
        ...prev,
        name: editedName,
        max_emails_per_day: editedMaxEmailsPerDay,
        sends_monday: sendingDays.sends_monday,
        sends_tuesday: sendingDays.sends_tuesday,
        sends_wednesday: sendingDays.sends_wednesday,
        sends_thursday: sendingDays.sends_thursday,
        sends_friday: sendingDays.sends_friday,
        sends_saturday: sendingDays.sends_saturday,
        sends_sunday: sendingDays.sends_sunday,
        sends_start_time: sendsStartTime,
        sends_end_time: sendsEndTime,
        timezone,
      }));
    } catch (error) {
      console.error('Error updating messaging settings:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleSaveBio = async () => {
    try {
      const token = await getAccessTokenSilently();
      setLoading(true);
      await api.put(
        `/profiles/${id}/bio`,
        { bio: editedBio },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      );
      setBioEditMode(false);
      setProfile((prev) => ({ ...prev, bio: editedBio }));
    } catch (error) {
      console.error('Error updating bio:', error);
    } finally {
      setLoading(false);
    }
  };

  const interviewSetBio = (val) => {
    setInterviewEdited(true);
    setLoader(true);
    setTimeout(() => {
      setLoader(false);
    }, 100);
    console.log(val);
    setEditedBio(val);
  };

  if (!profile) return <Spinner animation='border' />;

  const formattedCreatedAt = new Date(profile.created_at).toLocaleDateString();

  return (
    <div className='page-wrapper'>
      <div className='page-inner-wrapper profile-view sdrs'>
        <div className='page-header-area'>
          <div className='title-and-text'>
            <div className='breadcrumbs'>
              <div className='set'>
                <Link to='/profiles'>
                  <FontAwesomeIcon icon={faArrowLeft} /> Back to Profiles
                </Link>
              </div>
            </div>
            <h3>Edit Agent details</h3>
          </div>
        </div>
        <div className='lower-profile-area'>
          <div className='left-area'>
            <div className='internal-profile' key={profile.id}>
              <div className='backer'>
                <div className='dotter'></div>
              </div>
              <div className='silly-design-wrapper'>
                <div className='content-area'>
                  <div className='image-wrapper'>
                    {profile.profile_image_url ? (
                      <img src={profile.profile_image_url} alt='Profile' />
                    ) : (
                      <PersonCircleIcon />
                    )}
                  </div>
                  <h5>{profile.name}</h5>
                  <div className='extra-info-section'>
                    <div className='set'>
                      <p className='label'>Created On</p>
                      <p className='value'>{formattedCreatedAt}</p>
                    </div>
                    <div className='set'>
                      <p className='label'>Can</p>
                      <p className='value'>
                        {profile.oauth_id &&
                          profile.calendar_oauth_id &&
                          `Send Email & Take Meetings`}
                        {profile.oauth_id &&
                          !profile.calendar_oauth_id &&
                          `Send Email`}
                        {profile.calendar_oauth_id &&
                          !profile.oauth_id &&
                          `Take Meetings`}
                      </p>
                    </div>
                    <div className='set'>
                      <p className='label'>Active Campaigns</p>
                      <p className='value'>{profile.active_campaigns}</p>
                    </div>
                  </div>
                </div>
                <div className='dual-options-area'>
                  <Button
                    variant='link'
                    className='remove'
                    onClick={() => handleShowConfirmModal(profile)}
                  >
                    <TrashIcon /> Remove this profile
                  </Button>
                </div>
              </div>
            </div>
          </div>
          <div className='right-area'>
            <Tabs
              defaultActiveKey='biography'
              id='profile-tabs'
              className='dream-tabs large'
            >
              <Tab eventKey='biography' title='Biography'>
                <div className='tab-wrapper'>
                  <div className='header'>
                    <h5>Biography</h5>
                    <p>
                      Your agent will use your biography to write emails to
                      Prospects
                    </p>
                  </div>
                  <div className='buttons-row'>
                    <span>Improve your Biography:</span>
                    <Button onClick={() => setShowInterviewModal(true)}>
                      <MicrophoneIcon /> Get interviewed
                    </Button>
                    <Button onClick={() => setBioEditMode(!bioEditMode)}>
                      <EditManuallyIcon /> Edit biography manually
                    </Button>
                  </div>
                  <div className='biography-area'>
                    {bioEditMode ? (
                      <>
                        <Form.Control
                          as='textarea'
                          rows={5}
                          value={editedBio}
                          onChange={handleBioChange}
                        />
                        <div className='save-button-wrapper'>
                          <Button
                            variant='primary'
                            onClick={handleSaveBio}
                            disabled={loading}
                          >
                            {loading ? (
                              <Spinner animation='border' size='sm' />
                            ) : (
                              'Save Biography'
                            )}
                          </Button>
                        </div>
                      </>
                    ) : (
                      <>
                        {loader && <Spinner />}
                        {!loader && (
                          <>
                            {interviewEdited && (
                              <div className='profile-warning'>
                                <DreamInfoExpander
                                  alwaysExpanded
                                  heading='Warning'
                                >
                                  <p>
                                    This biography was edited during your
                                    interview. Things <b>WILL</b> be spelled
                                    incorrectly, you <b>MUST</b> double check
                                    it.
                                  </p>
                                </DreamInfoExpander>
                              </div>
                            )}
                            <div className='biography-wrapper'>
                              <ReactMarkdown>{editedBio}</ReactMarkdown>
                            </div>
                          </>
                        )}
                      </>
                    )}
                  </div>
                </div>
              </Tab>
              <Tab eventKey='messaging' title='Messaging'>
                <div className='tab-wrapper'>
                  <div className='header'>
                    <h5>Messaging</h5>
                  </div>
                  <Form className='dream-side-modal-form profile-settings'>
                    <Form.Group>
                      <Form.Label>Sending Days</Form.Label>
                      <Form.Text>
                        Select which days of the week your Sales Agent will send
                        emails
                      </Form.Text>
                      <div className='day-selector'>
                        {[
                          'monday',
                          'tuesday',
                          'wednesday',
                          'thursday',
                          'friday',
                          'saturday',
                          'sunday',
                        ].map((day) => (
                          <div
                            key={day}
                            className={`day ${sendingDays[`sends_${day}`] && 'active'}`}
                            onClick={() =>
                              handleSendingDayToggle(`sends_${day}`)
                            }
                          >
                            {sendingDays[`sends_${day}`] ? (
                              <CheckCircleFilledIcon />
                            ) : (
                              <CheckCircleIcon />
                            )}
                            {day.charAt(0).toUpperCase() + day.slice(1)}
                          </div>
                        ))}
                      </div>
                    </Form.Group>
                    <Form.Group className='sending-times'>
                      <Form.Label>Sending Times</Form.Label>
                      <Form.Text>
                        Select which time of day your Sales Agent will send
                        emails
                      </Form.Text>
                      <div className='time-selector'>
                        <span>Send emails between</span>
                        <Form.Control
                          type='time'
                          value={sendsStartTime}
                          onChange={handleSendsStartTimeChange}
                        />
                        <span> and </span>
                        <Form.Control
                          type='time'
                          value={sendsEndTime}
                          onChange={handleSendsEndTimeChange}
                        />
                        <TimezoneSelect
                          value={timezone}
                          onChange={handleTimezoneChange}
                          classNamePrefix='timezone-select'
                        />
                      </div>
                    </Form.Group>
                    <Form.Group>
                      <Form.Label>Max emails per day</Form.Label>
                      <Form.Text>
                        The maximum number of outreach emails this account will
                        send per day.
                        <br />
                        <b>Note: </b> we recommend setting this to a maximum of
                        50 to preserve your email rating.
                      </Form.Text>
                      <Form.Control
                        className='small-number-entry'
                        type='number'
                        value={editedMaxEmailsPerDay}
                        onChange={handleMaxEmailsPerDayChange}
                        size='lg'
                      />
                    </Form.Group>
                    <Form.Group>
                      <Form.Label>Message signature</Form.Label>
                      <Form.Text>
                        Add a signature to show below your messages. For complex
                        signatures we recommend a generator like{' '}
                        <a target='__blank' href='https://signature.email'>
                          Signature Email
                        </a>
                        .
                      </Form.Text>
                      <EditorProvider>
                        <Editor
                          value={signature}
                          onChange={(e) => setSignature(e.target.value)}
                        >
                          <Toolbar>
                            <BtnBold />
                            <BtnItalic />
                            <div className='rsw-separator'></div>
                            <BtnLink />
                            <div className='right-set'>
                              <Button
                                variant='outline-primary'
                                className='test-signature-button'
                                onClick={() => setShowEmailSigModal(true)}
                                disabled={testEmailSending}
                              >
                                {testEmailSending && (
                                  <>
                                    <Spinner size='sm' /> Sending
                                  </>
                                )}
                                {testEmailSent && (
                                  <>
                                    <CheckCircleIcon /> Email Sent
                                  </>
                                )}
                                {!testEmailSent && !testEmailSending && (
                                  <>Test signature</>
                                )}
                              </Button>
                            </div>
                          </Toolbar>
                          <div className='nice-lines'>
                            <div className='nice-line'></div>
                            <div className='nice-line'></div>
                          </div>
                        </Editor>
                      </EditorProvider>
                    </Form.Group>
                    <div className='save-button-wrapper'>
                      <Button
                        variant='primary'
                        onClick={handleSaveMessagingSettings}
                        disabled={loading}
                      >
                        {loading ? (
                          <>
                            Saving <Spinner animation='border' size='sm' />
                          </>
                        ) : (
                          'Save Changes'
                        )}
                      </Button>
                    </div>
                  </Form>
                </div>
              </Tab>
              <Tab eventKey='settings' title='Settings'>
                <div className='tab-wrapper'>
                  <div className='header'>
                    <h5>Settings</h5>
                  </div>
                  <Form className='dream-side-modal-form profile-settings'>
                    {profile.oauth_email && (
                      <Form.Group>
                        <Form.Label>Email account</Form.Label>
                        <Form.Text>
                          The account that we will send email from for this
                          agent.
                        </Form.Text>
                        <Form.Control
                          type='text'
                          value={profile.oauth_email}
                          disabled
                          size='lg'
                        />
                      </Form.Group>
                    )}
                    {profile.calendar_oauth_email && (
                      <Form.Group>
                        <Form.Label>Calendar account</Form.Label>
                        <Form.Text>
                          The calendar that meetings will be booked on for this
                          agent.
                        </Form.Text>
                        <Form.Control
                          type='text'
                          value={profile.calendar_oauth_email}
                          disabled
                          size='lg'
                        />
                      </Form.Group>
                    )}
                    <Form.Group>
                      <Form.Label>Display name</Form.Label>
                      <Form.Text>
                        The name that will appear to your prospects.
                      </Form.Text>
                      <Form.Control
                        type='text'
                        value={editedName}
                        onChange={handleNameChange}
                        size='lg'
                      />
                    </Form.Group>
                    <div className='save-button-wrapper'>
                      <Button
                        variant='primary'
                        onClick={handleSaveMessagingSettings}
                        disabled={loading}
                      >
                        {loading ? (
                          <>
                            Saving <Spinner animation='border' size='sm' />
                          </>
                        ) : (
                          'Save Changes'
                        )}
                      </Button>
                    </div>
                  </Form>
                </div>
              </Tab>
            </Tabs>
          </div>
        </div>
      </div>
      <ConfirmModal
        show={showConfirmModal}
        handleClose={handleCloseConfirmModal}
        handleConfirm={handleConfirmRemove}
        title='Confirm Profile Removal'
        bodyText={
          selectedProfile.type === 'internal'
            ? `You are about to remove the profile for ${selectedProfile.name}. If you do so, no new emails will be sent as this individual. The email account your team member has associated will still receive responses from historic emails.`
            : 'Are you sure you want to remove this connector?'
        }
        confirmLabel='Confirm'
        cancelLabel='Cancel'
      />

      <ConfirmModal
        show={showEmailSigModal}
        handleClose={() => setShowEmailSigModal(false)}
        handleConfirm={sendTestEmail}
        title='Test email signature'
        bodyText={
          <div className='sig-confirm-modal dream-side-modal-form'>
            <p>
              Send a test email to the address below. We recommend making sure
              that any images in your signature are hosted somewhere you can
              control to ensure they load consistently.
            </p>
            <Form.Group>
              <Form.Label>Email address</Form.Label>
              <Form.Control
                type='email'
                value={emailForSigTest}
                onChange={(e) => setEmailForSigTest(e.target.value)}
              />
            </Form.Group>
          </div>
        }
        confirmLabel='Send test email'
        cancelLabel='Cancel'
      />
      <BeInterviewedModal
        show={showInterviewModal}
        handleClose={() => setShowInterviewModal(false)}
        profileId={id}
        setBio={interviewSetBio}
      />
    </div>
  );
};

export default ProfileView;
