import React from 'react';
import { Tooltip, OverlayTrigger } from 'react-bootstrap';
import { ReactComponent as SoftProcessingIcon } from '../../icons/soft-processing.svg';

function Processing({ id, statusObject }) {
  const politeProgress = {
    SEARCH_TERMS_GENERATED: 'Search terms generated, now reading articles.',
    ARTICLES_READ: 'Articles read, considering',
    WRAPPING_UP: 'Updating database',
  };

  const detailsObject = statusObject?.jobDetails[0];
  const progress = detailsObject ? detailsObject?.progress?.step : false;
  const progressExplanation = progress
    ? politeProgress[progress]
    : 'Researching';

  return (
    <OverlayTrigger
      placement='top'
      trigger={['hover', 'focus']}
      overlay={<Tooltip id={`tooltip-${id}`}>{progressExplanation}</Tooltip>}
    >
      <span>
        <div className='soft-processing-frame'>
          <SoftProcessingIcon />
        </div>
      </span>
    </OverlayTrigger>
  );
}

export default Processing;
