import React, { useEffect, useState } from 'react';
import api from '../api';
import { useAuth0 } from '@auth0/auth0-react';
import ReactJson from '@microlink/react-json-view';

const Debug = () => {
  const { getAccessTokenSilently } = useAuth0();
  const [calls, setCalls] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchLLMCalls = async () => {
      try {
        const token = await getAccessTokenSilently();
        const response = await api.get('/debug/llm-calls', {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        setCalls(response.data);
      } catch (err) {
        setError(err);
      } finally {
        setLoading(false);
      }
    };

    fetchLLMCalls();
  }, []);

  const copyToClipboard = (text) => {
    navigator.clipboard.writeText(text);
  };

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error fetching calls: {error.message}</p>;

  return (
    <div className='page-wrapper'>
      <div className='page-inner-wrapper'>
        <div className='calls-list dream-list'>
          {calls.map((call, index) => {
            const params = call.call;
            const messages = params?.messages || [];

            return (
              <div className='dream-list-item' key={index}>
                <p>Other Params:</p>
                <pre>
                  {JSON.stringify({ ...params, messages: undefined }, null, 2)}
                </pre>
                <div>
                  {messages
                    .filter((message) => message.role === 'system')
                    .map((message, index) => (
                      <div key={index}>
                        <div>{message.content}</div>
                        <button
                          onClick={() => copyToClipboard(message.content)}
                        >
                          Copy to clipboard
                        </button>
                      </div>
                    ))}
                </div>
                <div>
                  {messages
                    .filter((message) => message.role === 'user')
                    .map((message, index) => (
                      <div key={index}>
                        <div
                          dangerouslySetInnerHTML={{
                            __html: message.content.replace(/\n/g, '<br />'),
                          }}
                        />
                        <button
                          onClick={() => copyToClipboard(message.content)}
                        >
                          Copy to clipboard
                        </button>
                      </div>
                    ))}
                </div>
                <div>
                  <h5>Response</h5>
                  <ReactJson src={call.response} collapsed />
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default Debug;
