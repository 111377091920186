import React from 'react';

const LeadStatus = ({ prospect }) => {
  const { conversation_status } = prospect;

  if (conversation_status) {
    const cs =
      conversation_status === 'VIEWED'
        ? 'Opened'
        : conversation_status.slice(0, 1).toUpperCase() +
          conversation_status.slice(1).toLowerCase();

    let add;
    if (cs === 'Replied') {
      add = 'neutral';
    } else if (cs === 'Opened') {
      add = 'semi-positive';
    } else {
      add = 'positive';
    }

    return <p className={`status-tag ${add}`}>{cs}</p>;
  }
};

export default LeadStatus;
