import React, { useState, useEffect } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { Alert, Spinner } from 'react-bootstrap';
import CompaniesSettings from './CompaniesSettings';
import EmbeddedCompaniesList from './EmbeddedCompaniesList';
import api from '../../../api';

function Companies({ advance, retreat, nextText, oagentId, wizard }) {
  const { getAccessTokenSilently } = useAuth0();
  const [step, setStep] = useState('list');
  const [hasNoCompanies, setHasNoCompanies] = useState(true);
  const [knownOrgs, setKnownOrgs] = useState([]);
  const [isInitialCheck, setIsInitialCheck] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    checkKnownOrgs();
  }, [oagentId]);

  const checkKnownOrgs = async () => {
    try {
      const token = await getAccessTokenSilently();
      const response = await api.get(`/oagents/${oagentId}/known-orgs`, {
        headers: { Authorization: `Bearer ${token}` },
      });

      setKnownOrgs(response.data);

      if (response.data.length > 0) {
        setHasNoCompanies(false);

        if (isInitialCheck) {
          setStep('list');
        }
      } else {
        setHasNoCompanies(true);
        if (isInitialCheck) {
          setStep('settings');
        }
      }
    } catch (err) {
      console.error('Error fetching known orgs:', err);
      setError('Error fetching known orgs: ' + err.message);
    } finally {
      setIsInitialCheck(false);
    }
  };

  const handleAdvance = async () => {
    if (step === 'settings') {
      try {
        setStep('list');
        const token = await getAccessTokenSilently();
        await api.post(
          `/oagents/${oagentId}/wizard-company-sync`,
          { force: true },
          { headers: { Authorization: `Bearer ${token}` } },
        );
      } catch (err) {
        console.error('Error syncing companies:', err);
        setError('Error syncing companies: ' + err.message);
      }
    } else {
      advance();
    }
  };

  const handleRetreat = () => {
    setStep('settings');
    checkKnownOrgs();
  };

  return (
    <div className='campaign-step-wrapper'>
      {error && <Alert variant='danger'>{error}</Alert>}

      {step === 'settings' ? (
        <CompaniesSettings
          oagentId={oagentId}
          wizard={wizard}
          nextFn={handleAdvance}
          exitWithoutSaving={!hasNoCompanies ? () => setStep('list') : false}
        />
      ) : (
        <EmbeddedCompaniesList
          openSettings={handleRetreat}
          nextFn={handleAdvance}
          nextText={nextText}
          oagentId={oagentId}
          knownOrgs={knownOrgs}
          setKnownOrgs={setKnownOrgs}
          isInitialCheck={isInitialCheck}
        />
      )}
    </div>
  );
}

export default Companies;
