import React, { useState, useEffect } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { Button, Form } from 'react-bootstrap';
import api from '../../api';
import './task-list.css';
import DreamSideModal from '../misc/DreamSideModal';
import DreamSideModalLoader from '../misc/DreamSideModalLoader';
import DreamModalInner from '../misc/DreamModalInner';
import MessageText from '../misc/MessageText';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';

function EscalationModal({ show, onHide, task }) {
  const { getAccessTokenSilently } = useAuth0();
  const [escalation, setEscalation] = useState(null);
  const [loading, setLoading] = useState(true);
  const [guidance, setGuidance] = useState('');
  const [showGuidanceForm, setShowGuidanceForm] = useState(false);

  useEffect(() => {
    const fetchEscalationDetails = async () => {
      setLoading(true);
      try {
        const token = await getAccessTokenSilently();
        const response = await api.get(`/escalations/${task.id}`, {
          headers: { Authorization: `Bearer ${token}` },
        });
        setEscalation(response.data);
      } catch (error) {
        console.error('Error fetching escalation details:', error);
      } finally {
        setLoading(false);
      }
    };

    if (task) {
      fetchEscalationDetails();
    }
  }, [task, getAccessTokenSilently]);

  const handleAction = async (action) => {
    if (loading) return;
    setLoading(true);

    try {
      const token = await getAccessTokenSilently();
      await api.post(
        `/escalations/${task.id}/action`,
        { action, guidance },
        {
          headers: { Authorization: `Bearer ${token}` },
        },
      );

      // Dispatch custom event when modal is closed
      const event = new Event('modalClose');
      window.dispatchEvent(event);

      onHide(); // Close modal and refresh queue
    } catch (error) {
      console.error(`Error performing action ${action}:`, error);
    } finally {
      setLoading(false);
    }
  };

  if (loading || !escalation) {
    return (
      <DreamSideModal show={show} handleClose={onHide}>
        <DreamModalInner>
          <DreamSideModalLoader>Loading</DreamSideModalLoader>
        </DreamModalInner>
      </DreamSideModal>
    );
  }

  return (
    <DreamSideModal show={show} handleClose={onHide}>
      <DreamModalInner>
        <DreamModalInner.Header>Message escalation</DreamModalInner.Header>
        <DreamModalInner.Body>
          <div className='escalations-wrapper'>
            <div className='heading'>
              <h6>
                You've received a reply that your agent isn't sure how to answer
              </h6>
            </div>
            {escalation.messageTexts.length > 0 && (
              <div className='responding-to-wrapper'>
                <MessageText
                  message={
                    escalation.messageTexts[escalation.messageTexts.length - 1]
                  }
                  shortHeader
                />
              </div>
            )}
            <div className='options-div'>
              <p className='heading'>
                How do you want to resolve this escalation?
              </p>
              <div className='button-wrapper'>
                <Button
                  onClick={() => setShowGuidanceForm(true)}
                  disabled={loading}
                >
                  Guide reply
                </Button>
                <Button
                  onClick={() => handleAction('ignore')}
                  disabled={loading}
                >
                  Don't reply
                </Button>
              </div>
            </div>
            {showGuidanceForm && (
              <Form.Group className='guidance-area dream-side-modal-form'>
                <Form.Label>Guidance</Form.Label>
                <Form.Control
                  as='textarea'
                  value={guidance}
                  onChange={(e) => setGuidance(e.target.value)}
                  placeholder='Enter feedback here'
                  rows={3}
                />
                <div className='button-wrapper campaigns'>
                  <Button onClick={() => handleAction('respond')}>
                    Draft response <FontAwesomeIcon icon={faArrowRight} />
                  </Button>
                </div>
              </Form.Group>
            )}
          </div>
        </DreamModalInner.Body>
      </DreamModalInner>
    </DreamSideModal>
  );
}

export default EscalationModal;
