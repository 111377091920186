import React, { useState } from 'react';
import { Form, Button, Alert } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { ReactComponent as SalesIcon } from '../../../icons/sales.svg';
import { ReactComponent as RecruitmentIcon } from '../../../icons/recruitment.svg';
import { ReactComponent as PartnershipsIcon } from '../../../icons/partnerships.svg';

import { faArrowRight } from '@fortawesome/free-solid-svg-icons';

const useCaseDescriptions = {
  sales: 'Describe your ideal customer(s)',
  recruitment: 'Describe The Companies Your Targets Work For',
  partnerships: "Describe The Kinds of Companies You're Looking For",
};

const useCaseText = {
  sales: 'Of course you have many but give us one or two to start.',
  recruitment: 'Of course there will be many but give us one or two to start.',
  partnerships: 'Of course there will be many but give us one or two to start.',
};

const useCaseExamples = {
  sales:
    'We target SaaS businesses with revenue of $10M or more that primarily sell to SMBs. An example of this is Hubspot and Canva.',
  recruitment:
    'We primarily hire from the NGO sector where employees have significant experience of foreign aid projects. An example is Unicef or WHO.',
  partnerships:
    'We work with UK banks to get their customers better home insurance. They offer our product through their affiliate portals. An example is Lloyds or Barclays.',
};

const icons = {
  sales: <SalesIcon />,
  recruitment: <RecruitmentIcon />,
  partnerships: <PartnershipsIcon />,
};

const Step2 = ({ formData, handleChange, handlePrevious, handleSubmit }) => {
  const [errorMessage, setErrorMessage] = useState('');

  const validateForm = () => {
    if (formData.use_case_description.length < 100) {
      setErrorMessage(
        'Please give us a bit more information about your target (100 chars min)',
      );
      return false;
    }
    setErrorMessage('');
    return true;
  };

  const handleFinalSubmit = (event) => {
    event.preventDefault();
    if (validateForm()) {
      handleSubmit();
    }
  };

  return (
    <Form onSubmit={handleFinalSubmit} className='company-form big-textarea'>
      <h1>Setup your profile</h1>
      <Form.Group controlId='formUseCaseType'>
        <Form.Label>What Will You Use Dream For Primarily?</Form.Label>
        <div className='use-case-options'>
          {['sales', 'recruitment', 'partnerships'].map((option) => (
            <Button
              key={option}
              className={
                formData.use_case_type === option ? 'selected' : 'unselected'
              }
              onClick={() =>
                handleChange({
                  target: { name: 'use_case_type', value: option },
                })
              }
            >
              {icons[option]}
              {option.charAt(0).toUpperCase() + option.slice(1)}
            </Button>
          ))}
        </div>
      </Form.Group>
      {formData.use_case_type && (
        <Form.Group controlId='formUseCaseDescription'>
          <Form.Label>
            {useCaseDescriptions[formData.use_case_type] ||
              'Describe your use case'}
          </Form.Label>
          <Form.Text>
            {useCaseText[formData.use_case_type] ||
              'Please provide a brief description.'}
          </Form.Text>
          <Form.Control
            as='textarea'
            name='use_case_description'
            value={formData.use_case_description}
            onChange={handleChange}
            className='use-case-description breathing-room'
            placeholder={`e.g. ${useCaseExamples[formData.use_case_type]}`}
          />
        </Form.Group>
      )}
      {errorMessage && (
        <div className='error-area'>
          <Alert variant='danger'>{errorMessage}</Alert>
        </div>
      )}
      {formData.use_case_type && (
        <div className='step-2-button-wrapper'>
          <Button variant='outline-primary' onClick={handlePrevious}>
            Back to company
          </Button>
          <Button variant='primary' type='submit'>
            Create Account{' '}
            <div className='yellow-icon'>
              <FontAwesomeIcon icon={faArrowRight} />
            </div>
          </Button>
        </div>
      )}
    </Form>
  );
};

export default Step2;
