import React, { createContext, useContext, useEffect, useState } from 'react';
import Pusher from 'pusher-js';
import { useAuth0 } from '@auth0/auth0-react';
import api from '../api'; // Import your API utility

const PusherContext = createContext();

export const usePusher = () => useContext(PusherContext);

export const PusherProvider = ({ children }) => {
  const { getAccessTokenSilently, user, isAuthenticated } = useAuth0();
  const [pusherChannel, setPusherChannel] = useState(null);

  useEffect(() => {
    const setupPusher = async () => {
      if (!isAuthenticated) return;

      try {
        const token = await getAccessTokenSilently();
        const response = await api.get('/confirm-user-company', {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        const { company_id } = response.data;

        const pusherInstance = new Pusher(process.env.REACT_APP_PUSHER_KEY, {
          cluster: process.env.REACT_APP_PUSHER_CLUSTER,
          authEndpoint: process.env.REACT_APP_API_URL + '/pusher/auth',
          auth: {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          },
        });

        const channel = pusherInstance.subscribe(
          `${process.env.REACT_APP_PUSHER_ENV}-private-company-${company_id}`,
        );
        setPusherChannel(channel);

        return () => {
          pusherInstance.unsubscribe(`private-company-${company_id}`);
        };
      } catch (error) {
        console.error('Error setting up Pusher:', error);
      }
    };

    setupPusher();
  }, [getAccessTokenSilently, isAuthenticated]);

  return (
    <PusherContext.Provider value={pusherChannel}>
      {children}
    </PusherContext.Provider>
  );
};
