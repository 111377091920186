import React, { useState, useEffect } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { Form, Spinner } from 'react-bootstrap';
import api from '../../api';
import { usePusher } from '../../contexts/PusherContext';
import FeedItem from './items/FeedItem';

import FeedSummary from './summaries/FeedSummary';

import { ReactComponent as ThumbsUpIcon } from '../../icons/thumbs-up.svg';
import './feed.css';

const Feed = ({ oagentId }) => {
  const { getAccessTokenSilently } = useAuth0();
  const pusherChannel = usePusher();
  const [feedItems, setFeedItems] = useState([]);
  const [loading, setLoading] = useState(true);
  const [topLoading, setTopLoading] = useState(false);
  const [initialLoading, setInitialLoading] = useState(true);
  const [types, setTypes] = useState('All');
  const [feedType, setFeedType] = useState('Recent');
  const [cursor, setCursor] = useState(null);
  const [limit, setLimit] = useState(40);
  const [nextCursor, setNextCursor] = useState(null);
  const [expandedItems, setExpandedItems] = useState({});

  const uniqueItems = (items) => {
    const seen = new Set();
    return items.filter((item) => {
      const key = `${item.id}-${item.type}-${item.time}`;
      if (seen.has(key)) {
        return false;
      } else {
        seen.add(key);
        return true;
      }
    });
  };

  const fetchFeed = async (isTopLoading = false) => {
    setLoading(!isTopLoading);
    setTopLoading(isTopLoading);
    const token = await getAccessTokenSilently();
    try {
      const endpoint = feedType === 'Upcoming' ? '/feed/upcoming' : '/feed';
      const response = await api.get(endpoint, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params: {
          limit,
          cursor: isTopLoading ? null : nextCursor,
          ...(oagentId && { oagent_id: oagentId }),
          ...(feedType === 'Recent' && types !== 'All' && { types }),
        },
      });

      /*
      isTopLoading is used when we refresh the feed in sub-component OR when we get a notification that something has happened. If we're in "recent" view then we want to add new things we've found to things we've already found (the isTopLoading means we're doing the basic search with no cursor for the first results as they will be the most recent).

      If we're in the upcoming view however, we may have actually stopped some future thing from happening (e.g. we may have sent a message listed as upcoming) so it should be removed. We handle this by replacing the feed.

      The by product is of course that it might destroy a lot of "View more" clicks but the workaround would be trickier and that's very edge case.
      */
      const newItems = isTopLoading
        ? feedType === 'recent'
          ? uniqueItems([...response.data.items, ...feedItems])
          : response.data.items
        : uniqueItems([...feedItems, ...response.data.items]);

      newItems.sort((a, b) => new Date(b.time) - new Date(a.time));

      setFeedItems(newItems);
      setNextCursor(response.data.next_cursor);
    } catch (error) {
      console.error('Error fetching feed:', error);
    } finally {
      setLoading(false);
      setInitialLoading(false);
      setTopLoading(false);
    }
  };

  useEffect(() => {
    fetchFeed();
  }, [cursor, types, feedType]);

  useEffect(() => {
    if (pusherChannel) {
      const handlePusherEvent = () => {
        fetchFeed(true); // Fetch new data without resetting the cursor
      };

      pusherChannel.bind('email_scheduled', handlePusherEvent);
      pusherChannel.bind('oagent_status_update', handlePusherEvent);
      pusherChannel.bind('conversation_update', handlePusherEvent);
      pusherChannel.bind('meeting_booked', handlePusherEvent);

      // Cleanup on unmount
      return () => {
        pusherChannel.unbind('email_scheduled', handlePusherEvent);
        pusherChannel.unbind('oagent_status_update', handlePusherEvent);
        pusherChannel.unbind('conversation_update', handlePusherEvent);
        pusherChannel.unbind('meeting_booked', handlePusherEvent);
      };
    }
  }, [pusherChannel]);

  const handleLoadMore = () => {
    setLoading(true);
    setCursor(nextCursor);
  };

  const handleTypeChange = (event) => {
    setTypes(event.target.value);
    setFeedItems([]); // Reset feed items
    setCursor(null); // Reset cursor
  };

  const handleFeedTypeToggle = (type) => {
    setFeedType(type);
    setFeedItems([]); // Reset feed items
    setCursor(null); // Reset cursor
  };

  const handleToggleExpand = (category, idx) => {
    setExpandedItems((prevExpanded) => ({
      ...prevExpanded,
      [`${category}-${idx}`]: !prevExpanded[`${category}-${idx}`],
    }));
  };

  // Helper function to categorize feed items
  const categorizeFeedItems = () => {
    const now = new Date();
    const categories = {
      'Last 24 hours': [],
      'Last 7 Days': [],
      'Last 30 Days': [],
      Older: [],
    };

    feedItems.forEach((item) => {
      const itemTime = new Date(item.time);
      const diffTime = (now - itemTime) / (1000 * 60 * 60 * 24); // Difference in days

      if (diffTime <= 1) {
        categories['Last 24 hours'].push(item);
      } else if (diffTime <= 7) {
        categories['Last 7 Days'].push(item);
      } else if (diffTime <= 30) {
        categories['Last 30 Days'].push(item);
      } else {
        categories['Older'].push(item);
      }
    });

    return categories;
  };

  const aggregateFeedItems = (categoryItems) => {
    let aggregatedItems = [];
    let currentGroup = [];
    const twoHours = 1000 * 60 * 60 * 2; // Two hours in milliseconds

    for (let i = 0; i < categoryItems.length; i++) {
      if (currentGroup.length === 0) {
        currentGroup.push(categoryItems[i]);
      } else {
        const timeDiff =
          new Date(categoryItems[i].time) - new Date(currentGroup[0].time);
        if (
          ['PROSPECT_ADDED_TO_OAGENT', 'COMPANY_ADDED_TO_OAGENT'].includes(
            categoryItems[i].type,
          ) &&
          categoryItems[i].type === currentGroup[0].type &&
          timeDiff <= twoHours
        ) {
          currentGroup.push(categoryItems[i]);
        } else {
          aggregatedItems.push(currentGroup);
          currentGroup = [categoryItems[i]];
        }
      }
    }

    if (currentGroup.length > 0) {
      aggregatedItems.push(currentGroup);
    }

    return aggregatedItems;
  };

  const categorizedFeedItems = categorizeFeedItems();

  return (
    <div className={`feed-wrapper ${initialLoading && 'loading'}`}>
      <div className='feed-head'>
        <div className='left-side'>
          <h5>Activity</h5>
        </div>
        {feedType === 'Recent' && (
          <div className='right-side'>
            <Form.Select value={types} onChange={handleTypeChange}>
              <option value='All'>All</option>
              <option value='REPLY_RECEIVED'>Replies Received</option>
              <option value='MEETING_BOOKED'>Meetings Booked</option>
              <option value='FIRST_CONTACT'>First Contact Made</option>
              <option value='FOLLOW_UP'>Follow-Ups Sent</option>
              <option value='COMPANY_ADDED_TO_OAGENT'>Company Added</option>
              <option value='PROSPECT_ADDED_TO_OAGENT'>Prospect Added</option>
            </Form.Select>
          </div>
        )}
      </div>

      <div className='feed-content'>
        {Object.keys(categorizedFeedItems).map(
          (category, index) =>
            categorizedFeedItems[category].length > 0 && (
              <div key={category}>
                <h6>{category}</h6>
                {index === 0 && topLoading && (
                  <div className='loading-bar'>
                    <Spinner size='sm' />
                  </div>
                )}
                {aggregateFeedItems(categorizedFeedItems[category]).map(
                  (group, idx) => (
                    <div className='feed-group' key={idx}>
                      {group.length === 1 ? (
                        <FeedItem
                          key={group[0].id}
                          item={group[0]}
                          refreshFeed={() => fetchFeed(true)}
                        />
                      ) : (
                        <>
                          <FeedSummary
                            count={group.length}
                            type={group[0].type}
                            items={group}
                            time={group[0].time}
                            onClick={() => handleToggleExpand(category, idx)}
                            refreshFeed={() => fetchFeed(true)}
                            expanded={!!expandedItems[`${category}-${idx}`]}
                          >
                            {expandedItems[`${category}-${idx}`] && (
                              <div className='expanded-area'>
                                {group.map((item, itemIdx) => (
                                  <FeedItem key={itemIdx} item={item} />
                                ))}
                              </div>
                            )}
                          </FeedSummary>
                        </>
                      )}
                    </div>
                  ),
                )}
              </div>
            ),
        )}
      </div>

      {!loading && feedItems.length > 0 && nextCursor && (
        <div className='load-more-button'>
          <button onClick={handleLoadMore}>Load More</button>
        </div>
      )}
      {!loading && feedItems.length > 0 && !nextCursor && (
        <div className='all-loaded'>
          <ThumbsUpIcon /> All activity loaded
        </div>
      )}
      {!loading && feedItems.length === 0 && (
        <div className='feed-empty-state'>
          <ThumbsUpIcon />
          <div>
            <h6>No activity</h6>
            <p>
              {feedType === 'recent'
                ? 'This is where you will see replies and booked meetings'
                : 'No upcoming activity, add more prospects to generate new meetings'}
            </p>
          </div>
        </div>
      )}
      {loading && !topLoading && (
        <div className='loading-bar'>
          <Spinner size='sm' />
        </div>
      )}
    </div>
  );
};

export default Feed;
