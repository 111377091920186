import React from 'react';
import BaseType from './BaseType';

const ProspectAdded = ({ items, children, count, time, onClick, expanded }) => {
  return (
    <BaseType
      logos={items.map((item) => {
        return item?.metadata?.prospect?.logo_url;
      })}
      mainText={`${count} prospects`}
      tagText='Added to campaigns'
      tagType='neutral'
      time={time}
      onClick={onClick}
      expanded={expanded}
    >
      {children}
    </BaseType>
  );
};

export default ProspectAdded;
