import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Auth0Provider } from '@auth0/auth0-react';
import { PostHogProvider } from 'posthog-js/react';
import { SidebarProvider } from './contexts/SidebarContext';
import { ProcessingProvider } from './contexts/ProcessingContext';
import App from './App';

const options = {
  api_host: process.env.REACT_APP_PUBLIC_POSTHOG_HOST,
};

function Dream() {
  const navigate = useNavigate();
  const onRedirectCallback = (appState) => {
    navigate(appState?.path || window.location.pathname, { replace: true });
  };

  return (
    <Auth0Provider
      domain={process.env.REACT_APP_AUTH0_DOMAIN}
      clientId={process.env.REACT_APP_AUTH0_CLIENT_ID}
      authorizationParams={{
        audience: process.env.REACT_APP_AUTH0_AUDIENCE,
        redirect_uri: window.location.origin,
      }}
      onRedirectCallback={onRedirectCallback}
    >
      <PostHogProvider
        apiKey={process.env.REACT_APP_PUBLIC_POSTHOG_KEY}
        options={options}
      >
        <ProcessingProvider>
          <SidebarProvider>
            <App />
          </SidebarProvider>
        </ProcessingProvider>
      </PostHogProvider>
    </Auth0Provider>
  );
}

export default Dream;
