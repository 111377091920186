import React from 'react';
import './dream-side-modal-loader.css';

function DreamSideModalLoader({ children }) {
  return (
    <div className='dream-side-modal-loader'>
      <div className='dream-side-modal-loader-icon'></div>
      <p>{children}</p>
    </div>
  );
}

export default DreamSideModalLoader;
