import React, { useEffect, useState, useRef } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import api from '../api';
import './dashboard/dashboard.css';
import TaskList from './tasks/TaskList';
import NewLeads from './dashboard/NewLeads';
import Feed from './feed/Feed';
import { ReactComponent as LicenseIcon } from '../icons/license.svg';
import { ReactComponent as MeetingsIcon } from '../icons/meetings.svg';
import { ReactComponent as EmailIcon } from '../icons/email.svg';
import { ReactComponent as ReplyIcon } from '../icons/reply.svg';
import DashChart from './dashboard/DashChart';

const Dashboard = () => {
  const { getAccessTokenSilently } = useAuth0();
  const [conversationStats, setConversationStats] = useState(null);
  const [prospectCount, setProspectCount] = useState(null);
  const [emailCount, setEmailCount] = useState(null);
  const [loading, setLoading] = useState(true);
  const [selectedInterval, setSelectedInterval] = useState(7);
  const [selectedDatasets, setSelectedDatasets] = useState(['prospects']);
  const hasShownLoadingRef = useRef(false);

  useEffect(() => {
    const fetchData = async () => {
      if (!hasShownLoadingRef.current) {
        setLoading(true);
      }

      const token = await getAccessTokenSilently();
      try {
        const fetchConversations = api.get(
          `/conversations?days=${selectedInterval}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          },
        );

        const fetchProspectCount = api.get(
          `/dashboard/prospect-count?days=${selectedInterval}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          },
        );

        const fetchEmailCount = api.get(
          `/dashboard/email-count?days=${selectedInterval}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          },
        );

        const [
          responseConversations,
          responseProspectCount,
          responseEmailCount,
        ] = await Promise.all([
          fetchConversations,
          fetchProspectCount,
          fetchEmailCount,
        ]);

        setConversationStats(responseConversations.data);
        setProspectCount(responseProspectCount.data);
        setEmailCount(responseEmailCount.data);
      } catch (error) {
        console.error('Error fetching data:', error);
      } finally {
        setLoading(false);
        hasShownLoadingRef.current = true;
      }
    };

    fetchData();

    const fetchInterval = setInterval(fetchData, 60000); // Refresh stats every minute

    return () => clearInterval(fetchInterval); // Clear interval on component unmount
  }, [getAccessTokenSilently, selectedInterval]);

  const handleIntervalChange = (newInterval) => {
    setSelectedInterval(newInterval);
  };

  const toggleDataset = (dataset) => {
    setSelectedDatasets((prevDatasets) =>
      prevDatasets.includes(dataset)
        ? prevDatasets.filter((d) => d !== dataset)
        : [...prevDatasets, dataset],
    );
  };

  const getTotalProspects = () => {
    if (!prospectCount) return 0;
    return Object.keys(prospectCount.breakdown).reduce(
      (prev, curr) => prev + prospectCount.breakdown[curr],
      0,
    );
  };

  const getNewProspects = () => {
    if (!prospectCount) return 0;
    return prospectCount.new_prospects;
  };

  const getTotalEmailsSent = () => {
    if (!emailCount) return 0;
    return Object.keys(emailCount.breakdown).reduce(
      (prev, curr) => prev + emailCount.breakdown[curr],
      0,
    );
  };

  const getNewEmailsSent = () => {
    if (!emailCount) return 0;
    return emailCount.new_emails;
  };

  const getRepliesCount = () => {
    if (!conversationStats) return 0;
    return Object.keys(conversationStats.breakdown).reduce((prev, curr) => {
      const obj = conversationStats.breakdown[curr];
      return prev + obj?.replied + obj?.booked + obj?.success;
    }, 0);
  };

  const getTotalConversations = () => {
    return Object.keys(conversationStats.breakdown).reduce((prev, curr) => {
      const obj = conversationStats.breakdown[curr];
      return (
        prev +
        obj?.replied +
        obj?.booked +
        obj?.fail +
        obj?.success +
        obj?.contacted +
        obj?.viewed
      );
    }, 0);
  };

  const getRepliesPercentage = () => {
    if (!conversationStats) return 0;
    const totalConversations = getTotalConversations();
    const totalReplies = getRepliesCount();
    return totalConversations > 0
      ? ((totalReplies / totalConversations) * 100).toFixed(1)
      : 0;
  };

  const getCallsBooked = () => {
    if (!conversationStats) return 0;
    return Object.keys(conversationStats.breakdown).reduce((prev, curr) => {
      const obj = conversationStats.breakdown[curr];
      return prev + obj?.booked + obj?.success;
    }, 0);
  };

  const getCallsBookedPercentage = () => {
    if (!conversationStats) return 0;
    const totalConversations = getTotalConversations();
    const totalBooked =
      getCallsBooked() + (conversationStats.success?.all_time || 0);
    return totalConversations > 0
      ? ((totalBooked / totalConversations) * 100).toFixed(1)
      : 0;
  };

  const polite = {
    prospects: 'New prospects',
    emails: 'Total emails sent',
    replies: 'Replies',
    meetings: 'Meetings booked',
  };

  return (
    <div className='page-wrapper'>
      <div className='page-inner-wrapper main-dashboard'>
        <div className='dream-top-boxes bolder'>
          <div
            className={`top-box prospects ${selectedDatasets.includes('prospects') ? 'selected' : ''} ${loading ? 'loading' : ''}`}
            onClick={() => toggleDataset('prospects')}
          >
            <LicenseIcon />
            <h5>New prospects</h5>
            <p>
              {getTotalProspects()}
              {selectedInterval === 7 && <span>+ {getNewProspects()}</span>}
            </p>
          </div>
          <div
            className={`top-box emails ${selectedDatasets.includes('emails') ? 'selected' : ''} ${loading ? 'loading' : ''}`}
            onClick={() => toggleDataset('emails')}
          >
            <EmailIcon />
            <h5>Total emails sent</h5>
            <p>
              {getTotalEmailsSent()}
              {selectedInterval === 7 && <span>+ {getNewEmailsSent()}</span>}
            </p>
          </div>
          <div
            className={`top-box replies ${selectedDatasets.includes('replies') ? 'selected' : ''} ${loading ? 'loading' : ''}`}
            onClick={() => toggleDataset('replies')}
          >
            <ReplyIcon />
            <h5>Replies</h5>
            <p>
              {getRepliesCount()}
              <span>{getRepliesPercentage()}%</span>
            </p>
          </div>
          <div
            className={`top-box meetings ${selectedDatasets.includes('meetings') ? 'selected' : ''} ${loading ? 'loading' : ''}`}
            onClick={() => toggleDataset('meetings')}
          >
            <MeetingsIcon />
            <h5>Meetings booked</h5>
            <p>
              {getCallsBooked()}
              <span>{getCallsBookedPercentage()}%</span>
            </p>
          </div>
        </div>

        <div className={`chart-management ${loading && 'loading'}`}>
          {!loading && (
            <>
              <div className='selected-and-toggle'>
                <div className='selected-datasets'>
                  {selectedDatasets.map((set) => (
                    <div className={`dataset-choice ${set}`} key={set}>
                      <div className='point'></div>
                      <span>{polite[set]}</span>
                    </div>
                  ))}
                </div>
                <div className='interval-toggle'>
                  <div
                    className={selectedInterval === 7 ? 'active' : ''}
                    onClick={() => handleIntervalChange(7)}
                  >
                    7 Days
                  </div>
                  <div
                    className={selectedInterval === 30 ? 'active' : ''}
                    onClick={() => handleIntervalChange(30)}
                  >
                    30 Days
                  </div>
                  <div
                    className={selectedInterval === 'all' ? 'active' : ''}
                    onClick={() => handleIntervalChange('all')}
                  >
                    All Time
                  </div>
                </div>
              </div>
              <DashChart
                conversationStats={conversationStats}
                prospectCount={prospectCount}
                emailCount={emailCount}
                selectedDatasets={selectedDatasets}
                showTimesAs={
                  parseInt(selectedInterval, 10) < 30 ? 'DAYS' : 'WEEKS'
                }
              />
            </>
          )}
        </div>

        <div className='lower-area'>
          <div className='feed-outer-wrapper'>
            <Feed />
          </div>
          <div className='lower-side-wrapper'>
            <div className='tasks-wrapper'>
              <TaskList />
            </div>
            <div className='new-leads-wrapper'>
              <NewLeads />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
