import React, { useEffect, useState, useCallback } from 'react';
import api from '../../api';
import { useAuth0 } from '@auth0/auth0-react';
import { Spinner } from 'react-bootstrap';

const debounce = (func, delay) => {
  let timerId;
  return (...args) => {
    if (timerId) clearTimeout(timerId);
    timerId = setTimeout(() => {
      func(...args);
    }, delay);
  };
};

const SearchVolumeEstimate = ({ type, metadata }) => {
  const { getAccessTokenSilently } = useAuth0();
  const [searchVolume, setSearchVolume] = useState(null);
  const [loading, setLoading] = useState(false);

  // We do this because otherwise we wouldn't update the useEffect if some internal property updated
  const metadataString = JSON.stringify(metadata);

  // Function to fetch search volume
  const fetchSearchVolume = async (metadata, type) => {
    setLoading(true);
    try {
      const token = await getAccessTokenSilently();
      const response = await api.post(
        '/estimate-search-volume',
        {
          metadata: JSON.parse(metadata), // Parsing the JSON string back to object
          type: type,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      );
      setSearchVolume(response.data.totalResults);
    } catch (err) {
      console.error('Error estimating search volume', err);
    } finally {
      setLoading(false);
    }
  };

  const debouncedFetchSearchVolume = useCallback(
    debounce(fetchSearchVolume, 200),
    [],
  );

  useEffect(() => {
    debouncedFetchSearchVolume(metadataString, type);
  }, [type, metadataString, debouncedFetchSearchVolume]);

  // Helper function to format the search volume
  const formatNumber = (number) => {
    return new Intl.NumberFormat('en-US').format(number);
  };

  return loading ? (
    <Spinner size='sm' />
  ) : searchVolume !== null ? (
    formatNumber(searchVolume)
  ) : (
    'N/A'
  );
};

export default SearchVolumeEstimate;
