import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Spinner } from 'react-bootstrap';
import { useAuth0 } from '@auth0/auth0-react';
import FakePic from './FakePic';
import { formatDistanceToNow, parseISO } from 'date-fns';
import { ReactComponent as ClockIcon } from '../../../icons/clock.svg';
import api from '../../../api'; // Ensure you have the correct path to your api module

const ScheduledEmail = ({ item, refreshFeed }) => {
  const { getAccessTokenSilently } = useAuth0();
  const [sending, setSending] = useState(false);
  const [error, setError] = useState(null);

  const handleSendNow = async () => {
    setSending(true);
    setError(null);
    try {
      const token = await getAccessTokenSilently();
      await api.post(
        `/oagent-prospects/${item.metadata.generated_email_id}/${item.metadata.oagent_id}/send`,
        {},
        {
          headers: { Authorization: `Bearer ${token}` },
        },
      );
    } catch (error) {
      setError(
        'Error sending email - please check the sending profile still has a linked email.',
      );
      console.error('Error sending email:', error);
    } finally {
      setSending(false);
      refreshFeed();
    }
  };

  return (
    <div className='feed-item upcoming'>
      <div className='scheduled-area'>
        <ClockIcon /> Scheduled{' '}
        {formatDistanceToNow(parseISO(item.time), {
          addSuffix: true,
        })}
      </div>
      <div className='who-wrapper'>
        <FakePic url={item.metadata?.known_org?.logo_url} />
        <div className='who-right'>
          <Link
            to={`/prospect/${item.metadata?.prospect?.id}?tab=oagent-${item.metadata.oagent_id}&contentTab=next-outreach-email`}
          >
            {item?.metadata?.prospect?.name}
          </Link>
          <Link to={`/analyst/${item.metadata?.known_org?.id}`}>
            {item?.metadata?.known_org?.name}
          </Link>
        </div>
      </div>
      <div className='tag-section'>
        <div
          className={`tag neutral ${sending ? 'sending' : ''}`}
          onClick={!sending ? handleSendNow : null}
        >
          {sending ? (
            <>
              <Spinner
                as='span'
                animation='border'
                size='sm'
                role='status'
                aria-hidden='true'
              />
              &nbsp;&nbsp;Sending...
            </>
          ) : (
            '✉️ Send message now'
          )}
        </div>
      </div>
      {error && <div className='error-message'>{error}</div>}
    </div>
  );
};

export default ScheduledEmail;
