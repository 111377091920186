import React from 'react';
import './CharacterTracker.css';

import { ReactComponent as RedHeartIcon } from '../../icons/red-heart.svg';
import { ReactComponent as RedThumbUpIcon } from '../../icons/red-thumb-up.svg';
import { ReactComponent as GreenThumbUpIcon } from '../../icons/green-thumb-up.svg';

const iconTypes = {
  heart: <RedHeartIcon />,
  greenThumb: <GreenThumbUpIcon />,
  redThumb: <RedThumbUpIcon />,
};

const CharacterTracker = ({
  recommendations,
  value,
  description,
  maxChars,
}) => {
  const charCount = value.length;

  // Find the appropriate recommendation
  const recommendation = recommendations.find(
    (rec) => charCount <= rec.chars,
  ) || { chars: 0, message: '', iconType: 'redThumb' };

  return (
    <div className='character-tracker'>
      <p>{description}</p>
      <div className='right-side'>
        <div className='state-wrapper'>
          {iconTypes[recommendation.iconType]}
          <span>{recommendation.message}</span>
        </div>
        <p>
          {charCount}/{maxChars}
        </p>
      </div>
    </div>
  );
};

export default CharacterTracker;
