import React, { useState, useEffect } from 'react';
import DreamSideModal from '../misc/DreamSideModal';
import DreamModalInner from '../misc/DreamModalInner';
import ConfirmModal from '../confirm-modals/ConfirmModal';
import { useAuth0 } from '@auth0/auth0-react';
import { Spinner, Button, Alert, Form } from 'react-bootstrap';
import { usePusher } from '../../contexts/PusherContext';
import api from '../../api';
import './campaign-edit-messaging.css';
import DreamSideModalLoader from '../misc/DreamSideModalLoader';
import { ReactComponent as EmailSendIcon } from '../../icons/email-send.svg';

function MessageDetailsModal({ show, onHide, email, oagentId }) {
  const { getAccessTokenSilently } = useAuth0();
  const pusher = usePusher();

  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [editing, setEditing] = useState(false);
  const [feedback, setFeedback] = useState('');
  const [loading, setLoading] = useState(false);
  const [sending, setSending] = useState(false); // New state for sending status
  const [updatedEmail, setUpdatedEmail] = useState(email);
  const [error, setError] = useState(null);

  useEffect(() => {
    setUpdatedEmail(email);
  }, [email]);

  const handleSendEmail = async () => {
    setSending(true);
    handleCloseConfirm();

    try {
      const token = await getAccessTokenSilently();

      await api.post(
        `/oagent-prospects/${email.email_id}/${oagentId}/send`,
        {},
        {
          headers: { Authorization: `Bearer ${token}` },
        },
      );

      setSending(false);
      onHide(); // Close the modal after successful send
    } catch (error) {
      console.error('Error sending email:', error);
      setError('Error sending email. Please try again.');
      setSending(false);
    }
  };

  const handleMakeChangeClick = () => {
    setEditing(true);
  };

  const handleFeedbackChange = async () => {
    setLoading(true);
    setError(null);
    setEditing(false);

    try {
      const token = await getAccessTokenSilently();
      const response = await api.put(
        `/request-changes/${email.email_id}`,
        { feedback },
        {
          headers: { Authorization: `Bearer ${token}` },
        },
      );

      const { request_id: requestId } = response.data;

      pusher.bind('email_changes_update', (data) => {
        if (data.request_id === requestId) {
          setLoading(false);

          if (data.error) {
            setError(data.error);
          } else {
            setUpdatedEmail((prev) => ({
              ...prev,
              subject: data.subject,
              content: data.body,
            }));
          }
          pusher.unbind('email_changes_update');
        }
      });
    } catch (error) {
      console.error('Error requesting changes:', error);
      setError(error);
      setLoading(false);
    }
  };

  const handleCloseConfirm = () => setShowConfirmModal(false);

  if (loading || sending) {
    return (
      <DreamSideModal show={show} handleClose={onHide}>
        <DreamModalInner>
          <DreamSideModalLoader>
            {sending ? 'Sending' : 'Loading'}
          </DreamSideModalLoader>
        </DreamModalInner>
      </DreamSideModal>
    );
  }

  return (
    <DreamSideModal show={show} handleClose={onHide}>
      <DreamModalInner>
        <DreamModalInner.Header>
          Email to {updatedEmail.prospect_name}
        </DreamModalInner.Header>
        <DreamModalInner.Body>
          {error && (
            <div className='campaign-edit-messaging-error-wrapper'>
              <Alert variant='danger'>
                An error occurred, please try again.
              </Alert>
            </div>
          )}
          <div className='message-details-wrapper'>
            {!editing ? (
              <div className='heading'>
                <div className='set'>
                  <p>Status</p>
                  <p>
                    {updatedEmail.status === 'SENT' ? (
                      <span className='status-tag positive'>Sent</span>
                    ) : updatedEmail.status === 'FORMALLY_SCHEDULED' ? (
                      <span className='status-tag pending'>
                        Formally Scheduled
                      </span>
                    ) : (
                      <span className='status-tag neutral'>Queued</span>
                    )}
                  </p>
                </div>
                <div className='set'>
                  <p>Message Sequence</p>
                  <p>
                    {!updatedEmail.is_new_thread
                      ? 'Follow-up'
                      : updatedEmail.email_order === 0
                        ? 'First email'
                        : 'New thread'}
                  </p>
                </div>
                {updatedEmail.status !== 'SENT' && (
                  <div className='send-button-wrapper'>
                    <Button
                      variant='primary'
                      onClick={() => setShowConfirmModal(true)}
                    >
                      <EmailSendIcon />
                      Send email now
                    </Button>
                  </div>
                )}
              </div>
            ) : (
              <div className='dream-side-modal-form'>
                <Form.Group>
                  <Form.Label>Describe the changes you want</Form.Label>
                  <Form.Text>
                    What changes do you want made to your email? Your agent will
                    use this to rewrite the message.
                  </Form.Text>
                  <Form.Control
                    as='textarea'
                    value={feedback}
                    onChange={(e) => setFeedback(e.target.value)}
                  />
                </Form.Group>
                <div className='actions-row'>
                  <Button variant='primary' onClick={handleFeedbackChange}>
                    Regenerate email
                  </Button>
                </div>
              </div>
            )}
            <div className='message-content'>
              {!loading && (
                <>
                  {updatedEmail.status !== 'SENT' && !editing && (
                    <div className='make-changes-wrapper'>
                      <Button
                        variant='outline-primary'
                        onClick={handleMakeChangeClick}
                      >
                        Make changes to this email
                      </Button>
                    </div>
                  )}
                  <>
                    <div className='subject-and-content'>
                      <h6>{updatedEmail.subject}</h6>
                      <p
                        dangerouslySetInnerHTML={{
                          __html: updatedEmail.content.replace(/\n/g, '<br />'),
                        }}
                      />
                    </div>
                  </>{' '}
                </>
              )}
            </div>
          </div>
        </DreamModalInner.Body>
      </DreamModalInner>

      <ConfirmModal
        show={showConfirmModal}
        handleClose={handleCloseConfirm}
        handleConfirm={handleSendEmail}
        title='Send Email'
        bodyText="Are you sure you want to send this email now? The reason that messages aren't sent straight away is to reduce spam warnings."
        confirmLabel='Send'
        cancelLabel='Cancel'
      />
    </DreamSideModal>
  );
}

export default MessageDetailsModal;
