import React, { useState, useEffect } from 'react';
import { Button, Spinner, Table } from 'react-bootstrap';
import { useAuth0 } from '@auth0/auth0-react';
import { ReactComponent as PlusIcon } from '../../icons/plus.svg';
import AddTeamMemberModal from './AddTeamMemberModal';
import DeleteTeamMemberModal from './DeleteTeamMemberModal';

import api from '../../api';

const Team = () => {
  const { getAccessTokenSilently, user } = useAuth0();
  const [teamMembers, setTeamMembers] = useState([]);
  const [showAddModal, setShowAddModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [selectedMember, setSelectedMember] = useState(null);
  const [deleteError, setDeleteError] = useState(null);
  const [isDeleting, setIsDeleting] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const fetchTeamMembers = async () => {
    setIsLoading(true);
    try {
      const token = await getAccessTokenSilently();
      const response = await api.get('/users', {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setTeamMembers(response.data);
    } catch (error) {
      console.error('Error fetching team members:', error);
    } finally {
      setIsLoading(false);
    }
  };

  // Fetch team members
  useEffect(() => {
    fetchTeamMembers();
  }, [getAccessTokenSilently]);

  const handleAddMember = () => {
    fetchTeamMembers();
  };

  const handleDeleteMember = async () => {
    try {
      setDeleteError(null);
      setIsDeleting(true);
      const token = await getAccessTokenSilently();
      await api.delete(`/users/${selectedMember.id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setTeamMembers(
        teamMembers.filter((member) => member.id !== selectedMember.id),
      );
      setShowDeleteModal(false);
    } catch (error) {
      console.error('Error deleting team member:', error);
      setDeleteError(error);
    } finally {
      setIsDeleting(false);
    }
  };

  if (isLoading) {
    return (
      <div className='team-loading'>
        <Spinner />
      </div>
    );
  }
  return (
    <div className='section team-settings'>
      <div className='heading'>
        <h6>Your team members</h6>
        <Button variant='primary' onClick={() => setShowAddModal(true)}>
          Add new team member <PlusIcon />
        </Button>
      </div>
      <div className='team-table-wrapper'>
        <div className='pseudo-table'>
          <div className='table-head'>
            <div>Name</div>
            <div>Email</div>
            <div>Account type</div>
            <div></div>
          </div>
          <div className='table-body'>
            {teamMembers.map((member) => (
              <div key={member.id} className='team-member'>
                <div>{member.name}</div>
                <div>
                  {member.email}{' '}
                  {member.id === user.sub && (
                    <span className='tag positive'>You</span>
                  )}
                </div>
                <div className=''>
                  {member?.role
                    ? member.role.slice(0, 1).toUpperCase() +
                      member.role.slice(1)
                    : 'Unknown'}
                </div>
                <div>
                  {member.id !== user.sub && (
                    <span
                      className='delete'
                      onClick={() => {
                        setSelectedMember(member);
                        setShowDeleteModal(true);
                      }}
                    >
                      Delete account
                    </span>
                  )}
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>

      <AddTeamMemberModal
        show={showAddModal}
        handleClose={() => setShowAddModal(false)}
        handleAddMember={handleAddMember}
      />

      <DeleteTeamMemberModal
        show={showDeleteModal}
        handleClose={() => setShowDeleteModal(false)}
        handleDeleteMember={handleDeleteMember}
        member={selectedMember}
        isDeleting={isDeleting}
        deleteError={deleteError}
      />
    </div>
  );
};

export default Team;
