import React, { useState } from 'react';

import { ReactComponent as PersonCircleIcon } from '../../../icons/person-circle.svg';
import { ReactComponent as OrgFakeIcon } from '../../../icons/org-fake.svg';

export default function FakePic({ url, type }) {
  const [loaded, setLoaded] = useState(false);
  const handleImageLoad = () => {
    setLoaded(true);
  };

  if (url) {
    return (
      <img
        className={`feed-profile-image ${loaded ? 'loaded' : 'loading'}`}
        src={url}
        onLoad={handleImageLoad}
      />
    );
  }

  return (
    <div className='fake-pic'>
      {type === 'org' ? (
        <div className='org-fake'>
          <OrgFakeIcon />
        </div>
      ) : (
        <PersonCircleIcon />
      )}
    </div>
  );
}
