import React, { useState, useEffect } from 'react';
import { Modal, Button, Form, Spinner, Alert } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faTrash } from '@fortawesome/free-solid-svg-icons';
import { useAuth0 } from '@auth0/auth0-react';
import api from '../../api';
import ConfirmModal from '../confirm-modals/ConfirmModal';

import DreamModalInner from '../misc/DreamModalInner';
import DreamSideModal from '../misc/DreamSideModal';

const ExistingCustomers = ({ productId }) => {
  const { getAccessTokenSilently } = useAuth0();
  const [customers, setCustomers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [currentCustomer, setCurrentCustomer] = useState({
    name: '',
    job_title_of_key_user: '',
    why_they_love: '',
  });
  const [customerToBeDeleted, setCustomerToBeDeleted] = useState(null);

  const fetchCustomers = async () => {
    try {
      const token = await getAccessTokenSilently();
      const response = await api.get(
        `/products/${productId}/existing-customers`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      );
      setCustomers(response.data);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching customers:', error);
      setError('Failed to load existing customers');
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchCustomers();
  }, [getAccessTokenSilently, productId]);

  const handleSaveCustomer = async () => {
    try {
      const token = await getAccessTokenSilently();
      if (currentCustomer.id) {
        await api.put(
          `/products/${productId}/existing-customers/${currentCustomer.id}`,
          currentCustomer,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          },
        );
      } else {
        await api.post(
          `/products/${productId}/existing-customers`,
          currentCustomer,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          },
        );
      }
      setShowModal(false);
      fetchCustomers();
    } catch (error) {
      console.error('Error saving customer:', error);
      setError('Failed to save existing customer');
    }
  };

  const handleEditCustomer = (customer) => {
    setCurrentCustomer(customer);
    setShowModal(true);
  };

  const handleAddCustomer = () => {
    setCurrentCustomer({
      name: '',
      job_title_of_key_user: '',
      why_they_love: '',
    });
    setShowModal(true);
  };

  const handleDeleteCustomerRequest = (customer) => {
    setCustomerToBeDeleted(customer);
    setShowConfirmModal(true);
  };

  const handleDeleteCustomer = async (customerId) => {
    try {
      const token = await getAccessTokenSilently();
      await api.delete(
        `/products/${productId}/existing-customers/${customerId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      );
      fetchCustomers();
    } catch (error) {
      console.error('Error deleting customer:', error);
      setError('Failed to delete existing customer');
    }
  };

  const confirmDeleteCustomer = () => {
    if (customerToBeDeleted && customerToBeDeleted.id) {
      handleDeleteCustomer(customerToBeDeleted.id);
    }
    setShowConfirmModal(false);
    setCustomerToBeDeleted(null);
  };

  if (loading) return <Spinner animation='border' />;
  if (error) return <Alert variant='danger'>{error}</Alert>;

  return (
    <div>
      {loading ? (
        <Spinner animation='border' />
      ) : error ? (
        <Alert variant='danger'>{error}</Alert>
      ) : (
        <div className='thin-headers dream-list'>
          {customers.map((customer) => (
            <div key={customer.id} className='dream-list-item has-action-icons'>
              <h5 onClick={() => handleEditCustomer(customer)}>
                {customer.name}
              </h5>
              <Button
                onClick={() => handleDeleteCustomerRequest(customer)}
                variant='outline-primary'
                className='remove-button'
              >
                Remove
              </Button>
            </div>
          ))}
          <div className='add-another-button-wrapper'>
            <Button onClick={handleAddCustomer}>
              Add new customer{' '}
              <div className='yellow-icon'>
                <FontAwesomeIcon icon={faPlus} />{' '}
              </div>
            </Button>
          </div>
        </div>
      )}

      <DreamSideModal show={showModal} onHide={() => setShowModal(false)}>
        <DreamModalInner>
          <DreamModalInner.Header closeButton>
            {currentCustomer.id ? 'Edit Customer' : 'Add Customer'}
          </DreamModalInner.Header>
          <DreamModalInner.Body>
            {error && <Alert variant='danger'>{error}</Alert>}
            <div className='product-form-wrapper dream-side-modal-form'>
              <Form.Group>
                <Form.Label>Customer Name</Form.Label>
                <Form.Text>
                  e.g. Walmart, Nike. A very happy customer.
                </Form.Text>
                <Form.Control
                  type='text'
                  value={currentCustomer.name}
                  onChange={(e) =>
                    setCurrentCustomer({
                      ...currentCustomer,
                      name: e.target.value,
                    })
                  }
                />
              </Form.Group>
              <Form.Group>
                <Form.Label>Buying Job Title(s)</Form.Label>
                <Form.Text>
                  e.g. CEO. Helps your agent link success stories to prospects.
                </Form.Text>
                <Form.Control
                  type='text'
                  value={currentCustomer.job_title_of_key_user}
                  onChange={(e) =>
                    setCurrentCustomer({
                      ...currentCustomer,
                      job_title_of_key_user: e.target.value,
                    })
                  }
                />
              </Form.Group>
              <Form.Group>
                <Form.Label>Why Do They Love The Product?</Form.Label>
                <Form.Text>
                  The success story that your agents will tell.
                </Form.Text>
                <Form.Control
                  as='textarea'
                  value={currentCustomer.why_they_love}
                  onChange={(e) =>
                    setCurrentCustomer({
                      ...currentCustomer,
                      why_they_love: e.target.value,
                    })
                  }
                />
              </Form.Group>
            </div>
          </DreamModalInner.Body>
          <DreamModalInner.Footer>
            <Button onClick={handleSaveCustomer}>
              {currentCustomer.id ? 'Save Changes' : 'Add Customer'}
            </Button>
            <Button
              onClick={() => setShowModal(false)}
              variant='outline-primary'
            >
              Cancel
            </Button>
          </DreamModalInner.Footer>
        </DreamModalInner>
      </DreamSideModal>

      <ConfirmModal
        show={showConfirmModal}
        handleClose={() => setShowConfirmModal(false)}
        handleConfirm={confirmDeleteCustomer}
        title='Confirm Deletion'
        bodyText='Are you sure you want to delete this customer?'
      />
    </div>
  );
};

export default ExistingCustomers;
