import React, { useState } from 'react';
import { Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import DreamSideModal from '../misc/DreamSideModal';
import DreamModalInner from '../misc/DreamModalInner';
import { ReactComponent as ResearchPointIcon } from '../../icons/research-point.svg';
import { ReactComponent as BoltIcon } from '../../icons/bolt.svg';

import './funnel.css';

function ResearchPointChooseModal({ onChoose, show, handleClose }) {
  const [selected, setSelected] = useState(null);
  return (
    <DreamSideModal show={show} handleClose={handleClose} position='right'>
      <DreamModalInner>
        <DreamModalInner.Header>
          <div className='red-icon'>
            <ResearchPointIcon />
          </div>{' '}
          Add Research
        </DreamModalInner.Header>
        <DreamModalInner.Body>
          <div className='dream-side-modal-form research-point-choose-modal'>
            <label>What kind of research do you want to add?</label>
            <div className='selection-set'>
              <div
                className={`selector ${selected === 'datapoint' && 'selected'}`}
                onClick={() => setSelected('datapoint')}
              >
                <ResearchPointIcon />
                <h6>Datapoint</h6>
                <p>
                  Datapoints are used to filter companies in your campaigns, and
                  to add deep customisation to your messaging.
                </p>
                <p>
                  <b>Best for:</b>
                  <br />
                  When you need details about companies to help decide if they
                  should be included in a campaign.
                </p>
              </div>
              <div
                className={`selector ${selected === 'campaign_trigger' && 'selected'}`}
                onClick={() => setSelected('campaign_trigger')}
              >
                <BoltIcon />
                <h6>Campaign Trigger</h6>
                <p>
                  Campaign Triggers are used to determine when a message should
                  be sent to a prospect. They’re checked every month.
                </p>
                <p>
                  <b>Best for:</b>
                  <br />
                  When you want to know if something has happened so you can
                  decide whether to send a message.
                </p>
              </div>
            </div>
          </div>
        </DreamModalInner.Body>
        <DreamModalInner.Footer>
          <Button
            variant='primary'
            disabled={!selected}
            className='yellow-icon'
            onClick={() => {
              onChoose(selected);
            }}
          >
            Next
            <FontAwesomeIcon icon={faArrowRight} />
          </Button>
          <Button onClick={handleClose} variant='outline-primary'>
            Close
          </Button>
        </DreamModalInner.Footer>
      </DreamModalInner>
    </DreamSideModal>
  );
}

export default ResearchPointChooseModal;
