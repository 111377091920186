import React from 'react';
import './app-loading.css';

function AppLoading() {
  return (
    <main className='dream-loading-main'>
      <svg
        xmlns='http://www.w3.org/2000/svg'
        width='1111'
        height='1110'
        fill='none'
      >
        <circle
          className='circ1'
          cx='555.533'
          cy='554.934'
          r='370.747'
          stroke='url(#a)'
          strokeOpacity='.8'
        />
        <circle
          className='circ2'
          cx='555.533'
          cy='554.933'
          r='554.3'
          stroke='url(#b)'
          strokeOpacity='.8'
        />
        <circle
          className='circ3'
          cx='555.533'
          cy='554.933'
          r='279.66'
          stroke='url(#c)'
          strokeOpacity='.8'
        />
        <defs>
          <linearGradient
            id='a'
            x1='555.533'
            x2='555.533'
            y1='183.687'
            y2='926.18'
            gradientUnits='userSpaceOnUse'
          >
            <stop stopColor='#FFD601' />
            <stop offset='1' stopColor='#000D26' />
          </linearGradient>
          <linearGradient
            id='b'
            x1='555.533'
            x2='555.533'
            y1='.133'
            y2='1109.73'
            gradientUnits='userSpaceOnUse'
          >
            <stop stopColor='#FFD601' />
            <stop offset='1' stopColor='#000D26' />
          </linearGradient>
          <linearGradient
            id='c'
            x1='555.533'
            x2='555.533'
            y1='274.773'
            y2='835.093'
            gradientUnits='userSpaceOnUse'
          >
            <stop stopColor='#FFD601' />
            <stop offset='1' stopColor='#000D26' />
          </linearGradient>
        </defs>
      </svg>

      <span>Loading</span>
    </main>
  );
}

export default AppLoading;
