import React, { useState, useEffect } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import api from '../../../api';
import { Button } from 'react-bootstrap';
import { ReactComponent as BulbIcon } from '../../../icons/bulb.svg';
import { ReactComponent as CheckCircleIcon } from '../../../icons/check-circle-filled.svg';

import EditEmailResearchPointModal from './EditEmailResearchPointModal';

const EmailResearchPoints = ({ oagentId, emailId }) => {
  const { getAccessTokenSilently } = useAuth0();
  const [loading, setLoading] = useState(true);
  const [researchPoints, setResearchPoints] = useState([]);
  const [additionalDataPoints, setAdditionalDataPoints] = useState([]);
  const [activeResearchPoints, setActiveResearchPoints] = useState([]);
  const [activeAdditionalData, setActiveAdditionalData] = useState([]);
  const [selectedDataPoint, setSelectedDataPoint] = useState(null);
  const [showModal, setShowModal] = useState(false);

  const fetchData = async () => {
    try {
      const token = await getAccessTokenSilently();
      const [
        compatibleResponse,
        activeResearchResponse,
        additionalDataResponse,
        activeAdditionalDataResponse,
      ] = await Promise.all([
        api.get(`/analyst/compatible-research-points/${oagentId}`, {
          headers: { Authorization: `Bearer ${token}` },
        }),
        api.get(`/oagents/${oagentId}/email-research-points/${emailId}`, {
          headers: { Authorization: `Bearer ${token}` },
        }),
        api.get(`/oagents/${oagentId}/additional-data-options`, {
          headers: { Authorization: `Bearer ${token}` },
        }),
        api.get(`/oagents/${oagentId}/email-additional-data/${emailId}`, {
          headers: { Authorization: `Bearer ${token}` },
        }),
      ]);
      setResearchPoints(compatibleResponse.data);
      setAdditionalDataPoints(additionalDataResponse.data.uniqueKeys);
      setActiveResearchPoints(activeResearchResponse.data);
      setActiveAdditionalData(activeAdditionalDataResponse.data);
    } catch (error) {
      console.error('Error fetching data points:', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, [oagentId, getAccessTokenSilently, emailId]);

  const handleDataPointClick = (dataPoint, isAdditionalData = false) => {
    setSelectedDataPoint({ ...dataPoint, isAdditionalData });
    setShowModal(true);
  };

  return (
    <div className='email-research-points'>
      <div className='header'>
        <h6>Your datapoints</h6>
        <p>
          Where relevant, your datapoints and additional data points are used to
          personalise emails. Select a datapoint below to edit how it’s used.
        </p>
      </div>
      <div className='research-points-list'>
        {additionalDataPoints.map((adp, index) => {
          const isActive = activeAdditionalData.some(
            (activeAdp) => activeAdp.key === adp,
          );
          return (
            <Button
              key={adp}
              className={`research-point-button ${isActive ? 'active' : ''}`}
              onClick={() => handleDataPointClick({ name: adp }, true)}
            >
              {isActive ? <CheckCircleIcon /> : <BulbIcon />}
              {adp}
            </Button>
          );
        })}
        {researchPoints.map((rp) => {
          const isActive = activeResearchPoints.some(
            (activeRp) => activeRp.research_point_id === rp.id,
          );
          return (
            <Button
              key={rp.id}
              className={`research-point-button ${isActive ? 'active' : ''}`}
              onClick={() => handleDataPointClick(rp)}
            >
              {isActive ? <CheckCircleIcon /> : <BulbIcon />}
              {rp.name}
            </Button>
          );
        })}
        {loading && (
          <>
            <div className='fake-datapoint'></div>
            <div className='fake-datapoint'></div>
            <div className='fake-datapoint'></div>
            <div className='fake-datapoint'></div>
            <div className='fake-datapoint'></div>
          </>
        )}
      </div>
      {selectedDataPoint && (
        <EditEmailResearchPointModal
          show={showModal}
          onClose={() => {
            setShowModal(false);
            fetchData();
          }}
          emailId={emailId}
          researchPoint={{
            ...selectedDataPoint,
            ...((selectedDataPoint.isAdditionalData
              ? activeAdditionalData.find(
                  (x) => x.key === selectedDataPoint.name,
                )
              : activeResearchPoints.find(
                  (x) => x.research_point_id === selectedDataPoint.id,
                )) || {}),
          }}
          oagentId={oagentId}
        />
      )}
    </div>
  );
};

export default EmailResearchPoints;
