import React, { useState, useEffect } from 'react';
import { Button, FormControl, Spinner, Form } from 'react-bootstrap';
import DataTable from 'react-data-table-component';
import api from '../../api';
import { useAuth0 } from '@auth0/auth0-react';
import { useNavigate } from 'react-router-dom';
import useDebounce from '../../utils/useDebounce';
import { ReactComponent as LinkedInIcon } from '../../icons/linkedin.svg';
import AnalystAnswer from './AnalystAnswer'; // Import the AnalystAnswer component
import FakePic from '../feed/items/FakePic';

const KnowledgeBase = () => {
  const { getAccessTokenSilently } = useAuth0();
  const [knownOrgs, setKnownOrgs] = useState([]);
  const [totalRows, setTotalRows] = useState(0);
  const [perPage, setPerPage] = useState(10);
  const [page, setPage] = useState(1);
  const [searchTerm, setSearchTerm] = useState('');
  const [loading, setLoading] = useState(false);
  const [sortField, setSortField] = useState('name');
  const [sortOrder, setSortOrder] = useState('asc');
  const [question, setQuestion] = useState('');
  const [isAskingQuestion, setIsAskingQuestion] = useState(false); // New state to track if we are asking a question

  const navigate = useNavigate();

  const debouncedSearchTerm = useDebounce(searchTerm, 500);

  const loadEntities = async (page, perPage, search, sortField, sortOrder) => {
    setLoading(true);
    try {
      const token = await getAccessTokenSilently();
      const response = await api.get('/analyst/known-orgs', {
        headers: { Authorization: `Bearer ${token}` },
        params: {
          page,
          pageSize: perPage,
          search,
          sortField,
          sortOrder,
        },
      });
      setKnownOrgs(response.data.knownOrgs);
      setTotalRows(response.data.totalItems);
    } catch (error) {
      console.error('Error fetching entities:', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    loadEntities(page, perPage, debouncedSearchTerm, sortField, sortOrder);
  }, [page, perPage, debouncedSearchTerm, sortField, sortOrder]);

  const handlePageChange = (page) => setPage(page);

  const handlePerRowsChange = (newPerPage, page) => {
    setPerPage(newPerPage);
    setPage(page);
  };

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const handleSort = (column, sortDirection) => {
    setSortField(column.id);
    setSortOrder(sortDirection);
  };

  const handleAskQuestion = () => {
    setIsAskingQuestion(true); // Set state to show AnalystAnswer component
  };

  const columns = [
    {
      name: '',
      width: '50px',
      cell: (row) =>
        row && row.linkedin_url ? (
          <a
            className='linkedin-icon'
            href={row.linkedin_url}
            target='_blank'
            rel='noreferrer'
          >
            <FakePic url={row.logo_url} type='org' />
          </a>
        ) : (
          '-'
        ),
      id: 'linkedin_url',
    },
    {
      name: 'Name',
      selector: (row) => row.name,
      sortable: true,
      id: 'name',
      cell: (row) => <span>{row.name}</span>,
    },
    {
      name: 'Domain',
      selector: (row) => row.domain,
      sortable: true,
      id: 'domain',
    },
  ];

  return (
    <div className='knowledge-base'>
      {isAskingQuestion ? (
        <AnalystAnswer
          question={question}
          handleBack={() => setIsAskingQuestion(false)}
        />
      ) : (
        <>
          <Form.Group className='ask-question-group'>
            <Form.Label>
              Ask a question about companies in your knowledge base
            </Form.Label>
            <Form.Control
              size='lg'
              type='text'
              value={question}
              onChange={(e) => setQuestion(e.target.value)}
            />
            {question && (
              <Button
                variant='primary'
                className='mt-3'
                onClick={handleAskQuestion}
              >
                Ask Question
              </Button>
            )}
          </Form.Group>

          <div>
            <div className='found-line'>
              <h4>
                {searchTerm ? (
                  <>
                    Your search matched <b>{totalRows}</b> companies
                  </>
                ) : (
                  <>
                    Your analyst has information on <b>{totalRows}</b> companies
                  </>
                )}
              </h4>
              <div className='search-input-wrapper'>
                <FormControl
                  placeholder='Search Companies...'
                  onChange={handleSearchChange}
                  value={searchTerm}
                  size='lg'
                />
              </div>
            </div>

            <div className={`dream-table-wrapper ${loading ? 'loading' : ''}`}>
              <DataTable
                columns={columns}
                data={knownOrgs}
                progressPending={loading}
                progressComponent={
                  <div className='dream-table-loader'>
                    <Spinner animation='border' />
                  </div>
                }
                pagination
                paginationServer
                paginationTotalRows={totalRows}
                onChangeRowsPerPage={handlePerRowsChange}
                onChangePage={handlePageChange}
                onSort={handleSort}
                sortServer
                defaultSortFieldId={sortField}
                defaultSortAsc={sortOrder === 'asc'}
                pointerOnHover
                onRowClicked={(row) => navigate(`/analyst/${row.id}`)}
                highlightOnHover
              />
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default KnowledgeBase;
