import React from 'react';
import { Modal, Button } from 'react-bootstrap';
import Confetti from 'react-confetti';
import { useWindowSize } from 'react-use';
import { ReactComponent as TrophyIcon } from '../../icons/trophy.svg';
import './CongratsModal.css';

const CongratsModal = ({ show, onHide, header, children }) => {
  const { width, height } = useWindowSize();

  return (
    <>
      {show && (
        <Confetti
          width={width}
          height={height}
          numberOfPieces={4500}
          recycle={false}
        />
      )}
      <Modal
        show={show}
        onHide={onHide}
        centered
        contentClassName='congrats-modal'
      >
        <Modal.Body>
          <TrophyIcon />
          {header && <h4>{header}</h4>}
          <div>{children}</div>
          <div className='button-row'>
            <Button variant='link' onClick={onHide}>
              Ok, close
            </Button>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default CongratsModal;
