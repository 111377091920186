// manifests/company_filter.js
import React from 'react';
import { ReactComponent as AgentFilterIcon } from '../../icons/agent-filter.svg';
const companyFilterManifest = [
  {
    name: 'No Filter',
    type: 'no_company_filter',
    description: 'No companies found will be filtered',
    hide_from_selection: true,
    credits_per: 0,
    linkedin_credits_per: 0,
    time_to_complete: 0,
  },
  {
    name: 'Agent Filter',
    icon: <AgentFilterIcon />,
    type: 'ai_filter',
    description:
      'Your agent will filter the company based on criteria you specify. This is very good if your filter is not strictly defined (for instance if you want to filter out small companies rather than companies strictly under 100 headcount).',
    additional_options: {
      ai_request: {
        description: 'How would you like your agent to filter?',
        input_type: 'textarea',
        long_description:
          'e.g. Filter out any companies that are multi-nationals',
      },
      ai_uncertain_behaviour: {
        description: "What should your agent do if it's uncertain?",
        input_type: 'select_as_radios',
        options: [
          { label: 'Filter company out', value: 'out' },
          { label: 'Keep company in', value: 'in' },
          { label: 'Use best guess', value: 'best-guess' },
        ],
      },
    },
    validate: ['ai_request'],
    tests: [
      {
        name: 'Test Agent Filter',
        description:
          'Use the form below to test whether or not the agent would filter out a given company. You can use any company name to test.',
        type: 'ai_filter_filters_out',
        inputs: [
          { name: 'company_name', description: 'Company Name', type: 'text' },
        ],
        responseFn: ({ filterOut, reason }) => (
          <>
            <p>Would be {filterOut ? 'filtered out' : 'kept'} because:</p>
            <p>
              <b>"{reason}"</b>
            </p>
          </>
        ),
      },
    ],
    display: {
      show: 'ai_request',
      how: 'text',
    },
    credits_per: 1,
    time_to_complete: 2,
  },
];

export default companyFilterManifest;
