import React, { useState, useEffect } from 'react';
import { Button, Spinner } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import DreamSideModal from '../misc/DreamSideModal';
import DreamModalInner from '../misc/DreamModalInner';
import { useAuth0 } from '@auth0/auth0-react';
import api from '../../api';
import { ReactComponent as ResearchPointIcon } from '../../icons/research-point.svg';
import ResearchPointModal from '../analyst/ResearchPointModal';
import DreamSideModalLoader from '../misc/DreamSideModalLoader';
import './funnel.css';

import GoogleIcon from '../../icons/google.png';
import LinkedInIcon from '../../icons/linkedin.png';
import OpenAIIcon from '../../icons/openai.png';
import CrunchbaseIcon from '../../icons/crunchbase.png';

const sourceIcons = {
  google: <img src={GoogleIcon} alt='Google icon' />,
  openai: <img src={OpenAIIcon} alt='OpenAI icon' />,
  linkedin: <img src={LinkedInIcon} alt='OpenAI icon' />,
  crunchbase: <img src={CrunchbaseIcon} alt='Crunchbase icon' />,
};

const sourceText = {
  google: 'Google',
  openai: 'LLMs',
  linkedin: 'LinkedIn',
  crunchbase: 'Crunchbase',
};

const ResearchPointSelectorModal = ({
  show,
  handleClose,
  onChoose,
  loading,
  initialResearchPoints = [],
  type,
}) => {
  const { getAccessTokenSilently } = useAuth0();
  const [researchPointsLoading, setResearchPointsLoading] = useState(false);
  const [researchPoints, setResearchPoints] = useState([]);
  const [selectedResearchPoints, setSelectedResearchPoints] = useState([]);
  const [showResearchPointModal, setShowResearchPointModal] = useState(false);

  const fetchData = async (toSelectId) => {
    try {
      setResearchPointsLoading(true);
      const token = await getAccessTokenSilently();
      const response = await api.get('/analyst/research-points', {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      const fetchedResearchPoints = response.data.filter(
        (dp) => dp.type === type,
      );
      setResearchPoints(fetchedResearchPoints);

      // Set initial selected datapoints from prop
      const initialSelected = fetchedResearchPoints.filter(
        (dp) =>
          initialResearchPoints.some((initial) => initial.id === dp.id) ||
          dp.id === toSelectId,
      );
      setSelectedResearchPoints(initialSelected);
      setResearchPointsLoading(false);
    } catch (error) {
      console.error('Error fetching research points:', error);
    }
  };

  useEffect(() => {
    if (show) {
      console.log('Going to fetch again');
      fetchData();
    }
  }, [show, initialResearchPoints, getAccessTokenSilently]);

  const handleSelect = (datapoint) => {
    setSelectedResearchPoints((prevSelected) => {
      if (prevSelected.includes(datapoint)) {
        return prevSelected.filter((dp) => dp !== datapoint);
      } else {
        return [...prevSelected, datapoint];
      }
    });
  };

  const handleChoose = () => {
    if (selectedResearchPoints.length > 0) {
      /*
        We use the below { remove, add } logic for optimistic updating
      */
      const initialIds = new Set(
        initialResearchPoints.map((point) => point.id),
      );
      const selectedIds = new Set(
        selectedResearchPoints.map((point) => point.id),
      );

      // Find items to remove: present in initial but not in selected
      const remove = initialResearchPoints.filter(
        (point) => !selectedIds.has(point.id),
      );

      // Find items to add: present in selected but not in initial
      const add = selectedResearchPoints.filter(
        (point) => !initialIds.has(point.id),
      );

      onChoose(selectedResearchPoints, { remove, add });
    }
  };

  const handleCreateNew = () => {
    setShowResearchPointModal(true);
  };

  return (
    <>
      <DreamSideModal show={show} handleClose={handleClose} position='right'>
        <DreamModalInner>
          {loading ? (
            <DreamSideModalLoader>{loading}</DreamSideModalLoader>
          ) : (
            <>
              <DreamModalInner.Header>
                Select {type === 'datapoint' ? 'Datapoint' : 'Campaign Trigger'}
              </DreamModalInner.Header>
              <DreamModalInner.Body>
                <div className='datapoint-selector-modal analyst'>
                  <div className='datapoints-wrapper'>
                    {researchPointsLoading && (
                      <div className='dp-loading-wrapper'>
                        <Spinner />
                      </div>
                    )}
                    {!researchPointsLoading && (
                      <>
                        {researchPoints.length > 0 ? (
                          <div className='datapoints-selection-wrapper'>
                            <label>
                              Your{' '}
                              {type === 'datapoint'
                                ? 'datapoints'
                                : 'campaign triggers'}
                            </label>
                            {researchPoints.map((datapoint) => (
                              <div
                                key={datapoint.id}
                                className={`datapoint-item ${selectedResearchPoints.includes(datapoint) ? 'active' : ''}`}
                                onClick={() => handleSelect(datapoint)}
                              >
                                <span>{datapoint.name}</span>
                                <span className='sources'>
                                  {datapoint?.sources?.map(
                                    (source) => sourceIcons[source],
                                  )}
                                </span>
                                <span className='credit-cost'>
                                  {datapoint?.credits > 0
                                    ? `${datapoint?.credits ? datapoint.credits.toFixed(2) : 0} credits per`
                                    : `No credits`}
                                </span>
                              </div>
                            ))}
                          </div>
                        ) : (
                          <div className='empty-state'>
                            <ResearchPointIcon />
                            <p>
                              You've not created any{' '}
                              {type === 'datapoint'
                                ? 'datapoints'
                                : 'campaign triggers'}
                            </p>
                            <Button onClick={handleCreateNew}>
                              Create new{' '}
                              {type === 'datapoint'
                                ? 'datapoint'
                                : 'campaign trigger'}{' '}
                              <FontAwesomeIcon icon={faPlus} />
                            </Button>
                          </div>
                        )}
                      </>
                    )}
                  </div>
                  {researchPoints.length > 0 && !researchPointsLoading && (
                    <div className='add-new-wrapper'>
                      <Button onClick={handleCreateNew}>
                        Create new{' '}
                        {type === 'datapoint'
                          ? 'datapoint'
                          : 'campaign trigger'}{' '}
                        <FontAwesomeIcon icon={faPlus} />
                      </Button>
                    </div>
                  )}
                </div>
              </DreamModalInner.Body>
              <DreamModalInner.Footer>
                <div className='analyst'>
                  <Button
                    variant='primary'
                    onClick={handleChoose}
                    disabled={selectedResearchPoints.length === 0}
                  >
                    Choose selected
                  </Button>
                </div>
                <Button variant='outline-primary' onClick={handleClose}>
                  Cancel
                </Button>
              </DreamModalInner.Footer>
            </>
          )}
        </DreamModalInner>
      </DreamSideModal>

      {showResearchPointModal && (
        <ResearchPointModal
          show={showResearchPointModal}
          handleClose={(id) => {
            setShowResearchPointModal(false);
            fetchData(id); // Refresh datapoints after closing the ResearchPointModal
          }}
          type={type}
        />
      )}
    </>
  );
};

export default ResearchPointSelectorModal;
