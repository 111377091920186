import React, { useState, useEffect } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { Alert, Spinner } from 'react-bootstrap';
import ProspectsSettings from './ProspectsSettings';
import ProspectsList from './ProspectsList';
import api from '../../../api';

function Prospects({ oagentId, advance, nextText, wizard }) {
  const { getAccessTokenSilently } = useAuth0();
  const [step, setStep] = useState('list');
  const [error, setError] = useState(null);
  const [hasNoProspects, setHasNoProspects] = useState(true);
  const [isInitialCheck, setIsInitialCheck] = useState(true);
  const [prospects, setProspects] = useState([]);

  useEffect(() => {
    checkProspects();
  }, [oagentId]);

  const checkProspects = async () => {
    try {
      const token = await getAccessTokenSilently();
      const response = await api.get(`/oagents/${oagentId}/prospects`, {
        headers: { Authorization: `Bearer ${token}` },
      });

      setProspects(response.data);

      if (response.data.length > 0) {
        setHasNoProspects(false);

        if (isInitialCheck) {
          setStep('list');
        }
      } else {
        setHasNoProspects(true);

        if (isInitialCheck) {
          setStep('settings');
        }
      }
    } catch (err) {
      console.error('Error fetching prospects:', err);
      setError('Error fetching prospects: ' + err.message);
    } finally {
      setIsInitialCheck(false);
    }
  };

  const handleAdvance = async () => {
    if (step === 'settings') {
      setStep('list');

      const token = await getAccessTokenSilently();
      const response = await api.get(`/oagents/${oagentId}/prospects`, {
        headers: { Authorization: `Bearer ${token}` },
      });

      await syncProspects(response.data.length === 0);
      checkProspects();
    } else {
      advance();
    }
  };

  const syncProspects = async (force) => {
    try {
      const token = await getAccessTokenSilently();
      await api.post(
        `/oagents/${oagentId}/wizard-prospect-sync`,
        { force },
        { headers: { Authorization: `Bearer ${token}` } },
      );
    } catch (err) {
      console.error('Error syncing prospects:', err);
      setError('Error syncing prospects: ' + err.message);
    }
  };

  const handleRetreat = () => {
    checkProspects();
    setStep('settings');
  };

  return (
    <div className='campaign-step-wrapper'>
      {error && <Alert variant='danger'>{error}</Alert>}
      {step === 'settings' ? (
        <ProspectsSettings
          oagentId={oagentId}
          wizard={wizard}
          nextFn={handleAdvance}
          exitWithoutSaving={!hasNoProspects ? () => setStep('list') : false}
        />
      ) : (
        <ProspectsList
          embedded
          oagentId={oagentId}
          nextFn={handleAdvance}
          nextText={nextText}
          openSettings={handleRetreat}
          prospects={prospects}
          setProspects={setProspects}
          isInitialCheck={isInitialCheck}
        />
      )}
    </div>
  );
}

export default Prospects;
