import React from 'react';
import { createButton } from 'react-simple-wysiwyg';
import { ReactComponent as BoldIcon } from '../../icons/bold.svg';
import { ReactComponent as ItalicIcon } from '../../icons/italic.svg';
import { ReactComponent as LinkIcon } from '../../icons/link-in-bar.svg';

export const BtnBold = createButton('Bold', <BoldIcon />, 'bold');
export const BtnItalic = createButton('Italic', <ItalicIcon />, 'italic');
export const BtnLink = createButton('Link', <LinkIcon />, ({ $selection }) => {
  if ($selection?.nodeName === 'A') {
    document.execCommand('unlink');
  } else {
    // eslint-disable-next-line no-alert
    document.execCommand('createLink', false, prompt('URL', '') || undefined);
  }
});
