import React, { useState, useEffect } from 'react';
import { Alert, Button, Spinner } from 'react-bootstrap';
import { useAuth0 } from '@auth0/auth0-react';
import api from '../../../api';
import { usePusher } from '../../../contexts/PusherContext';
import DataTable from 'react-data-table-component';
import { ReactComponent as TrashIcon } from '../../../icons/trash.svg';
import FakePic from '../../feed/items/FakePic';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';

function CompaniesList({
  oagentId,
  openSettings,
  nextFn,
  nextText,
  knownOrgs,
  setKnownOrgs,
  isInitialCheck,
}) {
  const { getAccessTokenSilently } = useAuth0();
  const pusherChannel = usePusher();

  const [runSummary, setRunSummary] = useState(null);
  const [error, setError] = useState(null); // New state for error handling
  const [selectedRows, setSelectedRows] = useState([]); // State to hold selected rows

  useEffect(() => {
    fetchAllData();

    pusherChannel.bind('oagent_status_update', (data) => {
      if (parseInt(data.oagentId, 10) === parseInt(oagentId, 10)) {
        if (data.sub_type === 'new_known_org') {
          setKnownOrgs((prevOrgs) => {
            if (!prevOrgs.find((x) => x.id === data.known_org.id)) {
              return [...prevOrgs, data.known_org];
            }
            return prevOrgs;
          });
        } else if (data.sub_type === 'import_run_update') {
          setRunSummary(data.company_summary);
        }
      }
    });

    return () => {
      pusherChannel.unbind('oagent_status_update');
    };
  }, [oagentId]);

  const fetchAllData = async () => {
    setError(null); // Reset error before fetching new data
    try {
      const token = await getAccessTokenSilently();
      const [runSummaryResponse] = await Promise.all([
        api.get(`/oagents/${oagentId}/import-run-summary/companies`, {
          headers: { Authorization: `Bearer ${token}` },
        }),
      ]);

      setRunSummary(runSummaryResponse.data);
    } catch (error) {
      console.error('Error fetching data:', error);
      setError('Error fetching data.');
    }
  };

  const handleResync = async () => {
    setError(null); // Reset error before resynchronizing
    try {
      const token = await getAccessTokenSilently();
      await api.post(
        `/oagents/${oagentId}/wizard-company-sync`,
        { force: true },
        {
          headers: { Authorization: `Bearer ${token}` },
        },
      );
      fetchAllData();
    } catch (error) {
      console.error('Error during resync:', error);
      setError('Error during resync.');
    }
  };

  const handleDelete = async () => {
    setError(null); // Reset error before deleting
    try {
      const token = await getAccessTokenSilently();
      const idsToDelete = selectedRows.map((row) => row.id);
      setKnownOrgs(knownOrgs.filter((org) => !idsToDelete.includes(org.id)));
      setSelectedRows([]); // Clear the selection

      await api.post(
        `/oagents/${oagentId}/deactivate-companies`,
        { ids: idsToDelete },
        {
          headers: { Authorization: `Bearer ${token}` },
        },
      );
      // Optimistically remove the selected rows
    } catch (error) {
      console.error('Error during delete:', error);
      setError('Error during delete.');
    }
  };

  const processingOrWaiting =
    runSummary &&
    runSummary.status_counts
      .filter(
        (item) =>
          item.status === 'processing' ||
          item.status === 'waiting' ||
          item.status === 'retry',
      )
      .reduce((sum, item) => sum + parseInt(item.count, 10), 0);

  const columns = [
    {
      name: '',
      cell: (row) => {
        return row.linkedin_url ? (
          <a href={row.linkedin_url} target='_blank' rel='noopener noreferrer'>
            <FakePic type='org' url={row.logo_url} />
          </a>
        ) : (
          <FakePic type='org' url={row.logo_url} />
        );
      },
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
      width: '50px',
    },
    {
      name: 'Name',
      selector: (row) => row.name,
      sortable: true,
      width: '200px',
    },
    {
      name: 'Matched datapoints',
      selector: (row) => <div className='text-wrap'>{row.reason}</div>,
      sortable: true,
    },
  ];

  const handleRowSelected = (state) => {
    setSelectedRows(state.selectedRows);
  };

  const empty = {
    logo_url: null, // Trigger fakepic
    linkedin_url: null,
    name: <div className='fake-placeholder'></div>,
    reason: <div className='fake-placeholder reason'></div>,
  };
  const placeholders = Array(5).fill(empty);

  return (
    <div className='ocl-wrapper'>
      <div className='embedded-list-header'>
        <div className='left'>
          <h5>Company list</h5>
          {openSettings && (
            <Button variant='outline-primary' onClick={openSettings}>
              Edit lists & research
            </Button>
          )}
          {selectedRows.length > 0 && (
            <Button
              variant='outline'
              onClick={handleDelete}
              className='table-delete-button'
            >
              <TrashIcon />
              Delete Selected
            </Button>
          )}
        </div>
        {nextFn && nextText && (
          <div className='right'>
            <Button variant='primary' onClick={nextFn}>
              Next: {nextText} <FontAwesomeIcon icon={faArrowRight} />
            </Button>
          </div>
        )}
      </div>
      <div
        className={`alert-wrapper ${processingOrWaiting > 0 ? 'visible' : 'hidden'}`}
      >
        <Alert variant='info'>
          <Spinner size='sm' />
          <span>
            We're checking <b>{processingOrWaiting}</b> companies on your lists
            to see if they match.{' '}
          </span>
        </Alert>
      </div>
      {error && <Alert variant='danger'>{error}</Alert>}

      <div className='dream-table-wrapper'>
        <DataTable
          columns={columns}
          data={!isInitialCheck ? knownOrgs : placeholders}
          pagination
          selectableRows
          onSelectedRowsChange={handleRowSelected}
          selectAllContextualLabel='Select All/De-select All'
        />
      </div>
    </div>
  );
}

export default CompaniesList;
