import React from 'react';
import BaseType from './BaseType';

const ProspectAdded = ({ item }) => {
  return (
    <BaseType
      url={item.metadata?.known_org?.logo_url}
      name={item.metadata?.known_org?.name}
      tagType='neutral'
      tagText={
        <>
          First email sent to <b>{item?.metadata?.prospect?.name}</b>
        </>
      }
      tagTo={`/prospect/${item?.metadata?.prospect?.id}`}
      time={item.time}
      conversationId={item.id}
      idIsScheduledEmail
    />
  );
};

export default ProspectAdded;
