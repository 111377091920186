import React, { useEffect, useState } from 'react';
import './campaign-new.css';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faArrowLeft,
  faPause,
  faPlay,
} from '@fortawesome/free-solid-svg-icons';
import { Button, Spinner } from 'react-bootstrap';
import { useAuth0 } from '@auth0/auth0-react';
import api from '../../api';

import { ReactComponent as ProspectIcon } from '../../icons/prospect.svg';
import { ReactComponent as OrgFakeIcon } from '../../icons/org-fake.svg';
import { ReactComponent as SpeechBubbleIcon } from '../../icons/speech-bubble.svg';
import { ReactComponent as SettingsIcon } from '../../icons/settings.svg';

import TaskList from '../tasks/TaskList';
import Inbox from './Inbox';
import { usePusher } from '../../contexts/PusherContext';
import OutboundQueueBanner from './OutboundQueueBanner';

function Campaign() {
  const { id } = useParams(); // Extract step from URL parameters
  const { getAccessTokenSilently } = useAuth0();
  const pusherChannel = usePusher();

  const [oagent, setOagent] = useState(null);
  const [initialLoading, setInitialLoading] = useState(true);
  const [autopilotLoading, setAutopilotLoading] = useState(false);

  const navigate = useNavigate();

  const playOrPause = async () => {
    try {
      setAutopilotLoading(true);
      const token = await getAccessTokenSilently();
      await api.put(
        `/oagents/${id}/set-autopilot/${oagent?.autopilot ? 'off' : 'on'}`,
        {},
        {
          headers: { Authorization: `Bearer ${token}` },
        },
      );
      setOagent((oagent) => ({
        ...oagent,
        autopilot: !oagent?.autopilot,
      }));
    } catch (e) {
      console.error(`Unable to update autopilot`, e);
    } finally {
      setAutopilotLoading(false);
    }
  };

  const fetchOagent = async (isInitialLoading = false) => {
    try {
      if (isInitialLoading) setInitialLoading(true);

      const token = await getAccessTokenSilently();

      // Fetch oagent details
      const [oagentResponse] = await Promise.all([
        api.get(`/oagents/${id}`, {
          headers: { Authorization: `Bearer ${token}` },
        }),
      ]);

      const oagentData = oagentResponse.data;

      setOagent(oagentData);

      if (oagentData.draft) {
        navigate('/campaign-wizard/' + oagentData.id);
      }

      if (isInitialLoading) setInitialLoading(false);
    } catch (error) {
      console.error('Error fetching oagent or campaign stats:', error);
      if (isInitialLoading) setInitialLoading(false);
    }
  };

  useEffect(() => {
    if (!pusherChannel || !id) return;
    pusherChannel.bind('oagent_status_update', (data) => {
      if (parseInt(data.oagentId, 10) === parseInt(id, 10)) {
        fetchOagent();
      }
    });

    return () => {
      pusherChannel.unbind('oagent_status_update');
    };
  }, [id, pusherChannel, id]);

  useEffect(() => {
    fetchOagent(true);
  }, [id, getAccessTokenSilently]);

  return (
    <div className='page-wrapper'>
      <div
        className={`page-inner-wrapper campaign-view ${initialLoading ? 'first-loading' : ''}`}
      >
        <div className='page-header-area'>
          <div className='title-and-text'>
            <div className='breadcrumbs'>
              <div className='set'>
                <Link to='/campaigns'>
                  <FontAwesomeIcon icon={faArrowLeft} /> Back to All Campaigns
                </Link>
              </div>
            </div>
            <h3>
              <b>Campaign:</b> {oagent?.name}
            </h3>
          </div>
          <div className='buttons-area autopilot'>
            <Button
              variant='primary'
              onClick={playOrPause}
              disabled={autopilotLoading}
            >
              {!autopilotLoading && (
                <>
                  {!oagent?.autopilot ? (
                    <>
                      Start Autopilot <FontAwesomeIcon icon={faPlay} />
                    </>
                  ) : (
                    <>
                      Pause Autopilot <FontAwesomeIcon icon={faPause} />
                    </>
                  )}
                </>
              )}
              {autopilotLoading && (
                <>
                  {oagent?.autopilot ? 'Disabling' : 'Enabling'}{' '}
                  <Spinner size='sm' />
                </>
              )}
            </Button>
          </div>
        </div>

        <div className='campaign-view-lower-area'>
          <div className='outbound-banner'>
            <OutboundQueueBanner
              oagentId={id}
              autopilotStatus={oagent?.autopilot}
            />
          </div>
          <div className='actions-row'>
            <div className='buttons-left'>
              <Button
                variant='outline-primary'
                onClick={() => navigate(`/campaign-companies/${oagent.id}`)}
              >
                <OrgFakeIcon />
                Companies
              </Button>
              <Button
                variant='outline-primary'
                onClick={() => navigate(`/campaign-prospects/${oagent?.id}`)}
              >
                <ProspectIcon />
                Prospects
              </Button>
              <Button
                variant='outline-primary'
                onClick={() => navigate(`/campaign/${oagent?.id}/messaging`)}
              >
                <SpeechBubbleIcon />
                Messaging
              </Button>
            </div>
            <div className='settings-right'>
              <div onClick={() => navigate(`/campaign-settings/${oagent?.id}`)}>
                <SettingsIcon />
                Settings
              </div>
            </div>
          </div>

          <div className='main-area'>
            <div className='inbox-wrapper'>
              <Inbox campaignId={id} />
            </div>
            <div className='task-list-wrapper'>
              <TaskList campaignId={id} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Campaign;
