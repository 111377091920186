import React, { useState, useEffect, useCallback } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import api from '../../../api';
import EditableFieldWithMatches from './EditableFieldWithMatches';
import CampaignWizardStepHeader from './CampaignWizardStepHeader.js';
import Settings from './Settings.js';

const Purpose = ({ oagentId, advance, nextText }) => {
  const [data, setData] = useState({
    use_case_description: '',
    product_context: '',
    match_logic: [],
  });
  const { getAccessTokenSilently } = useAuth0();

  const fetchInstructions = useCallback(async () => {
    try {
      const token = await getAccessTokenSilently();
      const response = await api.get(`/oagents/${oagentId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setData(response.data);
    } catch (error) {
      console.error('Error fetching instructions:', error);
    }
  }, [oagentId, getAccessTokenSilently]);

  const saveInstructions = async (updates, newMatches) => {
    const previousData = { ...data };
    const newData = { ...data, ...updates };

    if (newMatches) {
      newData.match_logic = newMatches;
    }

    setData(newData);

    try {
      const token = await getAccessTokenSilently();
      await api.put(`/oagents/${oagentId}`, newData, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
    } catch (error) {
      console.error('Error saving instructions:', error);
      alert('Error saving instructions');
      setData(previousData); // Revert to previous state
    }
  };

  useEffect(() => {
    fetchInstructions();
  }, [fetchInstructions]);

  return (
    <div className='campaign-step-wrapper'>
      <CampaignWizardStepHeader
        heading='Campaign Settings'
        nextText={nextText}
        advance={advance}
      />
      <div className='campaign-purpose'>
        <div className='editable-fields'>
          <EditableFieldWithMatches
            label='Campaign purpose'
            text={data.use_case_description}
            useMatches={true}
            onSave={(newValue, matches) =>
              saveInstructions({ use_case_description: newValue }, matches)
            }
            matches={data.match_logic}
            description="Having a clear purpose will help your agent achieve it's goals."
            placeholder='e.g. This agent is going to book meetings with new TurboTax customers.'
          />
          <EditableFieldWithMatches
            label='Key Variations'
            text={data.key_variations}
            useMatches={false}
            description='If your agent needs to bear in mind anything different vs what it knows about your company and products.'
            placeholder='e.g. This is a new market and we want to emphasise the .'
            onSave={(newValue) =>
              saveInstructions({ key_variations: newValue })
            }
          />
          <Settings oagentId={oagentId} />
        </div>
      </div>
    </div>
  );
};

export default Purpose;
