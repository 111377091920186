import React, { useState, useEffect } from 'react';
import api from '../../api';
import { useAuth0 } from '@auth0/auth0-react';
import { Container, Alert, Button, Spinner, Modal } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import currencyFormatter from 'currency-formatter';
import { loadStripe } from '@stripe/stripe-js';
import '../../index.scss';
import './settings.css';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);

const Subscription = () => {
  const { getAccessTokenSilently } = useAuth0();
  const [subscriptionData, setSubscriptionData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [checkoutLoading, setCheckoutLoading] = useState(false);
  const [error, setError] = useState(null);
  const [currency, setCurrency] = useState('usd');

  const fetchSubscriptionDetails = async () => {
    setLoading(true);
    try {
      const token = await getAccessTokenSilently();
      const response = await api.get('/subscription-details', {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setSubscriptionData(response.data);
      setLoading(false);
    } catch (error) {
      setError('Error fetching subscription details.');
      setLoading(false);
    }
  };

  useEffect(() => {
    setCheckoutLoading(false);
  }, [subscriptionData]);

  useEffect(() => {
    fetchSubscriptionDetails();
  }, [getAccessTokenSilently]);

  if (loading) {
    return (
      <div className='subscription-loading'>
        <Spinner animation='border' />
      </div>
    );
  }

  const isFreeTier = !subscriptionData.current_subscription_product;

  const sortedSubscriptionProducts = [
    ...subscriptionData.subscription_products,
  ].sort((a, b) => a.metadata.show_order - b.metadata.show_order);

  const sortedBoosterProducts = [...subscriptionData.booster_products].sort(
    (a, b) => a.metadata.show_order - b.metadata.show_order,
  );

  // Helper function to get the correct price
  const getPrice = (prices) => {
    const currencyPrices = prices.filter(
      (price) => price.currency === currency,
    );
    const fallbackPrices = prices.filter((price) => price.currency === 'usd');
    const highestCurrencyPrice = currencyPrices.length
      ? Math.max(...currencyPrices.map((price) => price.unit_amount))
      : null;
    const highestFallbackPrice = fallbackPrices.length
      ? Math.max(...fallbackPrices.map((price) => price.unit_amount))
      : null;
    const chosenPrice = highestCurrencyPrice ?? highestFallbackPrice ?? 0;
    return {
      formattedPrice: currencyFormatter.format(chosenPrice / 100, {
        code: currency.toUpperCase(),
      }),
      priceId: highestCurrencyPrice
        ? currencyPrices.find(
            (price) => price.unit_amount === highestCurrencyPrice,
          ).id
        : fallbackPrices.find(
            (price) => price.unit_amount === highestFallbackPrice,
          ).id,
    };
  };

  const handleCheckout = async (product_id, price_id, mode, type) => {
    if (!subscriptionData.can_write_subscription) {
      alert(
        'You must be an account administrator to adjust the subscription tier or purchase additional products',
      );
      return;
    }

    const confirmMessage =
      'You are about to downgrade. You will have a pro-rata share of already paid funds applied to your forward account balance. You will further have a new credit balance which is relative to what remains of your subscription period';
    if (type === 'downgrade' && !confirm(confirmMessage)) {
      return;
    }

    setCheckoutLoading(true);
    try {
      const token = await getAccessTokenSilently();
      const response = await api.post(
        '/create-checkout-session',
        {
          product_id,
          price_id,
          mode,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      );

      if (response.data.id) {
        const stripe = await stripePromise;
        await stripe.redirectToCheckout({ sessionId: response.data.id });
      } else {
        // Fetch updated subscription details
        fetchSubscriptionDetails();
      }
    } catch (err) {
      if (err === 'NO_PAYMENT_METHOD') {
        setError(
          'You have no stored payment method set to "Default" and so the change failed. Go to "Billing" to select a payment method as "Default".',
        );
      } else {
        setError('Error during checkout. Please try again.');
      }
      setCheckoutLoading(false);
    }
  };

  return (
    <div className='subscription-container'>
      {checkoutLoading && (
        <div className='overlay'>
          <Spinner animation='border' />
        </div>
      )}
      <div className='section'>
        <h5>Your subscription</h5>
        <div className='subscription-details-wrapper'>
          <div className='inner'>
            <div className='info-pair'>
              <p className='label'>Current Tier</p>
              <p className='value'>
                {subscriptionData.current_subscription_product
                  ? subscriptionData.current_subscription_product.name
                  : 'Free'}
              </p>
            </div>
            <div className='info-pair'>
              <p className='label'>Credits Remaining</p>
              <p className='value'>
                {Math.round(
                  subscriptionData.credits_remaining_from_subscription,
                )}
              </p>
            </div>
            <div className='info-pair'>
              <p className='label'>Renewal Date</p>
              <p className='value'>
                {new Date(
                  subscriptionData.credit_renewal_date,
                ).toLocaleDateString()}
              </p>
            </div>
          </div>
        </div>
        {!isFreeTier && (
          <p className='cancel-line'>
            To cancel at any time simply visit{' '}
            <Link to={process.env.REACT_APP_STRIPE_BILLING_URL}>billing</Link>{' '}
            and cancel your subscription.
          </p>
        )}
      </div>
      <div className='section'>
        {error && <Alert variant='danger'>{error}</Alert>}
        {isFreeTier ? (
          <div className='products-outer-wrapper'>
            <h5>Subscribe Now</h5>
            <p className='reverse'>
              Join great companies around the world building their dream
              pipeline today. Pick a subscription level below.
            </p>
            <div className='subscription-products'>
              {sortedSubscriptionProducts.map((product) => {
                const { formattedPrice, priceId } = getPrice(product.prices);
                return (
                  <div className='product' key={product.id}>
                    <div className='heading'>
                      <h3>{product.name}</h3>
                      <p>{formattedPrice} / month</p>
                    </div>
                    <p className='credits-view'>
                      <b>
                        {product.metadata.credits_granted
                          .toString()
                          .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                      </b>{' '}
                      Monthly Credits
                    </p>
                    <hr />
                    <p className='best-for'>{product.metadata.enticement}</p>
                    <div className='button-wrapper'>
                      <Button
                        variant='outline-primary'
                        onClick={() =>
                          handleCheckout(product.id, priceId, 'subscription')
                        }
                      >
                        Subscribe
                      </Button>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        ) : (
          <div className='products-outer-wrapper'>
            <h5>Upgrade Your Subscription</h5>
            <div className='subscription-products'>
              {sortedSubscriptionProducts.map((product) => {
                const isActive =
                  product.id ===
                  subscriptionData.current_subscription_product?.id;
                const isUpgrade =
                  product.metadata.show_order >
                  subscriptionData.current_subscription_product?.metadata
                    .show_order;
                const { formattedPrice, priceId } = getPrice(product.prices);
                return (
                  <div
                    className={`product ${isActive ? 'active-subscription' : ''}`}
                    key={product.id}
                  >
                    <div className='heading'>
                      <h3>{product.name}</h3>
                      <p>{formattedPrice} / month</p>
                    </div>
                    <p className='credits-view'>
                      <b>
                        {product.metadata.credits_granted
                          .toString()
                          .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                      </b>{' '}
                      Monthly Credits
                    </p>
                    <hr />
                    <p className='best-for'>{product.metadata.enticement}</p>
                    <div className='button-wrapper'>
                      {isActive ? (
                        <Button className='current-plan'>Current Plan</Button>
                      ) : (
                        <Button
                          variant='outline-primary'
                          onClick={() =>
                            handleCheckout(
                              product.id,
                              priceId,
                              'subscription',
                              !isUpgrade ? 'downgrade' : 'upgrade',
                            )
                          }
                        >
                          {isUpgrade ? 'Upgrade' : 'Downgrade'}
                        </Button>
                      )}
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        )}
        <p className='helpful-call-team'>
          If you have any questions then book a call with{' '}
          <a
            href='https://calendly.com/ben-bwestephenson'
            target='_blank'
            rel='noopener noreferrer'
          >
            our team
          </a>
          .
        </p>
      </div>
      <div className='section booster'>
        {isFreeTier ? (
          <>
            <div>
              <h5>Add a credit booster</h5>
              <p>
                Credit boosters are only available when you have an existing
                subscription.
              </p>
            </div>
          </>
        ) : (
          <>
            <div>
              <h5>Add a credit booster</h5>
              <p>
                Boosters are one-off purchases of credits that can help if you
                run out before your next renewal. They don't expire and are used
                only when you don't have enough credits remaining on your
                monthly allocation.
              </p>
            </div>
            <div className='booster-products'>
              {sortedBoosterProducts.map((product) => {
                const { formattedPrice, priceId } = getPrice(product.prices);
                return (
                  <div className='booster-product' key={product.id}>
                    <h3>{product.name}</h3>
                    <p>{formattedPrice}</p>
                    <p className='credits-granted'>
                      <b>{product?.metadata?.credits_granted || '0'}</b> Credits
                    </p>
                    <Button
                      variant='outline-primary'
                      onClick={() =>
                        handleCheckout(product.id, priceId, 'one-off')
                      }
                    >
                      Add credits
                    </Button>
                  </div>
                );
              })}
            </div>
          </>
        )}
      </div>
      {subscriptionData.boosters.length > 0 && (
        <div className='section booster-section'>
          <h2>Boosters</h2>
          <div className='booster-list'>
            {subscriptionData.boosters.map((booster, index) => (
              <div className='info-pair' key={index}>
                <p className='label'>Booster {index + 1}</p>
                <p className='value'>
                  {booster === 0 ? 'Exhausted' : `${booster} credits remaining`}
                </p>
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default Subscription;
