import React from 'react';
import { Tooltip, OverlayTrigger } from 'react-bootstrap';
import { ReactComponent as ErrorIcon } from '../../icons/error.svg';

function AnswerError({ id }) {
  return (
    <OverlayTrigger
      placement='top'
      trigger='hover'
      overlay={
        <Tooltip id={`tooltip-${id}`}>
          Something went wrong when finding an answer.
        </Tooltip>
      }
    >
      <span>
        <div className='error-frame'>
          <ErrorIcon />
        </div>
      </span>
    </OverlayTrigger>
  );
}

export default AnswerError;
