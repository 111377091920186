import React, { useEffect, useState } from 'react';
import { Button, Form, Spinner, Alert } from 'react-bootstrap';
import { useAuth0 } from '@auth0/auth0-react';
import DreamSideModal from '../../misc/DreamSideModal';
import DreamModalInner from '../../misc/DreamModalInner';
import DreamSideModalLoader from '../../misc/DreamSideModalLoader';
import api from '../../../api';
import { ReactComponent as ResearchPointIcon } from '../../../icons/research-point.svg';

const EditEmailResearchPointModal = ({
  researchPoint,
  emailId,
  oagentId,
  onClose,
  show,
}) => {
  const { getAccessTokenSilently } = useAuth0();
  const [whenToUse, setWhenToUse] = useState(researchPoint.when_to_use);
  const [isActive, setIsActive] = useState(!!researchPoint.when_to_use);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState('');

  useEffect(() => {
    setWhenToUse(researchPoint.when_to_use || '');
    setIsActive(!!researchPoint.when_to_use);
  }, [researchPoint]);

  const handleSave = async () => {
    try {
      setIsLoading(true);
      setError('');
      const token = await getAccessTokenSilently();
      const endpoint = researchPoint.isAdditionalData
        ? `/oagents/${oagentId}/email-additional-data/${researchPoint.name}/${emailId}`
        : `/oagents/${oagentId}/email-research-points/${researchPoint.id}/${emailId}`;

      await api.put(
        endpoint,
        {
          when_to_use: isActive ? whenToUse : null,
          active: isActive,
        },
        {
          headers: { Authorization: `Bearer ${token}` },
        },
      );
      onClose();
    } catch (error) {
      console.error('Error updating email data:', error);
      setError('Failed to save data');
    } finally {
      setIsLoading(false);
    }
  };

  if (isLoading) {
    return (
      <DreamSideModal show={true} handleClose={onClose}>
        <DreamModalInner>
          <DreamSideModalLoader>Saving</DreamSideModalLoader>
        </DreamModalInner>
      </DreamSideModal>
    );
  }

  return (
    <DreamSideModal show={show} handleClose={onClose}>
      <DreamModalInner>
        <DreamModalInner.Header>
          <div className='red-icon'>
            <ResearchPointIcon />
          </div>
          Edit Research Point
        </DreamModalInner.Header>
        <DreamModalInner.Body>
          {error && <Alert variant='danger'>{error}</Alert>}
          <div className='dream-side-modal-form edit-research-point-modal'>
            <Form.Group className='switch-group'>
              <Form.Check
                type='switch'
                id='custom-switch'
                checked={isActive}
                onChange={(e) => setIsActive(e.target.checked)}
              />
              <div className='switch-right'>
                <h6>Use this datapoint in my message</h6>
                <p>
                  Your agent will aim to use this datapoint as instructed when
                  generating your message.
                </p>
              </div>
            </Form.Group>
            <>
              <hr />
              <Form.Group className={`zombie ${isActive && 'active'}`}>
                <Form.Label>When & how to mention this datapoint</Form.Label>
                <Form.Text>
                  By default we'll only mention a datapoint "where appropriate"{' '}
                  <b>but</b> this is highly subjective. You should guide your
                  agent on when and how to use the datapoint.
                </Form.Text>
                <Form.Control
                  as='textarea'
                  value={whenToUse}
                  placeholder='e.g. If the statement is related to AI investment, adjust the initial email to highlight that.'
                  onChange={(e) => setWhenToUse(e.target.value)}
                />
              </Form.Group>
            </>
          </div>
        </DreamModalInner.Body>
        <DreamModalInner.Footer>
          <Button variant='primary' onClick={handleSave} disabled={isLoading}>
            Save
          </Button>
          <Button variant='outline-primary' onClick={onClose}>
            Cancel
          </Button>
        </DreamModalInner.Footer>
      </DreamModalInner>
    </DreamSideModal>
  );
};

export default EditEmailResearchPointModal;
