// components/analyst/Datapoint.js
import React, { useState, useEffect } from 'react';
import { Spinner, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSyncAlt } from '@fortawesome/free-solid-svg-icons';
import api from '../../api';
import { usePusher } from '../../contexts/PusherContext';
import { useAuth0 } from '@auth0/auth0-react';

import AnswerError from '../funnel/AnswerError';
import Processing from '../funnel/Processing';
import NoAnswer from '../funnel/NoAnswer';

const Datapoint = ({ datapoint, knownOrgId, refresh }) => {
  const { getAccessTokenSilently } = useAuth0();
  const pusherChannel = usePusher(); // Get the Pusher channel
  const [status, setStatus] = useState('not-scheduled');
  const [loading, setLoading] = useState(true);
  const [isRefreshing, setIsRefreshing] = useState(false);

  useEffect(() => {
    fetchStatus();
  }, [datapoint.id, getAccessTokenSilently]);

  useEffect(() => {
    if (pusherChannel && knownOrgId) {
      const statusUpdateHandler = async (data) => {
        if (data && data.research_point_id === parseInt(datapoint.id, 10)) {
          fetchStatus();
          refresh();
        }
      };

      // pusherChannel.bind('new_narrative', funnelUpdateHandler);
      pusherChannel.bind('analyst_status_update', statusUpdateHandler);

      // Unbind events on cleanup
      return () => {
        // pusherChannel.unbind('new_narrative', funnelUpdateHandler);
        pusherChannel.unbind('analyst_status_update', statusUpdateHandler);
      };
    }
  }, [pusherChannel, datapoint, knownOrgId, getAccessTokenSilently]);

  const fetchStatus = async () => {
    try {
      const token = await getAccessTokenSilently();
      const response = await api.get(
        `/analyst/research-point/${datapoint.id}/status/${knownOrgId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      );
      setStatus(response.data.status);
    } catch (error) {
      console.error('Error fetching status:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleRefresh = async () => {
    setIsRefreshing(true);
    try {
      const token = await getAccessTokenSilently();
      await api.post(
        `/analyst/research-point/${datapoint.id}/queue`,
        { known_org_id: knownOrgId },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      );
      // Update status to 'queued' after refreshing
      setStatus('queued');
      fetchStatus();
    } catch (error) {
      console.error('Error adding to queue:', error);
    } finally {
      setIsRefreshing(false);
    }
  };

  if (loading) {
    return null;
  }

  let valueDisplay;
  if (status === 'processing') {
    valueDisplay = <Processing />;
  } else if (status === 'completed') {
    valueDisplay = datapoint.value || <NoAnswer />;
  } else if (status === 'error') {
    valueDisplay = <AnswerError />;
  } else if (status === 'not-scheduled') {
    valueDisplay = '';
  }

  const valueWithOverlay = (value) => {
    let confidenceScore = parseFloat(datapoint.confidence_score);
    let confidenceLine = '';

    if (confidenceScore < 0.33) {
      confidenceLine = 'Low';
    } else if (confidenceScore >= 0.33 && confidenceScore < 0.66) {
      confidenceLine = 'Mid';
    } else if (confidenceScore >= 0.66) {
      confidenceLine = 'High';
    }

    const lastCheckedDate = new Date(
      datapoint.last_checked,
    ).toLocaleDateString();

    const tooltipText = (
      <div className='wrapper'>
        <p>Confidence: {confidenceLine}</p>
        {datapoint.value_context && <p>Context: {datapoint.value_context}</p>}
        <p>Last checked: {lastCheckedDate}</p>
      </div>
    );

    return (
      <div className='value-wrapper'>
        <OverlayTrigger
          overlay={
            <Tooltip id={`tooltip-${datapoint.id}`}>{tooltipText}</Tooltip>
          }
        >
          <p className='value'>{value}</p>
        </OverlayTrigger>
        {status !== 'queued' && !isRefreshing && (
          <FontAwesomeIcon
            icon={faSyncAlt}
            className='refresh-icon'
            onClick={handleRefresh}
          />
        )}
        {isRefreshing && <Spinner animation='border' size='sm' />}
      </div>
    );
  };

  const valueWithoutOverlay = (value) => (
    <div className='value-wrapper'>
      <p className='value'>{value}</p>
      {status !== 'queued' && !isRefreshing && (
        <FontAwesomeIcon
          icon={faSyncAlt}
          className='refresh-icon'
          onClick={handleRefresh}
        />
      )}
      {isRefreshing && <Spinner animation='border' size='sm' />}
    </div>
  );

  return (
    <div className='datapoint'>
      <p className='label'>{datapoint.name}</p>
      {datapoint.value
        ? valueWithOverlay(valueDisplay)
        : valueWithoutOverlay(valueDisplay)}
    </div>
  );
};

export default Datapoint;
