import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import api from '../api';
import DreamSideModal from './misc/DreamSideModal'; // Import the DreamSideModal
import Step1 from './funnel/Step1';
import SearchConfigModal from './funnel/SearchConfigModal';
import functionManifests from '../utils/function-manifests';
import DreamModalInner from './misc/DreamModalInner';
import DreamSideModalLoader from './misc/DreamSideModalLoader';

function CreateFunnel({ show, handleClose, type }) {
  const { getAccessTokenSilently } = useAuth0();
  const [step, setStep] = useState(1);
  const [useCaseOther, setUseCaseOther] = useState('');
  const [funnelName, setFunnelName] = useState('');
  const [searchStage, setSearchStage] = useState({ type: null, metadata: {} });
  const [useCaseType, setUseCaseType] = useState('');

  const [creating, setCreating] = useState(false);
  const [errors, setErrors] = useState([]);

  const navigate = useNavigate();

  const validateForm = () => {
    /* 
      IF CHANGING THIS YOU SHOULD PROBABLY CHANGE ADVANCEDMODE VALIDATE
    */
    const errorList = [];
    if (!useCaseType) errorList.push('Use case type is required.');
    if (!funnelName.trim()) errorList.push('Funnel name is required.');
    if (!searchStage) errorList.push('Must have a source of companies');

    const manifest = functionManifests['company_search'].find(
      (opt) => opt.type === searchStage?.type,
    );

    const metadata = searchStage.metadata;
    if (manifest && manifest.validate) {
      if (Array.isArray(manifest.validate)) {
        manifest.validate.forEach((entry) => {
          if (!metadata[entry]) {
            errorList.push(`"${manifest.name}" - ${entry} is required`);
          }
        });
      } else if (typeof manifest.validate === 'function') {
        const validationErrors = manifest.validate(metadata);
        if (!Array.isArray(validationErrors) && validationErrors)
          return errorList;
        errorList.push(...validationErrors);
      }
    }

    return errorList;
  };

  const handleFormSubmit = async (event) => {
    const token = await getAccessTokenSilently();
    event.preventDefault();
    const validationErrors = validateForm();
    if (validationErrors.length) {
      setErrors(validationErrors);
      return;
    }

    setErrors([]);
    const funnelData = {
      name: funnelName,
      use_case_type: useCaseType,
      use_case_description: useCaseOther || '',
      stages: { company_search: [searchStage], company_filter: [] },
    };

    try {
      setCreating(true);
      const response = await api.post('/funnels', funnelData, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (response.status === 200) {
        handleClose();
        navigate(`/funnel/${response.data?.funnelId}`);
      } else {
        alert('An error occurred while creating the funnel.');
      }
    } catch (error) {
      alert(
        `An error occurred: ${error.response ? error.response.data.error : error.message}`,
      );
    } finally {
      setCreating(false);
    }
  };

  const handleTypeChange = (type) => {
    setSearchStage({
      ...searchStage,
      type,
      metadata: {},
    });
  };

  const handleMetadataChange = (option, value) => {
    /* 
      IF CHANGING THIS YOU SHOULD PROBABLY CHANGE ADVANCEDMODE METADATA CHANGE
    */

    if (Array.isArray(value) && value.length > 0 && value[0].label) {
      value = value.map((x) => x.value);
    }

    setSearchStage({
      ...searchStage,
      metadata: {
        ...searchStage.metadata,
        [option]: value,
      },
    });
  };

  const handleAdvance = () => {
    setStep(2);
  };

  const handleBack = () => {
    setStep(1);
  };

  if (creating) {
    return (
      <DreamSideModal show>
        <DreamModalInner>
          <DreamSideModalLoader>Creating List</DreamSideModalLoader>
        </DreamModalInner>
      </DreamSideModal>
    );
  }

  return (
    <DreamSideModal show={show} handleClose={handleClose} position='right'>
      {step === 1 && (
        <Step1
          funnelName={funnelName}
          selectedType={useCaseType}
          setUseCaseType={setUseCaseType}
          setFunnelName={setFunnelName}
          onNext={handleAdvance}
          onClose={handleClose}
          useCaseOther={useCaseOther}
          setUseCaseOther={setUseCaseOther}
          isImport={type === 'list'}
        />
      )}
      {step === 2 && (
        <SearchConfigModal
          isImport={type === 'list'}
          searchStage={searchStage}
          handleTypeChange={handleTypeChange}
          handleMetadataChange={handleMetadataChange}
          handleBack={handleBack}
          handleSubmit={handleFormSubmit}
          name={funnelName}
          useCaseType={useCaseType}
          useCaseOther={useCaseOther}
          errors={errors}
        />
      )}
    </DreamSideModal>
  );
}

export default CreateFunnel;
