import React from 'react';
import { Tabs, Tab } from 'react-bootstrap';
import { useNavigate, useLocation } from 'react-router-dom';
import KnowledgeBase from './analyst/KnowledgeBase';
import CampaignTriggers from './analyst/CampaignTriggers';
import Datapoints from './analyst/Datapoints';

import './analyst/analyst.css';

function Analyst() {
  const navigate = useNavigate();
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const defaultTab = params.get('tab') || 'knowledge-base';

  const handleSelect = (key) => {
    navigate(`/analyst?tab=${key}`);
  };

  return (
    <div className='page-wrapper'>
      <div className='page-inner-wrapper analyst analyst-index'>
        <div className='page-header-area'>
          <div className='title-and-text'>
            <h3>Company Knowledge</h3>
          </div>
        </div>
        <div className='left-tabs-wrapper'>
          <Tabs
            activeKey={defaultTab}
            onSelect={handleSelect}
            className='dream-left-tabs'
          >
            <Tab eventKey='knowledge-base' title='Knowledge Base'>
              <KnowledgeBase />
            </Tab>
            <Tab eventKey='campaign-triggers' title='Campaign Triggers'>
              <CampaignTriggers />
            </Tab>
            <Tab eventKey='datapoints' title='Datapoints'>
              <Datapoints />
            </Tab>
          </Tabs>
        </div>
      </div>
    </div>
  );
}

export default Analyst;
