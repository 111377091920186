// manifests/company_search.js
import React from 'react';
import linkedinSearchManifest from './linkedin_search';
import jobSearchManifest from './jobs';
import { ReactComponent as BusinessIcon } from '../../icons/business.svg';
import { ReactComponent as UploadListIcon } from '../../icons/upload-list.svg';
import { ReactComponent as GlobeIcon } from '../../icons/globe.svg';
import { ReactComponent as AgentSearchIcon } from '../../icons/agent-search.svg';
import { ReactComponent as CRMIcon } from '../../icons/crm.svg';

const companySearchManifest = [
  {
    name: 'Import from CSV',
    icon: <UploadListIcon />,
    is_foundational_type: true,
    type: 'list',
    import: true,
    match_language: 'Companies matched',
    description: 'Upload a list of companies in CSV format.',
    speed_description: (
      <>
        <p>We're sourcing information for the companies you've imported</p>
        <p>
          Data is retrieved for <b>300</b> companies per hour.
        </p>
      </>
    ),
    additional_options: {
      list_of_companies: {
        input_type: 'list_from_csv',
      },
      list_contents: {
        description: 'List Contents',
        dev_name: 'list_contents',
        long_description: (
          <span>
            (Optional) Briefly describe the types of companies on the list (this
            helps your agents know if an entry for e.g. Apple means Apple Music
            or Apple Computer)
          </span>
        ),
        input_type: 'textarea',
      },
    },
    display: {},
  },
  {
    name: 'Sync from CRM',
    icon: <CRMIcon />,
    type: 'crm',
    import: true,
    is_foundational_type: true,
    description: 'Import companies from your linked CRM',
    speed_description: (
      <>
        <p>We're sourcing information for the companies you've imported</p>
        <p>
          Data is retrieved for <b>300</b> companies per hour.
        </p>
      </>
    ),
    additional_options: {
      owner_id: {
        input_type: 'text',
        dev_name: 'crm_user_id',
        description: 'CRM User ID',
        long_description:
          'If you want to only sync companies that belong to a certain user, input their ID on the CRM here.',
        short: true,
      },
    },
    display: {},
  },
  {
    name: 'Agent Search',
    icon: <AgentSearchIcon />,
    type: 'agent_search',
    is_foundational_type: true,
    hide_from_basic_selection: true,
    description:
      "Your agent will try and build your list using multiple online sources and it's own knowledge.",
    validate: ['list_contents'],
    additional_options: {
      list_contents: {
        description: 'List Contents',
        dev_name: 'list_contents',
        long_description: (
          <span>
            What you want your agent to search for. See <a href='/'>docs</a> for
            more details.
          </span>
        ),
        input_type: 'textarea',
      },
      ai_guidance: {
        description: 'Guidance',
        dev_name: 'guidance',
        long_description: (
          <span>
            (Optional) Further considerations your agent will make during
            company extraction. See <a href='/'>docs</a> for more details.
          </span>
        ),
        input_type: 'textarea',
      },
    },
    credit_cost: 2,
    speed_description: (
      <p>
        Retrieval speed varies between <b>50</b> to <b>100</b> retrievals per
        hour
      </p>
    ),
    list_language: 'Companies retrieved',
  },
  linkedinSearchManifest,
  jobSearchManifest,
  {
    name: 'Announcement Search',
    icon: <GlobeIcon />,
    type: 'announcement_search',
    long_description: `Find companies based on news or information about them. Your agent will search using publicly availably information to find companies that match your requirements.
You can go into as much or as little detail as you want.`,
    validate: ['announcement'],
    description:
      'Search for companies based on recent news or information about them.',
    additional_options: {
      announcement: {
        description: 'Find companies that have recently announced...',
        input_type: 'textarea',
        samples: [
          { label: '...profit warnings', value: 'profit warnings' },
          { label: '...office openings', value: 'office openings' },
          { label: '...new CEO hires', value: 'new CEO hires' },
          { label: '...design awards', value: 'design awards' },
        ],
      },
    },
    credit_cost: 2,
    speed_description: (
      <p>
        Agent will retrieve up to <b>300</b> companies per hour.
      </p>
    ),
  },
  {
    name: 'Local Business Search',
    icon: <BusinessIcon />,
    type: 'google_local_search',
    is_foundational_type: true,
    validate: ['location', 'search_term'],
    description:
      'Find companies that don’t have a significant internet presence, eg. brick and mortar businesses.',
    additional_options: {
      search_term: {
        description: 'What kind of company to look for?',
        long_description:
          "For example 'Marketing Agencies', 'Plumbers', 'Dentists'",
        input_type: 'text',
        dev_name: 'search_term',
      },
      location: {
        description: 'What area to search in?',
        long_description: 'Start typing a region or location',
        input_type: 'google_location_auto_complete',
        dev_name: 'location',
      },
    },
    tests: [
      {
        name: 'Test Local Search',
        description: (
          <>
            <p>
              Press run test to see a snapshot of the results of your search.
            </p>
            <p>
              <b>NB:</b> Results are restricted to 15 companies in this test.
            </p>
          </>
        ),
        type: 'google_local_search',
        inputs: [],
        responseFn: ({ companies, steps }) => (
          <>
            <div className='companies-list'>
              <p>{companies.join(', ')}</p>
            </div>
          </>
        ),
      },
    ],
    display: {
      show: 'search_term',
      how: 'text',
    },
    credits_cost: 1,
  },
];

export default companySearchManifest;
