import React from 'react';
import { Button } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';

const CompaniesList = ({
  allFunnels,
  existingFunnels,
  isFunnelInExisting,
  handleAddFunnel,
  handleRemoveFunnel,
}) => {
  const navigate = useNavigate();

  return (
    <div className='edit-companies-modal'>
      <label>Your lists</label>
      <div className='items-to-choose'>
        {allFunnels.map((funnel) => (
          <div
            key={funnel.id}
            className={`company item sexy-white ${
              isFunnelInExisting(funnel.id) ? 'active' : ''
            }`}
          >
            <div className='left-area'>
              <h6>{funnel.name}</h6>
              <small>
                {funnel.scheduling_allowed ? 'Autopilot' : 'Inactive'}
              </small>
            </div>
            <div className='button-wrapper'>
              {!isFunnelInExisting(funnel.id) ? (
                <Button
                  variant='outline-primary'
                  onClick={() => handleAddFunnel(funnel.id)}
                >
                  Add
                </Button>
              ) : (
                <Button
                  variant='primary'
                  onClick={() => handleRemoveFunnel(funnel.id)}
                >
                  Remove
                </Button>
              )}
            </div>
          </div>
        ))}
        <Button
          onClick={() => navigate('/funnel')}
          variant='outline-primary'
          className='create-new-button'
        >
          Create new List
        </Button>
      </div>
    </div>
  );
};

export default CompaniesList;
