import React, { useState, useEffect } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { Modal, Button, Form, Spinner, Alert } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import api from '../api';
import './products/products.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import DreamSideModal from './misc/DreamSideModal';
import DreamSideModalLoader from './misc/DreamSideModalLoader';
import DreamModalInner from './misc/DreamModalInner';

import { ReactComponent as TagIcon } from '../icons/tag.svg';
import { faArrowRight, faPlus } from '@fortawesome/free-solid-svg-icons';

function Products() {
  const { getAccessTokenSilently } = useAuth0();
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [creating, setCreating] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [newProductName, setNewProductName] = useState('');
  const [createError, setCreateError] = useState(null);

  const navigate = useNavigate();

  const fetchProducts = async () => {
    setLoading(true);
    setError(null);

    try {
      const token = await getAccessTokenSilently();
      const response = await api.get('/products', {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setProducts(response.data);
    } catch (error) {
      console.error('Error fetching products:', error);
      setError('Failed to load products');
    } finally {
      setLoading(false);
    }
  };

  const handleCreateProduct = async () => {
    setCreateError(null);
    setCreating(true);
    try {
      const token = await getAccessTokenSilently();
      const response = await api.post(
        '/products',
        { name: newProductName },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      );
      setShowModal(false);
      navigate(`/products/${response.data.id}`);
    } catch (error) {
      console.error('Error creating product:', error);
      setCreateError('Failed to create product');
    } finally {
      setCreating(false);
    }
  };

  useEffect(() => {
    fetchProducts();
  }, []);

  if (loading) {
    return (
      <div className='page-wrapper'>
        <div className='page-inner-wrapper products-page'>
          <div className='page-header-area'>
            <div className='title-and-text'>
              <h3>Your Products & Services</h3>
            </div>
          </div>
          <div className='product-loading-area'>
            <Spinner />
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className='page-wrapper'>
      <div className='page-inner-wrapper products-page'>
        <div className='page-header-area'>
          <div className='title-and-text'>
            <h3>Your Products & Services</h3>
          </div>
          <div className='buttons-area'>
            <Button variant='primary' onClick={() => setShowModal(true)}>
              Add new product
              <div className='yellow-icon'>
                <FontAwesomeIcon icon={faPlus} />
              </div>
            </Button>
          </div>
        </div>

        {products.length === 0 && (
          <div className='product-empty-state-wrapper'>
            <div className='empty-state'>
              <TagIcon />
              <div>
                <h6>You've not added any products or services</h6>
                <p>
                  Add your products. Setup pricing, key features, and ICPs.
                  <br />
                  <br /> You agents will use this information to help answer
                  questions in your campaigns.
                </p>
              </div>
              <Button variant='primary' onClick={() => setShowModal(true)}>
                Add new product
                <div className='yellow-icon'>
                  <FontAwesomeIcon icon={faPlus} />
                </div>
              </Button>
            </div>
          </div>
        )}
        {products.length > 0 && (
          <div className='product-lower-area'>
            <div className='product-header-row'>
              <p>Product name</p>
              <p>Description</p>
            </div>
            <div className='products-body'>
              {products.map((product) => (
                <div
                  className='product-row'
                  key={product.id}
                  onClick={() => navigate(`/products/${product.id}`)}
                >
                  <p>{product.name}</p>
                  <p>{product?.description?.slice(0, 500)}</p>
                </div>
              ))}
            </div>
          </div>
        )}
      </div>

      {creating && (
        <DreamSideModal>
          <DreamModalInner>
            <DreamSideModalLoader>Creating</DreamSideModalLoader>
          </DreamModalInner>
        </DreamSideModal>
      )}

      {!creating && (
        <DreamSideModal show={showModal} onHide={() => setShowModal(false)}>
          <DreamModalInner>
            <DreamModalInner.Header closeButton>
              <TagIcon /> Create New Product
            </DreamModalInner.Header>
            <DreamModalInner.Body>
              <div className='dream-side-modal-form product-form'>
                {createError && <Alert variant='danger'>{createError}</Alert>}
                <p>
                  Let us know the name of your product. Your agents will use
                  this during conversations with prospects. We recommend you use
                  a clearly understandable name for your product, omitting any
                  trademark symbols.
                </p>
                <Form.Group>
                  <Form.Label>What is the name of your product</Form.Label>
                  <Form.Control
                    type='text'
                    value={newProductName}
                    size='lg'
                    onChange={(e) => setNewProductName(e.target.value)}
                  />
                </Form.Group>
              </div>
            </DreamModalInner.Body>
            <DreamModalInner.Footer>
              <Button variant='primary' onClick={handleCreateProduct}>
                Create new product{' '}
                <div className='yellow-icon'>
                  <FontAwesomeIcon icon={faArrowRight} />
                </div>
              </Button>
              <Button
                variant='outline-primary'
                onClick={() => setShowModal(false)}
              >
                Cancel
              </Button>
            </DreamModalInner.Footer>
          </DreamModalInner>
        </DreamSideModal>
      )}
    </div>
  );
}

export default Products;
