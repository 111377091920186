import React, { useState, useEffect } from 'react';
import { Button, Alert } from 'react-bootstrap';
import api from '../../api';
import { useAuth0 } from '@auth0/auth0-react';
import MainPageLoader from '../misc/MainPageLoader';
import ReactMarkdown from 'react-markdown';
import ResearchPointModal from './ResearchPointModal';

const AnalystAnswer = ({ question, handleBack }) => {
  const { getAccessTokenSilently } = useAuth0();
  const [loading, setLoading] = useState(true);
  const [answer, setAnswer] = useState(null);
  const [suggestions, setSuggestions] = useState(null);
  const [error, setError] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [modalPresetName, setModalPresetName] = useState(null);
  const [researchPointsUsed, setResearchPointsUsed] = useState(null);
  const [modalType, setModalType] = useState('');
  const [autopilotEnabled, setAutopilotEnabled] = useState(false);
  const [boosts, setBoosts] = useState([]);
  const [boosting, setBoosting] = useState(false);

  useEffect(() => {
    const fetchInitialData = async () => {
      try {
        const token = await getAccessTokenSilently();

        // Fetch Autopilot Status
        const autopilotResponse = await api.get('/analyst-autopilot', {
          headers: { Authorization: `Bearer ${token}` },
        });
        setAutopilotEnabled(autopilotResponse.data.analyst_autopilot);

        // Fetch Answer
        const answerResponse = await api.post(
          '/analyst-question',
          { question },
          {
            headers: { Authorization: `Bearer ${token}` },
          },
        );
        setAnswer(answerResponse.data.answer);
        setResearchPointsUsed(answerResponse.data.research_points_used);
        setSuggestions(answerResponse.data.suggestions);

        // Fetch Boosts
        const boostsResponse = await api.get('/analyst-question/boosts', {
          headers: { Authorization: `Bearer ${token}` },
        });
        setBoosts(boostsResponse.data);
      } catch (error) {
        console.error('Error fetching initial data:', error);
        setError('There was an error fetching data. Please try again later.');
      } finally {
        setLoading(false);
      }
    };

    fetchInitialData();
  }, [question, getAccessTokenSilently]);

  const handleOpenModal = (type, preset) => {
    setModalType(type);
    setModalPresetName(preset);
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setModalPresetName(null);
    setModalType('');
  };

  const handleBoost = async () => {
    setBoosting(true);
    setError(null);

    try {
      const token = await getAccessTokenSilently();
      const promises = researchPointsUsed
        .filter(
          (rp) => !boosts.some((boost) => boost.research_point_id === rp.id),
        )
        .map((rp) =>
          api.post(
            '/analyst-question/boost',
            { research_point_id: rp.id },
            {
              headers: { Authorization: `Bearer ${token}` },
            },
          ),
        );

      await Promise.all(promises);

      // Refresh boosts
      const boostsResponse = await api.get('/analyst-question/boosts', {
        headers: { Authorization: `Bearer ${token}` },
      });
      setBoosts(boostsResponse.data);
    } catch (error) {
      console.error('Error boosting research points:', error);
      setError('Failed to boost research points. Please try again later.');
    } finally {
      setBoosting(false);
    }
  };

  const remainingPointsToBoost =
    researchPointsUsed?.filter(
      (rp) => !boosts.some((boost) => boost.research_point_id === rp.id),
    ) || [];

  if (loading) {
    return <MainPageLoader />;
  }

  return (
    <div className='analyst-answer'>
      {error && (
        <Alert variant='danger' onClose={() => setError(null)} dismissible>
          {error}
        </Alert>
      )}
      <div className='header-area'>
        <div className='breadcrumbs' onClick={handleBack}>
          <span>&larr; Back to Knowledge Base</span>
        </div>
        <div className='question-view'>{question}</div>
      </div>
      <div className='section'>
        <h5>Your answer</h5>
        <div className='answer-area'>
          <ReactMarkdown>{answer}</ReactMarkdown>
          {researchPointsUsed && researchPointsUsed.length > 0 && (
            <div className='research-points-used'>
              <div>
                <p>
                  To answer this question, we used the following datapoints:{' '}
                  {researchPointsUsed.map((rp) => `"${rp.name}"`).join(', ')}
                </p>
                {researchPointsUsed.map((rp, index) => (
                  <p key={index}>
                    {rp.name} is filled for {rp.have_data_count} companies, with{' '}
                    {rp.queued_count} companies being processed.
                  </p>
                ))}
              </div>
              {remainingPointsToBoost.length > 0 ? (
                <Button
                  variant='primary'
                  onClick={handleBoost}
                  disabled={boosting}
                >
                  {autopilotEnabled
                    ? 'Prioritise these datapoints'
                    : 'Collect data for all companies'}
                </Button>
              ) : (
                <Button variant='primary' disabled>
                  Datapoints prioritised
                </Button>
              )}
            </div>
          )}
        </div>
      </div>
      {suggestions?.datapoint && (
        <div className='section'>
          <h5>Create a Datapoint for this question</h5>
          <p className='description'>
            Add a datapoint for this question to filter companies in your
            Campaign and guarantee we have the answer for all companies in your
            lists.
          </p>
          <div className='sample'>
            <p>{suggestions.datapoint}</p>
            <hr />
            <div className='button-wrapper'>
              <Button
                variant='primary'
                onClick={() =>
                  handleOpenModal('datapoint', suggestions.datapoint)
                }
              >
                Create Datapoint
              </Button>
            </div>
          </div>
        </div>
      )}
      {suggestions?.campaign_trigger && (
        <div className='section'>
          <h5>Create a Campaign Trigger for this question</h5>
          <p className='description'>
            Use this question in your campaigns to determine when to message a
            company
          </p>
          <div className='sample'>
            <p>{suggestions.campaign_trigger}</p>
            <hr />
            <div className='button-wrapper'>
              <Button
                variant='primary'
                onClick={() =>
                  handleOpenModal(
                    'campaign_trigger',
                    suggestions.campaign_trigger,
                  )
                }
              >
                Create Campaign Trigger
              </Button>
            </div>
          </div>
        </div>
      )}
      <ResearchPointModal
        show={showModal}
        handleClose={handleCloseModal}
        type={modalType}
        presetName={modalPresetName}
      />
    </div>
  );
};

export default AnalystAnswer;
