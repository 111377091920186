import React, { useEffect, useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { Alert, Button } from 'react-bootstrap';
import api from '../../../api';
import { ReactComponent as KnownJobTitleIcon } from '../../../icons/known-job-title.svg';
import { ReactComponent as AgentGuidanceJobTitleIcon } from '../../../icons/agent-guidance-job-title.svg';
import MainPageLoader from '../../misc/MainPageLoader';
import KnownJobTitleModal from './KnownJobTitleModal';
import AgentGuidanceJobTitleModal from './AgentGuidanceJobTitleModal';

import './prospects-step.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';

function ProspectsSettings({ oagentId, nextFn, exitWithoutSaving }) {
  const { getAccessTokenSilently } = useAuth0();
  const [prospectSearch, setProspectSearch] = useState(null);
  const [loading, setLoading] = useState(true);
  const [showKnownJobTitleModal, setShowKnownJobTitleModal] = useState(false);
  const [showAgentGuidanceJobTitleModal, setShowAgentGuidanceJobTitleModal] =
    useState(false);
  const [editMode, setEditMode] = useState(false);
  const [editMetadata, setEditMetadata] = useState(null);
  const [editProspectSearchId, setEditProspectSearchId] = useState(null);

  const fetchProspectSearches = async () => {
    try {
      const token = await getAccessTokenSilently();
      const response = await api.get(`/oagents/${oagentId}/prospect-search`, {
        headers: { Authorization: `Bearer ${token}` },
      });

      setProspectSearch(response.data[0] || null);
    } catch (error) {
      console.error('Error fetching prospect searches:', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchProspectSearches();
  }, [getAccessTokenSilently, oagentId]);

  const clearProfile = async () => {
    try {
      const token = await getAccessTokenSilently();
      // Optimistically update the state
      setProspectSearch(null);
      // Call the deactivate endpoint
      await api.post(`/oagents/${oagentId}/prospect-search/deactivate`, null, {
        headers: { Authorization: `Bearer ${token}` },
      });
      // Refetch the prospect searches to ensure the data is up-to-date
      fetchProspectSearches();
    } catch (error) {
      console.error('Error clearing prospect profile:', error);
      // Revert the optimistic update in case of an error
      fetchProspectSearches();
    }
  };

  const handleEdit = (metadata, type, id) => {
    setEditMode(true);
    setEditMetadata(metadata);
    setEditProspectSearchId(id);
    if (type === 'known_job_title') {
      setShowKnownJobTitleModal(true);
    } else if (type === 'agent_guidance') {
      setShowAgentGuidanceJobTitleModal(true);
    }
  };

  if (loading) {
    return <MainPageLoader />;
  }

  return (
    <div>
      <div className='prospects-content'>
        {prospectSearch ? (
          <div className='ps-options-wrapper'>
            <div className='pso-header'>
              <h6>Target prospects</h6>
              <div className='actions'>
                {exitWithoutSaving && (
                  <>
                    <Button
                      variant='outline-primary'
                      onClick={exitWithoutSaving}
                    >
                      Exit without re-sync
                    </Button>
                    <Button variant='primary' onClick={nextFn}>
                      Resync prospects
                    </Button>
                  </>
                )}
                {!exitWithoutSaving && (
                  <>
                    <Button variant='primary' onClick={nextFn}>
                      Next: Find prospects
                      <FontAwesomeIcon icon={faArrowRight} />
                    </Button>
                  </>
                )}
              </div>
            </div>
            <div className='ps-wrapper'>
              <div className='ps-header'>
                <h6>Your target profile</h6>
                <span onClick={clearProfile}>Clear profile</span>
              </div>
              {prospectSearch.type === 'known_job_title' && (
                <>
                  <div className='info-bar'>
                    <div className='left'>
                      <label>Job titles</label>
                      <p>{prospectSearch?.metadata?.job_titles.join(', ')}</p>
                    </div>
                    <Button
                      variant='link'
                      onClick={() =>
                        handleEdit(
                          prospectSearch.metadata,
                          'known_job_title',
                          prospectSearch.id,
                        )
                      }
                    >
                      Edit
                    </Button>
                  </div>
                  <div className='info-bar'>
                    <div className='left'>
                      <label>Maximum prospects per company</label>
                      <p>{prospectSearch?.metadata?.num_prospects}</p>
                    </div>
                    <Button
                      variant='link'
                      onClick={() =>
                        handleEdit(
                          prospectSearch.metadata,
                          'known_job_title',
                          prospectSearch.id,
                        )
                      }
                    >
                      Edit
                    </Button>
                  </div>
                </>
              )}
              {prospectSearch.type === 'agent_guidance' && (
                <>
                  <div className='info-bar'>
                    <div className='left'>
                      <label>Job titles</label>
                      <p>
                        {prospectSearch?.metadata?.steps
                          ?.map((step) => step.titles_to_search.join(', '))
                          .join(' > ')}
                      </p>
                    </div>
                    <Button
                      variant='link'
                      onClick={() =>
                        handleEdit(
                          prospectSearch.metadata,
                          'agent_guidance',
                          prospectSearch.id,
                        )
                      }
                    >
                      Edit
                    </Button>
                  </div>
                  <div className='info-bar'>
                    <div className='left'>
                      <label>Guidance</label>
                      <p>{prospectSearch?.metadata?.guidance}</p>
                    </div>
                    <Button
                      variant='link'
                      onClick={() =>
                        handleEdit(
                          prospectSearch.metadata,
                          'agent_guidance',
                          prospectSearch.id,
                        )
                      }
                    >
                      Edit
                    </Button>
                  </div>
                </>
              )}
            </div>
          </div>
        ) : (
          <div className='prospect-search-wrapper'>
            <h6>Target prospect</h6>
            <div className='prospect-search-options'>
              <div className='option'>
                <div className='title'>
                  <div className='svg-frame'>
                    <KnownJobTitleIcon />
                  </div>
                  <p>Target all employees with certain job title(s)</p>
                </div>
                <Button
                  variant='outline-primary'
                  onClick={() => setShowKnownJobTitleModal(true)}
                >
                  Select
                </Button>
              </div>
              <div className='option'>
                <div className='title'>
                  <div className='svg-frame'>
                    <AgentGuidanceJobTitleIcon />
                  </div>
                  <p>Have agents pick the best prospects based on guidance</p>
                </div>
                <Button
                  variant='outline-primary'
                  onClick={() => setShowAgentGuidanceJobTitleModal(true)}
                >
                  Select
                </Button>
              </div>
            </div>
          </div>
        )}
      </div>
      <KnownJobTitleModal
        show={showKnownJobTitleModal}
        handleClose={() => setShowKnownJobTitleModal(false)}
        refreshProspectSearch={fetchProspectSearches}
        oagentId={oagentId}
        editMode={editMode}
        prospectSearchId={editProspectSearchId}
        metadata={editMetadata}
      />
      <AgentGuidanceJobTitleModal
        show={showAgentGuidanceJobTitleModal}
        refreshProspectSearch={fetchProspectSearches}
        handleClose={() => setShowAgentGuidanceJobTitleModal(false)}
        oagentId={oagentId}
        editMode={editMode}
        prospectSearchId={editProspectSearchId}
        metadata={editMetadata}
      />
    </div>
  );
}

export default ProspectsSettings;
