import React, { useState } from 'react';
import { Form, Modal, Button, Spinner } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleQuestion } from '@fortawesome/free-solid-svg-icons';
import Select from 'react-select';
import CreatableSelect from 'react-select/creatable';
import CsvUploader from './form-components/CsvUploader';
import GoogleCountrySelect from './form-components/GoogleCountrySelect';
import GoogleLocationAutoComplete from './form-components/GoogleLocationAutoComplete';
import FunnelSelect from './form-components/FunnelSelect';
import DreamInfoExpander from './misc/DreamInfoExpander';
import { useAuth0 } from '@auth0/auth0-react';

import api from '../api';

import { ReactComponent as WandIcon } from '../icons/magic-wand.svg';

const StageAdditionalOptions = ({
  manifest,
  stage,
  handleMetadataChange,
  metadataValue,
  useDevNaming,
}) => {
  const { getAccessTokenSilently } = useAuth0();
  const [modalVisible, setModalVisible] = useState(false);
  const [modalContent, setModalContent] = useState('');
  const [altJobTitleLoading, setAltJobTitleLoading] = useState(false);

  const handleInfoClick = (description) => {
    setModalContent(description);
    setModalVisible(true);
  };

  const metadataSource = metadataValue || stage.metadata;

  const fetchAlternateJobTitles = async (option) => {
    setAltJobTitleLoading(true);

    try {
      const token = await getAccessTokenSilently();
      const response = await api.post(
        '/prospect-search-test/alternate-job-titles',
        {
          metadata: {
            job_titles: metadataSource[option],
            agent_intuition: true,
            job_title_discretion: true,
          },
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      );

      const titlesResponse = response.data;

      handleMetadataChange(option, titlesResponse);
    } catch (error) {
      console.error('Error fetching alternate job titles:', error);
    } finally {
      setAltJobTitleLoading(false);
    }
  };

  const renderInputType = (opt, option) => {
    switch (opt.input_type) {
      case 'text':
        return (
          <Form.Control
            type='text'
            size='lg'
            placeholder={opt.example}
            value={metadataSource[option] || ''}
            onChange={(e) => handleMetadataChange(option, e.target.value)}
          />
        );
      case 'number':
        return (
          <Form.Control
            type='number'
            size='lg'
            placeholder={opt.example}
            value={metadataSource[option] || ''}
            onChange={(e) =>
              handleMetadataChange(option, parseInt(e.target.value, 10))
            }
          />
        );
      case 'select':
        return (
          <Form.Control
            as='select'
            size='lg'
            value={metadataSource[option] || ''}
            onChange={(e) => handleMetadataChange(option, e.target.value)}
          >
            <option value=''>--- Select One ---</option>
            {opt.options.map((optItem) => (
              <option key={optItem.value} value={optItem.value}>
                {optItem.label}
              </option>
            ))}
          </Form.Control>
        );
      case 'select_as_radios':
        return (
          <div className='select-as-radios-wrapper'>
            {opt.options.map((optItem) => (
              <Form.Check
                type='radio'
                id={`${option}-${optItem.value}`}
                name={option}
                key={optItem.value}
                label={optItem.label}
                value={optItem.value}
                checked={metadataSource[option] === optItem.value}
                onChange={(e) => handleMetadataChange(option, e.target.value)}
              />
            ))}
          </div>
        );
      case 'textarea':
        return (
          <Form.Control
            as='textarea'
            size='lg'
            placeholder={opt.example}
            value={metadataSource[option] || ''}
            onChange={(e) => handleMetadataChange(option, e.target.value)}
          />
        );
      case 'multiselect':
        return (
          <Select
            isMulti
            className='additional-options-select'
            classNamePrefix='additional-options-select'
            value={opt.options.filter((optItem) =>
              metadataSource[option]?.includes(optItem.value),
            )}
            options={opt.options}
            onChange={(selected) => handleMetadataChange(option, selected)}
          />
        );
      case 'multiselect-no-options':
        return (
          <CreatableSelect
            isMulti
            className='additional-options-select'
            classNamePrefix='additional-options-select'
            value={(metadataSource[option] || []).map((v) => ({
              value: v,
              label: v,
            }))}
            onChange={(selected) => handleMetadataChange(option, selected)}
          />
        );
      case 'list_from_csv':
        return (
          <CsvUploader
            onCsvDataChange={(data) => handleMetadataChange(option, data)}
          />
        );
      case 'google_country_select':
        return (
          <GoogleCountrySelect
            value={metadataSource[option] || ''}
            size='lg'
            onChange={(e) => handleMetadataChange(option, e.target.value)}
            hasPleaseSelect
          />
        );
      case 'funnel_select':
        return (
          <FunnelSelect
            value={metadataSource[option] || ''}
            size='lg'
            onChange={(e) => handleMetadataChange(option, e.target.value)}
            hasPleaseSelect
          />
        );
      case 'google_location_auto_complete':
        return (
          <GoogleLocationAutoComplete
            value={metadataSource[option] || ''}
            size='lg'
            onLocationSelected={(value) => handleMetadataChange(option, value)}
            hasPleaseSelect
          />
        );
      case 'checkbox':
        return (
          <Form.Check
            type='checkbox'
            label={opt.checkbox_inline_description}
            checked={metadataSource[option] || false}
            onChange={(e) => handleMetadataChange(option, e.target.checked)}
          />
        );
      case 'multiselect-jobtitle-help':
        return (
          <div className='jobtitle-help'>
            <CreatableSelect
              isMulti
              className='additional-options-select'
              classNamePrefix='additional-options-select'
              value={(metadataSource[option] || []).map((v) => ({
                value: v,
                label: v,
              }))}
              onChange={(selected) => handleMetadataChange(option, selected)}
            />
            {(metadataSource[option] || []).length > 0 && (
              <Button
                disabled={
                  (metadataSource[option] || []).length === 0 ||
                  altJobTitleLoading
                }
                variant='outline-primary'
                onClick={() => fetchAlternateJobTitles(option)}
              >
                {!altJobTitleLoading && (
                  <>
                    <WandIcon /> Add similar titles (recommended)
                  </>
                )}
                {altJobTitleLoading && (
                  <>
                    <Spinner /> Generating titles
                  </>
                )}
              </Button>
            )}
          </div>
        );
      default:
        return null;
    }
  };

  return (
    <div className='stage-additional-options'>
      {manifest.find((opt) => opt.type === stage.type)?.additional_options && (
        <div className='options-holder'>
          {Object.keys(
            manifest.find((opt) => opt.type === stage.type).additional_options,
          ).map((option) => {
            const opt = manifest.find((opt) => opt.type === stage.type)
              .additional_options[option];
            const className = `option ${opt.short ? 'short' : ''}`;

            return (
              <Form.Group
                className='inner-opt-group'
                key={option}
                controlId={`${stage}-${option}`}
              >
                {opt.description && (
                  <Form.Label>
                    {useDevNaming
                      ? opt.dev_name || opt.description
                      : opt.description}
                    {opt.modal_description && (
                      <span className='ficon-wrapper'>
                        <FontAwesomeIcon
                          icon={faCircleQuestion}
                          className='ml-2'
                          onClick={() => handleInfoClick(opt.modal_description)}
                        />
                      </span>
                    )}
                  </Form.Label>
                )}
                {opt.long_description && (
                  <Form.Text>{opt.long_description}</Form.Text>
                )}
                <div className={className}>{renderInputType(opt, option)}</div>
                {opt.samples && (
                  <div className='samples-holder'>
                    {opt.samples.map(({ value, label }) => {
                      return (
                        <div
                          className='sample'
                          key={label}
                          onClick={() => handleMetadataChange(option, value)}
                        >
                          {label}
                        </div>
                      );
                    })}
                  </div>
                )}
                {opt.contextual_description && (
                  <div className='contextual-description'>
                    <DreamInfoExpander
                      heading={opt.contextual_description_heading}
                      alwaysExpanded={true}
                    >
                      {opt.contextual_description}
                    </DreamInfoExpander>
                  </div>
                )}
              </Form.Group>
            );
          })}
        </div>
      )}

      <Modal
        show={modalVisible}
        onHide={() => setModalVisible(false)}
        size='lg'
      >
        <Modal.Header closeButton>
          <Modal.Title>Further Information</Modal.Title>
        </Modal.Header>
        <Modal.Body>{modalContent}</Modal.Body>
        <Modal.Footer>
          <Button variant='secondary' onClick={() => setModalVisible(false)}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default StageAdditionalOptions;
