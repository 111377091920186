import React, { useState } from 'react';
import { Button, Modal, Form, Alert } from 'react-bootstrap';
import DreamSideModalLoader from '../misc/DreamSideModalLoader';

const DeleteConfirmationModal = ({
  show,
  handleClose,
  handleDeleteMember,
  isDeleting,
  member,
  deleteError,
}) => {
  const [confirmationText, setConfirmationText] = useState('');

  const isDisabled = confirmationText !== `DELETE`;

  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Delete this account?</Modal.Title>
      </Modal.Header>
      {isDeleting && (
        <Modal.Body>
          <DreamSideModalLoader>Deleting</DreamSideModalLoader>
        </Modal.Body>
      )}
      {!isDeleting && (
        <>
          <Modal.Body>
            <div className='member-delete-modal-wrapper'>
              {deleteError && (
                <Alert variant='danger'>
                  Error deleting user, please try again.
                </Alert>
              )}
              <div className='shower'>{member?.email}</div>
              <div>
                <p>
                  Deleting this account willremove access for this user.
                  <br />
                  <br />
                  If you're sure you want to continue, type 'DELETE' into the
                  box below then choose Delete Account.
                </p>
              </div>
              <Form.Control
                type='text'
                placeholder=''
                value={confirmationText}
                onChange={(e) => setConfirmationText(e.target.value)}
              />
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant='outline-primary' onClick={handleClose}>
              Cancel
            </Button>
            <Button
              variant='primary'
              onClick={handleDeleteMember}
              disabled={isDisabled}
            >
              Delete account
            </Button>
          </Modal.Footer>
        </>
      )}
    </Modal>
  );
};

export default DeleteConfirmationModal;
