import React, { useState, useEffect } from 'react';
import { useParams, Link } from 'react-router-dom';
import api from '../api';
import functionManifests from '../utils/function-manifests';
import { Button, Spinner } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlay, faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { useAuth0 } from '@auth0/auth0-react';

import Agent from './Agent';
import OrgList from './funnel/OrgList';

import { ReactComponent as EditIcon } from '../icons/edit.svg';
import { ReactComponent as MaximizeIcon } from '../icons/maximize.svg';
import { ReactComponent as MinimizeIcon } from '../icons/minimize.svg';

import FunnelRunModal from './funnel/FunnelRunModal';

import CongratsModal from './misc/CongratsModal.js';

import StageEditModal from './funnel/StageEditModal.js';
import ResearchPointSelectorModal from './funnel/ResearchPointSelectorModal.js';
import ResearchPointChooseModal from './funnel/ResearchPointChooseModal.js';

import DownloadCSVButton from './funnel/DownloadCSVButton.js';
import CreditCost from './funnel/CreditCost.js';

import { useSidebar } from '../contexts/SidebarContext';
import { useProcessing } from '../contexts/ProcessingContext';
import { usePusher } from '../contexts/PusherContext';

import './funnel/funnel.css';

function Funnel() {
  const { getAccessTokenSilently } = useAuth0();
  const { setSidebarVisible } = useSidebar();
  const pusherChannel = usePusher(); // Get the Pusher channel

  const [fullscreen, setFullscreen] = useState(false);
  const [funnel, setFunnel] = useState(null);
  const [funnelLoading, setFunnelLoading] = useState(true);
  const [orgsLoading, setOrgsLoading] = useState(false);
  const [orgs, setOrgs] = useState({ filtered: [], searched: [] });
  const [stages, setStages] = useState([]);
  const [highlightStages, setHighlightStages] = useState(false);
  const [funnelRunStatus, setFunnelRunStatus] = useState(null);
  const [currentOrgView, setCurrentOrgView] = useState('filtered');
  const [firstStagesLoad, setFirstStagesLoad] = useState(false);
  const [showCongratsModal, setShowCongratsModal] = useState(false);
  const [showResearchPointSelectorModal, setShowResearchPointSelectorModal] =
    useState(false);
  const [showResearchPointChooser, setShowResearchPointChooser] =
    useState(false);
  const [researchPointType, setResearchPointType] = useState(null);
  const [stagesLoadAttempted, setStageLoadAttempted] = useState(false);
  const [funnelLoadAttempted, setFunnelLoadAttempted] = useState(false);
  const [researchPoints, setResearchPoints] = useState([]);
  const [downloadData, setDownloadData] = useState(null);

  const [showEditModal, setShowEditModal] = useState(false);
  const [editModalStage, setEditModalStage] = useState(null);

  const [showRunModal, setShowRunModal] = useState(false);

  const { id } = useParams();
  const { setFunnelName } = useProcessing();

  useEffect(() => {
    return () => {
      setSidebarVisible(true);
    };
  }, []);

  const toggleFullscreen = (value) => {
    setFullscreen(value);
    setSidebarVisible(!value);
  };

  const fetchFunnel = async () => {
    try {
      const token = await getAccessTokenSilently();
      const response = await api.get(`/funnels/${id}/full-page-details`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setFunnelLoadAttempted(true);
      setFunnel(response.data);
      setFunnelName(id, response.data?.funnel?.name);
    } catch (err) {
      console.error('Error fetching funnel details');
    }
  };

  useEffect(() => {
    if (Object.keys(stages).length > 0 && !firstStagesLoad) {
      if (isImport() && isEffectivelyEmpty('company_filter')) {
        setCurrentOrgView('searched');
      }
      setFirstStagesLoad(true);
    }
  }, [stages, firstStagesLoad]);

  const fetchFunnelRunStatus = async () => {
    try {
      const token = await getAccessTokenSilently();
      const response = await api.get(`/funnel-run-status/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setFunnelRunStatus(response.data.status);
    } catch (err) {
      console.error('Error fetching funnel run status:', err);
    }
  };

  const fetchOrgs = async () => {
    try {
      setOrgsLoading(true);
      const token = await getAccessTokenSilently();
      const response = await api.get(`/funnels/${id}/orgs`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setOrgs(response.data.orgs || { filtered: [], searched: [] });
    } catch (err) {
      console.error('Error fetching orgs');
    } finally {
      setOrgsLoading(false);
    }
  };

  const fetchStages = async (highlight = false) => {
    try {
      const token = await getAccessTokenSilently();
      const response = await api.get(`/funnels/${id}/stages`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setStages(response.data.stages);
      setStageLoadAttempted(true);

      if (highlight) {
        setHighlightStages(true);
        setTimeout(() => {
          setHighlightStages(false);
        }, 2000); // highlight for 2 seconds
      }
    } catch (err) {
      console.error('Error fetching stages');
    }
  };

  const runFunnel = async (maxCompanies, start_from) => {
    try {
      setFunnelRunStatus('processing');
      const token = await getAccessTokenSilently();
      const body = { funnel_id: id, max_companies: maxCompanies, start_from };
      await api.post('/run-funnel', body, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      fetchStages();
    } catch (error) {
      console.error('Error running funnel', error);
    } finally {
      fetchFunnelRunStatus();
      fetchFunnel();
    }
  };

  const startOrStop = async () => {
    if (funnelRunStatus !== 'processing') {
      setShowRunModal(true); // Open the modal before starting the funnel
    } else {
      const token = await getAccessTokenSilently();
      api
        .post(
          '/stop-funnel',
          { funnel_id: id },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          },
        )
        .then(
          () => fetchFunnelRunStatus(),
          () => fetchFunnel(),
        );
    }
  };

  const newResearchValueHandler = (data) => {
    const {
      research_point_id,
      known_org_id,
      value,
      confidence,
      value_context,
    } = data;

    if (!research_point_id || !known_org_id) {
      console.error(
        'Unable to update funnel without research_point_id or known_org_id',
      );
      return;
    }

    const updateResearchPoints = (orgs) => {
      const updatedOrgs = [...orgs]; // Shallow copy first level array

      const orgIndex = updatedOrgs.findIndex(
        (org) => org.known_org_id === known_org_id,
      );
      if (orgIndex === -1) {
        return updatedOrgs;
      }

      const org = updatedOrgs[orgIndex];
      const researchPoints = Array.isArray(org.research_points)
        ? org.research_points
        : [];

      const researchPointIndex = researchPoints.findIndex(
        (rp) => rp.id === research_point_id,
      );

      if (researchPointIndex === -1) {
        // Add new research point
        researchPoints.push({
          id: research_point_id,
          value,
          confidence,
          value_context,
        });
      } else {
        // Update existing research point
        researchPoints[researchPointIndex] = {
          id: research_point_id,
          value,
          confidence,
          value_context,
        };
      }

      updatedOrgs[orgIndex] = {
        ...org,
        research_points: researchPoints,
      };

      return updatedOrgs;
    };

    setOrgs((prevOrgs) => ({
      filtered: updateResearchPoints(prevOrgs.filtered),
      searched: updateResearchPoints(prevOrgs.searched),
    }));
  };

  // Set up Pusher event bindings
  useEffect(() => {
    if (pusherChannel && id) {
      const funnelStatusUpdateHandler = async (data) => {
        if (data && data.funnel_id === parseInt(id, 10)) {
          await fetchFunnelRunStatus();
        }
      };

      const newFilteredOrgHandler = async (data) => {
        const { funnel_id, filtered_org } = data;
        if (funnel_id === parseInt(id, 10)) {
          setOrgs((prevOrgs) => {
            const withAnimation = {
              ...filtered_org,
              animate_in: true,
            };
            return {
              ...prevOrgs,
              filtered: [withAnimation, ...prevOrgs.filtered],
            };
          });
        }
      };

      const newSearchedOrgHandler = async (data) => {
        const { funnel_id, searched_org } = data;
        if (funnel_id === parseInt(id, 10)) {
          setOrgs((prevOrgs) => {
            const withAnimation = {
              ...searched_org,
              animate_in: true,
            };
            return {
              ...prevOrgs,
              searched: [withAnimation, ...prevOrgs.searched],
            };
          });
        }
      };

      pusherChannel.bind('funnel_status_update', funnelStatusUpdateHandler);
      pusherChannel.bind('new_filtered_org', newFilteredOrgHandler);
      pusherChannel.bind('new_searched_org', newSearchedOrgHandler);
      pusherChannel.bind('analyst_value_update', newResearchValueHandler);

      // Unbind events on cleanup
      return () => {
        pusherChannel.unbind('funnel_status_update', funnelStatusUpdateHandler);
        pusherChannel.unbind('new_filtered_org', newFilteredOrgHandler);
        pusherChannel.unbind('new_searched_org', newSearchedOrgHandler);
        pusherChannel.unbind('analyst_value_update', newResearchValueHandler);
      };
    }
  }, [pusherChannel, id, getAccessTokenSilently]);

  const fetchResearchPoints = async () => {
    try {
      const token = await getAccessTokenSilently();

      const response = await api.get(`/funnels/${id}/research-points`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setResearchPoints(response.data);
    } catch (err) {
      console.error('Error fetching datapoints for the funnel:', err);
    }
  };

  const updateDatapoints = async (selectedDatapointIds) => {
    try {
      const token = await getAccessTokenSilently();
      await api.put(
        `/funnels/${id}/research-points`,
        { research_point_ids: selectedDatapointIds, type: researchPointType },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      );
      fetchResearchPoints();
    } catch (error) {
      console.error('Error updating datapoints:', error);
    }
  };

  const handleDatapointsUpdate = (selectedDatapoints, { remove, add }) => {
    const selectedDatapointIds = selectedDatapoints.map((dp) => dp.id);

    /*
      Optimistic updating
    */
    setResearchPoints((prevResearchPoints) => {
      // Remove the ones where ids are in remove and add the ones to the end where we don't have them
      const removeIds = new Set(remove.map((item) => item.id));
      const updatedResearchPoints = prevResearchPoints.filter(
        (point) => !removeIds.has(point.id),
      );

      // Add the new items to the end where we don't have them already
      add.forEach((item) => {
        if (!updatedResearchPoints.some((point) => point.id === item.id)) {
          updatedResearchPoints.push(item);
        }
      });

      return updatedResearchPoints;
    });

    setShowResearchPointChooser(false);
    updateDatapoints(selectedDatapointIds);
    setShowResearchPointSelectorModal(false);
  };

  useEffect(() => {
    if (funnelLoadAttempted && stagesLoadAttempted) {
      setFunnelLoading(false);
    }
  }, [funnelLoadAttempted, stagesLoadAttempted]);

  useEffect(() => {
    setFunnelLoadAttempted(false);
    setStageLoadAttempted(false);

    fetchFunnel();
    fetchOrgs();
    fetchStages();
    fetchFunnelRunStatus();
    fetchResearchPoints();
  }, [id, getAccessTokenSilently]);

  // Define extra details display function
  const getExtraDetails = (stageName, type, metadata) => {
    const stageConfig = functionManifests[stageName];
    if (stageConfig) {
      const typeConfig = stageConfig.find((item) => item.type === type);
      if (
        typeConfig &&
        typeConfig.display &&
        typeConfig.display.how === 'text'
      ) {
        const metadataField = typeConfig.display.show;
        return metadata[metadataField];
      }
      if (
        typeConfig &&
        typeConfig.display &&
        typeConfig.display.how === 'array'
      ) {
        const metadataField = typeConfig.display.show;
        return metadata[metadataField].join(', ');
      }
      if (
        typeConfig &&
        typeConfig.display &&
        typeof typeConfig.display.how === 'function'
      ) {
        const metadataField = typeConfig.display.show;
        return typeConfig.display.how(metadata[metadataField]);
      }
    }
    return null;
  };

  const isEffectivelyEmpty = (stageName) => {
    if (!stages[stageName]) return true;
    if (stages[stageName].length === 0) return true;
    const stageConfig = functionManifests[stageName];
    const typeConfig = stageConfig.find(
      (item) => item.type === stages[stageName][0].type,
    );

    if (stages[stageName].length > 1) {
      return false;
    }
    return typeConfig.hide_from_selection;
  };

  // Define extra details display function
  const getNameForStageType = (stageName, type) => {
    const stageConfig = functionManifests[stageName];
    if (stageConfig) {
      const typeConfig = stageConfig.find((item) => item.type === type);
      if (typeConfig) return typeConfig.name;
      return type;
    }
    return null;
  };

  const getShouldHide = (stageName, type) => {
    const stageConfig = functionManifests[stageName];
    if (stageConfig) {
      const typeConfig = stageConfig.find((item) => item.type === type);
      if (typeConfig) return typeConfig.hide_from_selection;
      return type;
    }
    return true;
  };

  const polite = {
    company_search: 'Source of companies',
    company_filter: 'Company Filters',
  };

  const isImport = () => {
    if (!stages?.company_search) return true;
    const stageConfig = functionManifests.company_search;
    if (stageConfig) {
      const typeConfig = stageConfig.find(
        (item) => item.type === stages.company_search[0].type,
      );
      if (typeConfig) return typeConfig.import;
    }
    return true;
  };

  const clickableClass = (type) => {
    if (currentOrgView === 'filtered') {
      return type === 'filtered' ? 'active' : 'inactive';
    } else {
      return type === 'searched' ? 'active' : 'inactive';
    }
  };

  if (funnelLoading) {
    return (
      <div className='page-wrapper'>
        <div className='funnel-view-page page-inner-wrapper funnel'>
          <div className='page-header-area'>
            <div className='title-and-text'>
              <div className='breadcrumbs'>
                <div className='set'>
                  <Link to='/funnel'>
                    <FontAwesomeIcon icon={faArrowLeft} />
                    Back to List Builder
                  </Link>
                </div>
              </div>
              <h3>{funnel?.funnel.name || 'Your List'}</h3>
            </div>
          </div>
          <div className='page-split'>
            <div className='lower-loading-area funnel-loading-area'>
              <Spinner />
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className='page-wrapper'>
      <div className='funnel-view-page page-inner-wrapper funnel'>
        <div className={`page-header-area ${fullscreen ? 'retreated' : ''}`}>
          <div className='title-and-text'>
            <div className='breadcrumbs'>
              <div className='set'>
                <Link to='/funnel'>
                  <FontAwesomeIcon icon={faArrowLeft} />
                  Back to List Builder
                </Link>
              </div>
            </div>
            <h3>{funnel?.funnel.name}</h3>
          </div>
          {!isImport() && (
            <div className='buttons-area'>
              <Button variant='primary' onClick={() => startOrStop()}>
                <span>
                  {funnelRunStatus === 'processing'
                    ? 'Sourcing in progress'
                    : 'Start sourcing'}
                </span>
                {funnelRunStatus === 'processing' ? (
                  <Spinner size='sm' />
                ) : (
                  <FontAwesomeIcon icon={faPlay} />
                )}
              </Button>
            </div>
          )}
        </div>
        <div className='page-split'>
          <div className='main-area'>
            <Agent funnelId={id} embedded onClick={() => startOrStop()} />
            <div className={`stages ${highlightStages ? 'highlight' : ''}`}>
              {Object.keys(stages || {})
                .filter((stageName) => !isEffectivelyEmpty(stageName))
                .map((stageName) => (
                  <>
                    <div key={stageName} className='stage-holder'>
                      <div className='stage'>
                        {(stages[stageName] || [])
                          .map((entry, index) => ({
                            ...entry,
                            originalIndex: index,
                          }))
                          .filter(
                            (entry) => !getShouldHide(stageName, entry.type),
                          )
                          .map((entry, index) => {
                            const extraDetails = getExtraDetails(
                              stageName,
                              entry.type,
                              entry.metadata,
                            );
                            const originalIndex = entry.originalIndex;

                            return (
                              <div
                                key={index}
                                className={`stage-entry ${stages[stageName][originalIndex]?.active || stageName === 'company_search' ? 'active' : 'disabled'}`}
                              >
                                <div className='set'>
                                  <p className='description'>
                                    {extraDetails
                                      ? getNameForStageType(
                                          stageName,
                                          entry.type,
                                        )
                                      : polite[stageName]}
                                  </p>
                                  <b>
                                    {extraDetails
                                      ? extraDetails
                                      : getNameForStageType(
                                          stageName,
                                          entry.type,
                                        )}
                                  </b>
                                </div>

                                {(stageName !== 'company_search' ||
                                  !isImport()) && (
                                  <div
                                    className='edit-line'
                                    onClick={() => {
                                      setEditModalStage(
                                        stages[stageName][originalIndex],
                                      );
                                      setShowEditModal(true);
                                    }}
                                  >
                                    <div className='edit-icon-wrapper'>
                                      <EditIcon />
                                    </div>
                                  </div>
                                )}
                              </div>
                            );
                          })}
                      </div>
                    </div>
                    {((stageName === 'company_search' &&
                      isEffectivelyEmpty('company_filter')) ||
                      stageName === 'company_filter') && (
                      <CreditCost
                        funnelId={id}
                        isEffectivelyEmpty={isEffectivelyEmpty}
                        stages={stages}
                        researchPointChanges={researchPoints}
                      />
                    )}
                  </>
                ))}
            </div>
            <div className='stats-header'>
              <div className='stats-left'>
                {isImport() && (
                  <>
                    <div className={`stats-area ${clickableClass('searched')}`}>
                      <p className='label'>Companies imported</p>
                      <p
                        className='value'
                        onClick={() => setCurrentOrgView('searched')}
                      >
                        {orgs?.searched?.length || '0'}
                      </p>
                    </div>
                    {!isEffectivelyEmpty('company_filter') && (
                      <div className='spacer-sorry-matt'></div>
                    )}
                    {!isEffectivelyEmpty('company_filter') && (
                      <div
                        className={`stats-area ${clickableClass('filtered')}`}
                      >
                        <p className='label'>Matched filter</p>
                        <p
                          className='value'
                          onClick={() => setCurrentOrgView('filtered')}
                        >
                          {orgs?.filtered?.length || '0'}
                        </p>
                      </div>
                    )}
                  </>
                )}

                {!isImport() && (
                  <>
                    {!isEffectivelyEmpty('company_filter') && (
                      <div
                        className={`stats-area ${clickableClass('searched')}`}
                      >
                        <p className='label'>Companies sourced</p>
                        <p
                          className='value'
                          onClick={() => setCurrentOrgView('searched')}
                        >
                          {orgs?.searched?.length || '0'}
                        </p>
                      </div>
                    )}
                    {!isEffectivelyEmpty('company_filter') && (
                      <div className='spacer-sorry-matt'></div>
                    )}
                    <div className={`stats-area ${clickableClass('filtered')}`}>
                      <p className='label'>
                        {isEffectivelyEmpty('company_filter')
                          ? 'Companies sourced'
                          : 'Matched filter'}
                      </p>
                      <p
                        className='value'
                        onClick={() => setCurrentOrgView('filtered')}
                      >
                        {orgs?.filtered?.length || '0'}
                      </p>
                    </div>
                  </>
                )}
              </div>
              <div className='stats-right funnel-button-area'>
                <Button
                  className={`full-screen-button ${fullscreen ? 'active' : 'inactive'}`}
                  onClick={() => toggleFullscreen(!fullscreen)}
                  variant='outline-primary'
                >
                  {fullscreen ? <MinimizeIcon /> : <MaximizeIcon />}
                </Button>
                {downloadData && (
                  <DownloadCSVButton
                    name={funnel?.funnel.name}
                    {...downloadData}
                  />
                )}
              </div>
            </div>
            <div className='center-area'>
              <OrgList
                orgs={orgs}
                orgsLoading={orgsLoading}
                refreshOrgs={() => {
                  fetchOrgs();
                }}
                funnelId={id}
                view={currentOrgView}
                isImport={isImport()}
                researchPointChanges={researchPoints}
                hasFilter={!isEffectivelyEmpty('company_filter')}
                refreshResearchPoints={() => fetchResearchPoints()}
                showResearchPointChooser={() =>
                  setShowResearchPointChooser(true)
                }
                setDownloadData={setDownloadData}
              />
            </div>
          </div>
        </div>
      </div>
      <CongratsModal
        show={showCongratsModal}
        onHide={() => setShowCongratsModal(false)}
        header='Nice! You’ve started your first autopilot agent!'
      >
        <>
          <p>Your agent is getting straight to work.</p>
          <p>If it needs your approval for anything it’ll let you know.</p>
        </>
      </CongratsModal>

      {showEditModal && (
        <StageEditModal
          show={showEditModal}
          onHide={() => setShowEditModal(false)}
          entry={editModalStage}
          refresh={fetchStages}
        />
      )}

      {showResearchPointSelectorModal && (
        <ResearchPointSelectorModal
          loading={false}
          onChoose={handleDatapointsUpdate}
          handleClose={() => setShowResearchPointSelectorModal(false)}
          initialResearchPoints={researchPoints}
          type={researchPointType}
          orgCount={orgs?.filtered?.length}
          show
        />
      )}

      {showResearchPointChooser && (
        <ResearchPointChooseModal
          onChoose={(choice) => {
            setResearchPointType(choice);
            setShowResearchPointSelectorModal(true);
          }}
          handleClose={() => setShowResearchPointChooser(false)}
          show
        />
      )}

      <FunnelRunModal
        show={showRunModal}
        handleClose={() => setShowRunModal(false)}
        handleRun={(maxCompanies) => runFunnel(maxCompanies)}
        hasFilter={stages.company_filter?.length > 0}
      />
    </div>
  );
}

export default Funnel;
