import React, { useState } from 'react';
import { Spinner, Alert } from 'react-bootstrap';
import { useAuth0 } from '@auth0/auth0-react';
import Step1 from './company-form-steps/Step1';
import Step2 from './company-form-steps/Step2';
import api from '../../api';

const CompanyForm = ({ setIsSubmitted }) => {
  const { getAccessTokenSilently } = useAuth0();
  const [formData, setFormData] = useState({
    user_name: '',
    name: '',
    size: '',
    how_did_you_find_us: '',
    how_did_you_find_us_other: '',
    use_case_type: '',
    use_case_description: '',
  });
  const [isLoading, setIsLoading] = useState(false);
  const [createError, setCreateError] = useState(null);
  const [step, setStep] = useState(1);

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleNext = () => setStep(2);
  const handlePrevious = () => setStep(1);

  const handleSubmit = async () => {
    setIsLoading(true);
    setCreateError(false);

    try {
      const token = await getAccessTokenSilently();
      const response = await api.post(
        '/create-company',
        {
          ...formData,
          timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      );

      if (response.status === 200) {
        setIsSubmitted(true);
      }
    } catch (error) {
      setCreateError(
        'An error occurred creating the company. Please try again.',
      );
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className='position-relative'>
      {!!createError !== false && <Alert variant='danger'>{createError}</Alert>}
      {isLoading && (
        <div className='overlay'>
          <Spinner animation='border' size='lg' />
        </div>
      )}
      {step === 1 && (
        <Step1
          formData={formData}
          handleChange={handleChange}
          handleNext={handleNext}
        />
      )}
      {step === 2 && (
        <Step2
          formData={formData}
          handleChange={handleChange}
          handlePrevious={handlePrevious}
          handleSubmit={handleSubmit}
        />
      )}
    </div>
  );
};

export default CompanyForm;
