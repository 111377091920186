import React, { createContext, useState, useEffect } from 'react';

const StatusContext = createContext();

const StatusProvider = ({ children }) => {
  const [statusUpdateTrigger, setStatusUpdateTrigger] = useState(0);

  const triggerStatusUpdate = () => {
    setStatusUpdateTrigger((prev) => prev + 1);
  };

  return (
    <StatusContext.Provider
      value={{ statusUpdateTrigger, triggerStatusUpdate }}
    >
      {children}
    </StatusContext.Provider>
  );
};

export { StatusContext, StatusProvider };
