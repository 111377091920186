import React from 'react';
import FakePic from '../../feed/items/FakePic';
import { ReactComponent as LinkedInIcon } from '../../../icons/linkedin.svg';
import { ReactComponent as BiographyIcon } from '../../../icons/biography.svg';
import { ReactComponent as AnalystIcon } from '../../../icons/analyst.svg';
import { ReactComponent as FeaturesIcon } from '../../../icons/features.svg';
import { ReactComponent as EditIcon } from '../../../icons/edit.svg';
import { ReactComponent as ConversationIcon } from '../../../icons/conversation.svg';
import { ReactComponent as SDRsIcon } from '../../../icons/sdrs.svg';

import './visual-loading.css';

const polite = {
  RESEARCH: 'Getting to know your prospect',
  MATCHING: 'Creating strategy',
  GENERATING: 'Loading relevant datapoints',
  WRITING: 'Drafting',
  SUPERVISING: 'Drafting',
  SUBJECT: 'Drafting',
};

const cardContents = {
  RESEARCHING: {
    icon: <LinkedInIcon />,
    text: 'Analysing Prospect LinkedIn',
  },
  BIOGRAPHY: {
    icon: <BiographyIcon />,
    text: 'Generating Biography',
  },
  PROFILE: {
    icon: <SDRsIcon />,
    text: 'Determining best sender',
  },
  ICP: {
    icon: <FeaturesIcon />,
    text: 'Matching ICP & features',
  },
  DATAPOINTS: {
    icon: <AnalystIcon />,
    text: 'Determining best datapoints',
  },
  WRITING: {
    icon: <EditIcon />,
    text: 'Writing a new email draft',
  },
  SUPERVISING: {
    icon: <ConversationIcon />,
    text: 'Getting feedback on email',
  },
  SUBJECT: {
    icon: <EditIcon />,
    text: 'Writing subject line',
  },
};

const VisualLoadingIndicator = ({
  visual,
  section,
  prospectImage,
  orgImage,
}) => {
  const bigId = section ? Object.keys(cardContents).indexOf(section) : 0;

  return (
    <div className='visual-loading-indicator'>
      <div className='title'>
        <div className='images'>
          <FakePic url={prospectImage} />
          <FakePic url={orgImage} />
        </div>
        <h3>{polite[visual] || 'Loading'}</h3>
      </div>
      <div className='activity-section'>
        {Object.entries(cardContents).map(([key, { icon, text }], index) => (
          <div
            key={key}
            className={`activity-card ${bigId === index ? 'center' : ''} ${bigId > index ? 'done' : ''}`}
            style={
              index === 0
                ? {
                    marginLeft: `calc(50% - ${bigId * 255}px - 127.5px)`,
                  }
                : {}
            }
          >
            <div className='icon-frame'>{icon}</div>
            <p>{text}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default VisualLoadingIndicator;
